<template>
  <div class="component-depth">
    <div v-if="showTitle" class="depth-header">
      {{ translateTitle("盘口") }}
    </div>
    <div class="depth-content">
      <div class="tab-content">
        <span
          class="tab-item"
          :class="activeItem == 1 ? 'active' : ''"
          @click="onItemClick(1)"
        >
          <img src="../../../assets/img/exchange/depth-1.png" alt="" />
        </span>
        <span
          class="tab-item"
          :class="activeItem == 2 ? 'active' : ''"
          @click="onItemClick(2)"
          ><img src="../../../assets/img/exchange/depth-2.png" alt=""
        /></span>

        <span
          class="tab-item"
          :class="activeItem == 3 ? 'active' : ''"
          @click="onItemClick(3)"
          ><img src="../../../assets/img/exchange/depth-3.png" alt=""
        /></span>
      </div>
      <ul class="depth-sub-title">
        <li class="time">{{ translateTitle("价格") }}({{ to_symbol_show }})</li>
        <li class="price">
          {{ translateTitle("数量") }}({{ from_symbol_show }})
        </li>
        <li class="num">{{ translateTitle("成交额") }}</li>
      </ul>
      <ul class="depth-sub-content" :class="sellContentClass">
        <li
          v-for="(item, index) in showListSell"
          v-bind:key="index"
          @click="onRowClick(item)"
        >
          <div class="depth-item">
            <div class="time sell DINPro">{{ item[0] }}</div>
            <div class="price DINPro">{{ formatterValue(item[1]) }}</div>
            <div class="num DINPro" v-if="item[1] == '--'">--</div>
            <div
              class="num DINPro"
              v-else-if="item[1] == '' || item[1] == null"
            ></div>
            <div class="num DINPro" v-else>
              {{ (item[0] * item[1]).toFixed(2) }}
            </div>
            <i
              v-if="
                showBg && Number(bigBuy) == Number(item[1]) && item[1] != null
              "
              class="bg-num"
              :style="{ width: 100 + '%' }"
            ></i>
            <i
              v-else-if="showBg && item[1] != null"
              class="bg-num"
              :style="{ width: (item[1] / totleBuy) * 100 + '%' }"
            ></i>
          </div>
        </li>
      </ul>
      <ul class="depth-sub-content-buy" :class="buyContentClass">
        <li class="buy-header">
          <div class="current-price DINPro" :class="colorClass">
            {{ marketInfoShow.last }}
            <span class="currencyPrice">
              <span style="margin-right: 5px">≈</span>
              <span class="fontStyle">
                {{ systemCurrencyMaps[systemCurrency] }}
              </span>
              {{ marketInfoShow.currency[systemCurrency] }}</span
            >
          </div>
          <div class="common-operate more-btn" @click="onMoreClick">
            {{ translateTitle("更多") }}
          </div>
        </li>
        <li
          v-for="(item, index) in showListBuy"
          @click="onRowClick(item)"
          v-bind:key="index"
        >
          <div class="depth-item">
            <div class="time DINPro">{{ item[0] }}</div>
            <div class="price DINPro">{{ formatterValue(item[1]) }}</div>
            <div class="num DINPro" v-if="item[1] == '--'">--</div>
            <div class="num DINPro" v-else>
              {{ (item[0] * item[1]).toFixed(2) }}
            </div>
            <i
              v-if="showBg && Number(bigSell) == Number(item[1])"
              class="bg-num"
              :style="{ width: 100 + '%' }"
            ></i>
            <i
              v-else-if="showBg && item[1] != null"
              class="bg-num"
              :style="{ width: (item[1] / totleSell) * 100 + '%' }"
            ></i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getNowTime } from "@/assets/js/getTime";
import Dispatcher from "@/assets/js/SocketDispatcher";
import { apiGetStaticTableInfo } from "@/model/settings";
import { systemCurrencyMaps } from "@/assets/js/keymap.js";
import BigNumber from "bignumber.js";
import commonMixin from "@/components/common/commonMixin.vue";
import { mapActions, mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  props: {
    depthList: {
      type: Object,
      default: function () {
        return {
          a: [],
          b: [],
        };
      },
    },
    swap_symbol_id: {
      type: [String, Number],
    },
    showTitle: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    marketList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    from_symbol: {
      type: [String, Number],
      default: function () {
        return "--";
      },
    },
    to_symbol: {
      type: [String, Number],
      default: function () {
        return "--";
      },
    },
  },
  data() {
    return {
      systemCurrencyMaps,
      pageLoading: false,
      colorClass: "",
      price_precision: 2,
      precisionValue: "0.01",
      activeItem: 1,
      deptLength: 12,
      showBg: true,
      options: [],
      tradePair: {},
    };
  },
  async created() {
    await this.getTradePairList();
    let value = sessionStorage.getItem("dept-item-active");
    if (value) {
      this.onItemClick(value);
    }
    for (const key in this.tradePair) {
      if (this.tradePair[key].symbol_id == this.swap_symbol_id) {
        this.price_precision = this.tradePair[key].price_precision;
      }
    }

    this.options = [
      {
        value: new BigNumber(1)
          .div(new BigNumber(Math.pow(10, this.price_precision)))
          .toNumber(),
      },
      {
        value: (1 / Math.pow(10, this.price_precision)) * 10,
      },
      {
        value: (1 / Math.pow(10, this.price_precision)) * 100,
      },
    ];
  },
  mounted() {},
  watch: {
    swap_symbol_id() {
      this.showBg = false;
      for (const key in this.tradePair) {
        if (this.tradePair[key].symbol_id == this.swap_symbol_id) {
          this.price_precision = this.tradePair[key].price_precision;
        }
      }
      setTimeout(() => {
        this.showBg = true;
      }, 300);
    },
    marketInfoShow: {
      handler(newValue, oldValue) {
        if (Number(newValue.last) - Number(oldValue.last) > 0) {
          this.colorClass = "green";
        } else if (Number(newValue.last) - Number(oldValue.last) < 0) {
          this.colorClass = "red";
        } else {
          this.colorClass = "";
        }
      },
      deep: true,
    },
  },
  methods: {
    routeSplicing,
    getNowTime,
    formatterValue(value) {
      if (value == "--") return "--";

      let unit = "";
      let resultNum = "";
      let data = 0;
      if (value >= 1000000000) {
        unit = "B";
        data = (value / 1000000000).toFixed(2);
      } else if (value >= 1000000) {
        unit = "M";
        data = (value / 1000000).toFixed(2);
      } else if (value >= 1000) {
        unit = "K";
        data = (value / 1000).toFixed(2);
      } else {
        unit = "";
        data = value;
      }
      let num = data + "";
      if (!unit) {
        return data;
      }

      let length = 4;
      if (num.includes(".")) {
        if (num.length < 5) {
          resultNum = num.padEnd(length + 1, "0");
        } else {
          let numArray = num.split(".");
          if (numArray[0].length >= 4) {
            resultNum = num.substring(0, length);
          } else {
            resultNum =
              numArray[0] +
              "." +
              numArray[1].substring(0, length - numArray[0].length);
          }
        }
      } else {
        if (num.length < length) {
          resultNum = (num + ".").padEnd(length + 1, "0");
        } else {
          resultNum = num.substring(0, length);
        }
      }
      return resultNum + unit;
    },
    onMoreClick() {
      let routeUrl = this.$router.resolve({
        path: "/orderbook/",
        query: {
          type: "exchange",
          symbol: `${this.from_symbol}_${this.to_symbol}`,
        },
      });
      window.location.href = routeUrl.href;
    },
    async getTradePairList() {
      const { status, data } = await apiGetStaticTableInfo(55);
      if (status == 200) {
        this.tradePair = data;
      }
    },
    ceilNum(d, prex = 2) {
      if (parseInt(d) == d) return d;
      let len = 1;
      let i = 0;
      while (parseInt(d) != d) {
        d = d * 10;
        len *= 10;
        i++;
      }
      let last = Math.abs(i <= prex ? 0 : d % Math.pow(10, i - prex));
      let d1 = d - last;
      if (last > 0) {
        d1 = d1 + Math.pow(10, i - prex);
      }
      d1 = d1 / len;
      return d1;
    },
    onPrecisionChange(value) {
      this.precisionValue = value;
    },
    onItemClick(value) {
      this.activeItem = value;
      sessionStorage.setItem("dept-item-active", value);
      if (value == 2) {
        this.deptLength = 25;
      } else {
        this.deptLength = 12;
      }
    },
    onRowClick(row) {
      Dispatcher.dispatchEvent("onPriceClick", row);
    },
  },

  computed: {
    ...mapGetters({
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    sellContentClass() {
      let className = "";
      if (this.activeItem == 1) {
        className = "";
      } else if (this.activeItem == 2) {
        className = "all";
      } else if (this.activeItem == 3) {
        className = "none";
      }
      return className;
    },
    buyContentClass() {
      let className = "";
      if (this.activeItem == 1) {
        className = "";
      } else if (this.activeItem == 2) {
        className = "none";
      } else if (this.activeItem == 3) {
        className = "all";
      }
      return className;
    },
    from_symbol_show() {
      return this.from_symbol.toUpperCase() || "--";
    },
    to_symbol_show() {
      return this.to_symbol.toUpperCase() || "--";
    },
    loading() {
      let val = 1;
      if (this.depthList instanceof Array && this.depthList.a[0][0] == "--") {
        val = 1;
      } else {
        val = 0;
      }
      return val;
    },

    bigBuy() {
      let tempList = this.depthList.a.slice(0, this.deptLength);
      let temp = [];
      if (this.depthList.a.length > 0) {
        temp = Number(this.depthList.a[0][1]);
        for (let i = 0; i < tempList.length - 1; i++) {
          temp =
            temp < Number(tempList[i + 1][1])
              ? Number(tempList[i + 1][1])
              : temp;
        }
      }
      return temp;
    },
    bigSell() {
      let tempList = this.depthList.b.slice(0, this.deptLength);
      let temp = [];
      if (this.depthList.b.length > 0) {
        Number(this.depthList.b[0][1]);
        for (let i = 0; i < tempList.length - 1; i++) {
          temp =
            temp < Number(tempList[i + 1][1])
              ? Number(tempList[i + 1][1])
              : temp;
        }
      }
      return temp;
    },
    totleBuy() {
      let temp = 0;
      this.depthList.a.slice(0, this.deptLength).forEach((ele) => {
        temp += Number(ele[1]);
      });
      return temp;
    },
    totleSell() {
      let temp = 0;
      this.depthList.b.slice(0, this.deptLength).forEach((ele) => {
        temp += Number(ele[1]);
      });
      return temp;
    },
    // a卖  b买
    showListSell() {
      let array = new Array(this.deptLength);
      if (this.depthList.a.length < this.deptLength) {
        array = this.depthList.a.slice(0, this.deptLength);
      } else {
        array = this.depthList.a.slice(0, this.deptLength);
      }
      // 翻转原数组
      for (let i = 0; i < array.length / 2; i++) {
        let temp = array[i];
        array[i] = array[array.length - 1 - i];
        array[array.length - 1 - i] = temp;
      }
      let tempLength = array.length;
      if (array.length < this.deptLength) {
        for (let index = 0; index < this.deptLength - tempLength; index++) {
          array.unshift([,]);
        }
      }
      return array;
    },
    showListBuy() {
      return this.depthList.b;
    },
    marketInfoShow() {
      let marketInfo = {
        last: "--",
        currency: { cny: "--" },
      };
      this.marketList.forEach((ele) => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    className() {
      let className = "";
      if (!this.marketInfoShow.price_change_percent) {
        className = "";
      } else if (this.marketInfoShow.price_change_percent > 0) {
        className = "green";
      } else if (this.marketInfoShow.price_change_percent < 0) {
        className = "red";
      }
      return className;
    },
  },
};
</script>
<style lang="scss">
.component-depth {
  user-select: none;
  height: 788px;
  .fontStyle {
    font-family: PingFang SC, robotoregular, Hiragino Sans GB, Heiti SC,
      Microsoft YaHei, WenQuanYi Micro Hei, Helvetica, Arial, monospace, serif !important;
  }
  .depth-header {
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    background-color: #f5f5f7;
    font-size: 14px;
    font-weight: 600;
    color: #1f2533;
    padding-left: 14px;
  }
  .depth-content {
    height: 713px;
    padding-top: 3px;
    .tab-content {
      height: 30px;
      line-height: 30px;
      margin-left: 8px;
      .tab-item {
        cursor: pointer;
        width: 22px;
        height: 22px;
        border-radius: 2px;
        border: solid 1px #e2e2e4;
        display: inline-block;
        margin: 6px 0px 8px 8px;
        &.active {
          border: solid 1px #3a7dff;
        }
      }
      .el-select {
        float: right;
        margin-right: 15px;
        .el-input__inner {
          height: 22px;
          line-height: 22px;
          margin-top: 4px;
          width: 102px;
        }
        .el-input__suffix {
          .el-input__icon {
            line-height: 30px;
          }
        }
      }
    }
    .depth-sub-title {
      padding: 0 13px 0 16px;
      display: flex;
      color: #7f8490;
      font-size: 12px;
      font-weight: 500;
      height: 36px;
      line-height: 36px;
      .time {
        width: 86px;
      }
      .price {
        width: 100.5px;
        text-align: right;
      }
      .num {
        width: 112.5px;
        text-align: right;
      }
    }
    .depth-sub-content {
      height: 311px;
      overflow: hidden;
      padding: 0 13px 0px 16px;
      cursor: pointer;
      &.all {
        height: 650px;
      }
      &.none {
        display: none;
      }
      .depth-item {
        width: 100%;
        position: relative;
        display: flex;
        .bg-num {
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          height: 100%;
          border-radius: 0.1rem;
          background-image: linear-gradient(to left, #c61b3d, #c61b3d 0%);
          opacity: 0.1;
        }
      }
      li {
        display: flex;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
        &:hover {
          background: #f5f5f7;
        }
        .time {
          width: 86px;
          color: #03ad79;
          &.sell {
            color: #c61b3d;
          }
        }
        .price {
          width: 100.5px;
          color: #354052;
          text-align: right;
        }
        .num {
          width: 112.5px;
          color: #354052;
          text-align: right;
        }
      }
    }
    .depth-sub-content-buy {
      // margin-top: 10px;
      height: 367px;
      overflow: hidden;
      padding: 0 13px 0 16px;
      cursor: pointer;
      &.all {
        height: 680px;
      }
      &.none {
        height: 30px;
      }
      .depth-item {
        width: 100%;
        position: relative;
        display: flex;
        .bg-num {
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          height: 100%;
          border-radius: 0.1rem;

          background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
          opacity: 0.1;
        }
      }
      .buy-header {
        height: fit-content;
        border-top: 1px solid #f1f2f5;
        border-bottom: 1px solid #f1f2f5;
        font-size: 16px;
        font-weight: bold;
        cursor: auto;
        user-select: none;
        &:hover {
          background: #fff;
        }
        .more-btn {
          font-size: 12px;
          line-height: 30px;
          min-width: 26px;
        }
        .current-price {
          color: #7f8490;
          line-height: 28px;
          .currencyPrice {
            line-height: 28px;
            display: inline-block;
            color: #7f8490;
            font-weight: normal;
            font-size: 12px;
            margin-left: 5px;
          }
          &.green {
            color: #03ad79;
          }
          &.red {
            color: #c61b3d;
          }
        }
      }
      li {
        display: flex;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
        justify-content: space-between;
        &:hover {
          background: #f5f5f7;
        }
        .time {
          width: 86px;
          color: #03ad79;
          line-height: 27px;
        }
        .price {
          width: 100.5px;
          line-height: 27px;
          color: #354052;
          text-align: right;
        }
        .num {
          width: 112.5px;
          color: #354052;
          text-align: right;
        }
      }
    }
  }
}
</style>
