<template>
  <div class="affiliate-program-content-page scrollbar-default">
    <div class="ap-content">
      <img src="@/assets/img/affiliate-program/banner.png" class="apc-banner" />
      <div class="apc-form">
        <div class="apc-title">
          {{ translateTitle("MaskEX Affiliate Program Application Form") }}
        </div>
        <div class="apc-notice">
          {{
            translateTitle(
              "Monetize your traffic and earn crypto commissions when you share MaskEX with your audiences. Content creators, influencers and platforms can earn commissions and special rewards on every trade."
            )
          }}
        </div>
        <div class="form-item">
          <p class="fi-label">{{ translateTitle("Name") }}:</p>
          <el-input v-model="programForm.name" placeholder=""></el-input>
        </div>
        <div class="form-item">
          <p class="fi-label">{{ translateTitle("Email Address") }}:</p>
          <el-input v-model="programForm.email" placeholder=""></el-input>
        </div>
        <div class="form-item">
          <p class="fi-label">{{ translateTitle("Contact Number") }}:</p>
          <el-input v-model="programForm.contact" placeholder="">
            <div class="area" slot="prefix">
              <el-input
                v-model="programForm.area"
                placeholder="+000"
              ></el-input>
              <div class="split"></div>
            </div>
          </el-input>
        </div>
        <div class="form-item">
          <p class="fi-label">{{ translateTitle("MaskEX UID") }}:</p>
          <el-input
            v-model="programForm.uid"
            :placeholder="
              translateTitle(
                'Please register if you don’t have MaskEX account.'
              )
            "
          >
            <div class="register" @click="goToRegister" slot="suffix">
              {{ translateTitle("Register") }}
            </div>
          </el-input>
        </div>
        <div class="form-item">
          <p class="fi-label">{{ translateTitle("Telegram ID") }}:</p>
          <el-input v-model="programForm.tid" placeholder=""></el-input>
        </div>
        <div class="form-item">
          <p class="fi-label">{{ translateTitle("Marketing Platform") }}:</p>
          <el-input v-model="programForm.marketing" placeholder=""></el-input>
        </div>
        <div class="form-item">
          <p class="fi-label mp">
            {{
              translateTitle(
                "Marketing Total number of followers on your marketing platform"
              )
            }}:
          </p>
          <div class="mp-item" @click="chooseMarketing(0)">
            <div class="selected" v-if="marketing == 0">
              <div class="s-active"></div>
            </div>
            <div class="un-selected" v-else></div>
            <span class="mpi-text">{{
              translateTitle("a. 1,000-10,000")
            }}</span>
          </div>
          <div class="mp-item" @click="chooseMarketing(1)">
            <div class="selected" v-if="marketing == 1">
              <div class="s-active"></div>
            </div>
            <div class="un-selected" v-else></div>
            <span class="mpi-text">{{
              translateTitle("b. 10,000-100,000")
            }}</span>
          </div>
          <div class="mp-item" @click="chooseMarketing(2)">
            <div class="selected" v-if="marketing == 2">
              <div class="s-active"></div>
            </div>
            <div class="un-selected" v-else></div>
            <span class="mpi-text">{{
              translateTitle("c. 100,000 and above")
            }}</span>
          </div>
        </div>
        <div class="submit-btn" @click="submit">
          {{ translateTitle("Submit") }}
        </div>
        <p class="notice-page">
          {{ translateTitle("Thank you for your registration!") }} <br />
          {{
            translateTitle(
              "Kindly ensure information filled above are correct. If you meet the qualification, we will contact within seven working days"
            )
          }}
          <br />
          {{
            translateTitle(
              "Please do not hesitate to contact us should you have any clarifications"
            )
          }}
          <br />
          {{ translateTitle("1. Email Assistance") }} –
          <a
            class="theme-color"
            href="mailto:marketing@maskex.com"
            target="_blank"
            >marketing@maskex.com</a
          >
          <br />
          {{ translateTitle("2. 24/7 Customer Support") }} –
          <a class="theme-color" href="https://www.maskex.com" target="_blank"
            >www.maskex.com</a
          >
          <br />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetStaticTableInfo } from "@/model/settings";
// import { getStaticCoinList as getCoinList } from "@/model/assets/index";
import { mapGetters } from "vuex";

// import visa from "@/assets/img/quickly-coins/visa.png";
// import mastercard from "@/assets/img/quickly-coins/mastercard.png";
// import moonpay from "@/assets/img/quickly-coins/Moonpay.png";
// import mercuryo from "@/assets/img/quickly-coins/mercuryo_n.png";
// import paxful from "@/assets/img/quickly-coins/Paxful.png";
// import transak from "@/assets/img/quickly-coins/Transak.png";
// import routelay from "@/assets/img/quickly-coins/Routelay.png";
// import itez from "@/assets/img/quickly-coins/itez.png";
// import alchemypay from "@/assets/img/quickly-coins/AlchemyPay.png";
// import wyre from "@/assets/img/quickly-coins/wyre.png";

// import ipay from "@/assets/img/quickly-coins/ipay.png";
// import scpa from "@/assets/img/quickly-coins/scpa.png";
// import choose_a from "@/assets/img/quickly-coins/m-choose-a.png";
// import choose from "@/assets/img/quickly-coins/m-choose.png";
// import * as Account from "@/model/user/account";
import commonMixin from "@/components/common/commonMixin.vue";
// import Recharge from "@/pages/quicklycoins/recharge.vue";
// import CryptoJS from "crypto-js/crypto-js";
// import {
//   apiGetFinanceAddressList,
//   apiWyreUrl,
//   apiFetchOtcUrl,
// } from "@/model/assets/index";
import { apiAffiliateProgram } from "@/model/broker";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  // components: { Recharge },
  data() {
    return {
      // visa,
      // mastercard,
      // ipay,
      // scpa,
      // moonpay,
      // mercuryo,
      // paxful,
      // transak,
      // routelay,
      // itez,
      // alchemypay,
      // wyre,
      // active: 1,
      // checked: false,
      // spendValue: "",
      // currencyList: [],
      // thirdPaymentMap: [],
      // otcThirdPaymentMap: [],
      // coinData: {},
      // coinBuyAvailable: [],
      // thirdPaymentArray: [],
      // thirdPaymentShowArray: [],
      // currencyCurr: "",
      // coinCurr: "",
      // radioCurr: "",
      // addressCurr: "",
      // rowCount: 0,
      // choose_a,
      // choose,
      marketing: 0,
      programForm: {
        name: "",
        email: "",
        contact: "",
        uid: "",
        tid: "",
        marketing: "",
        area: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language",
    }),
  },
  watch: {
    // currencyCurr(val) {
    //   let tempArray = [];
    //   if (val.logic_name.toUpperCase() != "USD") {
    //     this.thirdPaymentArray.forEach(item => {
    //       if (item.name.toLowerCase() != "wyre") {
    //         tempArray.push(item);
    //       }
    //     });
    //   } else {
    //     tempArray = this.thirdPaymentArray;
    //   }
    //   // tempArray = this.thirdPaymentArray;
    //   this.rowCount = Math.ceil(tempArray.length / 2);
    //   let index = null;
    //   for (let i = 0; i < tempArray.length; i++) {
    //     if (tempArray[i].name.toLowerCase() == "itez") {
    //       index = i;
    //       break;
    //     }
    //   }
    //   if (index !== null) {
    //     tempArray.unshift(...tempArray.splice(index, 1));
    //   }
    //   this.thirdPaymentShowArray = tempArray;
    // },
  },
  async created() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    routeSplicing,
    async submit() {
      let foll = "";
      if (this.marketing == 0) {
        foll = "1000-10000";
      } else if (this.marketing == 1) {
        foll = "10000-100000";
      } else if (this.marketing == 2) {
        foll = "100000-1000000";
      }
      const req = {
        name: this.programForm.name,
        email: this.programForm.email,
        mobile: this.programForm.area + "" + this.programForm.contact,
        telegram: this.programForm.tid,
        marketing_platform: this.programForm.marketing,
        followers: this.foll,
      };
      this.btnLoading = true;
      const { data, status } = await apiAffiliateProgram(req);
      if (status == 200) {
        this.$message(this.translateTitle("申请成功"), "success");
      }
      this.btnLoading = false;
    },

    chooseMarketing(index) {
      this.marketing = index;
    },
    goToRegister() {
      this.$router.push(this.routeSplicing(this.language, "register"));
    },
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     this.coinData = data;
    //   }
    // },
    // //7-static_currency-法币表
    // async getStaticCurrencyMap() {
    //   const { status, data } = await apiGetStaticTableInfo(7);
    //   if (status == 200) {
    //     this.currencyList = Object.values(JSON.parse(JSON.stringify(data)));
    //   }
    // },
    // async getStaticOtcThirdPaymentMap() {
    //   // const { status, data } = await apiGetStaticTableInfo(64);
    //   const { status, data } = await apiGetStaticTableInfo(69);
    //   if (status == 200) {
    //     this.otcThirdPaymentMap = Object.values(
    //       JSON.parse(JSON.stringify(data))
    //     );
    //   }
    // },
    // async getStaticThirdPaymentMap() {
    //   // const { status, data } = await apiGetStaticTableInfo(65);
    //   const { status, data } = await apiGetStaticTableInfo(68);
    //   if (status == 200) {
    //     this.thirdPaymentMap = Object.values(JSON.parse(JSON.stringify(data)));
    //     this.thirdPaymentMap.forEach(item => {
    //       item["img"] = this.$data[item.name.toLowerCase()];
    //     });
    //   }
    // },
    // changeActive(num) {
    //   this.active = num;
    // },
    // selectChange(val) {
    //   this.currencyCurr = val;
    // },
    // selectChangeCoin(val) {
    //   this.coinCurr = val;

    //   this.getAddress();
    //   let tempPaymentArray = [];
    //   this.coinCurr.payment.split(",").forEach(ele => {
    //     this.thirdPaymentMap.forEach(item => {
    //       if (ele == item.name) {
    //         tempPaymentArray.push(item);
    //       }
    //     });
    //   });
    //   tempPaymentArray.push({ name: "Wyre", img: this.wyre });
    //   this.thirdPaymentArray = tempPaymentArray;
    //   // this.radioCurr = this.thirdPaymentArray[0].name;
    //   this.rowCount = Math.ceil(tempPaymentArray.length / 2);
    //   this.thirdPaymentShowArray = tempPaymentArray;
    //   let index = null;
    //   for (let i = 0; i < this.thirdPaymentShowArray.length; i++) {
    //     if (this.thirdPaymentShowArray[i].name.toLowerCase() == "itez") {
    //       index = i;
    //       break;
    //     }
    //   }
    //   if (index !== null) {
    //     this.thirdPaymentShowArray.unshift(
    //       ...this.thirdPaymentShowArray.splice(index, 1)
    //     );
    //   }
    //   this.radioCurr = this.thirdPaymentShowArray[0].name;
    // },
    // async getAddress() {
    //   let params = {
    //     item_id: this.coinCurr.item_id,
    //     chain_tag_list: this.coinCurr.chain_name.toUpperCase(),
    //   };
    //   if (!this.userInfo) return;
    //   const { status, msg, data } = await apiGetFinanceAddressList(params);
    //   if (status == 200) {
    //     this.addressCurr = data[0]["address"];
    //   }
    // },
    // async doBuy() {
    //   if (!this.spendValue) {
    //     this.$message({
    //       message: this.translateTitle("请输入金额"),
    //       type: "warning",
    //       customClass: "zZindex",
    //     });
    //     return;
    //   }
    //   let url = await this.generateUrl(
    //     this.radioCurr,
    //     this.coinCurr.item_name.toUpperCase(),
    //     this.spendValue,
    //     this.currencyCurr.logic_name.toUpperCase(),
    //     this.addressCurr
    //   );
    //   window.open(url);
    // },
    // async generateUrl(paymentCode, symbol, amount, currency, address) {
    //   let resultUrl = "";
    //   if (paymentCode.toLowerCase() != "wyre") {
    //     const { status, data: dataUrl } = await apiFetchOtcUrl({
    //       payment_code: paymentCode.toLowerCase(),
    //       symbol: symbol,
    //       amount: amount,
    //       currency: currency,
    //       address: address,
    //     });
    //     if (status == 200) {
    //       resultUrl = dataUrl;
    //     }
    //   } else {
    //     const destCurrency =
    //       symbol.toUpperCase() == "BTC"
    //         ? "bitcoin:" + address
    //         : "ethereum:" + address;
    //     const { status, data } = await apiWyreUrl({
    //       sourceAmount: amount,
    //       sourceCurrency: currency,
    //       destCurrency: symbol,
    //       dest: destCurrency,
    //       country: "US",
    //     });
    //     if (status == 200) {
    //       resultUrl = data.url;
    //     }
    //   }
    //   return resultUrl;
    // },
  },
};
</script>
<style lang="scss">
.ar {
  .affiliate-program-content-page
    .quickly-rechange-center
    .quickly-rechange-center-content {
    .purchasing-price-text {
      direction: rtl;
      .step {
        margin-left: 6.6px;
      }
    }
    .checkbox-out .statement-checkbox {
      direction: rtl;
    }
    .statement {
      direction: rtl;
    }
  }
  .affiliate-program-content-page {
    .recharge-content {
      direction: rtl;
      .left-content {
        .step {
          margin-right: 0;
          margin-left: 6.6px;
        }
      }
      .right-content {
        margin-left: 0;
        margin-right: 60px;
      }
    }
    .bottom-content .purchasing-price-text {
      direction: rtl;

      .step {
        margin-right: 0;
        margin-left: 6.6px;
      }
    }
    .quickly-rechange-footer .quickly-rechange-footer-content {
      direction: rtl;
    }
  }
}
.affiliate-program-content-page {
  background-color: #f7f9fc;
  padding: 52px 0 73px;
  display: flex;
  justify-content: center;
  .ap-content {
    width: 800px;
    background-color: #fff;
    .apc-banner {
      width: 100%;
    }
    .apc-form {
      margin: 56px 60px 84px;
      color: #15192a;
      .apc-title {
        font-family: DINPro;
        font-size: 28px;
        font-weight: 500;
        // color: #15192a;
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
      }
      .apc-notice {
        font-family: DINPro;
        font-size: 15px;
        // color: #15192a;
        margin-bottom: 44px;
      }
      .form-item {
        margin-bottom: 20px;
        .fi-label {
          font-family: DINPro;
          font-size: 16px;
          margin-bottom: 7px;
        }
        .mp {
          margin-bottom: 20px;
        }
        .el-input {
          .el-input__inner {
            height: 36px;
            border-radius: 5px;
            border: solid 1px rgba(21, 25, 42, 0.16);
            &::placeholder {
              font-size: 14px;
              color: rgba(21, 25, 42, 0.2);
            }
          }
          .el-input__suffix {
            .el-input__suffix-inner {
              .register {
                cursor: pointer;
                height: 34px;
                padding: 0 20px;
                font-family: DINPro;
                font-size: 14px;
                color: #15beb4;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .el-input__prefix {
            left: 0px;
            .area {
              margin-top: 1px;
              margin-left: 1px;
              margin-right: 20px;
              width: 60px;
              display: flex;
              align-items: center;
              .el-input {
                .el-input__inner {
                  height: 34px;
                  padding-left: 15px;
                  padding-right: 0px;
                  border-width: 0;
                }
              }
              .split {
                width: 1px;
                height: 20px;
                flex-grow: 0;
                opacity: 0.2;
                background-color: #15192a;
              }
            }
          }
        }
        .el-input--prefix {
          .el-input__inner {
            padding-left: 70px;
          }
        }
        .mp-item {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .un-selected {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            flex-grow: 0;
            border: solid 1px rgba(21, 25, 42, 0.2);
          }
          .selected {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            flex-grow: 0;
            border: solid 1px rgba(21, 25, 42, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            .s-active {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              flex-grow: 0;
              background-color: #15beb4;
            }
          }
          .mpi-text {
            font-family: DINPro;
            font-size: 16px;
            color: #000;
            margin-left: 9px;
          }
        }
      }
      .submit-btn {
        margin-top: 36px;
        width: 160px;
        padding: 9.5px 52.5px;
        border-radius: 3px;
        background-color: #15beb4;
        font-family: DINPro;
        font-size: 16px;
        color: #fff;
      }
      .notice-page {
        margin-top: 76px;
        font-family: DINPro;
        font-size: 16px;
        color: #15192a;
        .theme-color {
          color: #15beb4;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
