import api from "@/assets/js/api";

import {
  VUE_APP_UCENTER,
  VUE_APP_SOTC,
  VUE_APP_APP,
} from "@/assets/js/stationSetting";

const detailsUrl =
  "https://top6688.zendesk.com/api/v2/help_center/zh-cn/sections/360005486458/articles";
// 获取全部国家
export function apiRecruitGetCountryList(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/Recruit/getCountryList`,
    data,
  });
}
// 获取全部职位
export function apiRecruitGetJobList(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/Recruit/getJobList`,
    data,
  });
}
// 获取岗位
export function apiRecruitGetIntroduceList(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/Recruit/getIntroduceList`,
    data,
  });
}
// 获取岗位详情
export function apiRecruitGetIntroduceInfo(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/Recruit/getIntroduceInfo`,
    data,
  });
}
// 用户建议
export function apiFormSubmitAdvice(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/Channel/FormSubmit/advice`,
    data,
  });
}

// 用户建议
export function apiBrokersProgram(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/Channel/FormSubmit/brokersProgram`,
    data,
  });
}
