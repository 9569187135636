<template>
  <div>
    <HxHomeContent v-if="isHx"></HxHomeContent>
    <MaskHomeContent v-if="isMask"></MaskHomeContent>
    <!-- <BvHomeContent v-if="is8v && darkMode == 2"></BvHomeContent>
    <HomeContent v-if="is8v && darkMode == 1"></HomeContent> -->
    <BvHomeContent v-if="is8v"></BvHomeContent>
    <!-- <BitdaHomeContent v-if="isbitda"></BitdaHomeContent> -->
    <!-- <BvHomeContent v-if="is8v"></BvHomeContent> -->
    <BitdaHomeContent v-if="isbitda"></BitdaHomeContent>
    <HomeContent v-if="isDefault"></HomeContent>
  </div>
</template>

<script>
import HomeContent from "./home-content.vue";
import MaskHomeContent from "./mask-home-content.vue";
import BvHomeContent from "./bv-home-content.vue";
// import BitdaHomeContent from "./bitda-home-content.vue";
import { mapActions, mapGetters } from "vuex";
// import BvHomeContent from "./bv-home-content.vue";
import BitdaHomeContent from "./bitda-home-content.vue";
import HxHomeContent from "./hx-home-content.vue";
import { VUE_APP_ID, VUE_UNION_STATION } from "@/assets/js/stationSetting";
export default {
  components: {
    HomeContent,
    MaskHomeContent,
    BvHomeContent,
    HxHomeContent,
    BitdaHomeContent,
  },

  data() {
    return {
      VUE_APP_ID,
      isMask: false,
      is8v: false,
      isbitda: false,
      isHx: false,
      isDefault: false,
    };
  },
  computed: {
    ...mapGetters({
      darkMode: "user/darkMode",
    }),
  },
  created() {
    if (VUE_UNION_STATION == "MaskEx") {
      this.isMask = true;
    } else if (VUE_UNION_STATION == "bitda") {
      this.isbitda = true;
    } else if (VUE_UNION_STATION == "8V") {
      this.is8v = true;
    } else if (VUE_UNION_STATION == "TopCredit") {
      this.isHx = true;
    } else {
      this.isDefault = true;
    }
  },
};
</script>

<style></style>
