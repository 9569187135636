<template>
  <div class="kyc-notice">
    <div class="header">
      <div class="header-content">
        {{ translateTitle("服务条款") }}
      </div>
    </div>
    <div class="notice-content" v>
      <div class="left-menu">
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-menu-item index="1">
            <span slot="title">{{ translateTitle("KYC政策") }}</span>
          </el-menu-item>
          <!-- <el-menu-item index="2">
            <span slot="title">导航二</span>
          </el-menu-item>
          <el-menu-item index="3">
            <span slot="title">导航三</span>
          </el-menu-item>
          <el-menu-item index="4">
            <span slot="title">导航四</span>
          </el-menu-item> -->
        </el-menu>
      </div>
      <div class="right-content" v-if="language == 'zh_CN'">
        <div class="title">反洗钱防制法(AML)与客户(KYC)政策</div>
        <div class="second-title">1- 介绍</div>
        <div class="content">
          <p>
            币达有限公司(“币达”或“公司”)旨在禁止、发现和积极开展防止洗钱和资助恐怖主义的活动，并承诺遵守所有相关的法律、法规和规章制度，不妥协任何上述非法活动。
          </p>
          <p>
            公司管理层致力于根据适用法律的反洗钱(“AML”)、反恐融资(“CFT”)合规，并高度重视揭露任何洗钱计划和/或恐怖主义融资活动。
          </p>
          <p>
            币达还要求其管理人员、雇员、介绍经纪人和附属公司遵守这些标准，以防止使用公司的产品和服务为目的进行洗钱和恐怖主义融资活动。
          </p>
        </div>
        <div class="second-title">2- 目的</div>
        <div class="content">
          <p>
            “AML, CFT &
            KYC政策”(“政策”)的目的是提供反洗钱(“AML”)、反恐融资(“CFT”)和了解您的客户(“KYC”)程序的指导，本公司遵循这些程序，完全遵守相关的AML和CTF法规。
          </p>
          <p>
            本政策适用于本公司所有管理人员、介绍经纪人、关联公司以及本公司提供的产品和服务。任何不遵守这些政策和程序的员工将受到严厉的纪律处分。
          </p>
        </div>
        <div class="second-title">3- 法律体制</div>
        <div class="content">
          <p>
            公司必须遵守有关防止洗钱和恐怖融资的适用法律的规定。这些法律的主要目的是界定对所有严重刑事犯罪所得收益的洗钱并将其定为刑事犯罪，目的是剥夺罪犯从其罪行中获得的利润。
          </p>
          <p>
            根据《反洗钱法》和《反恐融资法》，本公司有义务制定防止洗钱和恐怖主义融资活动的政策和程序。
          </p>
          <p>
            本公司实施的反洗钱和反恐程序是基于适用于圣文森特的反洗钱和反恐法律、金融行动特别工作组(FATF)的建议以及其他文件和信息。
          </p>
        </div>
        <div class="second-title">4- 定义</div>
        <div class="third-title">4.1- 洗钱</div>
        <div class="content V2">
          <p>
            洗钱是把从毒品走私或恐怖活动等严重犯罪活动中获得的巨额资金，制造出来源于合法来源的假象的过程。
          </p>
          <p>
            洗钱的过程包括三个步骤：安置、分层和整合。
          </p>
        </div>
        <div class="four-title">4.1.1- 安置</div>
        <div class="content V3">
          <p>
            安置是指以某种方式将“脏钱”(通过非法、犯罪手段获得的钱)引入金融体系的行为。
          </p>
          <p>
            洗钱的过程包括三个步骤：安置、分层和整合。
          </p>
        </div>
        <div class="four-title">4.1.2- 分层</div>
        <div class="content V3">
          <p>
            分层是通过一系列复杂的交易和复杂的簿记来隐藏资金来源的行为。
          </p>
        </div>
        <div class="four-title">4.1.3- 整合</div>
        <div class="content V3">
          <p>
            整合是指以据称合法的手段获取资金的行为。
          </p>
        </div>
        <div class="third-title">4.2- 恐怖主义融资</div>
        <div class="content V2">
          <p>
            恐怖主义融资(犯罪收益)是为恐怖主义分子或恐怖主义集团个人提供资金或财政支持的过程。
          </p>
          <p>
            恐怖分子，或恐怖组织，有促进或实施任何恐怖行动的目的或活动，可以涉及个人或团体。
          </p>
        </div>
        <div class="third-title">4.3- AML/CTF</div>
        <div class="content V2">
          <p>
            AML与CTF指的是“反洗钱和反恐融资”。
          </p>
        </div>
        <div class="four-title">4.3.1- 反洗钱</div>
        <div class="content V3">
          <p>
            反洗钱(“AML”)是指一套旨在阻止通过非法行为获取收入的程序、法律或法规。
          </p>
        </div>
        <div class="four-title">4.3.2- 打击恐怖主义融资</div>
        <div class="content V3">
          <p>
            反恐融资(“CTF”)是指旨在防止向恐怖分子个人或恐怖组织提供融资或资金支持的一套程序、法律或法规。
          </p>
        </div>
        <div class="third-title">4.4- 金融行动特别小组(FATF)</div>
        <div class="content V2">
          <p>
            反洗钱金融行动特别工作组(“FATF”)，法语又名financière(GAFI)组织，是1989年7月在巴黎召开的七国集团(G-7)峰会上成立的一个政府间组织，最初的目的是研究和制定打击洗钱的措施。
          </p>
          <p>
            2001年10月，FATF扩大了其任务范围，除洗钱外，还包括打击资助恐怖主义的努力。
          </p>
          <p>
            FATF的目标是为打击洗钱、资助恐怖主义和其他对国际金融体系完整性的相关威胁制定标准并促进有效执行法律、监管和业务措施。
          </p>
          <p>
            FATF从其成员国开始，监测各国在执行FATF建议方面的进展；审查洗钱和资助恐怖主义的技术和反措施；并在全球范围内促进FATF建议的通过和执行。
          </p>
          <p>
            FATF负责审查洗钱技术和趋势，审查已经在国家或国际一级采取的行动，并提出为打击洗钱仍需采取的措施。
          </p>
          <p>
            1990年4月，在成立不到一年后，FATF发表了一份报告，其中载有一套40项建议，目的是提供打击洗钱所需的全面行动计划。
          </p>
          <p>
            2001年，FATF的任务中增加了打击资助恐怖主义的标准的制定工作。
          </p>
          <p>
            2001年10月，FATF发布了八项特别建议，以处理恐怖主义融资问题。洗钱技术的持续发展导致金融行动特别工作组于2003年6月全面修订了FATF的标准。
          </p>
          <p>
            2004年10月，FATF发表了第九项特别建议，进一步加强打击洗钱和资助恐怖主义的商定国际标准——40+9建议。
          </p>
          <p>
            2012年2月，FATF完成了对其标准的彻底审查，并公布了修订后的FATF建议。这一修订旨在加强全球保障措施，并进一步保护金融体系的完整性，为各国政府提供更有力的工具，采取行动打击金融犯罪。它们已经扩大，以应对新的威胁，如资助大规模毁灭性武器的扩散，并在透明度方面更加明确，在腐败问题上更加严厉。关于资助恐怖主义的9项特别建议已与反洗钱措施充分结合起来。这就形成了一套更强有力、更清晰的标准。
          </p>
        </div>
        <div class="second-title">5- 程序</div>
        <div class="content ">
          <p>
            本公司通过的法律规定介绍了确保遵守与洗钱和恐怖主义融资活动有关的适用法律的程序和流程。
          </p>
        </div>
        <div class="third-title">5.1- 客户分类和识别程序</div>
        <div class="content V2">
          <p>
            公司采用了适用法律中与客户分类和识别以及尽职调查程序相关的所有要求，如下所述：
          </p>
        </div>
        <div class="four-title">5.1.1- 客户分类</div>
        <div class="content V3">
          <p>
            客户根据其风险状况分为三大类，如下所述：
          </p>
        </div>
        <div class="five-title">a- 低风险客户</div>
        <div class="content V4">
          <p>
            以下类型的客户被认为风险较低。应注意的是，公司应收集足够的信息，以确定客户是否有资格被归类为低风险客户：
          </p>
        </div>
        <div class="content V5">
          <p>
            i-
            位于另一国的信贷或金融机构，其要求高于或相当于公司监管机构规定的要求。
          </p>
          <p>
            ii -
            上市公司，其证券被允许在另一个国家的受监管市场上交易，其披露要求符合共同体法律。
          </p>
        </div>
        <div class="five-title">b- 正常风险客户</div>
        <div class="content V4">
          <p>
            所有不属于高风险或低风险类别的客户将被视为正常风险客户。
          </p>
        </div>
        <div class="five-title">c- 高风险客户</div>
        <div class="content V4">
          <p>
            根据以下条件将客户划分为高风险：
          </p>
        </div>
        <div class="content V5">
          <p>
            i- 非面对面客户
          </p>
          <p>
            ii- 以第三人名义开立的客户账户
          </p>
          <p>
            iii- 政治公众人物(“PEP”)账户
          </p>
          <p>
            iv- 通过互联网进行电子赌博/游戏
          </p>
          <p>
            v- 来自不充分适用金融行动特别工作组建议的国家的客户
          </p>
          <p>
            vi- 客户的性质需要更高的洗钱和恐怖融资风险
          </p>
          <p>
            vii- 本公司自行确定为此类客户的任何其他客户
          </p>
        </div>
        <div class="four-title">5.1.2- 客户识别（尽职调查）</div>
        <div class="five-title">a- 尽职调查条件</div>
        <div class="content V4">
          <p>
            客户识别和尽职调查程序适用于以下情况：
          </p>
        </div>
        <div class="content V5">
          <p>
            i- 建立业务关系。
          </p>
          <p>
            ii-有洗钱或恐怖融资嫌疑，不论交易金额大小。
          </p>
          <p>
            iii- 对先前获得的客户身份数据是否充分存在疑问。
          </p>
          <p>
            iv-
            客户在没有充分理由的情况下，未能或拒绝提交必要的数据和信息，以核实其身份和建立其经济状况。
          </p>
        </div>
        <div class="five-title">b- 尽职调查时间</div>
        <div class="content V4">
          <p>
            客户识别和尽职调查程序适用于以下情况：
          </p>
        </div>
        <div class="content V5">
          <p>
            i- 客户识别和尽职调查必须在企业成立之前进行关系或交易的进行。
          </p>
          <p>
            ii-
            在建立业务关系的过程中，如果有必要核实客户的身份，以避免妨碍正常的业务活动，并且洗钱或资助恐怖主义的风险很小。在这种情况下，这些程序需要尽快完成。
          </p>
          <p>
            iii-
            必须定期对现有记录进行审查，从而确保所保存的文件、数据或信息是最新的。
          </p>
          <p>
            iv-
            客户尽职调查程序不仅应适用于所有新客户，还应在适当的时间以风险敏感为基础对现有客户适用。
          </p>
          <p>
            v- 当一个客户的账户被打开时，它应该被密切监视。
          </p>
          <p>
            vi-
            每年至少进行两次评审，并准备一份总结评审结果的说明，必须保存在客户的档案中。
          </p>
          <p>
            vii- 公司应经常将估计值与账户实际营业额进行比较。
          </p>
          <p>
            viii-
            应调查任何严重的偏差，这不仅是为了公司可能采取的与特定账户有关的行动，而且也是为了评估介绍客户的个人或实体的可靠性。
          </p>
        </div>
        <div class="five-title">c- 尽职调查程序</div>
        <div class="content V4">
          <p>
            本公司为遵守法律对客户身份识别的要求而遵循的做法是基于风险的方法实现的，具体如下：
          </p>
        </div>
        <div class="content V5">
          <p>
            正常客户尽职调查程序
          </p>
        </div>
        <div class="content V6">
          <p>
            - 根据从可靠和独立来源获得的信息，识别客户并核实客户身份。
          </p>
          <p>
            - 对于法人，采取基于风险的适当措施，了解客户的所有权和控制结构。
          </p>
          <p>
            - 获取有关业务关系的目的和预期性质的信息。
          </p>
          <p>
            -
            对业务关系进行持续监控，包括审查整个关系过程中未发生的交易，以确保所进行的交易与公司持有的与客户有关的数据和信息一致。
          </p>
        </div>
        <div class="content V5">
          <p>
            ii-
            简化客户尽职调查程序 低风险客户可采用简化程序。这些措施应在不存在洗钱嫌疑的情况下适用，无论是否有任何减损、豁免或门坎，也不应在建立业务关系时适用。
          </p>
          <p>
            iii- 强化客户尽职调查程序
          </p>
        </div>
        <div class="content V5">
          <p>
            本公司应在性质上可能存在洗钱或恐怖融资高风险的情况下采取强化客户尽职调查措施。
          </p>
          <p>
            公司应采取具体、充分的措施，对高风险进行补偿，采取下列一种或多种措施：
          </p>
        </div>
        <div class="content V6">
          <p>
            - 根据从可靠和独立来源获得的信息，识别客户并核实客户身份。
          </p>
          <p>
            - 对于法人，采取基于风险的适当措施，了解客户的所有权和控制结构。
          </p>
          <p>
            - 获取有关业务关系的目的和预期性质的信息。
          </p>
          <p>
            -
            对业务关系进行持续监控，包括审查整个关系过程中未发生的交易，以确保所进行的交易与公司持有的与客户有关的数据和信息一致。
          </p>
        </div>
        <div class="five-title">d- 验证程序</div>
        <div class="content V4">
          <p>
            在建立业务关系期间，为了核实客户的身份，公司将遵循以下验证程序：
          </p>
        </div>
        <div class="five-title">d- 验证程序</div>
        <div class="content V4">
          <p>
            在建立业务关系期间，为了核实客户的身份，公司将遵循以下验证程序：
          </p>
        </div>
        <div class="content V5">
          <p>
            i-
            公司将确保在建立业务关系之前，始终进行经济概况的构建、适当性评估和适宜性评估。
          </p>
          <p>
            ii-
            向客户提供十五(15)天的宽限期，以便向公司提供其身份证明文件；在15天的宽限期内，公司应确保：
          </p>
        </div>
        <div class="content V6">
          <p>- 累计存入金额不超过2000美元。</p>
          <p>
            - 资金只能来自银行账户或通过与客户名义下的银行账户挂钩的其他方式。
          </p>
          <p>
            - 将向要求提供客户身份证明文件的客户发送通知/提醒电子邮件。
          </p>
          <p>
            - 在核查程序未在期限结束后结束的情况下关闭账户。
          </p>
          <p>
            - 公司不得截留客户资金，除非有洗钱嫌疑，不得冻结账户。
          </p>
        </div>
        <div class="five-title">e- 其他尽职调查相关事项</div>
        <div class="content V6">
          <p>
            i- 政治人物
          </p>
          <p>
            -
            政治公众人物(“PEP”)是指在国外担任重要公共职能的个人，而“亲密伙伴”是指与政治公众人物有密切关系的人。
          </p>
          <p>
            -
            公司应采取以下额外的尽职调查措施，以确定潜在客户是否为政治风险人物：
          </p>
          <p>
            - 在与客户建立业务关系之前获得高级管理层的特别批准。
          </p>
          <p>
            - 采取适当措施确定与建立业务关系或交易有关的客户资产来源和资金来源
          </p>
          <p>
            -加强和持续监控业务关系。
          </p>
        </div>
        <div class="content V5">
          <p>
            ii- 匿名或编号账户
          </p>

          <p>
            公司被禁止持有匿名或编号账户。此外，公司应特别注意可能因匿名产品或交易而产生的洗钱或恐怖融资威胁，并采取措施防止其用于洗钱或恐怖融资目的。
          </p>
          <p>
            iii- 第三方尽职调查
          </p>

          <p>
            本公司获准并可依赖第三方满足客户的尽职调查要求。然而，在这种情况下，满足这些要求的最终责任应由公司承担，公司依赖于上述各方。
          </p>
        </div>
        <div class="third-title">5.2- 客户帐户开设和KYC文件程序</div>
        <div class="content V2">
          <p>
            在接受新客户之前，公司应要求这些客户提供一定的信息和身份证明文件。
          </p>
        </div>
        <div class="four-title">5.2.1- 账户开设</div>
        <div class="content V3">
          <p>
            a- 开户所需资料
          </p>
          <p>
            所有有意在本公司开设账户的客户，请提供下列数据：
          </p>
        </div>
        <div class="content V4">
          <p>
            i- 客户的个人信息
          </p>
          <p>
            ii- 客户的经济概况结构
          </p>
          <p>
            iii- 客户的适当性/适合性评估
          </p>
        </div>
        <div class="content V3">
          <p>
            显然，对客户的识别，包括经济概况的结构和适当性/适合性评估，应在与客户建立业务关系之前进行。
          </p>
        </div>
        <div class="content V3">
          <p>
            b- 开户手续
          </p>
          <p>
            i- 客户完成帐户开户表格，填写所有必需的信息。
          </p>
          <p>
            ii-负责管理人收集客户的所有初始信息，并将其直接转发给高层管理人员，以及反洗钱合规官进行审查和批准。
          </p>
          <p>
            iii-
            审批通过后，管理员将所有必要的信息录入公司软件系统，并与相关部门沟通。
          </p>
        </div>
        <div class="four-title">5.2.2- KYC文件</div>
        <div class="content V3">
          <p>
            在接受新客户并允许其与本公司进行交易之前，应获取以下文件以核实客户的身份：
          </p>
          <p>
            a- 自然人
          </p>
          <p>
            为了有效地实施公司的KYC程序，需要自然人(个人客户)提供的身份证明文件如下：
          </p>
        </div>
        <div class="content V4">
          <p>
            i- 身份证明
          </p>
          <p>
            政府签发的有效身份证明(护照，身份证，驾照……)，其中应包括客户的全名，客户的出生日期，客户的照片，和有效状态(有效期和/或签发日期+有效期)
          </p>
          <p>
            ii- 住所证明
          </p>
          <p>
            本人姓名的最近家庭住址证明(银行对账单、水电账单、电话账单等)，其中应包括客户的全名、家庭住址和出具日期(不得超过6个月)。
          </p>
        </div>
        <div class="content V3">
          <p>
            b- 法人
          </p>
          <p>
            对在公司开户感兴趣的法人(公司客户)要遵循不同的身份识别程序。这些文件要求如下：
          </p>
        </div>
        <div class="content V4">
          <p>
            i- 公司成立文件
          </p>
          <p>
            公司文件的形式和名称可能因公司成立国家和/或法律形式的不同而有所不同。然而，要求由政府签发的公司文件应包括公司名称、成立日期和地点、注册办公地址、董事和授权签署人、所有权/股权结构(股东姓名和持股比例)、公司注册活动。
          </p>
          <p>
            这些文件可能包括但不限于：公司证书或注册证书、注册办公室证书、董事和秘书证书、注册股东证书、公司章程大纲和章程细则。
          </p>
          <p>
            ii- 董事及实益所有人文件
          </p>
          <p>
            个人KYC和身份证明文件必须来自：
          </p>
          <p>
            - 法人董事
          </p>
          <p>
            - 拥有10%以上实益所有权的法人最终受益所有人。
          </p>
          <p>
            这些身份证明文件包括身份证明和居住证明。
          </p>
          <p>
            iii- 董事会决议
          </p>
          <p>
            法人董事会关于开户并授予经营者权限的决议。
          </p>
        </div>
        <div class="third-title">5.3- 记录保存程序</div>
        <div class="content V2">
          <p>
            公司应保存以下所列文件和信息，以便国家当局对可能的洗钱或恐怖融资进行调查或分析。
          </p>
          <p>
            除以硬拷贝形式保存的原始文件或其经核证无误的副本外，文件/数据的保留可采用其他形式，如电子形式，前提是公司能够及时检索相关文件/数据，并在任何时候将其提交给有关当局，在请求之后。如果文件/数据使用的是英语以外的语言，则随附真实译文。
          </p>
        </div>
        <div class="content V3">
          <p>
            a-
            客户的姓名和地址以及官方身份证明文件(如护照、身份证或驾驶执照)的副本或记录。
          </p>
          <p>
            b- 交易对手的名称和地址(或标识符)。
          </p>
          <p>
            c- 指示或权威的形式。
          </p>
          <p>
            d- 支付资金的账户详细信息。
          </p>
          <p>
            e- 企业向客户付款的形式和目的地。
          </p>
          <p>
            f- 商务信函。
          </p>
          <p>
            g-
            在客户与客户的业务关系结束至少5年的尽职调查后，需要一份证明文件副本。
          </p>
          <p>
            h-
            对于业务关系和交易，应提供交易完成或业务关系结束后至少五年内的支持证据和记录。
          </p>
        </div>
        <div class="third-title">5.4- 可疑交易报告</div>
        <div class="content V2">
          <p>
            可疑交易报告
          </p>
          <p>
            本公司确保随时保持足够的信息，并充分了解其客户的活动，以便及时发现一项或一系列交易不寻常或可疑。
          </p>
        </div>
        <div class="four-title">5.4.1- 可疑交易示例</div>
        <div class="content V3">
          <p>
            可能构成与洗钱和资助恐怖主义有关的可疑交易/活动的例子包括但不限于：
          </p>
        </div>
        <div class="content V3">
          <p>
            a- 没有明确目的或不必要的复杂交易。
          </p>
          <p>
            b-
            使用所有权结构复杂的公司或公司集团的外国账户，根据客户的需求和经济状况，这是不合理的。
          </p>
          <p>
            c- 客户要求的交易或交易规模不符合其通常的惯例和业务活动。
          </p>
          <p>
            d-
            当客户的商业活动不足以证明此类活动的正当性时，存入或贷记到账户的大量交易和/或资金。
          </p>
          <p>
            e- 电子商务关系只涉及一个交易或者持续时间很短。
          </p>
          <p>
            f- 客户使用特定金融机构的服务没有明显的正当理由。
          </p>
          <p>
            g- 在同一金融工具中经常发生交易，且无明显原因，且交易条件似乎异常。
          </p>
          <p>
            h-
            客户经常以现金结算的方式少量购买某一金融工具，然后在一次交易中出售该金融工具的总数量，以现金结算，或在客户的指示下，将收益转移到其通常账户以外的账户中。
          </p>
          <p>
            i-
            任何性质、规模或频率似乎不寻常的交易，例如取消订单，特别是在支付对价之后。
          </p>
          <p>
            j- 不符合市场现行条件的交易，尤其是与订单规模和频率有关的交易。
          </p>
          <p>
            k-
            任何交易的结算，但主要是大额现金交易和/或由与发出订单的客户不同的第三方进行交易结算。
          </p>
          <p>
            l-
            不适用或不充分适用FAFT关于洗钱和恐怖主义融资的建议的国家或地理区域之间的资金转移。
          </p>
          <p>
            m-
            客户在建立业务关系时不愿意提供完整的信息，包括其业务活动的性质和目的、预期的账户活动、先前与金融机构的关系、其高级职员和董事的姓名或其业务地点的信息。
          </p>
          <p>
            n-
            客户通常提供最少的或误导性的信息，这些信息很难或昂贵，公司无法核实。
          </p>
          <p>
            o- 提供不寻常或可疑的无法轻易核实的身份证明文件。
          </p>
          <p>
            p- 频繁或大额交易且没有过去或现在雇佣经验记录的客户。
          </p>
          <p>
            q-
            由外国金融机构或其国家或地理区域不适用或不适用FATF关于洗钱和恐怖融资的建议的第三方介绍的客户。
          </p>
          <p>
            r-
            参与现金交易的个人的共享地址，特别是当该地址也是一个营业地点和/或似乎与所述职业不符时(例如，学生、失业人士、自雇人士等)。
          </p>
          <p>
            s-
            涉及现金交易的个人共享地址，特别是当该地址同时是营业地点及/或似乎与所述职业(例如学生、失业人士、自雇人士等)不相符时。
          </p>
          <p>
            t- 客户所述职业与已执行交易的水平或规模不相称。
          </p>
          <p>
            u- 以限制公司董事会行使控制权的方式使用一般提名人文件。
          </p>
        </div>
        <div class="four-title">5.4.2- 可疑交易报告程序</div>
        <div class="content V3">
          <p>
            报告客户可疑交易的程序如下：
          </p>
        </div>
        <div class="content V3">
          <p>
            a- 公司不同部门员工的报告由反洗钱合规官评估。
          </p>
          <p>
            b- 如果认为有必要，合规官应通知相关洗钱当局。
          </p>
          <p>
            c-
            在提交可疑报告后，客户的相关账户以及任何其他相关账户均处于合规官的密切监控之下。
          </p>
          <p>
            d-
            在提交可疑报告后，公司遵守相关洗钱当局发出的任何指示，特别是关于是否继续或暂停特定交易或维持特定账户的活跃。
          </p>
          <p>
            e- 对重大偏差进行调查，并将结果记录在各自的客户档案中。
          </p>
          <p>
            f-
            对根据客户现有信息无法证明其正当性的交易进行彻底检查，以确定是否存在洗钱或恐怖融资嫌疑，以便向合规官提交内部报告，然后由合规官向相关洗钱当局提交报告。
          </p>
          <p>
            g-
            对不符合客户现有信息的交易进行彻底检查，以确定是否存在洗钱或恐怖主义融资的嫌疑。如果是这种情况，将分别向合规官员和相关洗钱机构提交报告。
          </p>
        </div>
        <div class="third-title">5.5- AML合规官每日/每月程序</div>
        <div class="content V2">
          <p>
            AML合规官每天/每月应遵循的程序如下：
          </p>
          <p>
            i.
            每天收到员工关于任何可疑交易的报告。本公司确保随时保持足够的信息，并充分了解其客户的活动，以便及时发现一项或一系列交易不寻常或可疑。
          </p>
          <p>
            ii. 审查提交的报告（如有）。
          </p>
          <p>
            iii. 通知高级管理层并提出采取以下任何行动的必要性。
          </p>
          <p>
            iv. 如果正在处理事务，请停止该事务。
          </p>
          <p>
            v. -通知客户取消交易的原因。
          </p>
          <p>
            vi. -收集事务的信息（如果已执行）。
          </p>
          <p>
            vii. 根据法律要求，向交易的有关当局报告。
          </p>
        </div>
        <div class="second-title">6- 人员教育培训</div>
        <div class="content ">
          <p>
            公司通过实施完善的员工教育和培训计划，确保其员工充分意识到其在防止洗钱和恐怖融资方面的法律义务。
          </p>
          <p>
            培训计划的目的是教育雇员了解防止洗钱和资助恐怖主义的最新发展，包括为此目的使用的实际方法和趋势。
          </p>
          <p>
            该培训计划确保公司员工充分意识到，对于未能报告有关洗钱或恐怖主义融资的信息或怀疑，他们可能负有个人责任。为各部门员工提供培训的时间和内容根据各部门的需要进行调整。
          </p>
          <p>
            培训的频率可根据法律和/或监管要求的修订、员工的职责以及财务系统的任何其他变化而变化。
          </p>
          <p>
            培训计划对新员工、现有员工和公司不同部门根据他们提供的服务有不同的结构。
          </p>
          <p>
            定期进行持续培训，以确保员工意识到他们的职责和责任，并随时了解任何新的发展情况。
          </p>
          <p>
            收集到的有关客户的任何个人信息，如姓名、地址、出生日期和联系方式等，将严格出于业务目的由币达保存。客户交易、护照复印件和地址证明等其他信息将保密，仅在我们的账户服务部门和合规部门之间共享。币达可能会另外询问客户的信用价值，这也会在我们的客户档案中保密。这些信息可以通过物理或电子方式保存，并有严格的访问程序。
          </p>
          <p>
            币达可能会与负责营销、后台和客户服务职能的内部部门或附属办公室共享客户信息，以完成正常的业务操作。因客户信息需要保密，币达内的每个员工都签署了保密协议。
          </p>
          <p>
            有关我们隐私政策的任何问题或其他信息，请联系我们的客户服务部：support@bitda.io
          </p>
        </div>
      </div>
      <div class="right-content" v-else-if="language == 'zh_HK'">
        <div class="title">反洗錢防制法(AML)與客戶(KYC)政策</div>
        <div class="second-title">1- 介绍</div>
        <div class="content">
          <p>
            幣達有限公司(“幣達”或“公司”)旨在禁止、發現和積極開展防止洗錢和資助恐怖主義的活動，並承諾遵守所有相關的法律、法規和規章制度，不妥協任何上述非法活動。
          </p>
          <p>
            公司管理層致力於根據適用法律的反洗錢(“AML”)、反恐融資(“CFT”)合規，並高度重視揭露任何洗錢計劃和/或恐怖主義融資活動。
          </p>
          <p>
            幣達還要求其管理人員、雇員、介紹經紀人和附屬公司遵守這些標準，以防止使用公司的產品和服務為目的進行洗錢和恐怖主義融資活動。
          </p>
        </div>
        <div class="second-title">2- 目的</div>
        <div class="content">
          <p>
            “AML, CFT &
            KYC政策”(“政策”)的目的是提供反洗錢(“AML”)、反恐融資(“CFT”)和了解您的客戶(“KYC”)程序的指導，本公司遵循這些程序，完全遵守相關的AML和CTF法規。
          </p>
          <p>
            本政策適用於本公司所有管理人員、介紹經紀人、關聯公司以及本公司提供的產品和服務。任何不遵守這些政策和程序的員工將受到嚴厲的紀律處分。
          </p>
        </div>
        <div class="second-title">3- 法律體制</div>
        <div class="content">
          <p>
            公司必須遵守有關防止洗錢和恐怖融資的適用法律的規定。這些法律的主要目的是界定對所有嚴重刑事犯罪所得收益的洗錢並將其定為刑事犯罪，目的是剝奪罪犯從其罪行中獲得的利潤。
          </p>
          <p>
            根據《反洗錢法》和《反恐融資法》，本公司有義務制定防止洗錢和恐怖主義融資活動的政策和程序。
          </p>
          <p>
            本公司實施的反洗錢和反恐程序是基於適用於聖文森特的反洗錢和反恐法律、金融行動特別工作組(FATF)的建議以及其他文件和信息。
          </p>
        </div>
        <div class="second-title">4- 定義</div>
        <div class="third-title">4.1- 洗錢</div>
        <div class="content V2">
          <p>
            洗錢是把從毒品走私或恐怖活動等嚴重犯罪活動中獲得的巨額資金，制造出來源於合法來源的假象的過程。
          </p>
          <p>
            洗錢的過程包括三個步驟：安置、分層和整合。
          </p>
        </div>
        <div class="four-title">4.1.1- 安置</div>
        <div class="content V3">
          <p>
            安置是指以某種方式將“髒錢”(通過非法、犯罪手段獲得的錢)引入金融體系的行為。
          </p>
        </div>
        <div class="four-title">4.1.2- 分层</div>
        <div class="content V3">
          <p>
            分層是通過一系列複雜的交易和複雜的簿記來隱藏資金來源的行為。
          </p>
        </div>
        <div class="four-title">4.1.3- 整合</div>
        <div class="content V3">
          <p>
            整合是指以據稱合法的手段獲取資金的行為。
          </p>
        </div>
        <div class="third-title">4.2- 恐怖主義融資</div>
        <div class="content V2">
          <p>
            恐怖主義融資(犯罪收益)是為恐怖主義分子或恐怖主義集團個人提供資金或財政支持的過程。
          </p>
          <p>
            恐怖分子，或恐怖組織，有促進或實施任何恐怖行動的目的或活動，可以涉及個人或團體。
          </p>
        </div>
        <div class="third-title">4.3- AML/CTF</div>
        <div class="content V2">
          <p>
            AML與CTF指的是“反洗錢和反恐融資”。
          </p>
        </div>
        <div class="four-title">4.3.1- 反洗錢</div>
        <div class="content V3">
          <p>
            反洗錢(“AML”)是指一套旨在阻止通過非法行為獲取收入的程序、法律或法規。
          </p>
        </div>
        <div class="four-title">4.3.2- 打擊恐怖主義融資</div>
        <div class="content V3">
          <p>
            反恐融資(“CTF”)是指旨在防止向恐怖分子個人或恐怖組織提供融資或資金支持的一套程序、法律或法規。
          </p>
        </div>
        <div class="third-title">4.4- 金融行動特別小組(FATF)</div>
        <div class="content V2">
          <p>
            反洗錢金融行動特別工作組(“FATF”)，法語又名financière(GAFI)組織，是1989年7月在巴黎召開的七國集團(G-7)峰會上成立的一個政府間組織，最初的目的是研究和制定打擊洗錢的措施。
          </p>
          <p>
            2001年10月，FATF擴大了其任務範圍，除洗錢外，還包括打擊資助恐怖主義的努力。
          </p>
          <p>
            FATF的目標是為打擊洗錢、資助恐怖主義和其他對國際金融體系完整性的相關威脅制定標准並促進有效執行法律、監管和業務措施。
          </p>
          <p>
            FATF從其成員國開始，監測各國在執行FATF建議方面的進展；審查洗錢和資助恐怖主義的技術和反措施；並在全球範圍內促進FATF建議的通過和執行。
          </p>
          <p>
            FATF負責審查洗錢技術和趨勢，審查已經在國家或國際一級采取的行動，並提出為打擊洗錢仍需采取的措施。
          </p>
          <p>
            1990年4月，在成立不到一年後，FATF發表了一份報告，其中載有一套40項建議，目的是提供打擊洗錢所需的全面行動計劃。
          </p>
          <p>
            2001年，FATF的任務中增加了打擊資助恐怖主義的標准的制定工作。
          </p>
          <p>
            2001年10月，FATF發布了八項特別建議，以處理恐怖主義融資問題。洗錢技術的持續發展導致金融行動特別工作組於2003年6月全面修訂了FATF的標准。
          </p>
          <p>
            2004年10月，FATF發表了第九項特別建議，進一步加強打擊洗錢和資助恐怖主義的商定國際標准——40+9建議。
          </p>
          <p>
            2012年2月，FATF完成了對其標准的徹底審查，並公布了修訂後的FATF建議。這一修訂旨在加強全球保障措施，並進一步保護金融體系的完整性，為各國政府提供更有力的工具，采取行動打擊金融犯罪。它們已經擴大，以應對新的威脅，如資助大規模毀滅性武器的擴散，並在透明度方面更加明確，在腐敗問題上更加嚴厲。關於資助恐怖主義的9項特別建議已與反洗錢措施充分結合起來。這就形成了一套更強有力、更清晰的標准。
          </p>
        </div>
        <div class="second-title">5- 程序</div>
        <div class="content ">
          <p>
            本公司通過的法律規定介紹了確保遵守與洗錢和恐怖主義融資活動有關的適用法律的程序和流程。
          </p>
        </div>
        <div class="third-title">5.1- 客戶分類和識別程序</div>
        <div class="content V2">
          <p>
            公司采用了適用法律中與客戶分類和識別以及盡職調查程序相關的所有要求，如下所述：
          </p>
        </div>
        <div class="four-title">5.1.1- 客戶分類</div>
        <div class="content V3">
          <p>
            客戶根據其風險狀況分為三大類，如下所述：
          </p>
        </div>
        <div class="five-title">a- 低風險客戶</div>
        <div class="content V4">
          <p>
            以下類型的客戶被認為風險較低。應注意的是，公司應收集足夠的信息，以確定客戶是否有資格被歸類為低風險客戶：
          </p>
        </div>
        <div class="content V5">
          <p>
            i-
            位於另一國的信貸或金融機構，其要求高於或相當於公司監管機構規定的要求。
          </p>
          <p>
            ii-
            上市公司，其證券被允許在另一個國家的受監管市場上交易，其披露要求符合共同體法律。
          </p>
        </div>
        <div class="five-title">b- 正常風險客戶</div>
        <div class="content V4">
          <p>
            所有不屬於高風險或低風險類別的客戶將被視為正常風險客戶。
          </p>
        </div>
        <div class="five-title">c- 高風險客戶</div>
        <div class="content V4">
          <p>
            根據以下條件將客戶劃分為高風險：
          </p>
        </div>
        <div class="content V5">
          <p>
            i- 非面對面客戶
          </p>
          <p>
            ii- 以第三人名義開立的客戶賬戶
          </p>
          <p>
            iii- 政治公眾人物(“PEP”)賬戶
          </p>
          <p>
            iv- 通過互聯網進行電子賭博/遊戲
          </p>
          <p>
            v- 來自不充分適用金融行動特別工作組建議的國家的客戶
          </p>
          <p>
            vi- 客戶的性質需要更高的洗錢和恐怖融資風險
          </p>
          <p>
            vii- 本公司自行確定為此類客戶的任何其他客戶
          </p>
        </div>
        <div class="four-title">5.1.2- 客戶識別（盡職調查）</div>
        <div class="five-title">a- 盡職調查條件</div>
        <div class="content V4">
          <p>
            客戶識別和盡職調查程序適用於以下情況：
          </p>
        </div>
        <div class="content V5">
          <p>
            i- 建立業務關系。
          </p>
          <p>
            ii-有洗錢或恐怖融資嫌疑，不論交易金額大小。
          </p>
          <p>
            iii- 對先前獲得的客戶身份數據是否充分存在疑問。
          </p>
          <p>
            iv-
            客戶在沒有充分理由的情況下，未能或拒絕提交必要的數據和信息，以核實其身份和建立其經濟狀況。
          </p>
        </div>
        <div class="five-title">b- 盡職調查時間</div>
        <div class="content V5">
          <p>
            i- 客戶識別和盡職調查必須在企業成立之前進行關系或交易的進行。
          </p>
          <p>
            ii-
            在建立業務關系的過程中，如果有必要核實客戶的身份，以避免妨礙正常的業務活動，並且洗錢或資助恐怖主義的風險很小。在這種情況下，這些程序需要盡快完成。
          </p>
          <p>
            iii-
            必須定期對現有記錄進行審查，從而確保所保存的文件、數據或信息是最新的。
          </p>
          <p>
            iv-
            客戶盡職調查程序不僅應適用於所有新客戶，還應在適當的時間以風險敏感為基礎對現有客戶適用。
          </p>
          <p>
            v- 當一個客戶的帳戶被打開時，它應該被密切監視。
          </p>
          <p>
            vi-
            每年至少進行兩次評審，並准備一份總結評審結果的說明，必須保存在客戶的檔案中。
          </p>
          <p>
            vii- 公司應經常將估計值與賬戶實際營業額進行比較。
          </p>
          <p>
            viii-
            應調查任何嚴重的偏差，這不僅是為了公司可能采取的與特定賬戶有關的行動，而且也是為了評估介紹客戶的個人或實體的可靠性。
          </p>
        </div>
        <div class="five-title">c- 盡職調查程序</div>
        <div class="content V4">
          <p>
            本公司為遵守法律對客戶身份識別的要求而遵循的做法是基於風險的方法實現的，具體如下：
          </p>
        </div>
        <div class="content V5">
          <p>
            正常客戶盡職調查程序
          </p>
        </div>
        <div class="content V6">
          <p>
            - 根據從可靠和獨立來源獲得的信息，識別客戶並核實客戶身份。
          </p>
          <p>
            - 對於法人，采取基於風險的適當措施，了解客戶的所有權和控制結構。
          </p>
          <p>
            - 獲取有關業務關系的目的和預期性質的信息。
          </p>
          <p>
            -
            對業務關系進行持續監控，包括審查整個關系過程中未發生的交易，以確保所進行的交易與公司持有的與客戶有關的數據和信息一致。
          </p>
        </div>
        <div class="content V5">
          <p>
            ii-
            簡化客戶盡職調查程序低風險客戶可采用簡化程序。這些措施應在不存在洗錢嫌疑的情況下適用，無論是否有任何減損、豁免或門檻，也不應在建立業務關系時適用。
          </p>
          <p>
            iii- 強化客戶盡職調查程序
          </p>
        </div>
        <div class="content V5">
          <p>
            本公司應在性質上可能存在洗錢或恐怖融資高風險的情況下采取強化客戶盡職調查措施。
          </p>
          <p>
            公司應采取具體、充分的措施，對高風險進行補償，采取下列一種或多種措施：
          </p>
        </div>
        <div class="content V6">
          <p>
            - 確保通過其他文件、數據或信息確定客戶的身份。
          </p>
          <p>
            - 采取補充措施核實或證明所提供的文件。
          </p>
          <p>
            -
            確保以客戶名義在信貸機構開立的賬戶支付運營的首筆款項，該機構的運營要求高於或等於公司監管機構規定的要求。
          </p>
        </div>
        <div class="five-title">d- 驗證程序</div>
        <div class="content V4">
          <p>
            在建立業務關系期間，為了核實客戶的身份，公司將遵循以下驗證程序：
          </p>
        </div>
        <div class="content V5">
          <p>
            i-
            公司將確保在建立業務關系之前，始終進行經濟概況的構建、適當性評估和適宜性評估。
          </p>
          <p>
            ii-
            向客戶提供十五(15)天的寬限期，以便向公司提供其身份證明文件；在15天的寬限期內，公司應確保：
          </p>
        </div>
        <div class="content V6">
          <p>- 累計存入金額不超過2000美元。</p>
          <p>
            - 資金只能來自銀行賬戶或通過與客戶名義下的銀行賬戶掛鉤的其他方式。
          </p>
          <p>
            - 將向要求提供客戶身份證明文件的客戶發送通知/提醒電子郵件。
          </p>
          <p>
            - 在核查程序未在期限結束後結束的情況下關閉賬戶。
          </p>
          <p>
            - 公司不得截留客戶資金，除非有洗錢嫌疑，不得凍結賬戶。
          </p>
        </div>
        <div class="five-title">e- 其他盡職調查相關事項</div>
        <div class="content V6">
          <p>
            i- 政治人物
          </p>
          <p>
            政治公眾人物(“PEP”)是指在國外擔任重要公共職能的個人，而“親密夥伴”是指與政治公眾人物有密切關系的人。
          </p>
          <p>
            公司應采取以下額外的盡職調查措施，以確定潛在客戶是否為政治風險人物：
          </p>
          <p>
            - 在與客戶建立業務關系之前獲得高級管理層的特別批准。
          </p>
          <p>
            - 采取適當措施確定與建立業務關系或交易有關的客戶資產來源和資金來源。
          </p>
          <p>
            - 加強和持續監控業務關系。
          </p>
        </div>
        <div class="content V5">
          <p>
            ii- 匿名或編號賬戶
          </p>

          <p>
            公司被禁止持有匿名或編號賬戶。此外，公司應特別注意可能因匿名產品或交易而產生的洗錢或恐怖融資威脅，並采取措施防止其用於洗錢或恐怖融資目的。
          </p>
          <p>
            iii- 第三方盡職調查
          </p>

          <p>
            本公司獲准並可依賴第三方滿足客戶的盡職調查要求。然而，在這種情況下，滿足這些要求的最終責任應由公司承擔，公司依賴於上述各方。
          </p>
        </div>
        <div class="third-title">5.2- 客戶帳戶開設和KYC文件程序</div>
        <div class="content V2">
          <p>
            在接受新客戶之前，公司應要求這些客戶提供一定的信息和身份證明文件。
          </p>
        </div>
        <div class="four-title">5.2.1- 帳戶開設</div>
        <div class="content V3">
          <p>
            a- 開戶所需資料
          </p>
          <p>
            所有有意在本公司開設帳戶的客戶，請提供下列資料：
          </p>
        </div>
        <div class="content V4">
          <p>
            i- 客戶的個人信息
          </p>
          <p>
            ii- 客戶的經濟概況結構
          </p>
          <p>
            iii- 客戶的適當性/適合性評估
          </p>
        </div>
        <div class="content V3">
          <p>
            顯然，對客戶的識別，包括經濟概況的結構和適當性/適合性評估，應在與客戶建立業務關系之前進行。
          </p>
        </div>
        <div class="content V3">
          <p>
            b- 開戶手續
          </p>
          <p>
            i- 客戶完成帳戶開戶表格，填寫所有必需的信息。
          </p>
          <p>
            ii-負責管理人收集客戶的所有初始信息，並將其直接轉發給高層管理人員，以及反洗錢合規官進行審查和批准。
          </p>
          <p>
            iii-
            審批通過後，管理員將所有必要的信息錄入公司軟件系統，並與相關部門溝通。
          </p>
        </div>
        <div class="four-title">5.2.2- KYC文件</div>
        <div class="content V3">
          <p>
            在接受新客戶並允許其與本公司進行交易之前，應獲取以下文件以核實客戶的身份：
          </p>
          <p>
            a- 自然人
          </p>
          <p>
            為了有效地實施公司的KYC程序，需要自然人(個人客戶)提供的身份證明文件如下：
          </p>
        </div>
        <div class="content V4">
          <p>
            i- 身份證明
          </p>
          <p>
            政府簽發的有效身份證明(護照，身份證，駕照……)，其中應包括客戶的全名，客戶的出生日期，客戶的照片，和有效狀態(有效期和/或簽發日期+有效期)
          </p>
          <p>
            ii- 住所證明
          </p>
          <p>
            本人姓名的最近家庭住址證明(銀行對賬單、水電賬單、電話賬單等)，其中應包括客戶的全名、家庭住址和出具日期(不得超過6個月)。
          </p>
        </div>
        <div class="content V3">
          <p>
            b- 法人
          </p>
          <p>
            對在公司開戶感興趣的法人(公司客戶)要遵循不同的身份識別程序。這些文件要求如下：
          </p>
        </div>
        <div class="content V4">
          <p>
            i- 公司成立文件
          </p>
          <p>
            公司文件的形式和名稱可能因公司成立國家和/或法律形式的不同而有所不同。然而，要求由政府簽發的公司文件應包括公司名稱、成立日期和地點、注冊辦公地址、董事和授權簽署人、所有權/股權結構(股東姓名和持股比例)、公司注冊活動。
          </p>
          <p>
            這些文件可能包括但不限於：公司證書或注冊證書、注冊辦公室證書、董事和秘書證書、注冊股東證書、公司章程大綱和章程細則。
          </p>
          <p>
            ii- 董事及實益所有人文件
          </p>
          <p>
            個人KYC和身份證明文件必須來自：
          </p>
          <p>
            - 法人董事
          </p>
          <p>
            - 擁有10%以上實益所有權的法人最終受益所有人。
          </p>
          <p>
            這些身份證明文件包括身份證明和居住證明。
          </p>
          <p>
            iii- 董事會決議
          </p>
          <p>
            法人董事會關於開戶並授予經營者權限的決議。
          </p>
        </div>
        <div class="third-title">5.3- 記錄保存程序</div>
        <div class="content V2">
          <p>
            公司應保存以下所列文件和信息，以便國家當局對可能的洗錢或恐怖融資進行調查或分析。
          </p>
          <p>
            除以硬拷貝形式保存的原始文件或其經核證無誤的副本外，文件/數據的保留可采用其他形式，如電子形式，前提是公司能夠及時檢索相關文件/數據，並在任何時候將其提交給有關當局，在請求之後。如果文件/數據使用的是英語以外的語言，則隨附真實譯文。
          </p>
        </div>
        <div class="content V3">
          <p>
            a-
            客戶的姓名和地址以及官方身份證明文件(如護照、身份證或駕駛執照)的副本或記錄。
          </p>
          <p>
            b- 交易對手的名稱和地址(或識別碼)。
          </p>
          <p>
            c- 指示或權威的形式。
          </p>
          <p>
            d- 支付資金的賬戶詳細信息。
          </p>
          <p>
            e- 企業向客戶付款的形式和目的地。
          </p>
          <p>
            f- 商務信函。
          </p>
          <p>
            g-
            在客戶與客戶的業務關系結束至少5年的盡職調查後，需要一份證明文件副本。
          </p>
          <p>
            h-
            對於業務關系和交易，應提供交易完成或業務關系結束後至少五年內的支持證據和記錄。
          </p>
        </div>
        <div class="third-title">5.4- 可疑交易報告</div>
        <div class="content V2">
          <p>
            可疑交易報告
          </p>
          <p>
            本公司確保隨時保持足夠的信息，並充分了解其客戶的活動，以便及時發現一項或一系列交易不尋常或可疑。
          </p>
        </div>
        <div class="four-title">5.4.1- 可疑交易示例</div>
        <div class="content V3">
          <p>
            可能構成與洗錢和資助恐怖主義有關的可疑交易/活動的例子包括但不限於：
          </p>
        </div>
        <div class="content V3">
          <p>
            a- 沒有明確目的或不必要的複雜交易。
          </p>
          <p>
            b-
            使用所有權結構複雜的公司或公司集團的外國賬戶，根據客戶的需求和經濟狀況，這是不合理的。
          </p>
          <p>
            c- 客戶要求的交易或交易規模不符合其通常的慣例和業務活動。
          </p>
          <p>
            d-
            當客戶的商業活動不足以證明此類活動的正當性時，存入或貸記到賬戶的大量交易和/或資金。
          </p>
          <p>
            e- 電子商務關系只涉及一個交易或者持續時間很短。
          </p>
          <p>
            f- 客戶使用特定金融機構的服務沒有明顯的正當理由。
          </p>
          <p>
            g- 在同一金融工具中經常發生交易，且無明顯原因，且交易條件似乎異常。
          </p>
          <p>
            h-
            客戶經常以現金結算的方式少量購買某一金融工具，然後在一次交易中出售該金融工具的總數量，以現金結算，或在客戶的指示下，將收益轉移到其通常賬戶以外的賬戶中。
          </p>
          <p>
            i-
            任何性質、規模或頻率似乎不尋常的交易，例如取消訂單，特別是在支付對價之後。
          </p>
          <p>
            j- 不符合市場現行條件的交易，尤其是與訂單規模和頻率有關的交易。
          </p>
          <p>
            k-
            任何交易的結算，但主要是大額現金交易和/或由與發出訂單的客戶不同的第三方進行交易結算。
          </p>
          <p>
            l-
            不適用或不充分適用FAFT關於洗錢和恐怖主義融資的建議的國家或地理區域之間的資金轉移。
          </p>
          <p>
            m-
            客戶在建立業務關系時不願意提供完整的信息，包括其業務活動的性質和目的、預期的賬戶活動、先前與金融機構的關系、其高級職員和董事的姓名或其業務地點的信息。
          </p>
          <p>
            n-
            客戶通常提供最少的或誤導性的信息，這些信息很難或昂貴，公司無法核實。
          </p>
          <p>
            o- 提供不尋常或可疑的無法輕易核實的身份證明文件。
          </p>
          <p>
            p- 頻繁或大額交易且沒有過去或現在雇傭經驗記錄的客戶。
          </p>
          <p>
            q-
            由外國金融機構或其國家或地理區域不適用或不適用FATF關於洗錢和恐怖融資的建議的第三方介紹的客戶。
          </p>
          <p>
            r-
            參與現金交易的個人的共享地址，特別是當該地址也是一個營業地點和/或似乎與所述職業不符時(例如，學生、失業人士、自雇人士等)。
          </p>
          <p>
            s-
            涉及現金交易的個人共用地址，特別是當該地址同時是營業地點及/或似乎與所述職業(例如學生、失業人士、自雇人士等)不相符時。
          </p>
          <p>
            t- 客戶所述職業與已執行交易的水平或規模不相稱。
          </p>
          <p>
            u- 以限制公司董事會行使控制權的方式使用一般提名人文件。
          </p>
        </div>
        <div class="four-title">5.4.2- 可疑交易報告程序</div>
        <div class="content V3">
          <p>
            報告客戶可疑交易的程序如下：
          </p>
        </div>
        <div class="content V3">
          <p>
            a- 公司不同部門員工的報告由反洗錢合規官評估。
          </p>
          <p>
            b- 如果認為有必要，合規官應通知相關洗錢當局。
          </p>
          <p>
            c-
            在提交可疑報告後，客戶的相關賬戶以及任何其他相關賬戶均處於合規官的密切監控之下。
          </p>
          <p>
            d-
            在提交可疑報告後，公司遵守相關洗錢當局發出的任何指示，特別是關於是否繼續或暫停特定交易或維持特定賬戶的活躍。
          </p>
          <p>
            e- 對重大偏差進行調查，並將結果記錄在各自的客戶檔案中。
          </p>
          <p>
            f-
            對根據客戶現有信息無法證明其正當性的交易進行徹底檢查，以確定是否存在洗錢或恐怖融資嫌疑，以便向合規官提交內部報告，然後由合規官向相關洗錢當局提交報告。
          </p>
          <p>
            g-
            對不符合客戶現有信息的交易進行徹底檢查，以確定是否存在洗錢或恐怖主義融資的嫌疑。如果是這種情況，將分別向合規官員和相關洗錢機構提交報告。
          </p>
        </div>
        <div class="third-title">5.5- AML合規官每日/每月程序</div>
        <div class="content V2">
          <p>
            AML合規官每天/每月應遵循的程序如下：
          </p>
          <p>
            i. 每天收到員工關於任何可疑交易的報告。
          </p>
          <p>
            ii. 審查提交的報告（如有）。
          </p>
          <p>
            iii. 通知高級管理層並提出采取以下任何行動的必要性。
          </p>
          <p>
            iv. 如果正在處理事務，請停止該事務。
          </p>
          <p>
            v. -通知客戶取消交易的原因。
          </p>
          <p>
            vi. -收集事務的信息（如果已執行）。
          </p>
          <p>
            vii. 根據法律要求，向交易的有關當局報告。
          </p>
        </div>
        <div class="second-title">6- 人員教育培訓</div>
        <div class="content ">
          <p>
            公司通過實施完善的員工教育和培訓計劃，確保其員工充分意識到其在防止洗錢和恐怖融資方面的法律義務。
          </p>
          <p>
            培訓計劃的目的是教育雇員了解防止洗錢和資助恐怖主義的最新發展，包括為此目的使用的實際方法和趨勢。
          </p>
          <p>
            該培訓計劃確保公司員工充分意識到，對於未能報告有關洗錢或恐怖主義融資的信息或懷疑，他們可能負有個人責任。為各部門員工提供培訓的時間和內容根據各部門的需要進行調整。
          </p>
          <p>
            培訓的頻率可根據法律和/或監管要求的修訂、員工的職責以及財務系統的任何其他變化而變化。
          </p>
          <p>
            培訓計劃對新員工、現有員工和公司不同部門根據他們提供的服務有不同的結構。
          </p>
          <p>
            定期進行持續培訓，以確保員工意識到他們的職責和責任，並隨時了解任何新的發展情況。
          </p>
          <p>
            收集到的有關客戶的任何個人信息，如姓名、地址、出生日期和聯系方式等，將嚴格出於業務目的由幣達保存。客戶交易、護照複印件和地址證明等其他信息將保密，僅在我們的賬戶服務部門和合規部門之間共享。幣達可能會另外詢問客戶的信用價值，這也會在我們的客戶檔案中保密。這些信息可以通過物理或電子方式保存，並有嚴格的訪問程序。
          </p>
          <p>
            幣達可能會與負責營銷、後臺和客戶服務職能的內部部門或附屬辦公室共享客戶信息，以完成正常的業務操作。因客戶信息需要保密，幣達內的每個員工都簽署了保密協議。
          </p>

          <p>
            有關我們隱私政策的任何問題或其他信息，請聯系我們的客戶服務部：support@bitda.io
          </p>
        </div>
      </div>
      <div class="right-content" v-else>
        <div class="title">AML & KYC Policy</div>
        <div class="second-title">1- Introduction</div>
        <div class="content">
          <p>
            bitda Limited (“bitda” or “the Company”) aims to prohibit, detect
            and actively pursue the prevention of money laundering and
            terrorism-funding activities. It also vows to comply with all
            related laws, rules and regulations with full attention with no
            compromise to any of the abovementioned illegal activities.
          </p>
          <p>
            The management of the Company is committed to Anti-Money Laundering
            (“AML”), Counter Terrorism Financing (“CFT”) compliance in
            accordance with applicable laws, and places extremely high
            importance on unveiling any money laundering scheme and/or terrorism
            financing activities.
          </p>
          <p>
            bitda also requires its officers, employees, introducing brokers and
            affiliated companies to adhere to these standards in preventing the
            use company’s products and services for the purposes of money
            laundering and terrorism financing activities.
          </p>
        </div>
        <div class="second-title">2- Purpose</div>
        <div class="content">
          <p>
            The purpose of “AML, CFT & KYC Policy”, (“the Policy”), is to
            provide guidance on the Anti-Money Laundering (“AML”), Counter
            Terrorism Financing (“CFT”), and Know your Client (“KYC")
            procedures, which are followed by the Company in order to achieve
            full compliance with the relevant AML and CTF legislation.
          </p>
          <p>
            This policy applies to all of the Company’s Officers, introducing
            brokers, affiliated companies, as well as products and services
            offered by the Company. Any employee found not to be adhering to
            these policies and procedures will be subject to sever disciplinary
            action.
          </p>
        </div>
        <div class="second-title">3- Legal Framework</div>
        <div class="content">
          <p>
            The Company is required to comply with the provisions of the
            applicable laws regarding the prevention of Money Laundering and
            Terrorist Financing. The main purpose of these Laws is to define and
            criminalize the laundering of proceeds generated from all serious
            criminal offences aiming at depriving criminals from the profits of
            their crimes.
          </p>
          <p>
            In accordance with the AML and CTF Laws, the Company is obliged to
            set out policies and procedures for preventing money laundering and
            Terrorist Financing activities.
          </p>
          <p>
            The AML and CFT procedures, which are implemented by the Company,
            are based on AML and CFT laws applicable in Saint Vincent, the
            recommendations of The Financial Action Task Force (FATF), in
            addition to other documents and information.
          </p>
        </div>
        <div class="second-title">4- Definitions</div>
        <div class="third-title">4.1- Money Laundering</div>
        <div class="content V2">
          <p>
            Money laundering is the process of creating the appearance that
            large amounts of money obtained from serious crimes, such as drug
            trafficking or terrorist activity, originated from a legitimate
            source.
          </p>
          <p>
            There are three steps involved in the process of laundering money:
            Placement, Layering and Integration.
          </p>
        </div>
        <div class="four-title">4.1.1- Placement</div>
        <div class="content V3">
          <p>
            Placement refers to the act of introducing "dirty money" (money
            obtained through illegitimate, criminal means) into the financial
            system in some way.
          </p>
        </div>
        <div class="four-title">4.1.2- Layering</div>
        <div class="content V3">
          <p>
            Layering is the act of concealing the source of that money by way of
            a series of complex transactions and bookkeeping gymnastics.
          </p>
        </div>
        <div class="four-title">4.1.3- Integration</div>
        <div class="content V3">
          <p>
            Integration refers to the act of acquiring that money in purportedly
            legitimate means.
          </p>
        </div>
        <div class="third-title">4.2- Terrorism Financing</div>
        <div class="content V2">
          <p>
            Terrorist financing (proceeds for crime) is the process by which
            funds are provided for financing or financial support to individual
            terrorists or terrorist groups.
          </p>
          <p>
            A terrorist, or terrorist group, is one that has a purpose or
            activity to facilitate or carry out any terrorist action, and can
            involve: individuals or groups.
          </p>
        </div>
        <div class="third-title">4.3- AML/CTF</div>
        <div class="content V2">
          <p>
            The term AML CTF refers to “Anti Money Laundering and Counter
            Terrorism Financing” or “Anti Money Laundering and Combating
            Terrorism Financing”.
          </p>
        </div>
        <div class="four-title">4.3.1- Anti-Money Laundering</div>
        <div class="content V3">
          <p>
            Anti-Money Laundering (“AML”) refers to a set of procedures, laws or
            regulations designed to stop the practice of generating income
            through illegal actions.
          </p>
        </div>
        <div class="four-title">4.3.2- Counter Terrorism Financing</div>
        <div class="content V3">
          <p>
            Counter Terrorism Financing (“CTF”) refers to a set of procedures,
            laws or regulations designed to prevent financing or providing
            financial support to individual terrorists or terrorist groups.
          </p>
        </div>
        <div class="third-title">4.4- Financial Action Task Force (FATF)</div>
        <div class="content V2">
          <p>
            The Financial Action Task Force on Money Laundering (“FATF”), also
            known by its French name, Groupe d'action financière (GAFI), is an
            intergovernmental organization established in July 1989 by a Group
            of Seven (G-7) Summit in Paris, initially to examine and develop
            measures to combat money laundering.
          </p>
          <p>
            In October 2001, the FATF expanded its mandate to incorporate
            efforts to combat terrorist financing, in addition to money
            laundering.
          </p>
          <p>
            The objectives of the FATF are to set standards and promote
            effective implementation of legal, regulatory and operational
            measures for combating money laundering, terrorist financing and
            other related threats to the integrity of the international
            financial system.
          </p>
          <p>
            Starting with its own members, the FATF monitors countries' progress
            in implementing the FATF Recommendations; reviews money laundering
            and terrorist financing techniques and counter-measures; and,
            promotes the adoption and implementation of the FATF Recommendations
            globally.
          </p>
          <p>
            The Task Force was given the responsibility of examining money
            laundering techniques and trends, reviewing the action which had
            already been taken at a national or international level, and setting
            out the measures that still needed to be taken to combat money
            laundering.
          </p>
          <p>
            In April 1990, less than one year after its creation, the FATF
            issued a report containing a set of Forty Recommendations, which
            were intended to provide a comprehensive plan of action needed to
            fight money laundering.
          </p>
          <p>
            In 2001, the development of standards in the fight against terrorist
            financing was added to the mission of the FATF.
          </p>
          <p>
            In October 2001, the FATF issued the Eight Special Recommendations
            to deal with the issue of terrorist financing. The continued
            evolution of money laundering techniques led the FATF to revise the
            FATF standards comprehensively in June 2003.
          </p>
          <p>
            In October 2004, the FATF published a Ninth Special Recommendations,
            further strengthening the agreed international standards for
            combating money laundering and terrorist financing - the 40+9
            Recommendations.
          </p>
          <p>
            In February 2012, the FATF completed a thorough review of its
            standards and published the revised FATF Recommendations. This
            revision is intended to strengthen global safeguards and further
            protect the integrity of the financial system by providing
            governments with stronger tools to take action against financial
            crime. They have been expanded to deal with new threats such as
            financing the proliferation of weapons of mass destruction. The new
            standards also impose higher levels of transparency and tougher
            regulations to halt corruption. The 9 Special Recommendations on
            terrorist financing have been fully integrated with the measures
            against money laundering. This has resulted in a stronger and
            clearer set of standard.
          </p>
        </div>
        <div class="second-title">5- Procedures</div>
        <div class="content ">
          <p>
            The provisions of the Laws adopted by the Company introduces
            procedures and processes that ensure compliance with the applicable
            Laws related to Money Laundering and Terrorism Financing activities.
          </p>
        </div>
        <div class="third-title">
          5.1- Client Categorisation and Identification Procedures
        </div>
        <div class="content V2">
          <p>
            The Company has adopted all requirements of the applicable laws in
            relation to client Categorisation and identification and due
            diligence procedures as explained below:
          </p>
        </div>
        <div class="four-title">5.1.1- Client Categorisation</div>
        <div class="content V3">
          <p>
            Clients are categorised based on their risk profile into three main
            categories as explained below:
          </p>
        </div>
        <div class="five-title">a- Low Risk Clients</div>
        <div class="content V4">
          <p>
            The following types of clients are considered lower risk. It should
            be noted that the Company shall gather sufficient information to
            establish if the client qualifies to be classified as lower risk
            client:
          </p>
        </div>
        <div class="content V5">
          <p>
            i- Credit or financial institutions situated in another country
            which impose requirements higher or equivalent to those laid down by
            Company’s regulators.
          </p>
          <p>
            ii- Listed companies whose securities are admitted to trading on a
            regulated market of another countries, which are subject to
            disclosure requirements consistent with Community legislation.
          </p>
        </div>
        <div class="five-title">b- Normal Risk Clients</div>
        <div class="content V4">
          <p>
            All clients are neither High Risk nor Low Risk will be considered as
            Normal Risk Clients.
          </p>
        </div>
        <div class="five-title">c- High Risk Clients</div>
        <div class="content V4">
          <p>
            Clients with the following criteria are classified as High risk due
            to the following conditions:
          </p>
        </div>
        <div class="content V5">
          <p>
            i- Non-face-to-face customers
          </p>
          <p>
            ii- Client accounts in the name of a third person
          </p>
          <p>
            iii- Politically exposed persons (“PEP”) accounts
          </p>
          <p>
            iv- Electronic gambling /gaming through the internet
          </p>
          <p>
            v- Customers from countries which inadequately apply FATF’s
            recommendations
          </p>
          <p>
            vi- Clients whose nature entails a higher risk of money laundering
            and terrorist financing
          </p>
          <p>
            vii- Any other Client determined by the Company itself to be
            classified as such
          </p>
        </div>
        <div class="four-title">
          5.1.2- Client Identification (Due Diligence)
        </div>
        <div class="five-title">a- Due Diligence Conditions</div>
        <div class="content V4">
          <p>
            The Client Identification and Due Diligence procedures are applied
            in the following conditions:
          </p>
        </div>
        <div class="content V5">
          <p>
            i- Establishing a business relationship.
          </p>
          <p>
            ii-There is a suspicion of money laundering or terrorist financing,
            irrespective of the transaction amount.
          </p>
          <p>
            iii- There are doubts about the adequacy of previously obtained
            client identification data.
          </p>
          <p>
            iv- Failure or refusal by a client to submit the requisite data and
            information for the verification of his/her identity and the
            creation of his/her economic profile, without adequate
            justification.
          </p>
        </div>
        <div class="five-title">b- Due Diligence Timing</div>
        <div class="content V5">
          <p>
            i- Client identification and due diligence must take place before
            the establishment of a business relationship or the carrying out of
            a transaction.
          </p>
          <p>
            ii- The verification of the identity of the client may be completed
            during the establishment of a business relationship if this is
            necessary in order not to interrupt the normal conduct of business
            and where there is limited risk of money laundering or terrorist
            financing occurring. In such situation, these procedures needs to be
            completed as soon as possible.
          </p>
          <p>
            iii- Reviews of existing records must take place on a regular basis,
            thus ensuring that the documents, data or information held are
            up-to-date.
          </p>
          <p>
            iv- Failure or refusal by a client to submit the requisite data and
            information for the verification of his/her identity and the
            creation of his/her economic profile, without adequate
            justification.
          </p>
          <p>
            v- When a client’s account is opened, it should be closely
            monitored.
          </p>
          <p>
            vi- Client due diligence procedures shall be applied, not only to
            all new clients, but also at appropriate times to existing clients
            on a risk sensitive basis.
          </p>
          <p>
            vii- At frequent intervals, the Company should compare the estimated
            to the actual turnover of the account.
          </p>
          <p>
            viii- Any serious deviation, should be investigated, not only for
            possible action by the Company in relation to the particular account
            concerned, but also to gauge the reliability of the person or entity
            who has introduced the customer.
          </p>
        </div>
        <div class="five-title">c- Due Diligence Procedures</div>
        <div class="content V4">
          <p>
            The practice to which the Company adheres in order to comply with
            the requirements of the Law on the subject of the client
            identification is achieved on a risk-based approach, and it is set
            out below: Normal Client Due Diligence Procedure
          </p>
        </div>
        <div class="content V5">
          <p>
            Normal Client Due Diligence Procedure
          </p>
        </div>
        <div class="content V6">
          <p>
            - Identification of the client and verification of the client’s
            identity on the basis of information obtained from a reliable and
            independent source.
          </p>
          <p>
            - For legal persons, taking risk-based and adequate measures to
            understand the ownership and control structure of the client.
          </p>
          <p>
            - Obtaining information on the purpose and intended nature of the
            business relationship.
          </p>
          <p>
            - Constantly monitoring the business relationship, including
            scrutiny of transactions undertaken throughout the course of the
            relationship to ensure that the transactions being conducted are
            consistent with the data and information help by the firm in
            connection with the client.
          </p>
        </div>
        <div class="content V5">
          <p>
            ii- Simplified Due Diligence Procedure Simplified procedures may
            apply for low-risk clients. These measures shall apply when there is
            no suspicion of money laundering, regardless of any derogation,
            exemption or threshold, and not whenever a business relationship is
            established.
          </p>
          <p>
            iii- Enhanced Client Due Diligence Procedure
          </p>
        </div>
        <div class="content V5">
          <p>
            The Company should apply enhanced client due diligence measures in
            situations which by nature can present high risk of money laundering
            or terrorist financing.
          </p>
          <p>
            The Company shall take specific and adequate measures to compensate
            for the high risk by applying one or more of the following measures:
          </p>
        </div>
        <div class="content V6">
          <p>
            - Ensure that the client’s identity is established by additional
            documents, data or information.
          </p>
          <p>
            - Apply supplementary measures to verify or certify the documents
            supplied.
          </p>
          <p>
            - Ensure that the first payment of the operations is carried out
            through an account opened in the client’s name. This account shall
            be with a credit institution, which operates in a country that
            imposes requirements higher or equivalent to those laid down by
            Company’s regulators.
          </p>
        </div>
        <div class="five-title">d- Verification Procedure</div>
        <div class="content V4">
          <p>
            The following verification procedure will be followed by the Company
            in order to verify the identity of the client during the
            establishment of the business relationship:
          </p>
        </div>
        <div class="content V5">
          <p>
            i- The Company will ensure that the construction of the economic
            profile, assessment of appropriateness and assessment of suitability
            shall be performed at all times prior the establishment of the
            business relationship.
          </p>
          <p>
            ii- The clients are provided with a grace period of fifteen (15)
            days to provide the Company with their identification documents;
            During this 15-days period the Company is required to ensure the
            following:
          </p>
        </div>
        <div class="content V6">
          <p>
            - The cumulative amount of funds to be deposited will not exceed the
            amount of USD 2,000.
          </p>
          <p>
            - The funds may come only from a bank account or through other means
            that are linked to a bank account in the name of the client.
          </p>
          <p>
            - Notification / Reminder emails will be sent to the clients
            requesting the client’s identification documents.
          </p>
          <p>
            - Closure of the account in cases where the verification procedure
            is not concluded following the grace period.
          </p>
          <p>
            - The Company shall not withhold any clients’ funds, and no accounts
            shall be frozen unless under suspicion of money laundering.
          </p>
        </div>
        <div class="five-title">e- Other Due Diligence related matters</div>
        <div class="content V6">
          <p>
            i- Politically exposed persons
          </p>
          <p>
            Politically Exposed Persons (“PEPs”) are individuals who are or have
            been entrusted with prominent public functions in a foreign country,
            as well as those in a close relationship with politically exposed
            persons.
          </p>
          <p>
            The Company should adopt the following additional due diligence
            measures to determine whether a prospective client is a politically
            exposed person:
          </p>
          <p>
            - Special Approval from Senior Management prior to the establishment
            of a business relationship with the client.
          </p>
          <p>
            - Take appropriate measures for the establishment of the origin of
            the client’s assets and the source of funds that are related with
            the establishment of the business relationship or transaction.
          </p>
          <p>
            - Conduct enhanced and continuous monitoring of the business
            relationship.
          </p>
        </div>
        <div class="content V5">
          <p>
            ii- Anonymous or Numbered Accounts
          </p>

          <p>
            The Company is prohibited from keeping anonymous or numbered
            accounts. Additionally, the Company shall pay special attention to
            any money laundering or terrorist financing threat that may arise
            from products or transactions that might favour anonymity. The
            Company shall also take necessary measures to prevent their use for
            money laundering or terrorist financing purposes. iii- Due Diligence
            Performance by Third Parties
          </p>
          <p>
            iii- Due Diligence Performance by Third Parties
          </p>

          <p>
            The Company is permitted, and may rely on third parties, to meet the
            clients’ due diligence requirements. However, in such cases, the
            ultimate responsibility for meeting those requirements shall remain
            with the Company, which relies on such aforementioned parties.
          </p>
        </div>
        <div class="third-title">
          5.2- Client Account Opening and KYC Documentation Procedures
        </div>
        <div class="content V2">
          <p>
            Prior to accepting new clients, the company shall requires these
            clients to provide certain information and identification documents.
          </p>
        </div>
        <div class="four-title">5.2.1- Account Opening</div>
        <div class="content V3">
          <p>
            a- Required Information for Account Opening
          </p>
          <p>
            All clients interested in opening an account with the Company are
            requested to provide certain information including:
          </p>
        </div>
        <div class="content V4">
          <p>
            i- The Client’s personal details
          </p>
          <p>
            ii- The Client’s Economic Profile Construction
          </p>
          <p>
            iii- The Client’s Appropriateness/Suitability Assessment
          </p>
        </div>
        <div class="content V3">
          <p>
            It is obvious that the identification of the client, including
            construction of economic profile and appropriateness/suitability
            assessment, shall take place prior the establishment of the business
            relationship with the client.
          </p>
        </div>
        <div class="content V3">
          <p>
            b- Account-Opening Procedure
          </p>
          <p>
            i- The client completes the account-opening forms filling all
            required information.
          </p>
          <p>
            ii- The responsible administrator collects all of the client’s
            initial information and forwards it directly to Senior Management,
            as well as to the Anti-Money Laundering Compliance Officer for
            examination, review and approval.
          </p>
          <p>
            iii- Following the approval, the administrator records all necessary
            information into the Company’s software systems and communicates it
            to the related departments.
          </p>
        </div>
        <div class="four-title">5.2.2- KYC Documentation</div>
        <div class="content V3">
          <p>
            Prior to accepting new clients and allowing them to trade with the
            Company, the following documents shall be obtained for the
            verification of clients’ identity:
          </p>
          <p>
            a- Natural Persons
          </p>
          <p>
            The identification documents required from natural persons
            (Individual clients) in order to efficiently implement the Company’s
            KYC procedures are as follows:
          </p>
        </div>
        <div class="content V4">
          <p>
            i- Proof of Identity
          </p>
          <p>
            A Valid government-issued Proof of Identity, (Passport, National ID
            Card, Driving License, …) which should include Client’s Full Name,
            Client’s Date of Birth, Client’s Photograph, and Validity Status
            (Date of Expiry and/or Date Issuance + Validity Period)
          </p>
          <p>
            ii- Proof of Residence
          </p>
          <p>
            A Recent proof of home address in the person’s name (Bank Statement,
            Utility Bills, Phone Bills, ..) which should include Client’s Full
            Name, Client’s Home Address, and Date of Issuance (must not be more
            than 6 months old).
          </p>
        </div>
        <div class="content V3">
          <p>
            b- Legal Persons
          </p>
          <p>
            A different identification procedure is followed for Legal Persons
            (corporate clients) interested in opening an account with the
            Company. These documentation requirements are presented below:
          </p>
        </div>
        <div class="content V4">
          <p>
            i- Incorporation Documents
          </p>
          <p>
            The form and name of corporate documents may vary depending on
            country of incorporation and/or legal form of the company. However
            the required government-issued Corporate Documents should include
            Corporation name, Date and Place of Incorporation, Registered Office
            Address, Directors and authorised signatories,
            Ownership/shareholding structure (Shareholders names and
            shareholding percentage), Corporate registered activities.
          </p>
          <p>
            These documents may include but not limited to, Certificate of
            incorporation or Certificate of Registration, Certificate of
            Registered Office, Certificate of Directors and Secretary,
            Certificate of Registered Shareholders, Memorandum and articles of
            association,
          </p>
          <p>
            ii- Directors and Beneficial Owners Documents
          </p>
          <p>
            Personal KYC and identification documents are required from:
          </p>
          <p>
            - Legal Person’s Directors
          </p>
          <p>
            - Legal Person’s Ultimate Beneficial Owners with 10% beneficial
            ownership or more.
          </p>
          <p>
            These identification documents include Proof of Identity and Proof
            of Residence.
          </p>
          <p>
            iii- Board Resolution
          </p>
          <p>
            A resolution of the board of directors of the legal person for the
            opening of the account and granting authority to those who will
            operate it.
          </p>
        </div>
        <div class="third-title">5.3- Record Keeping Procedures</div>
        <div class="content V2">
          <p>
            The Company should keep the below listed documents and information
            for use in any investigation into, or analysis of, possible money
            laundering or terrorist financing by national authorities.
          </p>
          <p>
            The retention of the documents/data, other than the original
            documents or their certified true copies that are kept in a hard
            copy form, may be in other forms, such as electronic form, provided
            that the Company is able to retrieve the relevant documents/data
            without undue delay and present them at any time, to the relevant
            authorities, after a request. A true translation is attached in the
            case that the documents/data are in a language other than English.
          </p>
        </div>
        <div class="content V3">
          <p>
            a- The name and address of clients and copies or records of official
            identification documents (like passports, identity cards, or driving
            licenses).
          </p>
          <p>
            b- The name and address (or identification code) of counterparties.
          </p>
          <p>
            c- The form of instruction or authority.
          </p>
          <p>
            d- The account details from which any funds were paid.
          </p>
          <p>
            e- The form and destination of payment made by the business to the
            client.
          </p>
          <p>
            f- Business correspondence.
          </p>
          <p>
            g- For client due diligence, a copy of the references of the
            evidence is required, for a period of at least 5 years after the
            business relationship with the client has ended.
          </p>
          <p>
            h- For business relationship and transactions, the supporting
            evidence and records for a period of at least five years following
            the carrying out of the transactions, or the end of the business
            relationship.
          </p>
        </div>
        <div class="third-title">5.4- Suspicious Transactions Reporting</div>
        <div class="content V2">
          <p>
            A suspicious transaction is a transaction which is inconsistent with
            a client's known, legitimate business or personal activities or with
            the normal business of the specific account, or in general with the
            economic profile that the Company has created for the client.
          </p>
          <p>
            The Company ensures maintaining adequate information at all time and
            knows enough about its clients' activities in order to recognize on
            time that a transaction or a series of transactions is/are unusual
            or suspicious.
          </p>
        </div>
        <div class="four-title">5.4.1- Examples of Suspicious Transactions</div>
        <div class="content V3">
          <p>
            Examples of what might constitute suspicious transactions/activities
            related to money laundering and terrorist financing include but not
            limited to:
          </p>
        </div>
        <div class="content V3">
          <p>
            a- Transactions with no discernible purpose or are unnecessarily
            complex.
          </p>
          <p>
            b- Use of foreign accounts of companies, or group of companies with
            complicated ownership structures, which is not justified based on
            the needs and economic profile of the customer.
          </p>
          <p>
            c- Large volume of transactions and/or money deposited or credited
            into an account, when the nature of the customer’s business
            activities would not appear to justify such activity.
          </p>
          <p>
            d- There is no visible justification for a customer using the
            services of a particular financial organisation.
          </p>
          <p>
            e- There are frequent transactions in the same financial instrument
            without obvious reason and in conditions that appear unusual.
          </p>
          <p>
            f- There are frequent small purchases of a particular financial
            instrument by a customer who settles in cash, and then the total
            number of the financial instruments is sold in one transaction with
            settlement in cash, or with the proceeds being transferred with the
            customer’s instructions into an account different than his own.
          </p>
          <p>
            g- There are frequent small purchases of a particular financial
            instrument by a customer who settles in cash, and then the total
            number of the financial instrument is sold in one transaction with
            settlement in cash or with the proceeds being transferred, with the
            customer’s instructions, in an account other than his usual account.
          </p>
          <p>
            h- Transactions which are not in line with the prevailing market
            conditions, particularly in relation with the order’s size and
            frequency.
          </p>
          <p>
            i- The settlement of any transaction – mainly large ones – in cash
            and/or through a third party, which did not give the order.
          </p>
          <p>
            j- The settlement of any transaction but mainly large transactions
            in cash and/or settlement of the transaction by a third person which
            is different than the customer which gave the order.
          </p>
          <p>
            k- The transfer of funds to and from countries, or geographical
            areas, which do not apply, or inadequately apply FAFT’s
            Recommendations on money laundering and terrorist financing.
          </p>
          <p>
            l- Reluctance in providing complete personal information upon
            establishing a business relationship, namely regarding the nature
            and purpose of its business activities, anticipated account
            activity, prior relationships with financial organisations, names of
            its officers and directors, or business address.
          </p>
          <p>
            m- Providing information that are minimal, difficult or expensive
            for the Company to verify.
          </p>
          <p>
            n- Providing unusual or suspicious identification documents that
            cannot be readily verified.
          </p>
          <p>
            o- Frequent or large transactions by a customer with no record of
            past or present employment experience.
          </p>
          <p>
            p- The introduction of a customer via a foreign financial
            organisation, or third party whose country(ies) or geographical
            area(s) of origin do not apply or inadequately apply FATF’s
            Recommendations on money laundering and terrorist financing.
          </p>
          <p>
            q- The use of an address that is linked to individuals involved in
            cash transactions, particularly when that same address does not
            correspond with the stated occupation (e.g. student, unemployed,
            self-employed, etc.)
          </p>
          <p>
            r- Shared address for individuals involved in cash transactions,
            particularly when the address is also a business location and/or
            does not seem to correspond to the stated occupation (e.g. student,
            unemployed, self-employed, etc).
          </p>
          <p>
            s- The stated occupation of the customer is not commensurate with
            the level or size of the executed transactions.
          </p>
          <p>
            t- Use of general nominee documents in a way that restricts the
            control exercised by the company’s board of directors.
          </p>
        </div>
        <div class="four-title">
          5.4.2- Suspicious Transaction-Reporting Procedure
        </div>
        <div class="content V3">
          <p>
            The procedure to report a client’s suspicious transaction is as
            follows:
          </p>
        </div>
        <div class="content V3">
          <p>
            a- The reports of Company’s employees from different departments are
            evaluated by the AML Compliance Officer.
          </p>
          <p>
            b- If deemed necessary, the Compliance Officer shall notify the
            relevant Money Laundering Authorities.
          </p>
          <p>
            c- Following the report’s submission, the concerned customer’s
            account(s), as well as any other connected accounts, are placed
            under close monitoring by the Compliance Officer.
          </p>
          <p>
            d- After submitting the report, the Company adheres to any
            instructions given by relevant Money Laundering Authorities, and in
            particular, as to whether or not to resume or suspend a particular
            transaction, or to maintain the particular account active.
          </p>
          <p>
            e- Transactions executed for the customer are compared and evaluated
            against the anticipated account’s turnover, the usual turnover of
            the activities/operations of the customer and the data and
            information kept for the customer’s economic profile.
          </p>
          <p>
            f- Significant deviations are investigated, and the findings are
            recorded in the respective customer’s file.
          </p>
          <p>
            g- Transactions that are not justified by the available information
            on the customer are thoroughly examined so as to determine whether
            suspicion over money laundering or terrorist financing arise. Should
            this be the case, a report will be respectively submitted to the
            Compliance Officer and then to relevant Money Laundering
            Authorities.
          </p>
        </div>
        <div class="third-title">
          5.5- AML Compliance Officer Daily/Monthly Procedures
        </div>
        <div class="content V2">
          <p>
            The procedure to be followed by the AML compliance officer on a
            daily/monthly basis is as follows:
          </p>
          <p>
            i. Receive a daily report from staff members regarding any
            suspicious transactions.
          </p>
          <p>
            ii. Examine submitted reports (if any)
          </p>
          <p>
            iii. Inform Senior Management and advise on the necessity of taking
            any of the below actions.
          </p>
          <p>
            iv. Halt the transactions if reports are in process.
          </p>
          <p>
            v. Inform the client of the reasons why his transaction has been
            cancelled.
          </p>
          <p>
            vi. Collect the transaction information if it has already been
            executed.
          </p>
          <p>
            vii. Inform the appropriate authorities of the suspicious
            transaction as per the law.
          </p>
        </div>
        <div class="second-title">6- Personal Education and Training</div>
        <div class="content ">
          <p>
            The Company ensures that its employees are fully aware of their
            legal obligations in accordance with the law on the prevention of
            money laundering and terrorist financing through a complete employee
            education and training program.
          </p>
          <p>
            The training program aims at educating employees on the latest
            developments in the prevention of money laundering and terrorist
            financing, including the practical methods and trends used for this
            purpose.
          </p>
          <p>
            The training program ensures that Company’s employees are fully
            aware that they can be personally liable for failing to report
            information or suspicion regarding money laundering or terrorist
            financing. The timing and content of the training provided to
            employees of various departments is adjusted according to the needs
            of each department.
          </p>
          <p>
            The frequency of the training can vary depending on to the
            amendments of legal and/or regulatory requirements, employees’
            duties as well as any other changes in the financial system.
          </p>
          <p>
            The training program’s structure will be in line with the needs, as
            well as the various functions, of new employees, existing ones, as
            well as for the different departments within the Company.
          </p>
          <p>
            On-going training is given at regular intervals to ensure that the
            employees are reminded of their duties and responsibilities and kept
            informed of any new developments.
          </p>
          <p>
            Any personal information collected about the client such as name,
            address, date of birth and contact details will be maintained with
            bitda strictly for business purposes. Other information such as
            client transactions, copies of passports and proof of addresses will
            remain confidential and shared only between our account services and
            compliance departments. bitda may additionally inquire about the
            credit worthiness of the client, which will also remain confidential
            within our customer files. Such information may be maintained either
            physically or electronically with strict access procedures.
          </p>
          <p>
            bitda may share client information with internal departments or
            affiliate offices who conduct marketing, back-office and customer
            service functions to accomplish normal business operations. Each
            employee within the bitda has signed a Confidentiality Agreement as
            client information is required to be kept confidential.
          </p>

          <p>
            Any questions or additional information regarding our privacy policy
            may be directed to our Customer Service Department
            at support@bitda.io.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
  },
  created() {},
  methods: {
    translateTitle,
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style lang="scss">
.kyc-notice {
  background: #fff;
  //   height: 100%;
  .el-menu {
    border-right: none;
    .el-menu-item {
      padding-left: 0 !important;
      text-align: left;
      color: #354052;
      line-height: 2;
    }
    .el-menu-item.is-active {
      font-weight: 600;
      background-color: white !important;
    }
  }
  .header {
    height: 80px;
    background: url("../../assets/img/notice/notice-bg.png") 0 0 repeat;
    .header-content {
      color: #fff;
      width: 1200px;
      margin: 0 auto;
      font-size: 19px;
      font-weight: 600;
      line-height: 80px;
    }
  }
  .notice-content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 42px;
    padding-bottom: 42px;
    display: flex;
    justify-content: space-between;
  }
  .left-menu {
    width: 200px;
  }
  .right-content {
    width: 930px;
    color: #354052;
    .title {
      font-size: 24px;
      font-weight: 500;
      color: #354052;
      margin-bottom: 24px;
    }
    .second-title {
      margin: 15px 0;
    }
    .third-title {
      margin-left: 25px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .four-title {
      margin: 5px 0;
      margin-left: 50px;
    }
    .five-title {
      margin: 5px 0;
      margin-left: 70px;
    }
    .six-title {
      margin: 5px 0;
      margin-left: 90px;
    }
    .content {
      margin-left: 10px;
      p {
        margin-top: 5px;
        margin-bottom: 5px;
        text-indent: 25px;
      }
      &.V2 {
        margin-left: 15px;
      }
      &.V3 {
        margin-left: 35px;
      }
      &.V4 {
        margin-left: 50px;
      }
      &.V5 {
        margin-left: 75px;
      }
      &.V6 {
        margin-left: 90px;
      }
    }
  }
}
</style>
