<template>
  <div class="uc-bill-center">
    <!-- U本位仓位详情开始 -->
    <el-dialog
      width="500px"
      :title="
        (position_order_obj.operate_list[0] &&
        position_order_obj.operate_list[0].operate_type == 2
          ? translateTitle('平仓')
          : translateTitle('持仓')) + translateTitle('详情')
      "
      custom-class="liquidation-dialog"
      class="order-dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible="reChargeDialogVisible"
      :before-close="reChargehandleClose"
      @closed="reChargehandleClosed"
      :append-to-body="false"
    >
      <div class="dialog-body" v-loading="liquidation_dialog_loading">
        <div class="intro">
          <div class="order-status">
            <span class="symbol-name">{{
              liquidation_symbol_name || "--"
            }}</span>
            <span
              class="transaction-multiple"
              :class="
                position_order_obj.position_info.open_side == 1
                  ? 'income-color-green'
                  : 'income-color-red'
              "
              >{{
                position_order_obj.position_info.open_side == 1
                  ? translateTitle("多")
                  : translateTitle("空")
              }}·{{
                parseInt(position_order_obj.position_info.open_leverage) ||
                "--"
              }}X</span
            >
            <span class="price-share">
              <span
                class="last-price"
                v-if="
                  !(
                    position_order_obj.operate_list[0] &&
                    position_order_obj.operate_list[0].operate_type == 2
                  )
                "
                >{{ position_order_obj.position_info.last_price || "--" }}</span
              >
              <span
                class="iconfont iconswap-8 share-img"
                @click="createProfitLossPoster(position_order_obj)"
              ></span>
            </span>
          </div>
          <div class="income-status">
            <span class="income-num"
              >{{ translateTitle("收益") }}({{
                (coinData[
                  position_order_obj.position_info.settlement_item_id
                ] &&
                  coinData[
                    position_order_obj.position_info.settlement_item_id
                  ].item_name.toUpperCase()) ||
                "--"
              }})</span
            >
            <span class="income-num">{{ translateTitle("收益率") }}</span>
          </div>

          <div
            class="income-data-status"
            v-if="
              position_order_obj.operate_list[0] &&
              position_order_obj.operate_list[0].operate_type == 2
            "
          >
            <span class="income-data-num num DINPro">
              <span v-if="position_order_obj.position_info.profit_loss >= 0"
                >+</span
              >{{
                isNaN(position_order_obj.position_info.profit_loss)
                  ? "--"
                  : position_order_obj.position_info.profit_loss
              }}
            </span>
            <span
              class="income-data-num num DINPro"
              :class="
                position_order_obj.position_info.profit_loss_ratio >= 0
                  ? 'income-color-green'
                  : 'income-color-red'
              "
            >
              <span
                v-if="position_order_obj.position_info.profit_loss_ratio >= 0"
                >+</span
              >{{
                isNaN(position_order_obj.position_info.profit_loss_ratio)
                  ? "--"
                  : position_order_obj.position_info.profit_loss_ratio
              }}%</span
            >
          </div>
          <div class="income-data-status" v-else>
            <span class="income-data-num num DINPro">
              <span
                v-if="
                  position_order_obj.position_info.position_profit_loss >= 0
                "
                >+</span
              >{{
                isNaN(position_order_obj.position_info.position_profit_loss)
                  ? "--"
                  : position_order_obj.position_info.position_profit_loss
              }}</span
            >
            <span
              class="income-data-num num DINPro"
              :class="
                position_order_obj.position_info.position_profit_loss_procent >=
                0
                  ? 'income-color-green'
                  : 'income-color-red'
              "
            >
              <span
                v-if="
                  position_order_obj.position_info
                    .position_profit_loss_procent >= 0
                "
                >+</span
              >{{
                isNaN(
                  position_order_obj.position_info.position_profit_loss_procent
                )
                  ? "--"
                  : position_order_obj.position_info
                      .position_profit_loss_procent
              }}%</span
            >
          </div>

          <div class="splite-line"></div>
          <div class="transaction-data">
            <div class="opening-price-left">
              <span class="title">{{ translateTitle("开仓均价") }}</span>
              <span class="content">{{
                position_order_obj.position_info.open_price
              }}</span>
            </div>
            <div class="opening-price-middle">
              <span class="title"
                >{{ translateTitle("本金") }}({{
                  coinData[
                    position_order_obj.position_info.settlement_item_id
                  ] &&
                  coinData[
                    position_order_obj.position_info.settlement_item_id
                  ].item_name.toUpperCase()
                }})</span
              >
              <span class="content">{{
                position_order_obj.position_info.bail_number
              }}</span>
            </div>
            <div class="opening-price-right">
              <span class="title"
                >{{ translateTitle("持仓数量") }}({{
                  coinData[position_order_obj.position_info.open_item_id] &&
                  coinData[
                    position_order_obj.position_info.open_item_id
                  ].item_name.toUpperCase()
                }})</span
              >
              <span class="content">{{
                position_order_obj.position_info.open_interest
              }}</span>
            </div>
          </div>
          <div class="transaction-data">
            <div class="opening-price-left">
              <span class="title">{{ translateTitle("强平价") }}</span>
              <span class="content">{{
                position_order_obj.position_info.liquidation_price
              }}</span>
            </div>
            <div class="opening-price-middle">
              <span class="title">{{ translateTitle("止损价") }}</span>
              <span class="content">{{
                position_order_obj.position_info.stop_loss_price
              }}</span>
            </div>
            <div class="opening-price-right">
              <span class="title">{{ translateTitle("止盈价") }}</span>
              <span class="content">{{
                position_order_obj.position_info.stop_profit_price
              }}</span>
            </div>
          </div>

          <div
            class="transaction-data"
            v-if="
              position_order_obj.operate_list[0] &&
              position_order_obj.operate_list[0].operate_type == 2
            "
          >
            <div class="opening-price-left">
              <span class="title">{{ translateTitle("平仓价") }}</span>
              <span class="content">{{
                position_order_obj.position_info.close_price
              }}</span>
            </div>
            <div class="opening-price-middle">
              <span class="title">{{ translateTitle("平仓类型") }}</span>
              <span class="content">{{
                translateTitle(
                  closePositionTypeMap[
                    position_order_obj.position_info.close_position_type
                  ]
                )
              }}</span>
            </div>
            <div class="opening-price-right">
              <el-tooltip
                :visible-arrow="true"
                placement="top-start"
                :popper-options="{
                  boundariesElement: 'viewport',
                  removeOnDestroy: true,
                }"
              >
                <div slot="content">
                  {{ translateTitle("平仓后，持仓本金和平仓盈亏会归还到余额")
                  }}<br />{{ translateTitle("余额归还=持仓本金+平仓盈亏") }}
                </div>
                <span class="title under-line"
                  >{{ translateTitle("余额归还") }}({{
                    coinData[
                      position_order_obj.position_info.settlement_item_id
                    ] &&
                    coinData[
                      position_order_obj.position_info.settlement_item_id
                    ].item_name.toUpperCase()
                  }})</span
                >
              </el-tooltip>
              <span class="content">{{
                position_order_obj.position_info.balance_change_number
              }}</span>
            </div>
          </div>

          <div class="transaction-data" v-else>
            <div class="opening-price-left">
              <span
                class="position-button"
                @click="onOpenAppendMoney(position_order_obj.position_info)"
                >{{ translateTitle("追加本金") }}</span
              >
            </div>
            <div class="opening-price-middle">
              <span
                class="position-button"
                @click="onProfitPriceEdit(position_order_obj.position_info)"
                >{{ translateTitle("止盈止损") }}</span
              >
            </div>
            <div class="opening-price-right">
              <span
                class="position-button-blue"
                @click="
                  onOpenConfirm(
                    1,
                    position_order_obj.position_info.position_order_id
                  )
                "
                >{{ translateTitle("平仓") }}</span
              >
            </div>
          </div>
        </div>
        <div class="do-recode">{{ translateTitle("操作记录") }}</div>
        <div class="time-block">
          <el-timeline>
            <el-timeline-item
              placement="bottom"
              v-for="(item, index) in position_order_obj.operate_list"
              :key="index"
            >
              <el-card>
                <div
                  class="cardStartAndEnd"
                  v-if="
                    item.operate_type == 1 ||
                    item.operate_type == 2 ||
                    item.operate_type == 6
                  "
                >
                  <span class="time-copyinfo">
                    <span class="time">{{
                      moment(item.create_time * 1000).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    }}</span>
                    <span
                      class="copyinfo"
                      v-if="item.copy_trader && item.copy_trader.nick_name"
                      ><span class="title">{{ translateTitle("跟随") }}：</span
                      ><span class="info">{{
                        item.copy_trader.nick_name
                      }}</span></span
                    >
                    <span class="copyinfo" v-else></span>
                  </span>
                  <span class="titleStartAndEnd"
                    ><span>{{
                      translateTitle(operateTypeMap[item.operate_type])
                    }}</span
                    ><span style="font-weight: 500">{{
                      `  ${liquidation_symbol_name}`
                    }}</span
                    ><span
                      :class="
                        item.order_info.open_side == 1
                          ? 'income-color-green'
                          : 'income-color-red'
                      "
                    >
                      {{
                        item.order_info.open_side == 1
                          ? translateTitle("多")
                          : translateTitle("空")
                      }}·{{ parseInt(item.order_info.open_leverage) }}X</span
                    ></span
                  >
                  <div class="contentStartAndEnd">
                    <span class="sub-title">{{ translateTitle("价格") }}</span
                    ><span class="sub-content">{{
                      item.operate_type == 1
                        ? item.order_info.open_price
                        : item.order_info.close_price
                    }}</span>
                  </div>
                  <div class="contentStartAndEnd">
                    <span class="sub-title"
                      >{{ translateTitle("数量") }} ({{
                        coinData[
                          position_order_obj.position_info.open_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.open_item_id
                        ].item_name.toUpperCase()
                      }})</span
                    ><span class="sub-content">{{
                      item.order_info.open_interest
                    }}</span>
                  </div>
                  <div class="contentStartAndEnd">
                    <span class="sub-title"
                      >{{ translateTitle("金额") }} ({{
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ].item_name.toUpperCase()
                      }})</span
                    ><span class="sub-content">{{
                      item.order_info.bail_number
                    }}</span>
                  </div>
                  <div class="contentStartAndEnd">
                    <span class="sub-title"
                      >{{ translateTitle("手续费") }} ({{
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ].item_name.toUpperCase()
                      }})</span
                    >
                    <span
                      class="sub-content"
                      v-if="
                        currRow.assets_type == 35 || currRow.assets_type == 36
                      "
                      >{{
                        item.operate_type == 1
                          ? item.order_info.open_position_fee
                          : item.order_info.close_position_fee
                      }}<span class="rate">
                        [{{
                          item.operate_type == 1
                            ? position_order_obj.position_info
                                .open_position_rate
                            : position_order_obj.position_info
                                .close_position_rate
                        }}]</span
                      ></span
                    >

                    <span
                      class="sub-content"
                      v-if="
                        currRow.assets_type == 53 || currRow.assets_type == 54
                      "
                      >{{ item.order_info.fee_number
                      }}<span class="rate">
                        [{{ position_order_obj.position_info.fee_rate }}]</span
                      ></span
                    >
                  </div>
                  <!--&& item.order_info.liquidation_fee_number > 0 -->
                  <div
                    class="contentStartAndEnd"
                    v-if="
                      position_order_obj.position_info.close_position_type == 3
                    "
                  >
                    <el-tooltip
                      :visible-arrow="true"
                      placement="top-start"
                      :popper-options="{
                        boundariesElement: 'viewport',
                        removeOnDestroy: true,
                      }"
                    >
                      <div slot="content">
                        {{
                          translateTitle(
                            "当用户强平时，将收取强平清算费并存入风险基金"
                          )
                        }}
                      </div>
                      <span class="sub-title under-line">
                        {{ translateTitle("强平清算费") }} ({{
                          coinData[
                            position_order_obj.position_info.settlement_item_id
                          ] &&
                          coinData[
                            position_order_obj.position_info.settlement_item_id
                          ].item_name.toUpperCase()
                        }})</span
                      >
                    </el-tooltip>
                    <span class="sub-content">{{
                      item.order_info.liquidation_fee_number
                    }}</span>
                  </div>
                </div>
                <!-- 加仓 -->
                <div class="cardStartAndEnd" v-else-if="item.operate_type == 8">
                  <span class="time-copyinfo">
                    <span class="time">{{
                      moment(item.create_time * 1000).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    }}</span>
                    <span
                      class="copyinfo"
                      v-if="item.copy_trader && item.copy_trader.nick_name"
                      ><span class="title">{{ translateTitle("跟随") }}：</span
                      ><span class="info">{{
                        item.copy_trader.nick_name
                      }}</span></span
                    >
                    <span class="copyinfo" v-else></span>
                  </span>
                  <span class="titleStartAndEnd"
                    ><span>{{
                      translateTitle(operateTypeMap[item.operate_type])
                    }}</span
                    ><span style="font-weight: 500">{{
                      `  ${liquidation_symbol_name}`
                    }}</span>
                    <span
                      class="income-color-green"
                      :class="
                        item.order_info.open_side == 1
                          ? 'income-color-green'
                          : 'income-color-red'
                      "
                    >
                      {{
                        item.order_info.open_side == 1
                          ? translateTitle("多")
                          : translateTitle("空")
                      }}·{{
                        parseInt(
                          position_order_obj.position_info.open_leverage
                        )
                      }}X</span
                    >
                  </span>
                  <div class="contentStartAndEnd">
                    <span class="sub-title">{{ translateTitle("价格") }}</span
                    ><span class="sub-content">{{
                      item.order_info.open_price
                    }}</span>
                  </div>

                  <div class="contentStartAndEnd">
                    <span class="sub-title"
                      >{{ translateTitle("数量") }} ({{
                        coinData[
                          position_order_obj.position_info.open_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.open_item_id
                        ].item_name.toUpperCase()
                      }})</span
                    ><span class="sub-content">{{
                      item.order_info.open_interest
                    }}</span>
                  </div>
                  <div class="contentStartAndEnd">
                    <span class="sub-title"
                      >{{ translateTitle("金额") }} ({{
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ].item_name.toUpperCase()
                      }})</span
                    ><span class="sub-content">{{
                      item.order_info.bail_number
                    }}</span>
                  </div>
                  <div class="contentStartAndEnd">
                    <span class="sub-title"
                      >{{ translateTitle("手续费") }} ({{
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ].item_name.toUpperCase()
                      }})</span
                    >

                    <span
                      class="sub-content"
                      v-if="
                        currRow.assets_type == 35 || currRow.assets_type == 36
                      "
                      >{{
                        item.operate_type == 1
                          ? item.order_info.open_position_fee
                          : item.order_info.close_position_fee
                      }}<span class="rate">
                        [{{
                          item.operate_type == 1
                            ? position_order_obj.position_info
                                .open_position_rate
                            : position_order_obj.position_info
                                .close_position_rate
                        }}]</span
                      ></span
                    >

                    <span
                      class="sub-content"
                      v-if="
                        currRow.assets_type == 53 || currRow.assets_type == 54
                      "
                      >{{ item.order_info.fee_number
                      }}<span class="rate">
                        [{{ position_order_obj.position_info.fee_rate }}]</span
                      ></span
                    >
                  </div>
                </div>

                <!-- 减仓 -->
                <div class="cardStartAndEnd" v-else-if="item.operate_type == 9">
                  <span class="time-copyinfo">
                    <span class="time">{{
                      moment(item.create_time * 1000).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    }}</span>
                    <span
                      class="copyinfo"
                      v-if="item.copy_trader && item.copy_trader.nick_name"
                      ><span class="title">{{ translateTitle("跟随") }}：</span
                      ><span class="info">{{
                        item.copy_trader.nick_name
                      }}</span></span
                    >
                    <span class="copyinfo" v-else></span>
                  </span>
                  <span class="titleStartAndEnd"
                    ><span>{{
                      translateTitle(operateTypeMap[item.operate_type])
                    }}</span
                    ><span style="font-weight: 500">{{
                      `  ${liquidation_symbol_name}`
                    }}</span>
                    <span
                      class="income-color-green"
                      :class="
                        item.order_info.open_side == 1
                          ? 'income-color-green'
                          : 'income-color-red'
                      "
                    >
                      {{
                        item.order_info.open_side == 1
                          ? translateTitle("多")
                          : translateTitle("空")
                      }}·{{
                        parseInt(
                          position_order_obj.position_info.open_leverage
                        )
                      }}X</span
                    >
                  </span>
                  <div class="contentStartAndEnd">
                    <span class="sub-title">{{ translateTitle("价格") }}</span
                    ><span class="sub-content">{{
                      item.order_info.open_price
                    }}</span>
                  </div>

                  <div class="contentStartAndEnd">
                    <span class="sub-title"
                      >{{ translateTitle("数量") }} ({{
                        coinData[
                          position_order_obj.position_info.open_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.open_item_id
                        ].item_name.toUpperCase()
                      }})</span
                    ><span class="sub-content">{{
                      item.order_info.open_interest
                    }}</span>
                  </div>
                  <div class="contentStartAndEnd">
                    <span class="sub-title"
                      >{{ translateTitle("金额") }} ({{
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ].item_name.toUpperCase()
                      }})</span
                    ><span class="sub-content">{{
                      item.order_info.bail_number
                    }}</span>
                  </div>
                  <div class="contentStartAndEnd">
                    <span class="sub-title"
                      >{{ translateTitle("手续费") }} ({{
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ].item_name.toUpperCase()
                      }})</span
                    >

                    <span
                      class="sub-content"
                      v-if="
                        currRow.assets_type == 35 || currRow.assets_type == 36
                      "
                      >{{
                        item.operate_type == 1
                          ? item.order_info.open_position_fee
                          : item.order_info.close_position_fee
                      }}<span class="rate">
                        [{{
                          item.operate_type == 1
                            ? position_order_obj.position_info
                                .open_position_rate
                            : position_order_obj.position_info
                                .close_position_rate
                        }}]</span
                      ></span
                    >

                    <span
                      class="sub-content"
                      v-if="
                        currRow.assets_type == 53 || currRow.assets_type == 54
                      "
                      >{{ item.order_info.fee_number
                      }}<span class="rate">
                        [{{ position_order_obj.position_info.fee_rate }}]</span
                      ></span
                    >
                  </div>

                  <div class="contentStartAndEnd">
                    <span class="sub-title"
                      >{{ translateTitle("余额归还") }} ({{
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ].item_name.toUpperCase()
                      }})</span
                    ><span class="sub-content">{{
                      item.order_info.balance_change_number
                    }}</span>
                  </div>
                </div>

                <div
                  class="cardStep"
                  v-else-if="
                    item.operate_type == 3 ||
                    item.operate_type == 4 ||
                    item.operate_type == 7
                  "
                >
                  <span class="time">{{
                    moment(item.create_time * 1000).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>

                  <div class="contentStep" v-if="item.operate_param[0]">
                    <span class="sub-title">{{
                      translateTitle("设置止盈价")
                    }}</span>
                    <span class="sub-content"
                      >{{ item.operate_param[0] }}
                      {{
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ].item_name.toUpperCase()
                      }}</span
                    >
                  </div>
                  <div class="contentStep" v-else>
                    <span class="sub-title">{{
                      translateTitle("清除止盈价")
                    }}</span>
                    <span class="sub-content"
                      >{{ item.operate_param[0] }}
                    </span>
                  </div>

                  <div class="contentStep" v-if="item.operate_param[1]">
                    <span class="sub-title">{{
                      translateTitle("设置止损价")
                    }}</span>
                    <span class="sub-content"
                      >{{ item.operate_param[1] }}
                      {{
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ].item_name.toUpperCase()
                      }}</span
                    >
                  </div>
                  <div class="contentStep" v-else>
                    <span class="sub-title">{{
                      translateTitle("清除止损价")
                    }}</span>
                    <span class="sub-content"
                      >{{ item.operate_param[1] }}
                    </span>
                  </div>
                </div>

                <div class="cardStep" v-else-if="item.operate_type == 5">
                  <span class="time-copyinfo">
                    <span class="time">{{
                      moment(item.create_time * 1000).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    }}</span>

                    <span
                      class="copyinfo"
                      v-if="item.copy_trader && item.copy_trader.nick_name"
                      ><span class="title">{{ translateTitle("跟随") }}：</span
                      ><span class="info">{{
                        item.copy_trader.nick_name
                      }}</span></span
                    >
                    <span class="copyinfo" v-else></span>
                  </span>
                  <div class="contentStep">
                    <span class="sub-title">{{
                      translateTitle(operateTypeMap[item.operate_type])
                    }}</span>

                    <span class="sub-content"
                      >{{ handleCutZero(item.operate_param[0]) }}
                      {{
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ].item_name.toUpperCase()
                      }}</span
                    >
                  </div>
                </div>
                <div class="cardStep" v-else>
                  <span class="time">{{
                    moment(item.create_time * 1000).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <div class="contentStep">
                    <span class="sub-title">{{
                      translateTitle(operateTypeMap[item.operate_type])
                    }}</span>

                    <span class="sub-content"
                      >{{ item.order_info.fund_rate }}
                      {{
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ] &&
                        coinData[
                          position_order_obj.position_info.settlement_item_id
                        ].item_name.toUpperCase()
                      }}</span
                    >
                  </div>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </el-dialog>
    <!-- U本位仓位详情结束 -->
    <el-dialog
      custom-class="append-money-dialog"
      :title="translateTitle('追加本金')"
      :visible.sync="appendMoneydialog"
      :before-close="appendMoneydialogClose"
      :close-on-click-modal="false"
      width="500px"
    >
      <p class="first-content">
        <span class="left-item">{{ translateTitle("追加金额") }}</span>
        <span>
          <span class="available-assets"
            >{{ translateTitle("可用") }} {{ assetsInfoShow }}
            {{
              [35, 36].includes(currRow.assets_type)
                ? to_symbol.toUpperCase()
                : from_symbol.toUpperCase()
            }}
          </span>
          <span class="common-operate" @click="goToRecharge">
            {{ translateTitle("充值") }}</span
          ></span
        >
      </p>
      <div class="second-content">
        <InputNumber
          v-model="bail_number"
          :placeholder="translateTitle('请输入追加金额')"
          maxlength="14"
          :decimals="
            [35, 36].includes(currRow.assets_type)
              ? price_precision
              : settlement_precision
          "
          :suffix="
            [35, 36].includes(currRow.assets_type)
              ? to_symbol.toUpperCase()
              : from_symbol.toUpperCase()
          "
        ></InputNumber>
        <span class="all-btn common-operate" @click="onClickAll">{{
          translateTitle("全部")
        }}</span>
      </div>
      <div class="three-content">
        <div class="item-one">
          <div class="left-item">
            <span class="black DINPro">{{ beforeBailNumber }}</span>
            <span>{{ translateTitle("当前本金") }}</span>
          </div>
          <span><img src="@/assets/img/tran-to.png" alt="" /></span>
          <div class="right-item">
            <span class="red DINPro">{{ afterBailNumberShow }}</span>
            <span>{{ translateTitle("追加后本金") }}</span>
          </div>
        </div>
        <div class="item-two">
          <div class="left-item">
            <span class="black DINPro">{{ beforeLiquidationPrice }}</span>
            <span>{{ translateTitle("当前强平价") }}</span>
          </div>
          <span><img src="@/assets/img/tran-to.png" alt="" /></span>
          <div class="right-item">
            <span class="red DINPro">{{ afterLiquidationPriceShow }}</span>
            <span>{{ translateTitle("追加后强平价") }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="appendMoneydialogClose">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="onAppendMoney">{{
          translateTitle("确定")
        }}</span>
      </span>
    </el-dialog>
    <!-- OTC 充值 提现 详情页开始 -->
    <el-dialog
      width="500px"
      :title="
        user_order_obj.isRechargeWithdrawOrder
          ? user_order_obj.isBuy
            ? translateTitle('充值详情')
            : translateTitle('提现详情')
          : translateTitle('订单详情')
      "
      custom-class="reCharge-dialog"
      class="order-dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="otcDialogVisible"
      :before-close="otcHandleClose"
    >
      <div class="intro">
        <div class="reCharge-status">
          <div class="icon-title">
            <img
              v-if="user_order_obj.isRechargeWithdrawOrder ? false : true"
              class="reChargeIcon"
              :src="
                coinData[user_order_obj.item_id] &&
                coinData[user_order_obj.item_id].itemDetail.coin_url
              "
            />
            <span class="reChargeTitle">{{
              user_order_obj.isRechargeWithdrawOrder
                ? user_order_obj.isBuy
                  ? translateTitle("充值")
                  : translateTitle("提现")
                : user_order_obj.isBuy
                ? translateTitle("购买") +
                  (coinData[user_order_obj.item_id] &&
                    coinData[user_order_obj.item_id].item_name.toUpperCase())
                : translateTitle("出售") +
                  (coinData[user_order_obj.item_id] &&
                    coinData[user_order_obj.item_id].item_name.toUpperCase())
            }}</span>
          </div>
          <span
            class="status"
            :class="
              ['1', '2', '3'].includes(user_order_obj.order_status)
                ? 'status-yellow'
                : ''
            "
            >{{
              getOtcOrderStatusMaps(
                user_order_obj.isRechargeWithdrawOrder,
                user_order_obj.isBuy,
                user_order_obj.order_status
              )
            }}</span
          >
        </div>

        <div class="item-outer" v-if="user_order_obj.isRechargeWithdrawOrder">
          <div class="reCharge-space"></div>
          <div class="reCharge-item">
            <span class="item-title">{{
              user_order_obj.isBuy
                ? translateTitle("充值金额")
                : translateTitle("提现金额")
            }}</span>
            <span class="item-value">{{
              handleCutZero(
                user_order_obj.isBuy
                  ? user_order_obj.buyer_payable_number
                  : currRow.item_number
              ) +
              " " +
              (staticCurrencyMap[user_order_obj.currency_id] &&
                staticCurrencyMap[user_order_obj.currency_id].abbreviate)
            }}</span>
          </div>
          <div class="reCharge-space"></div>
          <div class="reCharge-item">
            <span class="item-title">{{
              user_order_obj.isBuy
                ? translateTitle("付款金额")
                : translateTitle("到账金额")
            }}</span>
            <span class="item-value">{{
              handleCutZero(
                user_order_obj.isBuy
                  ? user_order_obj.buyer_payable_number
                  : user_order_obj.seller_receive_number
              ) +
              " " +
              (staticCurrencyMap[user_order_obj.currency_id] &&
                staticCurrencyMap[user_order_obj.currency_id].abbreviate)
            }}</span>
          </div>
        </div>

        <div class="item-outer" v-else>
          <div class="reCharge-space"></div>
          <div class="reCharge-item">
            <span class="item-title">{{ translateTitle("单价") }}</span>
            <span class="item-value">{{
              staticCurrencyMap[user_order_obj.currency_id] &&
              staticCurrencyMap[user_order_obj.currency_id].mark +
                " " +
                user_order_obj.price
            }}</span>
          </div>
          <div class="reCharge-space"></div>
          <div class="reCharge-item">
            <span class="item-title">{{ translateTitle("数量") }}</span>

            <span class="item-value">{{
              user_order_obj.number +
              " " +
              (coinData[user_order_obj.item_id] &&
                coinData[user_order_obj.item_id].item_name.toUpperCase())
            }}</span>
          </div>
          <div class="reCharge-space"></div>
          <div class="reCharge-item">
            <span class="item-title">{{ translateTitle("金额") }}</span>
            <span class="item-value" v-if="user_order_obj.isBuy">{{
              user_order_obj.buyer_payable_number +
              " " +
              (staticCurrencyMap[user_order_obj.currency_id] &&
                staticCurrencyMap[user_order_obj.currency_id].abbreviate)
            }}</span>
            <span class="item-value" v-else>{{
              user_order_obj.seller_receive_number +
              " " +
              (staticCurrencyMap[user_order_obj.currency_id] &&
                staticCurrencyMap[user_order_obj.currency_id].abbreviate)
            }}</span>
          </div>
        </div>

        <div class="reCharge-space"></div>
        <div class="reCharge-item">
          <span class="item-title" v-if="user_order_obj.isBuy">{{
            translateTitle("付款方式")
          }}</span>
          <span class="item-title" v-else>{{
            translateTitle("收款方式")
          }}</span>
          <span class="item-value">{{
            (staticCurrencyMap[user_order_obj.currency_id] &&
              staticCurrencyMap[user_order_obj.currency_id].abbreviate) +
            "-" +
            (sotcStaticPaymentMap[user_order_obj.payment_id] &&
              sotcStaticPaymentMap[user_order_obj.payment_id].language[
                `name_${language}`
              ])
          }}</span>
        </div>
        <el-divider class="reCharge-divider"></el-divider>
        <div class="reCharge-item">
          <span class="item-title">{{ translateTitle("订单号") }}</span>
          <span class="item-value"
            ><span class="order-no">{{ user_order_obj.order_no }}</span>
            <i
              class="iconfont iconicon-copy copy-i"
              v-clipboard:copy="user_order_obj.order_no"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            ></i
          ></span>
        </div>
        <div class="reCharge-space"></div>
        <div class="reCharge-item">
          <span class="item-title">{{ translateTitle("创建时间") }}</span>
          <span class="item-value">{{
            moment(user_order_obj.create_time * 1000).format(
              "YYYY/MM/DD HH:mm:ss"
            )
          }}</span>
        </div>

        <div
          class="item-outer"
          v-if="
            user_order_obj.lockTimeType &&
            !user_order_obj.isRechargeWithdrawOrder
          "
        >
          <div class="reCharge-space"></div>
          <div class="reCharge-item">
            <el-tooltip
              v-if="user_order_obj.isBuy"
              :visible-arrow="true"
              placement="top-start"
              :popper-options="{
                boundariesElement: 'viewport',
                removeOnDestroy: true,
              }"
            >
              <div slot="content">
                {{ translateTitle("订单完成后，您买到的USDT需锁定的时间。")
                }}<br />{{
                  translateTitle(
                    "在此期间如果您付给客服的资金出现问题，客服可申诉赔偿"
                  )
                }}
              </div>
              <span class="item-title under-line">{{
                translateTitle("保护时间")
              }}</span>
            </el-tooltip>
            <el-tooltip
              v-else
              :visible-arrow="true"
              placement="top-start"
              :popper-options="{
                boundariesElement: 'viewport',
                removeOnDestroy: true,
              }"
            >
              <div slot="content">
                {{ translateTitle("订单完成后，客服承诺的包赔时间。") }}<br />{{
                  translateTitle("在此期间如果您收到的资金出现问题，可申诉赔偿")
                }}
              </div>
              <span class="item-title under-line">{{
                translateTitle("包赔时间")
              }}</span>
            </el-tooltip>
            <span class="item-value">{{ user_order_obj.aging }}</span>
          </div>
        </div>
        <div class="item-outer" v-if="!user_order_obj.isRechargeWithdrawOrder">
          <div class="reCharge-space"></div>
          <div class="reCharge-item">
            <span class="item-title">{{ translateTitle("客服昵称") }}</span>
            <span class="item-value">{{ user_order_obj.nick_name }}</span>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <ul>
          <li
            class="classify-area"
            :class="{ active: currIndex == item.value }"
            v-for="item of tabList"
            :key="item.value"
            @click="onAreaChange(item)"
          >
            {{ translateTitle(item.label) }}
            <i v-if="currIndex == item.value" class="item-bar"></i>
          </li>
        </ul>
        <span
          v-if="currIndex == 1"
          class="copy-account-msg"
          v-clipboard:copy="copyAccountMsg"
          v-clipboard:success="copySuccess"
          v-clipboard:error="copyError"
          >{{ translateTitle("一键复制") }}</span
        >
      </div>

      <div class="billPanel" v-if="currIndex == 1">
        <no-data
          slot="empty"
          :text="user_order_obj.detailEmptyTitle"
          v-if="
            user_order_obj.receivingAccount &&
            user_order_obj.receivingAccount.length == 2 &&
            user_order_obj.receivingAccount[0].content == '--' &&
            user_order_obj.receivingAccount[1].content == '--'
          "
        />
        <div v-else>
          <div
            class="outer-bill-item"
            v-for="(item, index) in user_order_obj.receivingAccount"
            :key="index"
          >
            <div class="bill-item" v-if="!item.isQrCode">
              <span class="bill-item-title">{{
                translateTitle(item.title)
              }}</span>
              <span class="bill-item-content"
                >{{ item.content }}
                <i
                  v-if="item.content != '--'"
                  class="iconfont iconicon-copy copy-i"
                  v-clipboard:copy="item.content"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                ></i>
                <i v-else></i>
              </span>
            </div>

            <div class="bill-item" v-else>
              <span class="bill-item-title">{{
                translateTitle("收款二维码")
              }}</span>

              <span class="bill-item-content">
                {{ translateTitle("点击放大") }}
                <el-image
                  class="bill-item-content-image"
                  ref="preview"
                  :src="qrCode"
                  :preview-src-list="[item.content]"
                  :z-index="3000"
                  fit="cover"
                ></el-image>
                <el-image
                  class="image-qr-code"
                  ref="preview"
                  :src="qrCode"
                  :preview-src-list="[item.content]"
                  :z-index="3000"
                  fit="cover"
                ></el-image>
              </span>
            </div>

            <div class="bill-space"></div>
          </div>
        </div>
      </div>

      <div class="billImagePanel" v-if="currIndex == 2">
        <div
          class="no-data-panel"
          v-if="
            user_order_obj.pay_voucher_price_list &&
            user_order_obj.pay_voucher_price_list.length == 0
          "
        >
          <no-data slot="empty" :text="user_order_obj.picEmptyTitle" />
        </div>

        <div
          v-else
          class="image-block"
          v-for="(item, index) in user_order_obj.pay_voucher_price_list"
          :key="index"
        >
          <el-image
            class="image-block-item"
            :src="item.url"
            :preview-src-list="[item.url]"
            :z-index="3000"
            fit="cover"
            ><div
              slot="placeholder"
              style="width: 100%; height: 100%"
              v-loading="true"
            ></div
          ></el-image>
          <span class="demonstration"></span>
        </div>
      </div>

      <div class="billPanel" v-if="currIndex == 3">
        <el-timeline>
          <el-timeline-item
            placement="bottom"
            v-for="(item, index) in user_order_obj.timelineArray"
            :icon="item.icon"
            :type="item.type"
            :color="item.color"
            :key="index"
          >
            <el-card :class="item.time ? '' : 'noActive'">
              <div class="cardStep">
                <div class="contentStep">
                  <span
                    class="sub-title"
                    :class="item.curr ? 'income-color-green' : ''"
                    >{{ item.title }}</span
                  >
                  <span class="sub-content"></span>
                </div>
                <span
                  class="time"
                  :class="item.curr ? 'income-color-green' : ''"
                  >{{ item.time }}</span
                >
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
    <!-- OTC 充值 提现 详情页结束 -->
    <!-- 设置止盈止损价弹框开始 -->
    <el-dialog
      custom-class="set-profitPrice-dialog"
      :title="translateTitle('设置止盈止损')"
      :visible.sync="setProfitPricedialog"
      :before-close="setProfitPricedialogClose"
      :close-on-click-modal="false"
      width="500px"
    >
      <div class="profitPrice-three-content">
        <div class="item-one">
          <div class="left-item">
            <span class="black">{{ rowMarketInfoShow.last || "--" }}</span>
            <span>{{ translateTitle("当前价") }}</span>
          </div>
          <div class="center-item">
            <span class="black">{{
              rowData.open_price ? rowData.open_price : rowData.trigger_price
            }}</span>
            <span>{{ translateTitle("开仓价") }}</span>
          </div>
          <div class="right-item">
            <span class="black">{{ rowData.liquidation_price }}</span>
            <span>{{ translateTitle("强平价") }}</span>
          </div>
        </div>
      </div>
      <p class="profitPrice-first-content">
        <span class="left-item">{{ translateTitle("止盈价") }}</span>
        <span class="right-item"
          >{{ translateTitle("预计盈利") }}
          <span class="green">
            {{ expectedProfit }}
            {{
              [35, 36].includes(currRow.assets_type)
                ? to_symbol.toUpperCase()
                : from_symbol.toUpperCase()
            }}
            <span v-if="expectedProfitPercent != '--'">
              ({{ expectedProfitPercent }}%)
            </span>
          </span></span
        >
      </p>
      <div class="profitPrice-second-content">
        <InputNumber
          v-model="inputProfitPrice"
          :placeholder="translateTitle('请输入止盈价')"
          maxlength="10"
          decimals="8"
        ></InputNumber>
        <span class="all-btn common-operate" @click="onClearAll">{{
          translateTitle("清除")
        }}</span>
      </div>
      <p class="profitPrice-first-content V2">
        <span class="left-item">{{ translateTitle("止损价") }}</span>
        <span class="right-item"
          >{{ translateTitle("预计亏损") }}
          <span class="red"
            >{{ expectedLoss }}
            {{
              [35, 36].includes(currRow.assets_type)
                ? to_symbol.toUpperCase()
                : from_symbol.toUpperCase()
            }}

            <span v-if="expectedLossPercent != '--'">
              ({{ expectedLossPercent }}%)
            </span>
          </span></span
        >
      </p>
      <div class="profitPrice-second-content">
        <InputNumber
          v-model="inputLossPrice"
          :placeholder="translateTitle('请输入止损价')"
          maxlength="10"
          decimals="8"
        ></InputNumber>
        <span class="all-btn common-operate" @click="onClearAllV2">{{
          translateTitle("清除")
        }}</span>
      </div>

      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="setProfitPricedialog = false">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="onSetPositionProfitLoss">{{
          translateTitle("确定")
        }}</span>
      </span>
    </el-dialog>
    <!-- 设置止盈止损价弹框结束 -->
    <!-- 平仓弹框开始 -->
    <el-dialog
      :title="translateTitle('操作提示')"
      custom-class="confirm-dialog"
      :visible.sync="dialogVisible"
      width="440px"
      :lock-scroll="false"
    >
      <span style="font-size: 16px">{{ confirmMessage }}</span>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="dialogVisible = false">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="onOK">{{
          translateTitle("确认")
        }}</span>
      </span>
    </el-dialog>
    <!-- 平仓弹框结束 -->
    <!-- 转账图片展示 -->
    <el-dialog
      width="500px"
      :modal="true"
      custom-class="image-dialog"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :destroy-on-close="true"
      :visible.sync="notifyVisible"
      :show-close="false"
    >
      <div v-if="false" slot="title"></div>
      <img :src="notifyImg" class="dialog-image" />
    </el-dialog>
    <vue-canvas-poster
      :widthPixels="1000"
      :painting="painting"
      @success="posterSuccess"
      @fail="posterFail"
    ></vue-canvas-poster>
  </div>
</template>
<script>
// import TopTitle from "../../components/Title";
import { floorNumber, ceilNumber } from "@/assets/js/digital";
import Socket from "@/assets/js/socket.js";
import Cookies from "js-cookie";
import { apiWsUrl } from "@/model/currencyBasedContract/index";
import {
  apiAppendInterest,
  apiSetPositionProfitLoss,
  apiSetPlanProfitLoss,
  apiClosePosition,
} from "@/model/currencyBasedContract/order.js";
import {
  apiAppendInterest as apiAppendInterest4U,
  apiClosePosition as apiClosePosition4U,
  apiSetPositionProfitLoss as apiSetPositionProfitLoss4U,
  apiSetPlanProfitLoss as apiSetPlanProfitLoss4U,
} from "@/model/contractExchange/order.js";
import NoData from "@/components/common/NoData";
import InputNumber from "@/components/common/InputNumber";
import { apiGetAssets } from "@/model/exchange/index";
import { apiGetStaticTableInfo } from "@/model/settings";
import commonMixin from "@/components/common/commonMixin.vue";
import { showMessage } from "@/components/dialog/message";
import { mapGetters } from "vuex";

import { VUE_APP_ID, VUE_UNION_STATION } from "@/assets/js/stationSetting";

import { getStaticCoinList as getCoinList } from "@/model/assets/index";
import {
  apiGetAssetsLog,
  apiGetContractPositionInfo,
  apiGetFuturesPositionInfo,
  apiGetUserOrderInfo,
} from "@/model/ucenter/billCenter";
import { VueCanvasPoster } from "vue-canvas-poster";
import {
  apiGetRebateCommonInfo,
  apiAppIntialDataV2,
  apiGetInviteUrl,
} from "@/model/ucenter/spreadCenter";
import { translateTitle } from "@/assets/js/i18n";
import b9Logo from "@/assets/img/siteLogo/b9.png";
import maskLogo from "@/assets/img/siteLogo/mask.png";
import bxcLogo from "@/assets/img/siteLogo/bxc.png";
import hxLogo from "@/assets/img/siteLogo/hx.png";
import AhexLogo from "@/assets/img/siteLogo/Ahex.png";
import logo5A from "@/assets/img/siteLogo/logo5A.png";
import logobitda from "@/assets/img/siteLogo/logobitda.png";
import logoS6 from "@/assets/img/siteLogo/logoS6.png";
import logoEasy from "@/assets/img/siteLogo/logoEasy.png";
import logoSpex from "@/assets/img/siteLogo/spex.png";
import logo8coin from "@/assets/img/siteLogo/8coin.png";
import logo8V from "@/assets/img/siteLogo/8v.png";
import hxlogo_text from "@/assets/img/siteLogo/hxlogo_text.png";
import qrCode from "@/assets/img/qr_code.png";
import profitLossShareBg from "@/assets/img/profit_loss_share_bg.png";
import expressionBad from "@/assets/img/expression/expression-bad.png";
import expressionGreat from "@/assets/img/expression/expression-great.png";
import expressionCool from "@/assets/img/expression/expression-cool.png";
import routeSplicing from "@/assets/js/routeSplicing";
import BigNumber from "bignumber.js";

import moment from "moment";
export default {
  mixins: [commonMixin],
  components: {
    NoData,
    VueCanvasPoster,
    InputNumber,
  },
  props: {
    isStartWS: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    marketListTran: {
      type: Array,
      default: function () {
        return [];
      },
    },
    assetsInfoTran: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      tt: {},
      lockReconnect: false,
      sloganColor: "#c61b3e",
      currRow: {},
      wsUrl: "",
      isDestoryWS: false,
      wsContentTime: 0,
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--",
          },
          list: [],
        },
      },
      marketList: [],
      appendMoneydialog: false,
      staticChainMap: {}, //静态表1
      staticCurrencyMap: {},
      sotcStaticPaymentMap: {},
      sotcStaticProtectMap: {},
      sotcStaticGuaranteeMap: {},
      copyAccountMsg: "",
      apiAssetsInfo: {},
      from_item_id: "",
      to_item_id: "",
      from_symbol: "",
      to_symbol: "",
      swap_symbol_id: "",
      beforeBailNumber: "",
      afterBailNumber: "",
      beforeLiquidationPrice: "",
      afterLiquidationPrice: "",
      symbolList: {},
      price_precision: "",
      settlement_precision: "",
      contract_value: "",
      fee_rate: "",
      liquidation_risk_rate: "",
      bail_number: "",
      rowData: {},
      row_swap_symbol_id: "",
      inputProfitPrice: "",
      inputLossPrice: "",
      setProfitPricedialog: false,
      dialogType: "",
      confirmMessage: "",
      dialogVisible: false,
      profitLossShareBg,
      expressionBad,
      expressionCool,
      expressionGreat,
      notifyImg: "",
      notifyVisible: false,
      painting: {},
      b9Logo,
      bxcLogo,
      hxLogo,
      logo5A,
      logobitda,
      logoS6,
      logoEasy,
      logoSpex,
      logo8coin,
      logo8V,
      AhexLogo,
      maskLogo,
      hxlogo_text,
      qrCode,
      projectEnglishName: "",
      projectFullName: "",
      projectLogo: "",
      default_invite_code: {},
      centerHost: "",
      operateTypeMap: {
        1: this.translateTitle("开仓"),
        2: this.translateTitle("平仓"),
        3: this.translateTitle("设置止损价"),
        4: this.translateTitle("设置止盈价"),
        5: this.translateTitle("追加本金"),
        6: this.translateTitle("已强平"),
        7: this.translateTitle("同时设置止盈止损"),
        8: this.translateTitle("加仓"),
        9: this.translateTitle("减仓"),
        10: this.translateTitle("资金费用"),
      },
      position_order_id: "",
      position_order_obj: { position_info: {}, operate_list: [] },
      user_order_obj: {},
      closePositionTypeMap: {
        1: this.translateTitle("未平仓"),
        2: this.translateTitle("用户平仓"),
        3: this.translateTitle("强制平仓"),
        4: this.translateTitle("止盈平仓"),
        5: this.translateTitle("止损平仓"),
        6: this.translateTitle("自动减仓"),
      }, //平仓类型
      openPositionTypeMap: {
        1: this.translateTitle("手动开仓"),
        2: this.translateTitle("触发价开仓"),
      }, //开仓类型
      liquidation_symbol_name: "",
      outerSearch: [],

      coinData: {},
      staticSymbolData: {},
      staticFuturesSymbolData: {},
      searchCoin: [],
      staticAssetsTypeData: [],
      staticStockData: {},
      searchStaticAssetsType: [],
      reChargeDialogVisible: false,
      otcDialogVisible: false,

      tabList: [
        { label: this.translateTitle("收款账号"), value: 1 },
        { label: this.translateTitle("付款截图"), value: 2 },
        { label: this.translateTitle("订单历史"), value: 3 },
      ],

      currIndex: 1,
      currIndexPosition: 1,

      coinInfoMap: {},
      liquidation_dialog_loading: false,
    };
  },

  async created() {
    this.getAppIntialDataV2();
    this.getStaticChainMap();
    this.getStaticCurrencyMap();
    this.getStaticPaymentMap();

    // this.getSotcStaticGuaranteeMap();
    // this.getSotcStaticProtectMap();

    this.getItemList();
    this.getContractConfigBySymbolId();
    this.getFuturesConfigBySymbolId();
    // this.getStockConfigBySymbolId();
    this.getStaticAssetsType();

    this.getAssets();
    let tempCookies = Cookies.get("topcredit_exchange_t");
    if (tempCookies) {
      this.getRebateCommonInfo();
    }
    if (this.isStartWS) {
      if (tempCookies == undefined) {
        await this.initURL();
        this.initWs();
      } else {
        this.getRebateCommonInfo();
        this.token = Cookies.get("topcredit_exchange_t");
        await this.initURL();
        this.initWs();
      }
    }

    if (
      (this.language && VUE_APP_ID == "UhkNBRbpeSGTuaf0yuiG1Yin") ||
      VUE_APP_ID == "OCzy5RbpeSGTuaf0yuiG1Yin"
    ) {
      this.projectFullName = "Bullxcow";
      this.sloganColor = "#c61b3e";
      this.projectLogo = this.bxcLogo;
    } else if (this.language && VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
      this.projectFullName = "B9";
      this.sloganColor = "#fdac35";
      this.projectLogo = this.b9Logo;
    } else if (this.language && VUE_UNION_STATION == "MaskEx") {
      this.projectFullName = "MaskEx";
      this.sloganColor = "#32c8a4";
      this.projectLogo = this.maskLogo;
    } else if (this.language && VUE_UNION_STATION == "100Ex") {
      this.projectFullName = "100Ex";
      this.sloganColor = "#e9393c";
      this.projectLogo = this.logo100;
    } else if (this.language && VUE_UNION_STATION == "EverTrust") {
      this.projectFullName = "EverTrust";
      this.sloganColor = "#fdac35";
    } else if (this.language && VUE_UNION_STATION == "8V") {
      this.projectFullName = "8V";
      this.sloganColor = "#fdac35";
      this.projectLogo = this.logo8V;
    } else if (this.language && VUE_UNION_STATION == "UEEx") {
      this.projectFullName = "UEEx";
      this.sloganColor = "#f35023";
    } else if (this.language && VUE_UNION_STATION == "Ahex") {
      this.projectFullName = "Ahex";
      this.sloganColor = "#c61b3e";
      this.projectLogo = this.AhexLogo;
    } else if (this.language && VUE_UNION_STATION == "5A") {
      this.projectFullName = "5A";
      this.sloganColor = "#c61b3e";
      this.projectLogo = this.logo5A;
    } else if (VUE_UNION_STATION == "bitda") {
      this.projectFullName = "bitda";
      this.sloganColor = "#702070";
      this.projectLogo = this.logobitda;
    } else if (VUE_UNION_STATION == "S6") {
      this.projectFullName = "S6";
      this.sloganColor = "#F7b239";
      this.projectLogo = this.logoS6;
    } else if (VUE_UNION_STATION == "easy") {
      this.projectFullName = "ezex";
      this.sloganColor = "#c61b3d";
      this.projectLogo = this.logoEasy;
    } else if (VUE_UNION_STATION == "spex") {
      this.projectFullName = "SPEX";
      this.sloganColor = "#e37c30";
      this.projectLogo = this.logoSpex;
    } else if (VUE_UNION_STATION == "8coin") {
      this.projectFullName = "8coin";
      this.sloganColor = "#3960fb";
      this.projectLogo = this.logo8coin;
    } else {
      this.projectFullName = "汇信国际";
      this.sloganColor = "#c61b3e";
      this.projectLogo = this.hxLogo;
    }
  },

  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    // 现有U资产
    assetsInfoShow() {
      let tempObj = "0";
      if (
        JSON.stringify(this.assetsInfo) != "{}" &&
        this.assetsInfo.remain.list.length > 0
      ) {
        this.assetsInfo.remain.list.forEach((ele) => {
          if ([35, 36].includes(this.currRow.assets_type)) {
            if (ele.item_id == this.to_item_id) {
              tempObj = ele.over;
            }
          } else {
            if (ele.item_id == this.from_item_id) {
              tempObj = ele.over;
            }
          }
        });
      } else if (
        JSON.stringify(this.apiAssetsInfo) != "{}" &&
        this.apiAssetsInfo.remain.list.length > 0
      ) {
        this.apiAssetsInfo.remain.list.forEach((ele) => {
          if ([35, 36].includes(this.currRow.assets_type)) {
            if (ele.item_id == this.to_item_id) {
              tempObj = ele.over;
            }
          } else {
            if (ele.item_id == this.from_item_id) {
              tempObj = ele.over;
            }
          }
        });
      } else {
        tempObj = "--";
      }
      return tempObj;
    },
    rowMarketInfoShow() {
      let marketInfo = {
        last: "",
        currency: { cny: "" },
      };
      this.marketList.forEach((ele) => {
        if (ele.symbol_id == this.row_swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    afterLiquidationPriceShow() {
      //强平价 =  1 / ((持仓张数×单张合约价值 × 1/开仓价 × (1 做多| -1做空) + 保证金 × (1 − 强平风险率))/持仓张数/单张合约价值 / (1 做多 | -1做空))
      let tempValue = "";
      if ([35, 36].includes(this.currRow.assets_type)) {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.bail_number != "") {
          tempValue =
            this.rowData.open_price -
            (tempSide *
              (this.afterBailNumberShow *
                (1 -
                  this.symbolList[this.swap_symbol_id].liquidation_risk_rate /
                    10000) -
                this.rowData.close_position_fee -
                this.rowData.open_position_fee)) /
              this.rowData.open_interest;

          if (this.rowData.open_side == 1) {
            //做多 做多的时候小数位用全进
            tempValue = (
              Math.ceil(tempValue * Math.pow(10, this.price_precision)) /
              Math.pow(10, this.price_precision)
            ).toFixed(this.price_precision);
          } else {
            // 做空的时候用截断
            tempValue = tempValue
              .toString()
              .substring(
                0,
                tempValue.toString().indexOf(".") + this.price_precision + 1
              );
          }
        } else {
          tempValue = this.afterLiquidationPrice;
        }
        if (Number(tempValue) < 0) {
          tempValue = "0";
        }
      } else {
        let side = this.rowData.open_side == 1 ? 1 : -1;
        if (this.bail_number != "") {
          // tempValV2 持仓张数
          let tempOpenPrice = "";
          tempOpenPrice = this.rowData.trigger_price
            ? new BigNumber(this.rowData.trigger_price)
            : new BigNumber(this.rowData.open_price);
          // if (this.currIndex == 1) {
          //   tempOpenPrice = new BigNumber(this.rowData.open_price);
          // } else {
          //   tempOpenPrice = new BigNumber(this.rowData.trigger_price);
          // }
          let tempSide = new BigNumber(side);
          let bigMarketPrice = new BigNumber(this.rowData.open_price);
          let tempMultipleNum = new BigNumber(this.rowData.open_leverage);

          let tempConsumerCosts = new BigNumber(this.afterBailNumberShow);
          let tempLiquidationRiskRate = new BigNumber(
            Number(this.liquidation_risk_rate) / 10000
          );
          let tempContractValue = new BigNumber(this.contract_value);
          let tempQty = new BigNumber(this.rowData.qty);
          let one = new BigNumber(1);

          // 强平价 =  1 / { 1 / 开仓价 + [持仓方向 × 保证金 × (1 −强平⻛险率)/ 张数 / 单张合约价值] }

          let tempValV2 = one.div(
            one
              .div(tempOpenPrice)
              .plus(
                tempSide
                  .times(tempConsumerCosts)
                  .times(one.minus(tempLiquidationRiskRate))
                  .div(tempQty)
                  .div(tempContractValue)
              )
          );

          tempValue = tempValV2.toString();

          if (this.rowData.open_side == 1) {
            //做多 做多的时候小数位用全进
            tempValue = (
              Math.ceil(tempValue * Math.pow(10, this.price_precision)) /
              Math.pow(10, this.price_precision)
            ).toFixed(this.price_precision);
          } else {
            // 做空的时候用截断
            tempValue = tempValue
              .toString()
              .substring(
                0,
                tempValue.toString().indexOf(".") + this.price_precision + 1
              );
          }
        } else {
          tempValue = this.afterLiquidationPrice;
        }
        if (Number(tempValue) < 0) {
          tempValue = "0";
        }
      }
      return tempValue;
    },
    //  未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)
    expectedProfit() {
      let tempValue = "--";
      if ([35, 36].includes(this.currRow.assets_type)) {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
          let temp_open_price = "";
          temp_open_price = this.rowData.open_price
            ? this.rowData.open_price
            : this.rowData.trigger_price;
          // if (this.currIndexPosition == 1) {
          //   temp_open_price = this.rowData.open_price;
          // } else {
          //   temp_open_price = this.rowData.trigger_price;
          // }

          tempValue =
            (Number(this.inputProfitPrice) - Number(temp_open_price)) *
              tempSide *
              Number(this.rowData.open_interest) -
            Number(this.rowData.close_position_fee);
          tempValue = floorNumber(tempValue, 2);
          if (Number(tempValue) <= 0) {
            tempValue = "--";
          }
        }
      } else {
        let side = this.rowData.open_side == 1 ? 1 : -1;
        let tempOpenPrice = "";
        if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
          // if (this.currIndex == 1) {
          //   tempOpenPrice = new BigNumber(this.rowData.open_price);
          // } else {
          //   tempOpenPrice = new BigNumber(this.rowData.trigger_price);
          // }
          tempOpenPrice = this.rowData.trigger_price
            ? new BigNumber(this.rowData.trigger_price)
            : new BigNumber(this.rowData.open_price);
          let tempInputProfitPrice = new BigNumber(this.inputProfitPrice);
          let tempContractValue = new BigNumber(this.contract_value);
          let tempSide = new BigNumber(side);
          let one = new BigNumber(1);
          let tempQty = new BigNumber(this.rowData.qty);
          // 未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)

          tempValue = tempQty
            .times(tempContractValue)
            .times(one.div(tempOpenPrice).minus(one.div(tempInputProfitPrice)))
            .times(tempSide)
            .toNumber();

          if (this.rowData.open_side == 1) {
            tempValue = Math.ceil(
              tempValue * Math.pow(10, this.settlement_precision)
            );

            tempValue =
              (tempValue * 1.0) / Math.pow(10, this.settlement_precision);
            //做多 做多的时候小数位用全进
            // tempValue = (
            //   Math.ceil(tempValue * Math.pow(10, this.settlement_precision)) /
            //   Math.pow(10, this.settlement_precision)
            // ).toFixed(this.settlement_precision);
          } else {
            // 做空的时候用截断
            tempValue = tempValue
              .toString()
              .substring(
                0,
                tempValue.toString().indexOf(".") +
                  this.settlement_precision +
                  1
              );
          }

          // 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)

          if (Number(tempValue) <= 0) {
            tempValue = "--";
          }
        }
      }
      return tempValue;
    },
    expectedProfitPercent() {
      let tempValue = "--";
      if ([35, 36].includes(this.currRow.assets_type)) {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
          let temp_open_price = "";
          temp_open_price = this.rowData.open_price
            ? this.rowData.open_price
            : this.rowData.trigger_price;
          // if (this.currIndexPosition == 1) {
          //   temp_open_price = this.rowData.open_price;
          // } else {
          //   temp_open_price = this.rowData.trigger_price;
          // }

          tempValue =
            (((this.inputProfitPrice - temp_open_price) *
              tempSide *
              this.rowData.open_interest -
              this.rowData.close_position_fee) /
              this.rowData.bail_number) *
            100;
          tempValue = floorNumber(tempValue, 2);
          if (Number(tempValue) <= 0) {
            tempValue = "--";
          } else {
            tempValue = "+" + tempValue;
          }
        }
      } else {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
          let temp_open_price = "";
          temp_open_price = this.rowData.open_price
            ? this.rowData.open_price
            : this.rowData.trigger_price;
          // if (this.currIndex == 1) {
          //   temp_open_price = this.rowData.open_price;
          // } else {
          //   temp_open_price = this.rowData.trigger_price;
          // }

          tempValue = (this.expectedProfit / this.rowData.bail_number) * 100;
          tempValue = floorNumber(tempValue, 2);
          if (Number(tempValue) <= 0) {
            tempValue = "--";
          } else {
            tempValue = "+" + tempValue;
          }
        }
      }
      // inputProfitPrice
      return tempValue;
    },
    // 平仓盈亏
    expectedLoss() {
      let tempValue = "--";
      if ([35, 36].includes(this.currRow.assets_type)) {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
          let temp_open_price = "";
          if (this.currIndexPosition == 1) {
            temp_open_price = this.rowData.open_price;
          } else {
            temp_open_price = this.rowData.trigger_price;
          }
          tempValue =
            (this.inputLossPrice - temp_open_price) *
              tempSide *
              this.rowData.open_interest -
            this.rowData.close_position_fee;
          tempValue = ceilNumber(tempValue, 2);
          // tempValue = Math.ceil(tempValue * 100) / 100;
          if (Number(tempValue) < this.rowData.bail_number * -1) {
            tempValue = this.rowData.bail_number * -1;
          }
          if (Number(tempValue) > 0) {
            tempValue = "--";
          }
        }
      } else {
        let side = this.rowData.open_side == 1 ? 1 : -1;
        let tempOpenPrice = "";
        if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
          // if (this.currIndex == 1) {
          //   tempOpenPrice = new BigNumber(this.rowData.open_price);
          // } else {
          //   tempOpenPrice = new BigNumber(this.rowData.trigger_price);
          // }
          tempOpenPrice = this.rowData.open_price
            ? new BigNumber(this.rowData.open_price)
            : new BigNumber(this.rowData.trigger_price);
          let tempInputProfitPrice = new BigNumber(this.inputLossPrice);
          let tempQty = new BigNumber(this.rowData.qty);
          let tempContractValue = new BigNumber(this.contract_value);
          let tempSide = new BigNumber(side);
          let one = new BigNumber(1);
          // 未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)

          tempValue = tempQty
            .times(tempContractValue)
            .times(one.div(tempOpenPrice).minus(one.div(tempInputProfitPrice)))
            .times(tempSide)
            .toNumber();
          if (this.rowData.open_side == 1) {
            if (Number(tempValue) < 0) {
              //做多 做多的时候小数位用全进
              let tempValueABS = Math.abs(tempValue);
              tempValue = Math.ceil(
                tempValueABS * Math.pow(10, this.settlement_precision)
              );

              tempValue =
                (tempValue * 1.0) / Math.pow(10, this.settlement_precision);
              tempValue = tempValue * -1;
            }
            // tempValue =
            //   Math.round(tempValue * Math.pow(10, this.settlement_precision)) /
            //   Math.pow(10, this.settlement_precision);
          } else {
            // 做空的时候用截断
            tempValue = tempValue
              .toString()
              .substring(
                0,
                tempValue.toString().indexOf(".") +
                  this.settlement_precision +
                  1
              );
          }
          if (Number(tempValue) > 0) {
            tempValue = "--";
          } else if (Number(tempValue * -1) > this.rowData.bail_number) {
            tempValue = this.rowData.bail_number * -1;
          }
        }
      }
      return tempValue;
    },
    expectedLossPercent() {
      let tempValue = "--";
      if ([35, 36].includes(this.currRow.assets_type)) {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
          let temp_open_price = "";
          if (this.currIndexPosition == 1) {
            temp_open_price = this.rowData.open_price;
          } else {
            temp_open_price = this.rowData.trigger_price;
          }

          tempValue =
            (((this.inputLossPrice - temp_open_price) *
              tempSide *
              this.rowData.open_interest -
              this.rowData.close_position_fee) /
              this.rowData.bail_number) *
            100;

          tempValue = ceilNumber(tempValue, 2);
          if (Number(tempValue) < -100) {
            tempValue = -100;
          }
          if (Number(tempValue) > 0) {
            tempValue = "--";
          }
        }
      } else {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
          let temp_open_price = "";
          temp_open_price = this.rowData.trigger_price
            ? this.rowData.trigger_price
            : this.rowData.open_price;
          // if (this.currIndex == 1) {
          //   temp_open_price = this.rowData.open_price;
          // } else {
          //   temp_open_price = this.rowData.trigger_price;
          // }

          tempValue = (this.expectedLoss / this.rowData.bail_number) * 100;
          tempValue = ceilNumber(tempValue, 2);
          if (Number(tempValue) < -100) {
            tempValue = -100;
          }
          if (Number(tempValue) > 0) {
            tempValue = "--";
          }
        }
      }
      // inputLossPrice
      return tempValue;
    },
    //追加本金后
    afterBailNumberShow() {
      if ([35, 36].includes(this.currRow.assets_type)) {
        return (
          Number(this.afterBailNumber) + Number(this.bail_number)
        ).toFixed(2);
      } else {
        return (
          Number(this.afterBailNumber) + Number(this.bail_number)
        ).toFixed(this.settlement_precision);
      }
    },
  },
  methods: {
    routeSplicing,
    moment,
    translateTitle,
    goToRecharge() {
      window.open(
        this.routeSplicing(this.language, "ucenter/assets/recharge/usdt")
      );
    },

    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all",
      };
      const { status, data } = await apiGetAssets(params);
      if (status == 200) {
        this.apiAssetsInfo = data;
      }
    },
    onOpenConfirm(type, id) {
      this.dialogType = type;
      if (type == 1) {
        this.position_order_id = id;
        this.confirmMessage = this.translateTitle("是否确认平仓此订单？");
      }
      // else if (type == 2) {
      //   this.confirmMessage = this.translateTitle("是否确认全部平仓？");
      // } else if (type == 3) {
      //   this.confirmMessage = this.translateTitle("是否确认全部撤销？");
      // } else if (type == 4) {
      //   this.plan_order_id = id;
      //   this.confirmMessage = this.translateTitle("是否确认撤销此委托单？");
      // }
      this.dialogVisible = true;
    },
    onOK() {
      if (this.dialogType == 1) {
        this.onClosePosition();
      }
      // else if (this.dialogType == 2) {
      //   this.onCancelOrder();
      // } else if (this.dialogType == 3) {
      //   this.onRevokeAllOrder();
      // } else if (this.dialogType == 4) {
      //   this.onRevokePlan();
      // }
    },
    onClosePosition() {
      let params = {
        position_order_id: this.position_order_id,
      };
      if ([35, 36].includes(this.currRow.assets_type)) {
        apiClosePosition4U(params).then((res) => {
          if (res.status == 200) {
            this.dialogVisible = false;
            this.$message({
              message: this.translateTitle("平仓成功"),
              type: "success",
              customClass: "zZindex",
            });
            this.getContractPositionInfo();
          }
        });
      } else {
        apiClosePosition(params).then((res) => {
          if (res.status == 200) {
            this.dialogVisible = false;
            this.$message({
              message: this.translateTitle("平仓成功"),
              type: "success",
              customClass: "zZindex",
            });
            this.getFuturesPositionInfo();
          }
        });
      }
    },
    //设置止盈止损价
    onProfitPriceEdit(row) {
      this.rowData = row;
      this.row_swap_symbol_id = row.symbol_id;
      if (row.stop_profit_price != "--") {
        this.inputProfitPrice = row.stop_profit_price;
      } else {
        this.inputProfitPrice = "";
      }
      if (row.stop_loss_price != "--") {
        this.inputLossPrice = row.stop_loss_price;
      } else {
        this.inputLossPrice = "";
      }
      if ([35, 36].includes(this.currRow.assets_type)) {
        this.symbolList = this.staticSymbolData;
      } else {
        this.symbolList = this.staticFuturesSymbolData;
        for (const key in this.symbolList) {
          if (key == row.symbol_id) {
            this.price_precision = this.symbolList[key].price_precision;
            this.settlement_precision =
              this.symbolList[key].settlement_precision;
            this.contract_value = this.symbolList[key].contract_value;
            this.fee_rate = this.symbolList[key].fee_rate;
            this.liquidation_risk_rate =
              this.symbolList[key].liquidation_risk_rate;
          }
        }
      }

      this.setProfitPricedialog = true;
    },
    onClearAll() {
      this.inputProfitPrice = "";
    },
    onClearAllV2() {
      this.inputLossPrice = "";
    },
    onSetPositionProfitLoss() {
      // this.currIndex == 1 //1币本位持仓 2币本位当前委托 3U本位持仓 4U本位当前委托
      if (this.rowData.open_side == 1) {
        if (
          Number(this.rowMarketInfoShow.last) < Number(this.rowData.open_price)
        ) {
          if (
            Number(this.inputProfitPrice) != 0 &&
            Number(this.inputProfitPrice) <= Number(this.rowData.open_price)
          ) {
            showMessage({
              message: this.translateTitle("买涨时，止盈价需大于开仓价"),
              type: "warning",
            });
            return;
          }
        } else {
          if (
            Number(this.inputProfitPrice) != 0 &&
            Number(this.inputProfitPrice) <= Number(this.rowMarketInfoShow.last)
          ) {
            showMessage({
              message: this.translateTitle("买涨时，止盈价需大于当前价"),
              type: "warning",
            });
            return;
          }
        }

        // if(止损价 > 强平价){
        // 	if(当前价 < 开仓价){
        // 		止损价 >= 当前价 ? "提示：买涨时，止损价需小于当前价" ：止损价
        // 	}else{
        // 		止损价 >= 开仓价 ? "提示：买涨时，止损价需小于开仓价" ：止损价
        // 	}
        // esle{
        // 	提示：买涨时，止损价需大于强平价
        // }
        if (
          Number(this.inputLossPrice) != 0 &&
          Number(this.inputLossPrice) >= Number(this.rowData.liquidation_price)
        ) {
          if (
            Number(this.rowMarketInfoShow.last) <
            Number(this.rowData.open_price)
          ) {
            if (
              Number(this.inputLossPrice) >= Number(this.rowMarketInfoShow.last)
            ) {
              showMessage({
                message: this.translateTitle("买涨时，止损价需小于当前价"),
                type: "warning",
              });
              return;
            }
          } else {
            if (
              Number(this.inputLossPrice) >= Number(this.rowData.open_price)
            ) {
              showMessage({
                message: this.translateTitle("买涨时，止损价需小于开仓价"),
                type: "warning",
              });
              return;
            }
          }
        } else if (Number(this.inputLossPrice) != 0) {
          Number(this.inputLossPrice) < Number(this.rowData.liquidation_price);
          showMessage({
            message: this.translateTitle("买涨时，止损价需大于强平价"),
            type: "warning",
          });
          return;
        }
      } else {
        // 卖跌
        if (Number(this.inputProfitPrice) > 0) {
          if (
            Number(this.rowMarketInfoShow.last) <
            Number(this.rowData.open_price)
          ) {
            if (
              Number(this.inputProfitPrice) >=
              Number(this.rowMarketInfoShow.last)
            ) {
              showMessage({
                message: this.translateTitle("买跌时，止盈价需小于当前价"),
                type: "warning",
              });
              return;
            }
          } else {
            if (
              Number(this.inputProfitPrice) >= Number(this.rowData.open_price)
            ) {
              showMessage({
                message: this.translateTitle("买跌时，止盈价需小于开仓价"),
                type: "warning",
              });
              return;
            }
          }
        } else if (Number(this.inputProfitPrice) < 0) {
          showMessage({
            message: this.translateTitle("买跌时，止盈价需大于0"),
            type: "warning",
          });
          return;
        }

        // if(止损价 < 强平价){
        // 	if(当前价 < 开仓价){
        // 		止损价  <= 开仓价 ? "提示：买跌时，止损价需大于开仓价" ：止损价
        // 	}else{
        // 		止损价 <= 当前价 ? "提示：买跌时，止损价需大于当前价" ：止损价
        // 	}
        // esle{
        // 	提示：买跌时，止损价需小于强平价
        // }

        if (
          Number(this.inputLossPrice) != 0 &&
          Number(this.inputLossPrice) <= Number(this.rowData.liquidation_price)
        ) {
          if (
            Number(this.rowMarketInfoShow.last) <
            Number(this.rowData.open_price)
          ) {
            if (
              Number(this.inputLossPrice) <= Number(this.rowData.open_price)
            ) {
              showMessage({
                message: this.translateTitle("买跌时，止损价需大于开仓价"),
                type: "warning",
              });
              return;
            }
            if (
              Number(this.inputLossPrice) <= Number(this.rowMarketInfoShow.last)
            ) {
              showMessage({
                message: this.translateTitle("买跌时，止损价需大于当前价"),
                type: "warning",
              });
              return;
            }
          }
        } else if (Number(this.inputLossPrice) != 0) {
          showMessage({
            message: this.translateTitle("买跌时，止损价需小于强平价"),
            type: "warning",
          });
          return;
        }
      }
      let tempInputProfitPrice = "";
      if (Number(this.inputProfitPrice) == 0) {
        tempInputProfitPrice = "";
      } else {
        tempInputProfitPrice = this.inputProfitPrice;
      }

      let tempInputLossPrice = "";
      if (Number(this.inputLossPrice) == 0) {
        tempInputLossPrice = "";
      } else {
        tempInputLossPrice = this.inputLossPrice;
      }
      let params = {
        position_order_id: this.rowData.position_order_id,
        market_price: this.rowMarketInfoShow.last,
        stop_profit_price: tempInputProfitPrice,
        stop_loss_price: tempInputLossPrice,
      };
      if ([35, 36].includes(this.currRow.assets_type)) {
        apiSetPositionProfitLoss4U(params).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: this.translateTitle("设置成功"),
              type: "success",
              customClass: "zZindex",
            });
            this.getContractPositionInfo();
            this.setProfitPricedialogClose();
          }
        });
      } else {
        apiSetPositionProfitLoss(params).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: this.translateTitle("设置成功"),
              type: "success",
              customClass: "zZindex",
            });
            this.getFuturesPositionInfo();
            this.setProfitPricedialogClose();
          }
        });
      }
    },
    setProfitPricedialogClose() {
      this.inputLossPrice = "";
      this.inputProfitPrice = "";
      this.setProfitPricedialog = false;
    },
    //追回本金开启
    onOpenAppendMoney(row) {
      this.from_item_id = row.open_item_id;
      this.to_item_id = row.settlement_item_id;
      this.from_symbol =
        this.coinInfoMap[row.open_item_id].item_name.toUpperCase();
      this.to_symbol =
        this.coinInfoMap[row.settlement_item_id].item_name.toUpperCase();
      this.swap_symbol_id = row.symbol_id;
      this.position_order_id = row.position_order_id;
      this.beforeBailNumber = row.bail_number;
      this.afterBailNumber = row.bail_number;
      this.beforeLiquidationPrice = row.liquidation_price;
      this.afterLiquidationPrice = row.liquidation_price;
      this.rowData = row;
      if (![35, 36].includes(this.currRow.assets_type)) {
        this.symbolList = this.staticFuturesSymbolData;
        // this.staticSymbolData  assets_type 35,36 [35, 36].includes(this.currRow.assets_type)
        for (const key in this.symbolList) {
          if (key == row.symbol_id) {
            this.price_precision = this.symbolList[key].price_precision;
            this.settlement_precision =
              this.symbolList[key].settlement_precision;
            this.contract_value = this.symbolList[key].contract_value;
            this.fee_rate = this.symbolList[key].fee_rate;
            this.liquidation_risk_rate =
              this.symbolList[key].liquidation_risk_rate;
          }
        }
      } else {
        this.symbolList = this.staticSymbolData;
        for (const key in this.symbolList) {
          if (key == row.symbol_id) {
            this.price_precision = this.symbolList[key].price_precision;
          }
        }
      }

      this.appendMoneydialog = true;
    },
    //追加本金关闭
    appendMoneydialogClose() {
      this.bail_number = "";
      this.appendMoneydialog = false;
    },
    //追加本金-点击全部
    onClickAll() {
      this.bail_number = this.assetsInfoShow;
    },
    onAppendMoney() {
      if (Number(this.bail_number) == 0) {
        this.$message({
          message: this.translateTitle("请输入追加金额"),
          type: "error",
          customClass: "zZindex",
        });
        return;
      }
      let params = {
        position_order_id: this.position_order_id,
        bail_number: this.bail_number,
      };
      if ([35, 36].includes(this.currRow.assets_type)) {
        apiAppendInterest4U(params).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: this.translateTitle("追加成功"),
              type: "success",
              customClass: "zZindex",
            });
            this.getContractPositionInfo();
            this.appendMoneydialog = false;
          }
        });
      } else {
        apiAppendInterest(params).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: this.translateTitle("追加成功"),
              type: "success",
              customClass: "zZindex",
            });
            this.getFuturesPositionInfo();
            this.appendMoneydialog = false;
          }
        });
      }
    },

    //Base64 To Blob
    convertBase64ToBlob(base64, type) {
      var imgs = base64.replace(/^data:image\/\w+;base64,/, ""); //去掉base64位头部
      var bytes = window.atob(imgs);
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: type });
    },
    //写入剪切板
    async writeClipboard(src) {
      try {
        const blob = this.convertBase64ToBlob(src, "image/png");
        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ]);
      } catch (err) {
        console.error(err.name, err.message);
      }
    },
    //图片生成失败回调函数
    posterFail(err) {
      console.log("fail", err);
    },
    // 复制成功
    copySuccess() {
      this.$message({
        message: this.translateTitle("复制成功"),
        type: "success",
        customClass: "zZindex",
      });
    },
    // 复制成功
    copyImageSuccess() {
      this.$message({
        message: this.translateTitle("图片已复制"),
        type: "success",
        customClass: "zZindex",
      });
    },
    //图片生成成功回调函数
    posterSuccess(src) {
      this.notifyImg = src;
      this.notifyVisible = true;
      this.writeClipboard(src);
      this.copyImageSuccess();
    },
    createProfitLossPoster(obj) {
      let strs = [];
      let index = 0;
      if (
        obj.position_info.position_profit_loss_procent >= 0 &&
        obj.position_info.position_profit_loss_procent <= 5
      ) {
        strs = [
          this.translateTitle("小试牛刀，加个鸡腿！"),
          this.translateTitle("什么是赢，不输为赢！"),
          this.translateTitle("小赚小赚，谢谢大家！"),
        ];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (
        obj.position_info.position_profit_loss_procent > 5 &&
        obj.position_info.position_profit_loss_procent < 20
      ) {
        strs = [
          this.translateTitle("我走的慢，但我永不后退！"),
          this.translateTitle("要啥自行车！"),
          this.translateTitle("小赚小赚，毛毛雨啦！"),
        ];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (
        obj.position_info.position_profit_loss_procent >= 20 &&
        obj.position_info.position_profit_loss_procent < 40
      ) {
        strs = [
          this.translateTitle("不求闻达于诸侯"),
          this.translateTitle("搏一搏，单车变摩托！"),
          this.translateTitle("承让了！弟弟!"),
        ];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (
        obj.position_info.position_profit_loss_procent >= 40 &&
        obj.position_info.position_profit_loss_procent < 60
      ) {
        strs = [
          this.translateTitle("币圈索罗斯， 正是在下！"),
          this.translateTitle("人之所以能，是因为相信"),
        ];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (
        obj.position_info.position_profit_loss_procent >= 60 &&
        obj.position_info.position_profit_loss_procent < 80
      ) {
        strs = [
          this.translateTitle("再接再厉，勇创辉煌"),
          this.translateTitle("就问一句，还有谁？"),
        ];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (
        obj.position_info.position_profit_loss_procent >= 80 &&
        obj.position_info.position_profit_loss_procent < 100
      ) {
        strs = [
          this.translateTitle("会当凌绝顶，一览众山小"),
          this.translateTitle("高处不胜寒，何似在人间"),
        ];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (obj.position_info.position_profit_loss_procent >= 100) {
        strs = [
          this.translateTitle("荣耀王者"),
          this.translateTitle("日进斗金，盆满钵满"),
        ];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      }

      if (obj.position_info.position_profit_loss_procent <= -50) {
        strs = [this.translateTitle("天生我财必有用，千金散尽还复来")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (
        obj.position_info.position_profit_loss_procent < -20 &&
        obj.position_info.position_profit_loss_procent >= -50
      ) {
        strs = [this.translateTitle("亏损，是所有成功者的必修课！")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (
        obj.position_info.position_profit_loss_procent < -5 &&
        obj.position_info.position_profit_loss_procent >= -20
      ) {
        strs = [this.translateTitle("及时止损，稳字当头")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (obj.position_info.position_profit_loss_procent < 0) {
        strs = [this.translateTitle("让我静静，谢谢")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      }
      this.painting = JSON.parse(JSON.stringify(this.profitLossPoster(obj)));
    },
    profitLossPoster(obj) {
      const tempObj = JSON.parse(JSON.stringify(obj));

      let result = {
        width: "650px",
        height: "1023px",
        background: "#fff",
        views: [
          {
            type: "rect",
            css: {
              top: "0px",
              left: "0px",
              width: "650px",
              height: "848px",
              color: this.sloganColor,
            },
          },
          //row.show_name
          {
            type: "image",
            url: this.profitLossShareBg,
            css: {
              top: "0px",
              left: "0px",
              width: "650px",
              height: "598px",
            },
          },
          {
            type: "text",
            text: this.currRow.show_name,
            css: {
              top: "30px",
              left: "40px",
              maxLines: 1,
              fontSize: "42px",
              color: "#fff",
            },
          },
          {
            type: "image",
            url:
              tempObj.position_info.position_profit_loss_procent >= 0
                ? this.expressionCool
                : this.expressionBad,
            css: {
              top: "147px",
              left: "215px",
              width: "220px",
              height: "220px",
            },
          },
          {
            type: "text",
            text: tempObj.solgan,
            css: {
              top: "399px",
              // left: "40px",
              textAlign: "center",
              width: "650px",
              maxLines: 1,
              fontSize: "34px",
              color: "#fff",
            },
          },
          {
            type: "text",
            text: this.translateTitle("收益率"),
            css: {
              top: "489px",
              textAlign: "center",
              width: "650px",
              maxLines: 1,
              fontSize: "34px",
              color: "#ffffff66",
            },
          },
          {
            type: "text",
            text:
              tempObj.operate_list[0] &&
              tempObj.operate_list[0].operate_type == 2
                ? tempObj.position_info.profit_loss_ratio >= 0
                  ? "+" + tempObj.position_info.profit_loss_ratio + "%"
                  : tempObj.position_info.profit_loss_ratio + "%"
                : tempObj.position_info.position_profit_loss_procent >= 0
                ? "+" + tempObj.position_info.position_profit_loss_procent + "%"
                : tempObj.position_info.position_profit_loss_procent + "%",
            css: {
              top: "535px",
              textAlign: "center",
              width: "650px",
              maxLines: 1,
              fontSize: "84px",
              color: "#ffffff",
              fontWeight: "bold",
            },
          },
          {
            type: "rect",
            css: {
              top: "661px",
              left: "25px",
              width: "600px",
              height: "148px",
              color: "#00000019",
              borderRadius: "12px",
            },
          },
          {
            type: "text",
            text: this.liquidation_symbol_name,
            css: {
              top: "692px",
              left: "69px",
              fontSize: "24px",
              color: "#ffffff",
              fontWeight: "500",
            },
          },
          {
            type: "text",
            text: this.translateTitle("开仓价格"),
            css: {
              top: "692px",
              left: "278px",
              fontSize: "24px",
              color: "#ffffff",
              fontWeight: "500",
            },
          },
          {
            type: "text",
            text: this.translateTitle("标记价格"),
            css: {
              top: "692px",
              left: "447px",
              fontSize: "24px",
              color: "#ffffff",
              fontWeight: "500",
            },
          },
          {
            type: "rect",
            css: {
              top: "738px",
              left: "70px",
              width: "160px",
              height: "43px",
              color: "#fff",
              borderRadius: "5.2px",
            },
          },
          {
            type: "text",
            text:
              tempObj.position_info.open_side == 1
                ? this.translateTitle("多") +
                  "·" +
                  parseInt(tempObj.position_info.open_leverage) +
                  "X"
                : this.translateTitle("空") +
                  "·" +
                  parseInt(tempObj.position_info.open_leverage) +
                  "X",
            css: {
              top: "743px",
              textAlign: "center",
              left: "70px",
              width: "160px",
              fontSize: "26px",
              color:
                tempObj.position_info.open_side == 1
                  ? "rgb(0 188 160)"
                  : "rgb(240 74 90)",
              fontWeight: "600",
            },
          },

          {
            type: "text",
            text: tempObj.position_info.open_price,
            css: {
              top: "737px",
              left: "278px",
              fontSize: "30px",
              color: "#fff",
              fontWeight: "500",
            },
          },
          {
            type: "text",
            text: tempObj.position_info.last_price,
            css: {
              top: "737px",
              left: "447px",
              fontSize: "30px",
              color: "#fff",
              fontWeight: "500",
            },
          },
          {
            type: "image",
            url: this.projectLogo,
            css: {
              top: "888px",
              left: "35px",
              width: "60px",
              height: "60px",
              borderRadius: "100%",
            },
          },
          {
            type: "text",
            text: this.projectFullName,
            css: {
              top: "895px",
              left: "110px",
              maxLines: 1,
              fontSize: "37px",
              fontWeight: "600",
              color: "#131f30",
            },
          },
          {
            type: "text",
            text:
              this.translateTitle("邀请码") +
              ":" +
              (this.default_invite_code &&
                this.default_invite_code.invite_url &&
                this.default_invite_code.invite_url.split("=")[1]),
            css: {
              top: "953px",
              left: "110px",
              fontSize: "26px",
              color: "#354052",
              fontWeight: "500",
            },
          },

          {
            type: "qrcode",
            content: this.centerHost + this.default_invite_code.invite_url,
            css: {
              top: "879px",
              left: "503px",
              color: "#000",
              width: "113px",
              height: "113px",
            },
          },
        ],
      };
      return result;
    },
    // 复制失败
    copyError() {
      this.$message({
        message: this.translateTitle("复制失败"),
        type: "error",
        customClass: "zZindex",
      });
    },
    //获取币种
    async getItemList() {
      const { status, data } = await getCoinList();
      if (status == 200) {
        this.coinInfoMap = this.coinData = data;
      }
    },
    //获取默认邀请码
    getRebateCommonInfo() {
      apiGetRebateCommonInfo().then((res) => {
        if (res.status == 200) {
          this.default_invite_code = res.data.default_invite_code;
        }
      });
    },
    //获取邀请链接 新
    getAppIntialDataV2() {
      apiGetInviteUrl().then((res) => {
        if (res.status == 200) {
          this.centerHost = res.data.invite_url;
        }
      });
    },
    //56-static_chain-区块链
    async getStaticChainMap() {
      const { status, data } = await apiGetStaticTableInfo(56);
      if (status == 200) {
        this.staticChainMap = Object.values(JSON.parse(JSON.stringify(data)));
      }
    },
    //7-static_currency-法币表
    async getStaticCurrencyMap() {
      const { status, data } = await apiGetStaticTableInfo(7);
      if (status == 200) {
        this.staticCurrencyMap = JSON.parse(JSON.stringify(data));
      }
    },
    //22-sotc_static_payment-支付方式静态表
    async getStaticPaymentMap() {
      const { status, data } = await apiGetStaticTableInfo(22);
      if (status == 200) {
        this.sotcStaticPaymentMap = JSON.parse(JSON.stringify(data));
      }
    },
    // 保护时间静态表-sotc_static_protect_type-41
    async getSotcStaticProtectMap() {
      const { status, data } = await apiGetStaticTableInfo(41);
      if (status == 200) {
        this.sotcStaticProtectMap = JSON.parse(JSON.stringify(data));
      }
    },
    // 包赔时间静态表 -sotc_static_guarantee_type-42
    async getSotcStaticGuaranteeMap() {
      const { status, data } = await apiGetStaticTableInfo(42);
      if (status == 200) {
        this.sotcStaticGuaranteeMap = JSON.parse(JSON.stringify(data));
      }
    },
    getSotcOrderDetailAccountInfos(orderObj) {
      // this.sotcStaticPaymentMap
      const payment = this.sotcStaticPaymentMap[orderObj.payment_id];
      const fields = JSON.parse(payment.app_client_field);
      let tempObj = { title: "", content: "", isQrCode: "" };
      let resultMap = [];
      Object.keys(fields).forEach((field) => {
        if (field != "qr_code_url") {
          const content = orderObj.payment_info[field];
          if (content) {
            tempObj.title = fields[field];
            tempObj.content = content;
            tempObj.isQrCode = false;
            resultMap.push(JSON.parse(JSON.stringify(tempObj)));
          }
        }
      });
      if (fields["qr_code_url"]) {
        const qrCodeUrl = orderObj.payment_info["qr_code_url"];
        if (qrCodeUrl) {
          tempObj.title = "qr_code_url";
          tempObj.content = qrCodeUrl;
          tempObj.isQrCode = true;
          resultMap.push(JSON.parse(JSON.stringify(tempObj)));
        }
      }
      const money = this.handleCutZero(orderObj.payment_info["ceiling"]);
      const content = money
        ? money +
          (this.staticCurrencyMap[orderObj.currency_id] &&
            this.staticCurrencyMap[orderObj.currency_id].abbreviate)
        : "--";
      tempObj.title = this.translateTitle("限额");
      tempObj.content = content;
      tempObj.isQrCode = false;
      resultMap.push(JSON.parse(JSON.stringify(tempObj)));

      tempObj.title = this.translateTitle("附言");
      tempObj.content = orderObj.payment_info["postscript"] || "--";
      tempObj.isQrCode = false;
      resultMap.push(JSON.parse(JSON.stringify(tempObj)));
      this.copyAccountMsg = "";
      resultMap.forEach((item) => {
        if (!item.isQrCode && item.content && item.content != "--") {
          this.copyAccountMsg =
            this.copyAccountMsg + item.title + ":" + item.content + "\n";
        }
      });
      return resultMap;
    },
    //根据链名称获取链信息
    getChainByName(name) {
      let chainObj = {};
      this.staticChainMap.forEach((item) => {
        if (item.chain_tag == name) {
          chainObj = item;
        }
      });
      return chainObj;
    },
    //U本位合约交易对
    async getContractConfigBySymbolId() {
      const { status, data } = await apiGetStaticTableInfo(49);
      if (status == 200) {
        this.staticSymbolData = JSON.parse(JSON.stringify(data));
      }
    },
    //币本位合约交易对
    async getFuturesConfigBySymbolId() {
      const { status, data } = await apiGetStaticTableInfo(58);
      if (status == 200) {
        this.staticFuturesSymbolData = JSON.parse(JSON.stringify(data));
      }
    },
    //账单类型
    async getStaticAssetsType() {
      const { status, data } = await apiGetStaticTableInfo(27);
      if (status == 200) {
        let tempTypeData = Object.values(JSON.parse(JSON.stringify(data)));
        this.staticAssetsTypeData = tempTypeData.filter((item) => {
          let filterStatus = !this.outerSearch.includes(item.assets_type);
          return filterStatus && item.is_show == 1;
        });
      }
    },
    //股票静态表
    async getStockConfigBySymbolId() {
      const { status, data } = await apiGetStaticTableInfo(46);
      if (status == 200) {
        this.staticStockData = JSON.parse(JSON.stringify(data));
      }
    },

    //用户获取订单详情-OTC 充值 提现
    async getUserOrderInfo() {
      const req = {
        pay_order_id: this.currRow.order_info.pay_order_id,
      };
      const { status, data } = await apiGetUserOrderInfo(req);
      if (status == 200) {
        let tempObj = JSON.parse(JSON.stringify(data));
        tempObj.isRechargeWithdrawOrder = ![5, 6].includes(
          this.currRow.assets_type
        );
        tempObj.isBuy = [5, 8].includes(this.currRow.assets_type);
        if (!tempObj.isRechargeWithdrawOrder) {
          tempObj.lockTimeType = tempObj.isBuy
            ? this.sotcStaticProtectMap[tempObj.protect_card_type]
            : this.sotcStaticGuaranteeMap[tempObj.guarantee_type];
          const status = tempObj.isBuy
            ? tempObj.protect_card_status
            : tempObj.guarantee_status;
          const unlockString =
            tempObj.lockTimeType.option +
            (tempObj.isBuy
              ? "[" + this.translateTitle("已解锁") + "]"
              : "[" + this.translateTitle("已过期") + "]");
          let aging = tempObj.lockTimeType.option;
          if (status == 1) {
            aging = tempObj.lockTimeType.option;
          } else if (status == 3) {
            aging =
              tempObj.lockTimeType.option +
              "[" +
              this.translateTitle("暂停") +
              "]";
          } else if (status == 4) {
            aging = unlockString;
          } else if (status == 3) {
            const now = parseInt(Date.parse(new Date()) / 1000);
            const expire = tempObj.isBuy
              ? tempObj.protect_card_expire
              : tempObj.guarantee_expire;
            const seconds = expire - now;
            const leftTime = moment.utc(seconds).format("HH:mm:ss");
            const leftTimeString = this.translateTitle("剩余") + leftTime;
            aging =
              seconds <= 0
                ? unlockString
                : tempObj.lockTimeType.option + "[" + leftTimeString + "]";
          }
          tempObj.aging = aging;
        }
        tempObj.receivingAccount = this.getSotcOrderDetailAccountInfos(tempObj);

        let timelineObj = {
          title: "",
          time: "",
          curr: false,
          color: "",
          type: "",
          icon: "",
        };
        let timelineArray = [];
        const cardDidGive = tempObj.issuing_card_time > 0;
        const canceled = tempObj.cancel_time > 0;
        const moneyDidPay = tempObj.payment_time > 0;
        const coinDidRelease = tempObj.do_received_time > 0;
        const isOrderCreatedByServer = tempObj.active_type == "2";
        //订单创建
        timelineObj.time = this.moment(tempObj.create_time * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        timelineObj.title = tempObj.isBuy
          ? isOrderCreatedByServer
            ? this.translateTitle("客服已创建订单，待客服给收款账号")
            : this.translateTitle("您已创建订单，待客服给收款账号")
          : isOrderCreatedByServer
          ? this.translateTitle("客服已创建订单,待您接单")
          : this.translateTitle("您已创建订单，待客服接单");
        timelineArray.push(JSON.parse(JSON.stringify(timelineObj)));
        //注意取消订单
        //已给卡
        if (!canceled && !coinDidRelease) {
          timelineObj.time = cardDidGive
            ? this.moment(tempObj.issuing_card_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : "";
          timelineObj.title = tempObj.isBuy
            ? this.translateTitle("客服已给收款账号，待您付款")
            : isOrderCreatedByServer
            ? this.translateTitle("您已接单，客服付款中")
            : this.translateTitle("客服已接单，付款中");
          timelineArray.push(JSON.parse(JSON.stringify(timelineObj)));
        }
        //已付款
        if (!canceled && !coinDidRelease) {
          timelineObj.time = moneyDidPay
            ? this.moment(tempObj.payment_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : "";
          timelineObj.title = tempObj.isRechargeWithdrawOrder
            ? tempObj.isBuy
              ? this.translateTitle("您已付款，待客服充值")
              : this.translateTitle("客服已付款，待您确认收款")
            : tempObj.isBuy
            ? this.translateTitle("您已付款，待客服放币")
            : this.translateTitle("客服已付款，待您放币");
          timelineArray.push(JSON.parse(JSON.stringify(timelineObj)));
        }
        //已放币(已完成)
        if (!canceled) {
          timelineObj.time = coinDidRelease
            ? this.moment(tempObj.do_received_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : "";
          timelineObj.title = tempObj.isRechargeWithdrawOrder
            ? tempObj.isBuy
              ? this.translateTitle("客服已充值，订单完成")
              : this.translateTitle("您已确认收款，订单完成")
            : tempObj.isBuy
            ? this.translateTitle("客服已放币，订单完成")
            : this.translateTitle("您已放币，订单完成");
          timelineArray.push(JSON.parse(JSON.stringify(timelineObj)));
        }
        const cancelTime = canceled
          ? this.moment(tempObj.cancel_time * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "";
        if (canceled) {
          const reasons = {
            5: this.translateTitle("您取消订单"),
            6: this.translateTitle("客服取消订单"),
            7: this.translateTitle("系统取消订单"),
          };
          timelineObj.time = cancelTime;
          timelineObj.title = reasons[tempObj.order_status]
            ? reasons[tempObj.order_status]
            : this.translateTitle("取消订单");
          timelineArray.push(JSON.parse(JSON.stringify(timelineObj)));
        }
        let nextIndex = 0;
        for (let i = 0; i < timelineArray.length; i++) {
          timelineArray[i].color = "#00bca0";
          timelineArray[i].type = "success";
          timelineArray[i].icon = "el-icon-check";
          if (!timelineArray[i].time) {
            timelineArray[i - 1].curr = true;
            timelineArray[i - 1].color = "#00bca0";
            timelineArray[i - 1].type = "primary";
            timelineArray[i - 1].icon = "el-icon-more";

            timelineArray[i].color = "";
            timelineArray[i].type = "";
            timelineArray[i].icon = "";
            break;
          }
        }
        tempObj.timelineArray = timelineArray;

        const haveAccounts = tempObj.payment_info_list.length > 0;
        if (!haveAccounts) {
          let detailEmptyTitle = tempObj.isBuy
            ? this.translateTitle("客服正在准备收款账号")
            : this.translateTitle("请您接单");
          if (tempObj.order_status != "1") {
            detailEmptyTitle = tempObj.isBuy
              ? this.translateTitle("客服未给收款账号")
              : this.translateTitle("无收款账号");
          }
          tempObj.detailEmptyTitle = detailEmptyTitle;
        }
        tempObj.picEmptyTitle = this.getPicEmptyTitle(tempObj);
        this.user_order_obj = tempObj;
      }
    },
    getPicEmptyTitle(tempObj) {
      let picEmptyTitle = "";
      if (tempObj.isBuy) {
        if (tempObj.order_status == "1") {
          picEmptyTitle =
            this.translateTitle("请收到客服收款账号后上传付款截图");
          return picEmptyTitle;
        }
        if (!tempObj.pay_voucher_price_list.length) {
          picEmptyTitle = this.translateTitle("您未上传付款截图");
          return picEmptyTitle;
        }
      } else {
        if (tempObj.order_type == "1") {
          if (tempObj.active_type == "2") {
            picEmptyTitle = this.translateTitle("请您接单");
            return picEmptyTitle;
          }
          picEmptyTitle = this.translateTitle("请等待客服接单");
          return picEmptyTitle;
        }
        if (tempObj.order_type == "2") {
          picEmptyTitle = this.translateTitle("请等待客服付款并上传付款截图");
          return picEmptyTitle;
        }
        if (!tempObj.pay_voucher_price_list.length) {
          picEmptyTitle = this.translateTitle("客服未上传付款截图");
          return picEmptyTitle;
        }
      }
    },
    getOtcOrderStatusMaps(isRechargeWithdrawOrder, isBuy, statusCode) {
      let orderStatus = "";
      switch (statusCode) {
        case "1":
          orderStatus = isBuy
            ? this.translateTitle("待客服给卡")
            : this.translateTitle("待客服接单");
          break;
        case "2":
          orderStatus = isBuy
            ? this.translateTitle("待您付款")
            : this.translateTitle("待客服付款");
          break;
        case "3":
          orderStatus = isRechargeWithdrawOrder
            ? isBuy
              ? this.translateTitle("待客服充值")
              : this.translateTitle("待您确认收款")
            : isBuy
            ? this.translateTitle("待客服放币")
            : this.translateTitle("待您放币");
          break;
        case "4":
          orderStatus = this.translateTitle("订单完成");
          break;
        case "5":
          orderStatus = this.translateTitle("用户取消");
          break;
        case "6":
          orderStatus = this.translateTitle("客服取消");
          break;
        case "7":
          orderStatus = this.translateTitle("系统取消");
          break;
        default:
      }
      return orderStatus;
    },
    //获取U本位合约详情
    async getContractPositionInfo() {
      const req = {
        position_order_id: this.position_order_id,
      };
      const { status, data } = await apiGetContractPositionInfo(req);
      if (status == 200) {
        let position_order_obj_temp = JSON.parse(JSON.stringify(data));
        if ([35, 36].includes(this.currRow.assets_type)) {
          this.symbolList = this.staticSymbolData;
        } else {
          this.symbolList = this.staticFuturesSymbolData;
        }
        for (const key in this.symbolList) {
          if (
            this.symbolList[key].open_item_id ==
            position_order_obj_temp.position_info.open_item_id
          ) {
            position_order_obj_temp.position_info.settlement_precision =
              this.symbolList[key].settlement_precision;
            position_order_obj_temp.position_info.contract_value =
              this.symbolList[key].contract_value;
          }
        }
        this.liquidation_dialog_loading = false;
        this.position_order_obj = position_order_obj_temp;
      }
    },

    //获取币本位合约详情
    async getFuturesPositionInfo() {
      const req = {
        position_order_id: this.position_order_id,
      };
      const { status, data } = await apiGetFuturesPositionInfo(req);
      if (status == 200) {
        let position_order_obj_temp = JSON.parse(JSON.stringify(data));
        if ([35, 36].includes(this.currRow.assets_type)) {
          this.symbolList = this.staticSymbolData;
        } else {
          this.symbolList = this.staticFuturesSymbolData;
        }
        for (const key in this.symbolList) {
          if (
            this.symbolList[key].open_item_id ==
            position_order_obj_temp.position_info.open_item_id
          ) {
            position_order_obj_temp.position_info.settlement_precision =
              this.symbolList[key].settlement_precision;
            position_order_obj_temp.position_info.contract_value =
              this.symbolList[key].contract_value;
          }
        }
        this.liquidation_dialog_loading = false;
        this.position_order_obj = position_order_obj_temp;
      }
    },

    async productInfoClick(row) {
      this.currRow = row;
      this.currRow.coinImageUrl =
        this.coinData[this.currRow.item_id].itemDetail.coin_url;
      this.currRow.coinItemName =
        this.coinData[this.currRow.item_id].item_name.toUpperCase();
      await this.getUserOrderInfo();
      this.otcDialogVisible = true;
    },

    //请求U本位合约并打开仓位详情
    async liquidationContractInfoClick(tempRow = "") {
      this.liquidation_dialog_loading = true;
      this.reChargeDialogVisible = true;
      this.currRow = tempRow;
      this.position_order_id = this.currRow.order_info.position_order_id;
      await this.getContractPositionInfo();
      this.liquidation_symbol_name =
        this.staticSymbolData[
          this.position_order_obj.position_info.symbol_id
        ] &&
        this.staticSymbolData[this.position_order_obj.position_info.symbol_id]
          .language[`symbol_name_${this.language}`];
    },
    //请求币本位合约并打开仓位详情
    async liquidationFuturesInfoClick(tempRow = "") {
      this.liquidation_dialog_loading = true;
      this.reChargeDialogVisible = true;
      this.currRow = tempRow;
      this.position_order_id = this.currRow.order_info.position_order_id;
      await this.getFuturesPositionInfo();
      this.liquidation_symbol_name =
        this.staticFuturesSymbolData[
          this.position_order_obj.position_info.symbol_id
        ] &&
        this.staticFuturesSymbolData[
          this.position_order_obj.position_info.symbol_id
        ].language[`symbol_name_${this.language}`];
    },

    reChargehandleClose() {
      this.reChargeDialogVisible = false;
    },
    reChargehandleClosed() {
      this.liquidation_symbol_name = "--";
      this.position_order_obj = { position_info: {}, operate_list: [] };
    },
    otcHandleClose(row) {
      this.otcDialogVisible = false;
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },

    handleCutZero(num) {
      if (!num) return 0;
      //拷贝一份 返回去掉零的新串
      let newstr = num;
      //循环变量 小数部分长度
      // console.log(num.indexOf('.') - 1);
      let leng = num.length - num.indexOf(".") - 1;
      //判断是否有效数
      if (num.indexOf(".") > -1) {
        //循环小数部分
        for (let i = leng; i > 0; i--) {
          //如果newstr末尾有0
          if (
            newstr.lastIndexOf("0") > -1 &&
            newstr.substr(newstr.length - 1, 1) == 0
          ) {
            let k = newstr.lastIndexOf("0");
            //如果小数点后只有一个0 去掉小数点
            if (newstr.charAt(k - 1) == ".") {
              return newstr.substring(0, k - 1);
            } else {
              //否则 去掉一个0
              newstr = newstr.substring(0, k);
            }
          } else {
            //如果末尾没有0
            return newstr;
          }
        }
      }
      return num;
    },
    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWs() {
      const me = this;
      const option = {
        url: this.wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "assets",
          },
        },
        callback,
        onConnect,
        mounted,
      };

      let wsNew2 = new Socket(option).getInstance();
      me.global.setWs2(wsNew2);
      // 重新连接
      function onConnect() {
        if (me.isDestoryWS) return;
        if (me.lockReconnect) return;
        // if (me.wsContentTime > 3) {
        //   window.location.reload();
        // }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWs();
          me.lockReconnect = false;
        }, 3000);
      }
      function mounted() {
        me.global.ws2.sub({
          action: "Topic.sub",
          data: {
            type: "contractM",
            app_id: VUE_APP_ID,
          },
        });
        me.global.ws2.sub({
          action: "Topic.sub",
          data: {
            type: "futureM",
            app_id: VUE_APP_ID,
          },
        });
      }
      function callback(data) {
        const { action } = data;
        if (action == "Pushdata.futureM") {
          if (data.data) {
            if ([35, 36].includes(me.currRow && me.currRow.assets_type)) return;
            let tempArr = data.data;
            tempArr.forEach((ele) => {
              ele.lastV2 = Number(ele.last);
              ele.currency.usdV2 = Number(ele.usd);
              ele.price_change_percentV2 = Number(ele.price_change_percent);
            });
            me.marketList = tempArr;
          }
        }

        if (action == "Pushdata.contractM") {
          if (data.data) {
            if (![35, 36].includes(me.currRow && me.currRow.assets_type)) {
              return;
            }
            let tempArr = data.data;
            tempArr.forEach((ele) => {
              ele.lastV2 = Number(ele.last);
              ele.currency.usdV2 = Number(ele.usd);
              ele.price_change_percentV2 = Number(ele.price_change_percent);
            });
            me.marketList = tempArr;
          }
        }

        // 资产
        if (action == "Pushdata.assets") {
          if (data.data == null || data.data instanceof Array) {
            return;
          }
          me.assetsInfo = data.data;
        }
      }
    },
  },
  watch: {
    marketListTran: {
      //深度监听，可监听到对象、数组的变化
      handler(newV, oldV) {
        if (!this.isStartWS) {
          this.marketList = newV;
        }
      },
      deep: true,
    },
    assetsInfoTran: {
      //深度监听，可监听到对象、数组的变化
      handler(newV, oldV) {
        if (!this.isStartWS) {
          this.assetsInfo = newV;
        }
      },
      deep: true,
    },
    marketList() {
      if ([35, 36].includes(this.currRow.assets_type)) {
        this.marketList.forEach((item) => {
          if (
            item.symbol_id == this.position_order_obj.position_info.symbol_id
          ) {
            this.position_order_obj.position_info.last_price = item.last;
          }
        });
        let tempSide =
          this.position_order_obj.position_info.open_side == 1 ? 1 : -1;
        // 未实现盈亏
        this.position_order_obj.position_info.position_profit_loss = (
          (this.position_order_obj.position_info.last_price -
            this.position_order_obj.position_info.open_price) *
          tempSide *
          this.position_order_obj.position_info.open_interest
        ).toFixed(2);
        this.position_order_obj.position_info.position_profit_loss_procent = (
          (((this.position_order_obj.position_info.last_price -
            this.position_order_obj.position_info.open_price) *
            tempSide *
            this.position_order_obj.position_info.open_interest) /
            this.position_order_obj.position_info.bail_number) *
          100
        ).toFixed(2);
      } else {
        this.marketList.forEach((item) => {
          if (
            item.symbol_id == this.position_order_obj.position_info.symbol_id
          ) {
            this.position_order_obj.position_info.last_price = item.last;
          }
        });

        // 未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价)
        let tempContractValue = new BigNumber(
          this.position_order_obj.position_info.contract_value
        );
        let side =
          this.position_order_obj.position_info.open_side == 1 ? 1 : -1;
        let tempSide = new BigNumber(side);
        let one = new BigNumber(1);
        let oneHundred = new BigNumber(100);
        let tempQty = new BigNumber(this.position_order_obj.position_info.qty);
        let tempOpenPrice = new BigNumber(
          this.position_order_obj.position_info.open_price
        );
        let tempLastPrice = new BigNumber(
          this.position_order_obj.position_info.last_price
        );
        let tempSettlementPrecision = new BigNumber(
          this.position_order_obj.position_info.settlement_precision
        );
        // 未实现盈亏
        this.position_order_obj.position_info.position_profit_loss = tempQty
          .times(tempContractValue)
          .times(one.div(tempOpenPrice).minus(one.div(tempLastPrice)))
          .times(tempSide)
          .toNumber()
          .toFixed(this.position_order_obj.position_info.settlement_precision);
        // .toFixed(this.number_precision);
        let tempValue = new BigNumber(
          this.position_order_obj.position_info.position_profit_loss
        );
        this.position_order_obj.position_info.position_profit_loss_procent =
          tempValue
            .div(this.position_order_obj.position_info.bail_number)
            .times(oneHundred)
            .toFixed(2);
      }
    },
  },
};
</script>
<style lang="scss">
.zZindex {
  z-index: 3000 !important;
}
.uc-bill-center {
  .income-color-red {
    color: rgb(240 74 90) !important;
  }
  .income-color-green {
    color: rgb(0 188 160) !important;
  }
  .set-profitPrice-dialog {
    .el-dialog__header {
      padding: 20px 30px 5px;
    }
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      padding: 0px 30px 5px;
    }
    .el-dialog__footer {
      padding: 35px 20px 30px;
    }
    .profitPrice-first-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .left-item {
        color: #354052;
        font-size: 16px;
        font-weight: 500;
      }
      .right-item {
        font-size: 12px;
        .green {
          color: #03ad79;
        }
        .red {
          color: #c61b3d;
        }
      }
      &.V2 {
        margin-top: 15px;
      }
    }
    .profitPrice-second-content {
      position: relative;
      .el-input__suffix {
        line-height: 40px;
        margin-right: 40px;
        color: #354052;
      }
      .all-btn {
        position: absolute;
        right: 10px;
        top: 9px;
      }
    }
    .profitPrice-three-content {
      width: 440px;
      height: 70px;
      margin: 15px 0px 15px 0px;
      padding: 14px 15px;
      border-radius: 4px;
      background-color: #f8f8f8;
      .item-one,
      .item-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          display: block;
          margin-bottom: 2px;
          color: #7f8490;
          font-size: 12px;
          &.black {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
          }
          &.red {
            font-size: 14px;
            color: #f04a5a;
          }
        }
        .left-item {
          width: 150px;
        }
        .center-item {
          width: 150px;
          text-align: center;
        }
        .right-item {
          width: 150px;
          text-align: right;
        }
      }
    }
  }
  .append-money-dialog {
    .el-dialog__header {
      padding: 20px 30px 20px;
    }
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      padding: 0px 30px 5px;
    }
    .el-dialog__footer {
      padding: 35px 20px 30px;
    }
    .first-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;
      font-size: 12px;
      .available-assets {
        color: #bec1c8;
      }
      .left-item {
        color: #354052;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .second-content {
      position: relative;
      .el-input__suffix {
        line-height: 40px;
        margin-right: 40px;
        color: #354052;
        .el-input__suffix-inner {
          font-size: 12px;
        }
      }
      .all-btn {
        position: absolute;
        right: 15px;
        top: 12px;
        font-size: 12px;
      }
    }
    .three-content {
      width: 440px;
      height: 120px;
      margin: 20px 30px 20px 0px;
      padding: 14px 15px;
      border-radius: 4px;
      background-color: #f8f8f8;
      .item-one,
      .item-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          display: block;
          margin-bottom: 2px;
          color: #9aa1b1;
          font-size: 12px;
          &.black {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
          }
          &.red {
            font-weight: 500;
            font-size: 14px;
            color: #f04a5a;
          }
        }
        .left-item {
          width: 150px;
        }
        .right-item {
          width: 150px;
          text-align: right;
        }
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
  /* dialog居中开始 */
  .order-dialog .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 60px);
    max-width: calc(100% - 30px);
  }
  .order-dialog .el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar-track-piece {
      background-color: #fff;
      border-radius: 4px;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #dddddd;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #bbb;
    }
  }
  /* dialog居中结束 */
  .liquidation-dialog {
    .el-dialog__header {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 30px 10px;
    }
    .el-dialog__body {
      padding: 10px 30px 30px;
    }
    .time-block {
      width: 100%;
      padding: 20px 15px 0 4px;
      min-height: 223px;
      .el-timeline {
        // .el-timeline-item {
        //   .el-timeline-item__tail {
        //     position: absolute;
        //     left: 4px;
        //     top: calc(50% - 20px);
        //     transform: translate(0%, -100%);
        //     height: 100%;
        //     border-left: 2px solid #e4e7ed;
        //   }
        //   .el-timeline-item__node--normal {
        //     left: -1px;
        //     width: 12px;
        //     top: calc(50% - 20px);
        //     height: 12px;
        //   }
        // }
        // .el-timeline-item:last-child {
        //   .el-timeline-item__tail {
        //     display: block;
        //     position: absolute;
        //     left: 4px;
        //     top: calc(50% - 20px);
        //     transform: translate(0%, -100%);
        //     // height: 75%;
        //     border-left: 2px solid #e4e7ed;
        //   }
        // }
        // .el-timeline-item:first-child {
        //   .el-timeline-item__tail {
        //     display: block;
        //     position: absolute;
        //     left: 4px;
        //     top: calc(50% - 20px);
        //     transform: translate(0%, 0%);
        //     height: 100%;
        //     border-left: 2px solid #e4e7ed;
        //   }
        // }
      }
      .cardStep {
        display: flex;
        flex-direction: column;
        .time-copyinfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .time {
            color: rgb(154 161 177);
            font-size: 13px;
            display: flex;
          }
          .copyinfo {
            .title {
              color: rgb(154 161 177);
              font-size: 14px;
            }
            .info {
              color: #354052;
              font-size: 14px;
            }
          }
        }
        .time {
          color: rgb(154 161 177);
          font-size: 13px;
        }
        .contentStep {
          margin-top: 5px;
          display: flex;
          justify-content: space-between;
          .sub-title {
            color: rgb(53 64 82);
            font-size: 14px;
          }
          .sub-content {
            color: rgb(53 64 82);
            font-size: 14px;
          }
        }
      }
      .cardStartAndEnd {
        display: flex;
        flex-direction: column;
        .time-copyinfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .time {
            color: rgb(154 161 177);
            font-size: 13px;
            display: flex;
          }
          .copyinfo {
            .title {
              color: rgb(154 161 177);
              font-size: 14px;
            }
            .info {
              color: #354052;
              font-size: 14px;
            }
          }
        }

        .titleStartAndEnd {
          margin: 5px 0;
          color: rgb(53 64 82);
          font-size: 15px;
        }
        .contentStartAndEnd {
          margin-bottom: 3px;
          display: flex;
          justify-content: space-between;
          .under-line {
            border-bottom: 0.5px solid rgb(194 199 208);
            text-decoration: none;
            cursor: pointer;
          }
          .sub-title {
            color: rgb(154 161 177);
            font-size: 13px;
          }
          .sub-content {
            color: rgb(53 64 82);
            font-size: 13px;
            .rate {
              color: rgb(154 161 177);
              font-size: 12px;
            }
          }
        }
      }
    }
    .time-block::-webkit-scrollbar-track-piece {
      /* background-color: #f8f8f8; */
      background-color: #fff;
    }
    .time-block::-webkit-scrollbar {
      width: 6px;
    }
    .time-block::-webkit-scrollbar-thumb {
      background-color: #dddddd;
      border-radius: 100px;
    }
    .time-block::-webkit-scrollbar-thumb:hover {
      background-color: #bbb;
    }
    .do-recode {
      width: 100%;
      height: auto;
      padding: 15px 0;
      color: rgb(53 64 82);
      font-size: 16px;
    }
    .intro {
      width: 100%;
      height: auto;
      padding: 20px 15px;
      border-radius: 6px;
      backdrop-filter: blur(9.1px);
      box-shadow: 0 0 15px 0 rgba(53, 64, 82, 0.06);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .transaction-data {
        margin-top: 15px;
        display: flex;
        .position-button-blue {
          color: #3a7dff;
          font-size: 14px;
          padding: 5px 40px;
          border-radius: 4px;
          background-color: #eff3f8;
          cursor: pointer;
        }
        .position-button {
          border: 0.5px solid rgb(194 199 208);
          font-size: 14px;
          border-radius: 4px;
          cursor: pointer;
          width: 80%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .opening-price-left {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 14px;
            color: rgb(194 199 208);
          }
          .content {
            margin-top: 3px;
            font-size: 14px;
            color: rgb(53 64 82);
          }
        }
        .opening-price-middle {
          flex: 1;
          margin-left: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 14px;
            color: rgb(194 199 208);
          }
          .content {
            margin-top: 3px;
            font-size: 14px;
            color: rgb(53 64 82);
          }
        }
        .opening-price-right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          .title {
            font-size: 14px;
            color: rgb(194 199 208);
          }
          .under-line {
            border-bottom: 0.5px solid rgb(194 199 208);
            text-decoration: none;
            cursor: pointer;
          }
          .content {
            margin-top: 3px;
            font-size: 14px;
            color: rgb(53 64 82);
          }
        }
      }
      .splite-line {
        width: 100%;
        height: 1px;
        background-color: rgb(238 238 238);
        margin-top: 13px;
        margin-bottom: 0px;
      }
      .income-data-status {
        margin-top: 3px;
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .income-data-num {
          font-weight: 500;
          font-size: 20px;
          color: rgb(53 64 82);
        }
        .income-color-red {
          color: rgb(240 74 90);
        }
        .income-color-green {
          color: rgb(0 188 160);
        }
      }
      .income-status {
        margin-top: 15px;
        width: 100%;
        height: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .income-num {
          font-size: 14px;
          color: rgb(194 199 208);
        }
      }
      .order-status {
        width: 100%;
        height: 22px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .symbol-name {
          font-size: 15px;
          color: rgb(53 64 82);
          font-weight: 500;
        }
        .transaction-multiple {
          margin-left: 10px;
          font-size: 15px;
        }
        .price-share {
          margin-left: auto;
          font-size: 15px;
          color: rgb(53 64 82);
          font-weight: 500;
          .last-price {
            margin-right: 5px;
          }
          .share-img {
            font-size: 20px;
            vertical-align: -9%;
            cursor: pointer;
          }
        }
      }
    }
  }
  .reCharge-dialog {
    .el-dialog__header {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 30px 10px;
    }
    .el-dialog__body {
      padding: 10px 30px 30px;
    }
    .billImagePanel {
      margin-top: 10px;
      width: 100%;
      height: auto;
      padding: 20px 15px 0px;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(53, 64, 82, 0.06);
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      min-height: 160px;
      .no-data-panel {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .image-block {
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 85px;
        .image-block-item {
          width: 70px;
          height: 70px;
          border-radius: 4.2px;
        }
      }
    }
    .billPanel {
      margin-top: 10px;
      width: 100%;
      height: auto;
      padding: 20px 15px 0px;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(53, 64, 82, 0.06);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .el-timeline {
        // .el-timeline-item {
        //   .el-timeline-item__tail {
        //     position: absolute;
        //     left: 4px;
        //     top: calc(50% - 20px);
        //     transform: translate(0%, -100%);
        //     height: 100%;
        //     border-left: 2px solid #e4e7ed;
        //   }
        //   .el-timeline-item__node--normal {
        //     left: -1px;
        //     width: 12px;
        //     top: calc(50% - 20px);
        //     height: 12px;
        //   }
        // }
        // .el-timeline-item:last-child {
        //   .el-timeline-item__tail {
        //     display: block;
        //     position: absolute;
        //     left: 4px;
        //     top: calc(50% - 20px);
        //     transform: translate(0%, -100%);
        //     height: 100%;
        //     border-left: 2px solid #e4e7ed;
        //   }
        // }
        // .el-timeline-item:first-child {
        //   .el-timeline-item__tail {
        //     display: block;
        //     position: absolute;
        //     left: 4px;
        //     top: calc(50% - 20px);
        //     transform: translate(0%, 0%);
        //     height: 100%;
        //     border-left: 2px solid #e4e7ed;
        //   }
        // }
      }
      .noActive {
        box-shadow: 0 0px 0px 0 rgb(0 0 0 / 10%);
        border: 0px;
        .cardStep {
          .contentStep {
            .sub-title {
              color: #c2c3ce;
            }
            .sub-title-green {
              color: #00bca0;
            }
          }
        }
      }
      .cardStep {
        display: flex;
        flex-direction: column;
        .time {
          color: rgb(154 161 177);
          font-size: 13px;
        }
        .contentStep {
          margin-top: 5px;
          display: flex;
          justify-content: space-between;
          .sub-title {
            color: rgb(53 64 82);
            font-size: 14px;
          }
          .sub-content {
            color: rgb(53 64 82);
            font-size: 14px;
          }
        }
      }
      .image-block {
        display: flex;
      }
      .outer-bill-item {
        display: flex;
        flex-direction: column;
      }
      .bill-space {
        width: 100%;
        height: 0px;
        margin: 8px auto;
      }
      .bill-item {
        width: 100%;
        /* height: 20px; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        .bill-item-title {
          display: inline-block;
          width: 70px;
          font-size: 14px;
          color: #354052;
          flex-shrink: 0;
        }

        .bill-item-content {
          font-size: 13px;
          color: #9aa1b1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          padding: 0 0 0 15px;
          .bill-item-content-image {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
          }
          .image-qr-code {
            margin-left: 8px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .copy-i {
            margin-left: 8px;
            width: 18px;
            height: 20px;
            font-size: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
    .tab-content {
      user-select: none;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .copy-account-msg {
        margin-top: 6px;
        color: #3a7dff;
        padding: 5px 15px;
        border-radius: 20px;
        background-color: #eff3f8;
        cursor: pointer;
      }
      ul {
        display: flex;
      }
      .classify-area {
        margin-top: 5px;
        padding: 12px 15px 12px 15px;
        cursor: pointer;
        font-size: 15px;
        line-height: 25px;
        color: #354052;
        position: relative;
        &:first-child {
          margin-left: -15px;
        }
        &.active {
          font-weight: 500;
          background-color: #ffffff;
          color: #354052;
        }
        .item-bar {
          position: absolute;
          width: 32%;
          height: 4px;
          border-radius: 2.5px;
          background-color: #e81b40;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }
      }
    }
    .intro {
      width: 100%;
      height: auto;
      padding: 20px 15px;
      border-radius: 6px;
      backdrop-filter: blur(9.1px);
      box-shadow: 0 0 15px 0 rgba(53, 64, 82, 0.06);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .item-outer {
        display: flex;
        flex-direction: column;
      }
      .reCharge-space {
        width: 100%;
        height: 0px;
        margin: 8px 0;
      }
      .reCharge-divider {
        background-color: #eee;
      }
      .reCharge-item {
        width: 100%;
        height: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item-title {
          font-size: 14px;
          font-weight: 400;
          color: #9aa1b1;
        }
        .under-line {
          border-bottom: 0.5px solid rgb(194 199 208);
          text-decoration: none;
          cursor: pointer;
        }
        .item-value {
          font-size: 14px;
          color: #354052;
          .order-no {
            margin-right: 10px;
          }
          .copy-i {
            width: 15px;
            height: 15px;
            font-size: 15px;
            cursor: pointer;
            color: #c2c7d0;
          }
        }
      }
      .reCharge-status {
        width: 100%;
        height: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .status {
          font-size: 14px;
          color: #9aa1b1;
        }
        .status-yellow {
          color: #e58611;
        }
        .icon-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .reChargeIcon {
            width: 22px;
            height: 22px;
            margin-right: 5.5px;
            user-select: none;
          }
          .reChargeTitle {
            font-size: 16px;
            font-weight: 500;
            color: #354052;
          }
        }
      }
    }
  }
  .image-dialog.el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
  }
  .image-dialog.el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
    border-radius: 4px;
  }
  .image-dialog {
    .el-dialog__header {
      display: none;
      padding: 0;
    }
    .el-dialog__body {
      padding: 0 0 0 0;
      .dialog-image {
        width: 100%;
        height: 100%;
        // margin-bottom: -5px;
      }
    }
  }
  .confirm-dialog {
    .button-group {
      margin: 20px 25px 0 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-space {
        width: 10px;
        height: 40px;
      }
      .btn-cancel-disable {
        width: 160px;
        height: 40px;
        border-radius: 3px;
        font-size: 14px;
        text-align: center;
        color: #fff;
        background-color: #c2c7d0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .btn-cancel {
        width: 160px;
        height: 40px;
        border-radius: 3px;
        border: solid 1px #c2c7d0;
        font-size: 14px;
        text-align: center;
        color: #354052;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .btn-confirm {
        width: 160px;
        height: 40px;
        border-radius: 3px;
        background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
        font-size: 14px;
        text-align: center;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .btn-confirm-common {
        width: 160px;
        height: 40px;
        border-radius: 3px;
        border-color: #c2c7d0;
        font-size: 14px;
        text-align: center;
        color: #354052;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .billPanel {
      margin-top: 10px;
      width: 100%;
      max-height: 300px;
      padding: 20px 15px 0px;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(53, 64, 82, 0.06);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      &::-webkit-scrollbar-track-piece {
        background-color: #fff;
        border-radius: 4px;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        border-radius: 100px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
      }
      .bill-space {
        width: 100%;
        height: 20px;
      }
      .bill-item {
        width: 100%;
        min-height: 18px;
        display: flex;
        .bill-item-title {
          display: inline-block;
          width: 90px;
          font-size: 14px;
          color: #9aa1b1;
        }
        .bill-item-content {
          margin-left: 10px;
          display: inline-block;
          width: calc(100% - 90px - 40px);
          font-size: 14px;
          color: #354052;
          white-space: wrap;
        }
        .copy-i {
          width: 18px;
          margin-left: 20px;
          height: 18px;
          font-size: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
    .coinNum {
      width: 100%;
      height: 88px;
      padding: 20px 15px;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(53, 64, 82, 0.06);
      background-color: #fff;
      display: flex;
      .coinImg {
        width: 48px;
        height: 48px;
      }
      .coinMsg {
        margin-left: 13px;
        width: calc(100% - 48px);
        display: flex;
        flex-direction: column;
        .coinCount {
          color: #f04a5a;
          font-size: 16px;
          font-weight: 500;
        }
        .coinStatus {
          margin-top: 6px;
          font-size: 13px;
          font-weight: 500;
          color: #c2c7d0;
        }
      }
    }
  }
  .trade-container {
    .header {
      border-radius: 4px;
      .content-title {
        background-color: #fff;
        position: relative;
        display: flex;
        box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%);
        align-items: center;
        background-color: #ffffff;
        user-select: none;
        h2 {
          font-size: 26px;
          font-weight: 600;
          color: #354052;
          text-align: left;
          height: 80px;
          line-height: 80px;
          padding-left: 24px;
        }
      }
    }

    .bill-center-content {
      padding: 0px 20px;
      background: #ffffff;
      overflow: hidden;
      margin: 20px;
      min-height: 600px;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);

      .filter-box {
        margin: 15px 0px 10px 0px;
        .tenWid {
          width: 10px;
          height: 1px;
          display: inline-block;
          position: relative;
        }
        .twentyWid {
          width: 20px;
          height: 1px;
          display: inline-block;
          position: relative;
        }
        .btn-search {
          user-select: none;
          cursor: pointer;
          width: 120px;
          height: 16px;
          padding: 10px 21px;
          border-radius: 3px;
          background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
          color: #fff;
          font-size: 14px;
        }
        .btn-reset {
          user-select: none;
          cursor: pointer;
          width: 120px;
          height: 16px;
          padding: 9px 20px;
          border-radius: 3px;
          color: #354052;
          font-size: 14px;
          border: solid 1px #c2c7d0;
          box-sizing: border-box;
        }
        .btn-export {
          user-select: none;
          cursor: pointer;
          /* width: 120px; */
          padding: 10px 12px;
          border-radius: 3px;
          color: #354052;
          font-size: 14px;
          border: solid 1px #c2c7d0;
          box-sizing: border-box;
          float: right;
          .export-icon {
            color: #9aa1b1;
            margin-right: 4px;
          }
        }
        .el-input {
          width: 200px;

          .el-input__inner {
            border: solid 1px #e2e2e4;
            box-sizing: border-box;
            border-radius: 3px;
          }
        }
        .el-checkbox {
          margin-left: 20px;
          .el-checkbox__label {
            font-size: 14px;
            color: #354052;
            font-weight: 400;
          }
        }
        .selectStyle {
          width: 180px;
          .el-input.el-input--suffix {
            width: 100%;
          }
        }
      }

      .market-header {
        border-bottom: solid 1px #ecedf1;
        margin-bottom: 20px;
        ul {
          display: flex;
        }
        .classify-area {
          height: 40px;
          margin-top: 10px;
          font-size: 16px;
          font-weight: 500;
          color: #354052;

          padding: 0 10px;
          cursor: pointer;
          text-align: center;
          line-height: 32px;
          font-weight: normal;
          position: relative;
          user-select: none;
          &.active {
            font-weight: 500;
          }
          .item-bar {
            position: absolute;
            width: calc(100% - 20px);
            height: 3px;
            background-color: #e81b40;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }
      .content-header {
        width: 120px;
        height: 40px;
        margin-top: 10px;
        font-size: 20px;
        font-weight: 500;
        color: #354052;
      }
      .el-table {
        min-height: 570px;
      }
      .no-data-box {
        min-height: 520px;
      }
      .el-table__row {
        height: 50px !important;
        .cell {
          color: #354052;
          font-size: 14px;
          align-items: center;
        }
      }
      .coin-ing {
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
      .operation {
        font-size: 14px;
        color: #3a7dff;
        font-weight: 400;
        cursor: pointer;
        padding: 0px 0px 0px 20px;
      }
      .operation-ban {
        font-size: 14px;
        color: #9aa1b1;
        font-weight: 400;
        padding: 0px 0px 0px 20px;
      }
    }
  }
}
</style>
