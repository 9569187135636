<template>
  <div>
    <SpreadCenterInner v-if="defaultPage"></SpreadCenterInner>
    <SpreadCenterInnerBv v-if="is8V"></SpreadCenterInnerBv>
    <!-- <IndexInner v-if="is8V && darkMode == 1"></IndexInner> -->
  </div>
</template>
<script>
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
import { translateTitle } from "@/assets/js/i18n";
import SpreadCenterInner from "@/pages/ucenter/pages/spread-center/spread-center-inner.vue";
import SpreadCenterInnerBv from "@/pages/ucenter/pages/spread-center/spread-center-inner-bv.vue";
export default {
  mixins: [],

  data() {
    return {
      is8V: false,
      defaultPage: false,
    };
  },
  components: { SpreadCenterInner, SpreadCenterInnerBv },
  computed: {},
  created() {
    if (VUE_UNION_STATION == "8V") {
      this.is8V = true;
    } else {
      this.defaultPage = true;
    }
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
