<template>
  <div class="bitda-home-page">
    <div
      :class="userInfoShow ? 'not-resiger' : 'is-resiger'"
      class="home-content-one"
    >
      <video muted="true" loop="" autoplay="autoplay" class="bgvideo">
        <source type="video/mp4" src="../../assets/img/bitdabg.mp4" />
      </video>

      <!-- <video class="video" loop muted autoplay>
        <source
          src="../../assets/img/home/bitda/bg-video.mp4"
          type="video/mp4"
        />
      </video> -->
      <div class="one-item-content">
        <div class="one-content">
          <div class="one-item">
            <p class="first-lable">
              <span v-if="language == 'zh_CN'"> 币达 </span>
              <span v-else-if="language == 'zh_HK'"> 幣達 </span>
              <span v-else> bitda </span>
              {{ translateTitle(projectTitle) }}
            </p>

            <p class="second-lable">
              {{ translateTitle("即刻交易比特币、以太坊等上百种数字资产") }}
            </p>
            <div v-show="!userInfoShow" class="regsiter-content">
              <input
                class="regsiter-input"
                v-model="resiterValue"
                type="text"
                :placeholder="translateTitle(`输入用户名/邮箱/手机号`)"
              />
              <span @click="onResiter(1)" class="goRegsiter">{{
                translateTitle("注册")
              }}</span>
            </div>
            <!-- <div v-show="userInfoShow" class="regsiter-content">
            <span @click="onTrade" class="goRegsiter theme-btn">{{
              translateTitle("立即交易")
            }}</span>
          </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="home-content-two" :class="imgList.length == 0 ? '' : ''">
      <div class="swiper-sider-content" v-if="showBanner">
        <div class="swiper-content">
          <div v-if="imgList.length > 4" class="swiper-prev" slot="button-prev">
            <img src="../../assets/img/home/bitda/left-arrow.png" alt="" />
          </div>
          <div class="old-img" v-if="imgList.length <= 4">
            <ul v-for="(item, index) in oldImgList" :key="index">
              <li>
                <span @click="jumpToUrl(item.language['url_' + language])">
                  <img :src="item.language['img_url_' + language]" alt="" />
                </span>
              </li>
            </ul>
          </div>

          <swiper
            v-else
            :options="swiperOption"
            ref="mySwiper"
            class="swiper-container"
          >
            <swiper-slide
              class="swiper-item"
              v-for="(item, index) of imgList"
              :key="index"
            >
              <span>
                <img
                  @click="jumpToUrl(item.language['url_' + language])"
                  class="swiper-img"
                  :src="item.language['img_url_' + language]"
                  alt=""
                />
              </span>
            </swiper-slide>
          </swiper>

          <div v-if="imgList.length > 4" class="swiper-next" slot="button-next">
            <img src="../../assets/img/home/bitda/right-arrow.png" alt="" />
          </div>
        </div>
      </div>
      <div
        v-if="imgList.length >= 4"
        class="swiper-pagination"
        slot="pagination"
      ></div>
      <!-- <div class="tips-massage-content">
        <div class="left">
          <i class="iconfont iconxiaoxi"></i>
          <a :href="firstArticles.html_url" target="_blank">
            <span class="article-title">
              {{ firstArticles.title || "--" }}</span
            >
          </a>
        </div>
        <div class="left-driver"></div>
        <div class="bottom">
          <a :href="secondArticles.html_url" target="_blank">
            <span class="article-title">
              {{ secondArticles.title || "--" }}</span
            >
          </a>
        </div>
        <div class="right-driver"></div>
        <div @click="onClickMore" class="right">
          <span>{{ translateTitle("更多") }}</span>
          <i class="iconfont icongengduo"></i>
        </div>
      </div> -->
      <div class="table-header">
        <div class="left">
          <span class="title-pointer"></span>
          <span class="title-text">{{
            translateTitle("开启您的数字货币之旅")
          }}</span>
        </div>
        <div class="right">
          <a :href="routeSplicing(language, 'markets')">
            <span @click="onLookMarket"
              >{{ translateTitle("查看更多") }} ></span
            >
          </a>
        </div>
      </div>
      <div class="quotes-content">
        <el-table
          v-loading="tableLoading"
          :data="tableDataComputed"
          row-key="symbol_id"
          style="width: 100%"
          class="trade-table"
          @row-click="onClickRow"
        >
          <el-table-column
            :label="translateTitle('交易对')"
            align="left"
            min-width="140"
            prop="from_symbol"
          >
            <template slot-scope="{ row }" style="ext-align: left">
              <span
                class="coin-content"
                @click="onSymbolClick(row)"
                style="cursor: pointer"
              >
                <img class="coin-img" :src="row.coin_url" alt="" />
                <span
                  class="left-symbol"
                  style="margin-left: 10px; font-size: 14px"
                  >{{ row.from_symbol }}</span
                >
                <span class="dark-color"> / {{ row.to_symbol }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('最新价')"
            align="left"
            prop="usd"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro left-row"> {{ row.last }} </span>
                <span class="dark-color DINPro"
                  >/ {{ systemCurrencyMaps[systemCurrency]
                  }}{{ row.currency[systemCurrency] }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('24H涨跌幅')"
            align="center"
            prop="info.price_change_percent"
            min-width="120"
          >
            <template slot-scope="{ row }">
              <span
                class="left-row DINPro"
                :class="Number(row.price_change_percent) > 0 ? 'green' : 'red'"
                ><span
                  class="green"
                  v-show="Number(row.price_change_percent) > 0"
                  style="margin-right: -3px"
                >
                  + </span
                >{{ row.price_change_percent }} %</span
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('行情')"
            align="center"
            prop="info.high"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <!-- <span class="DINPro">{{ row.info.high }}</span> -->
              <img
                class="mrket-img"
                v-if="Number(row.price_change_percent) > 0"
                src="../../assets/img/markets/markt-up.png"
              />
              <img
                class="mrket-img"
                v-else
                src="../../assets/img/markets/markt-download.png"
              />
            </template>
          </el-table-column>

          <el-table-column
            :label="translateTitle('操作')"
            align="center"
            min-width="70"
          >
            <template slot-scope="{ row }">
              <el-button class="operateV2" @click="onSymbolClick(row)">{{
                translateTitle("交易")
              }}</el-button>
            </template>
          </el-table-column>
          <no-data
            slot="empty"
            v-show="!tableLoading && tableDataComputed.length"
          ></no-data>
        </el-table>
        <div class="more-info">
          <a :href="routeSplicing(language, 'markets')">
            <div @click="onLookMarket" class="more">
              <span class="more-title">{{
                translateTitle("查看更多行情")
              }}</span>
              <i class="iconfont icongengduo"></i>
            </div>
          </a>
        </div>
      </div>
      <!-- 成交量介绍 -->
      <h1 class="volume-title">
        <span class="title-pointer"> </span>
        <span class="title-name" v-if="language == 'zh_CN'"> 币达 </span>
        <span class="title-name" v-else-if="language == 'zh_HK'"> 幣達 </span>
        <span v-else class="title-name">
          {{ projectEnglishName }}
        </span>
        {{ translateTitle("近24小时成交量") }}
        <strong class="theme-red">1,368,254,278</strong>
        {{ translateTitle("美元") }}
      </h1>
      <span class="line"></span>
      <p class="volume-sub-title">
        <span v-if="language == 'zh_CN'"> 币达 </span>
        <span v-else-if="language == 'zh_HK'"> 幣達 </span>
        <span v-else>
          {{ projectEnglishName }}
        </span>
        {{
          translateTitle("提供数百种币币和合约交易对，以满足多样化的交易需求。")
        }}
      </p>
      <p class="volume-sub-title">
        {{
          translateTitle(
            "平台在200个国家和地区中有超过2千万用户，是全球领先的数字资产服务平台。"
          )
        }}
      </p>
      <div class="point">
        <div class="point-item v1"></div>
        <div class="point-item v2"></div>
        <div class="point-item v3"></div>
        <div class="point-item v4"></div>
      </div>
    </div>

    <div class="home-content-four">
      <p class="content-title">
        <!-- {{ translateTitle("随时随地，多平台终端交易") }} -->
      </p>
      <div class="four-content">
        <div class="right-content">
          <!-- <img src="../../assets/img/home/bitda/home-app-image-en.png" alt="" /> -->
        </div>
        <div class="left-content">
          <p class="left-content-title-V2">
            {{ translateTitle("随时随地，多平台终端交易") }}
          </p>
          <p class="left-content-title">
            {{ translateTitle("多终端完美兼容，随时满足各种场景的交易需求。") }}
          </p>
          <ul class="tips-details">
            <li class="">
              <i class="iconfont iconsize16-3"></i>
              <span>{{ translateTitle("随时查看实时行情") }}</span>
            </li>
            <li>
              <i class="iconfont iconsize16-3"></i>
              <span>{{
                translateTitle("轻松买卖BTC，ETH，DOGE和其他数字资产")
              }}</span>
            </li>
            <li>
              <i class="iconfont iconsize16-3"></i>
              <span>{{
                translateTitle("获取您关注的加密货币价格变化预警")
              }}</span>
            </li>
            <li>
              <i class="iconfont iconsize16-3"></i>
              <span>
                {{ translateTitle("查看比特币现货、期货、期权价格") }}</span
              >
            </li>
            <li>
              <i class="iconfont iconsize16-3"></i>
              <span>{{ translateTitle("比较各交易所加密货币价格") }}</span>
            </li>
          </ul>
          <div class="download-content">
            <div class="download-item">
              <a :href="android_url" target="_blank" rel="noopener noreferrer">
                <img src="../../assets/img/home/google-down.png" alt="" />
              </a>
            </div>
            <div class="download-item">
              <a :href="appstore_url" target="_blank" rel="noopener noreferrer">
                <img src="../../assets/img/home/app-down.png" alt="" />
              </a>
            </div>
            <div
              class="download-item qr-code"
              @mouseenter="onShowDownLoad"
              @mouseleave="onHiddenDownLoad"
            >
              <img src="../../assets/img/home/down-btn-bitda.png" alt="" />
            </div>
          </div>
          <div v-show="showDownLoadImg" class="download-code">
            <img class="qr_code_canvas" :src="appDownLoadImg" alt="" />
            <!-- <qrcode-vue
              class="qr_code_canvas"
              v-show="appDownLoadImg"
              :value="appDownLoadImg"
              :size="100"
              level="H"
            ></qrcode-vue> -->
            <div class="scren-code">{{ translateTitle("扫码下载") }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-content-five">
      <p class="content-title">{{ translateTitle("开启您的数字货币之旅") }}</p>
      <div class="five-content">
        <div class="details">
          <div class="five-item">
            <img src="../../assets/img/home/bitda/journey-01.png" alt="" />

            <p class="p-content">{{ translateTitle("快捷购买数字货币") }}</p>
            <p class="s-content">
              {{ translateTitle("使用多种支付方式交易数字货币，安全快捷") }}
            </p>
          </div>
          <div class="five-item" style="margin: 0 25px">
            <img
              class="v2"
              src="../../assets/img/home/bitda/journey-02.png"
              alt=""
            />

            <p class="p-content">
              {{ translateTitle("最优的交易费率") }}
            </p>
            <p class="s-content">
              {{
                translateTitle(
                  "优惠的交易费率，富有竞争力的VIP权益，享受最优品质的服务"
                )
              }}
            </p>
          </div>
          <div class="five-item">
            <img
              class="v3"
              src="../../assets/img/home/bitda/journey-03.png"
              alt=""
            />

            <p class="p-content">
              {{ translateTitle("可信赖的安全保障") }}
            </p>
            <p class="s-content">
              {{
                translateTitle(
                  "我们复杂的安全措施和SAFU基金保护您的数字资产免受所有风险。"
                )
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="home-content-six">
      <img
        class="reg-img"
        src="../../assets/img/home/bitda/fast-register-bg.png"
        alt=""
      />
      <div class="six-content">
        <div class="six-content-title">
          <img src="../../assets/img/home/bitda/10-s.png" alt="" />
          <div class="text-content">
            <span class="text-v1">
              {{ translateTitle("快速创建您的账号") }}
            </span>
            <span class="text-v2">
              {{ translateTitle("开启") }}
            </span>
            <span class="text-v3">{{ translateTitle("20") }}</span>
            <span class="text-v2">
              {{ translateTitle("倍收益杠杆交易") }},
            </span>
            <span class="text-v3">{{ translateTitle("100") }}</span>
            <span class="text-v2"> {{ translateTitle("倍收益合约交易") }}</span>
          </div>
          <span
            v-show="!userInfoShow"
            @click="onResiter(2)"
            class="goRegsiter theme-btn"
            >{{ translateTitle("注册") }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import Socket from "@/assets/js/socket.js";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { apiGetStaticTableInfo } from "@/model/settings";
import NoData from "@/components/common/NoData";
import Cookies from "js-cookie";
import { apiGetBannerList, apiGetMainV2 } from "@/model/home/index";
import { mapActions, mapGetters } from "vuex";
import { apiWsUrl } from "@/model/exchange/index";
import { systemCurrencyMaps } from "@/assets/js/keymap.js";
import QrcodeVue from "qrcode.vue";
import axios from "axios";
import routeSplicing from "@/assets/js/routeSplicing";
var vm = null;
import {
  VUE_APP_ID,
  VUE_UNION_STATION,
  VUE_APP_APP,
} from "@/assets/js/stationSetting";
export default {
  mixins: [commonMixin],
  components: {
    swiper,
    swiperSlide,
    // QrcodeVue,
    NoData,
  },

  data() {
    return {
      tt: {}, //WS延时触发器
      lockReconnect: false, //避免重复连接
      wsContentTime: 0,
      detailsUrl: "",
      systemCurrencyMaps,
      token: "",
      wsUrl: "",
      appDownLoadImg: "",
      activeName: "1",
      // realIndex:0,
      isHuiXin: false,
      is8V: false,
      isUeasy: false,
      isS6: false,
      sideLen: 4,
      isResiter: false,
      resiterVal: "",
      resiterValue: "",
      isMobile: false,
      isEmail: false,
      showDownLoadImg: false,
      showBanner: false,
      android_url: "",
      appstore_url: "",
      doanGoogleUrl: "https://www.topcreditbank.com/app_download/",
      doanIosUrl: "https://apps.apple.com/app/id1525081932",
      tipUrl: "",

      firstArticles: {},
      secondArticles: {},
      swiperOption: {
        initialSlide: 0,

        // 箭头配置
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
        on: {
          click: function () {
            // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
            // console.log(this); // -> Swiper
            // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
            const realIndex = this.realIndex;
            vm.handleClickSlide(realIndex);
          },
        },

        // slidesPerView: 1,
        spaceBetween: 24,
        slidesPerView: 4,
        loopedSlides: 8,
        slidesPerGroup: 1,

        // slidesPerView: 'auto',
        // // spaceBetween: 10,
        // // slidesPerView: "auto",
        // loopedSlides: 1,
        // slidesPerGroup: 'auto',
        loopFillGroupWithBlank: true,
        infinite: 1, // 无限滚动前后遍历数
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },

        // autoplay: {
        //   disableOnInteraction: false,
        //   delay: 4000,
        // },
        autoplay: false,
        followFinger: false,
        autoplayDisableOnInteraction: false,
        // //循环
        loop: false,
        // // //每张播放时长3秒，自动播放
        // autoplay: 2000,
        // // //滑动速度
        // speed: 1000,
      },
      oldImgList: [
        {
          language: {
            img_url_ar: require("../../assets/img/home/bitda/banner-1.png"),
            img_url_en: require("../../assets/img/home/bitda/banner-1.png"),
            img_url_ja: require("../../assets/img/home/bitda/banner-1.png"),
            img_url_ko: require("../../assets/img/home/bitda/banner-1.png"),
            img_url_zh_CN: require("../../assets/img/home/bitda/banner-1.png"),
            img_url_zh_HK: require("../../assets/img/home/bitda/banner-1.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/bitda/banner-2.png"),
            img_url_en: require("../../assets/img/home/bitda/banner-2.png"),
            img_url_ja: require("../../assets/img/home/bitda/banner-2.png"),
            img_url_ko: require("../../assets/img/home/bitda/banner-2.png"),
            img_url_zh_CN: require("../../assets/img/home/bitda/banner-2.png"),
            img_url_zh_HK: require("../../assets/img/home/bitda/banner-2.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/bitda/banner-3.png"),
            img_url_en: require("../../assets/img/home/bitda/banner-3.png"),
            img_url_ja: require("../../assets/img/home/bitda/banner-3.png"),
            img_url_ko: require("../../assets/img/home/bitda/banner-3.png"),
            img_url_zh_CN: require("../../assets/img/home/bitda/banner-3.png"),
            img_url_zh_HK: require("../../assets/img/home/bitda/banner-3.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/bitda/banner-4.png"),
            img_url_en: require("../../assets/img/home/bitda/banner-4.png"),
            img_url_ja: require("../../assets/img/home/bitda/banner-4.png"),
            img_url_ko: require("../../assets/img/home/bitda/banner-4.png"),
            img_url_zh_CN: require("../../assets/img/home/bitda/banner-4.png"),
            img_url_zh_HK: require("../../assets/img/home/bitda/banner-4.png"),
          },
          param: { url: "" },
        },
      ],
      imgList: [],
      articlesList: [],
      tableDataComputed: [],
      exchangeCoinMap: {},
      coinInfoList: {},
      tableLoading: true,
      tableData: [],
      activeSymbol: "1",
      collectSymbol: [22],
      ws: null,
      logoImg: "",
      timer: null,
    };
  },
  async created() {
    this.logoImg = require("../../assets/img/logo_bitda.png");

    await this.getBannerData();

    await this.getExchangeCoinMap();

    let qrcode_download = JSON.parse(sessionStorage.getItem("qrcode_download"));
    let about = JSON.parse(sessionStorage.getItem("about"));
    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    this.appDownLoadImg = qrcode_download.url;

    if (zendesk_notice.language) {
      this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`];
      this.detailsUrl =
        zendesk_notice.language[`articles_url_${this.language}`];
    } else {
      this.tipUrl = zendesk_notice.notice_url;
      this.detailsUrl = zendesk_notice.articles_url;
    }

    await this.getStaticTableInfo(1);
    this.getDeatilsData();
    let tempCookies = Cookies.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      await this.initURL();
      this.tableLoading = true;
      this.initWS();
    } else {
      this.token = Cookies.get("topcredit_exchange_t");
      await this.initURL();
      this.tableLoading = true;
      this.initWS();
    }
    vm = this;
    this.voucherInterval = setInterval(() => {
      this.getBannerData();
      this.getDeatilsData();
    }, 5000 * 60 * 6);
  },
  mounted() {
    if (this.language == "zh_CN") {
      let meta = document.getElementsByTagName("meta");
      meta["title"].setAttribute(
        "content",
        "Bitda Cryptocurrency Exchange 币达加密货币交易所"
      );
      meta["description"].setAttribute(
        "content",
        "一款区块链数字资产交易平台。主要是为用户提供比特币，莱特币等虚拟货币行情资讯和相关交易服务，使用非常的方便，安全，操作也十分的简单，帮助用户节省了很多不必要的流程。"
      );
    }
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
  },

  methods: {
    routeSplicing,
    jumpToUrl(url) {
      if (url != "" && url) {
        window.open(url);
      }
    },
    getMainV2() {
      let params = {
        channel: 0,
      };
      apiGetMainV2(params).then((res) => {
        alert(res.status);
        if (res.status == 200) {
        }
      });
    },
    async getExchangeCoinMap() {
      const { status, data } = await apiGetStaticTableInfo(55);
      if (status == 200) {
        let tempObj = {};
        for (const key in data) {
          if (data[key].symbol_type == 1 && data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        this.exchangeCoinMap = JSON.parse(JSON.stringify(tempObj));
      }
    },

    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
        this.android_url = data.android_url;
        this.appstore_url = data.super_sign_url;
      }
    },
    handleClick() {},
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     sessionStorage.setItem("itemList", JSON.stringify(data));
    //   }
    // },
    onResiter(type) {
      var name = "";
      var inputVal = "";
      if (type == 1) {
        inputVal = this.resiterValue;
      } else {
        inputVal = this.resiterVal;
      }
      var emailReg = new RegExp(
        "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
      ); //正则表达式
      var numReg = /^\d{9,}$/;
      if (inputVal) {
        if (emailReg.test(inputVal)) {
          name = "email";
        } else if (numReg.test(inputVal)) {
          name = "phone";
        } else {
          name = "account";
        }
      } else {
        name = "";
        inputVal = "";
      }
      this.$router.push({
        name: "Register",
        params: {
          name: name,
          val: inputVal,
        },
      });
    },
    handleClickSlide(index) {
      console.log("當前點擊索引：", index);
    },
    onClickMore() {
      window.open(this.tipUrl);
    },
    onLookMarket() {
      {
        /* this.$router.push(this.routeSplicing(this.language, "markets")); */
      }
    },
    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },

    async getBannerData() {
      const req = {
        type: 1,
        title: "main",
      };
      const { status, data } = await apiGetBannerList(req);
      if (status == 200) {
        let len = data.length;
        if (len <= 4) {
          data.forEach((ele, index) => {
            this.oldImgList[index] = ele;
          });
        } else {
          this.imgList = data;
        }
        this.showBanner = true;
        this.sideLen = len >= 4 ? 4 : len;
      }
    },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.coinInfoList = JSON.parse(JSON.stringify(data));
      }
    },

    async getDeatilsData() {
      var that = this;
      axios
        .get(this.detailsUrl)
        .then(function (response) {
          that.articlesList = response.data.articles;
          if (response.data.articles.length >= 1) {
            that.firstArticles = response.data.articles[0];
          } else {
            that.firstArticles.html_url = "";
            that.firstArticles.title = "";
          }
          if (response.data.articles.length >= 2) {
            that.secondArticles = response.data.articles[1];
          } else {
            that.secondArticles.html_url = "";
            that.secondArticles.title = "";
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    initWS() {
      let wsUrl = this.wsUrl;
      let me = this;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "marketM",
            app_id: VUE_APP_ID,
          },
        },
        callback: this.onResponse,
        onConnect,
      };
      window.USDT_WS = this.ws = new Socket(option).getInstance();
      me.global.setWs(this.ws);
      function onConnect() {
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWS();
          me.lockReconnect = false;
        }, 3000);
      }
      //   setTimeout(() => {}, 1200);
      // this.getSubData();
    },
    onResponse(data) {
      this.tableLoading = false;
      if (data.action == "Pushdata.marketM") {
        this.tableData = data.data;

        if (this.tableData == null || this.tableData.length == 0) {
          return;
        }
        this.tableData.forEach((ele) => {
          for (const key in this.exchangeCoinMap) {
            if (key == ele.symbol_id) {
              ele["from_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[0]
                .toUpperCase();
              ele["to_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[1]
                .toUpperCase();
              ele["info"] = this.exchangeCoinMap[key];
              ele["web_recommend"] = this.exchangeCoinMap[key].is_recommend;
            }
          }
          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (
                this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
              ) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
        });
        this.tableDataComputed = this.tableData.filter((ele) => {
          return ele.from_symbol != undefined && ele.web_recommend == 1;
        });
      }
    },
    onTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
    },
    onSymbolClick(row) {
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      );
      window.open(tempUrl);
    },
    onClickRow(row, column, event) {
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      );
      window.open(tempUrl);
    },
  },
};
</script>
<style lang="scss">
.zh_HK {
  .bitda-home-page .home-content-four .right-content {
    background: url("../../assets/img/home/bitda/home-app-image-hk.png") 0 0
      no-repeat;
    background-size: 100% 100%;
  }
}
.ar {
  .bitda-home-page .el-table tbody .cell .coin-content {
    float: right;
    margin-right: 80px;
    .coin-img {
      margin-left: 10px;
    }
  }

  .bitda-home-page .home-content-four .left-content .tips-details li i {
    margin-left: 6px;
    margin-right: 0;
  }
  .bitda-home-page .home-content-four .left-content .download-content {
    direction: ltr;
  }
  .bitda-home-page .home-content-four .left-content .download-code {
    height: 160px;
    .qr_code_canvas {
      margin-right: 0;
    }
  }
}
.zh_CN {
  .bitda-home-page .home-content-four .right-content {
    background: url("../../assets/img/home/bitda/home-app-image-cn.png") 0 0
      no-repeat;
    background-size: 100% 100%;
  }
  .bitda-home-page .home-content-two .point-item.v1 {
    background: url("../../assets/img/home/bitda/feature-01-zh.png") 0 0
      no-repeat;
    background-size: 100% 100%;
  }
  .bitda-home-page .home-content-two .point-item.v2 {
    background: url("../../assets/img/home/bitda/feature-02-zh.png") 0 0
      no-repeat;
    background-size: 100% 100%;
  }
  .bitda-home-page .home-content-two .point-item.v3 {
    background: url("../../assets/img/home/bitda/feature-03-zh.png") 0 0
      no-repeat;
    background-size: 100% 100%;
  }
  .bitda-home-page .home-content-two .point-item.v4 {
    background: url("../../assets/img/home/bitda/feature-04-zh.png") 0 0
      no-repeat;
    background-size: 100% 100%;
  }
}
.bitda-home-page {
  margin-top: -2px;
  // height: 2977px;
  display: block;
  [v-cloak] {
    display: none;
  }
  .fiv-svg {
    position: relative;
    i {
      z-index: 900;
      position: absolute;
      font-size: 60px;
      top: -2px;
      left: 110px;
    }
  }

  // font-family: PingFangSC, BinancePlex, Arial, sans-serif !important;
  .is-resiger {
    height: 340px;
  }
  .no-resiger {
    height: 340px;
  }
  user-select: none;
  padding: 0;
  // min-height: 2000px;
  flex: 1;
  .swiper-img {
    width: 100%;
    height: 144px;
    border-radius: 6px;
  }

  .home-content-one {
    // background: url("../../assets/img/home/bitda/banner.png") 0 0 no-repeat;
    // background-color: #f7f8fb;
    position: relative;
    min-height: 553px;
    background-size: 100% 100%;
    // video {
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    // }
    .bgvideo {
      width: 100%;
      top: 0;
      height: 576px;
      // height: 100%;
      position: fixed;
      object-fit: cover;
    }
    .one-item-content {
      position: absolute;
      top: 1px;
      width: 100%;
      .one-item {
        width: 1200px;
        margin: 0 auto;
        z-index: 10;
        .regsiter-content {
          justify-content: flex-start;
        }
      }
    }
    .first-lable {
      color: white;
      font-size: 50px;
      font-weight: bolder;
      padding-top: 80px;
    }
    .second-lable {
      color: #f2c9db;
      font-size: 23px;
      padding-top: 15px;
    }
  }
  .home-content-two {
    min-height: 788px;
    position: relative;
    z-index: 2;
    background: url("../../assets/img/home/bitda/home-bg-01.png") 0 0 no-repeat;
    background-size: 100% 100%;
    margin-top: -100px;
    padding-bottom: 30px;
    &.V2 {
      min-height: 588px;
    }
    .swiper-sider-content {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      position: static;
      .swiper-next {
        cursor: pointer;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        z-index: 99;
        width: 30px;
        height: 144px;
        top: 0px;
        outline: none;
        position: absolute;
        // background-color: rgb(0, 0, 0, 0.4);
        right: -37px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        img {
          height: 17px;
          width: 10px;
        }
      }
      .swiper-prev {
        cursor: pointer;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        z-index: 99;
        width: 30px;
        height: 144px;
        top: 0px;
        outline: none;
        position: absolute;
        // background-color: rgb(0, 0, 0, 0.4);
        left: -37px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        img {
          height: 17px;
          width: 10px;
        }
      }
      .swiper-content {
        display: flex;
        position: relative;
        align-items: center;
        width: 1200px;
        .button-prev {
          margin-right: 13px;
        }
        i {
          &:focus {
            border: 0px;
            outline: 0px;
          }
        }
        .iconfont {
          font-size: 28px;
          color: #747a7e;
          outline: none;
          cursor: pointer;
          border: 0px;
        }
        .swiper-container {
          width: 1200px;
          height: 144px;
          position: relative;
          border-radius: 6px;
          .swiper-wrapper {
            text-align: center !important;
          }

          img {
            // width: 282px;
            height: 144px;
            // opacity: 0.7;
            box-sizing: border-box;
            opacity: 1;
            &:hover {
              opacity: 1;
            }
          }
          .swiper-slide {
            margin-left: 0px !important;
          }
        }
      }
      .old-img {
        display: flex;
        width: 1200px;
        ul {
          // width: 1110px;
          &:last-child {
            li img {
              margin-right: 0;
            }
          }
          li {
            cursor: pointer;
            // width: 282px;
            // padding-right: 20px;
          }
        }

        img {
          width: 282px;
          height: 144px;
          margin-right: 24px;
          border-radius: 10px;
        }
      }
    }
    .swiper-pagination {
      position: relative !important;
      margin-top: 15px;
      span {
        margin: 0px 10px;
        // color: #9aa1b1;
      }
    }

    .tips-massage-content {
      padding: 20px 0px;
      font-size: 14px;
      color: #080c34;
      display: flex;
      align-items: center;
      justify-content: center;
      .left {
        // min-width: 265px;
        // width: auto;
        i {
          font-size: 16px;
          color: #080c34;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      .left-driver {
        width: 1px;
        height: 18px;
        margin: 2px 15px 0;
        background-color: #c2c7d0;
      }

      .bottom {
        // min-width: 200px;
        // margin-left: 15px;
        text-align: left;
      }
      .article-title {
        color: #080c34 !important;
        min-width: 250px;
        display: inline-block;
        vertical-align: middle;
        max-width: 550px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .right-driver {
        width: 1px;
        height: 18px;
        margin: 2px 20px 0 15px;
        background-color: #c2c7d0;
      }
      .right {
        cursor: pointer;
        i {
          font-size: 14px;
          color: #9aa1b1;
          margin-top: 2px;
          &:hover {
            color: #3a7dff;
          }
        }
        &:hover {
          color: #3a7dff;
          i {
            color: #3a7dff;
          }
        }
      }
    }
    .table-header {
      width: 1200px;
      margin: 50px auto 27px;
      display: flex;
      justify-content: space-between;
      .left {
        .title-pointer {
          display: inline-block;
          vertical-align: middle;
          width: 34.2px;
          height: 34.2px;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          background-image: linear-gradient(111deg, #eb0885 4%, #7b2bc0 103%);
        }
        .title-text {
          margin-left: 10px;
          display: inline-block;
          vertical-align: middle;
          font-size: 26px;
          font-weight: normal;
          color: #262b36;
        }
      }
      .right {
        span {
          cursor: pointer;
          width: 173px;
          height: 43px;
          margin: 51px 14.5px 27px 31px;
          padding: 12px 50px 10px 52px;
          border-radius: 21.5px;
          color: #fff;
          font-size: 15px;
          background-image: linear-gradient(111deg, #eb0885 4%, #7b2bc0 103%);
        }
      }
    }
    .quotes-content {
      width: 1200px;
      // height: 572px;
      margin: 0 auto;
      padding: 20px 80px 0px;
      border-radius: 6px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
      background-color: #ffffff;
      .left-symbol {
        color: #354052;
        font-size: 14px;
        font-weight: 500;
      }
      .left-row {
        color: #354052;
        font-size: 14px;
        font-weight: 600;
      }

      .el-table {
        min-height: 310px !important;
        tr {
          cursor: pointer;
        }
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #f1f5f6;
      }
      .more-info {
        display: flex;
        align-items: center;
        height: 72px;
        justify-content: center;

        text-align: center;
        font-size: 16px;
        color: #7f8490;
        .more-title {
          width: 102px;
        }
        .more {
          cursor: pointer;
          &:hover {
            color: #3a7dff;
            span {
              color: #3a7dff;
            }
          }
        }

        span {
          color: #9aa1b1;
        }
        .icongengduo {
          font-size: 14px;
          // margin-top: 4px;
        }
      }

      .el-table {
        min-height: 350px;
        .el-icon-star-off {
          cursor: pointer;
        }
      }
      .el-table::before {
        height: 0;
      }
    }
  }
  .home-content-two {
    .line {
      display: block;
      width: 459px;
      height: 1px;
      margin: 25px auto;
      background-color: #fff;
    }
    .volume-title {
      font-size: 38px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      line-height: 45px;
      padding: 75px 0 12px;
      .theme-red {
        // color: #c61b3d;

        background-image: -webkit-linear-gradient(bottom, #7b2bc0, #eb0885);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .title-pointer {
        display: inline-block;
        width: 34.2px;
        height: 34.2px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background-image: linear-gradient(111deg, #eb0885 4%, #7b2bc0 103%);
      }
      .title-name {
        font-size: 63px;
      }
    }

    .volume-sub-title {
      width: 1000px;
      margin: 0 auto;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      color: #fff;

      line-height: 30px;
    }

    .point {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 45px auto 80px;

      &-item {
        display: flex;
        flex-direction: column;
        margin-right: 25px;
        width: 275px;
        height: 177px;
        text-align: center;
        padding-top: 33px;
        font-style: oblique;
        &.v1 {
          background: url("../../assets/img/home/bitda/feature-01.png") 0 0
            no-repeat;
          background-size: 100% 100%;
        }
        &.v2 {
          background: url("../../assets/img/home/bitda/feature-02.png") 0 0
            no-repeat;
          background-size: 100% 100%;
        }
        &.v3 {
          background: url("../../assets/img/home/bitda/feature-03.png") 0 0
            no-repeat;
          background-size: 100% 100%;
        }
        &.v4 {
          background: url("../../assets/img/home/bitda/feature-04.png") 0 0
            no-repeat;
          background-size: 100% 100%;
        }

        p {
          color: #ffffff;
          font-size: 48px;
          font-weight: 600;
          height: 50px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 77px;
          // margin-top: 15px;
        }
      }

      &-item:last-child {
        margin-right: 0;
      }
    }
  }
  .home-content-four {
    height: 880px;
    text-align: center;
    position: relative;
    z-index: 2;
    background: url("../../assets/img/home/bitda/home-bg-02.png") 0 0 no-repeat;
    background-size: 100% 100%;
    .content-title {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      padding: 60px 0 60px;
      color: #354052;
    }
    .left-content {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      position: relative;
      width: 600px;
      // min-width: 420px;
      // max-width: 500px;
      margin-left: 80px;
      .tips-details {
        li {
          display: flex;
          i {
            margin-top: 6px;
            margin-right: 10px;
            color: #8808c5;
          }
          span {
            font-size: 20px;
          }
        }
      }

      .left-content-title-V2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 25px;
        color: #702070;
      }
      .left-content-title {
        font-size: 22px;
        color: #262b36;
        margin-bottom: 50px;
      }
      li {
        margin-bottom: 14px;
        color: #354052;
      }
      img {
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
      .download-content {
        margin-top: 70px;
        display: flex;
        position: relative;
        .download-item {
          img {
            width: 217px;
            height: 78px;
          }
        }
        .qr-code {
          img {
            cursor: pointer;
            border-radius: 3px;
            text-align: center;
            width: 86px;
            height: 78px;
            color: #ffffff;
          }
        }
      }
      .download-code {
        // float: right;
        // margin-right: 60px;
        position: absolute;
        right: 0px;
        box-sizing: border-box;
        width: 120px;
        height: 145px;
        border-color: #eaecef;
        margin-top: 10px;
        background: #ffffff;
        padding: 10px;
        border-radius: 4px;
        z-index: 900;

        img {
          width: 100px;
          height: 100px;
        }
        .scren-code {
          width: 100%;
          margin-top: 5px;
          text-align: center;
          box-sizing: border-box;
          color: #1e2329;
          font-size: 14px;
        }
      }
    }
    .right-content {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 718px;
      height: 550px;
      background: url("../../assets/img/home/bitda/home-app-image-en.png") 0 0
        no-repeat;
      background-size: 100% 100%;
      .home-bg-logo {
        position: absolute;
        top: 21px;
        left: 6px;
        img {
          height: 16px;
        }
      }
    }
  }
  .home-content-five {
    height: 940px;
    position: relative;
    z-index: 2;
    background: url("../../assets/img/home/bitda/home-bg-03.png") 0 0 no-repeat;
    background-size: 100% 100%;
    .content-title {
      padding-top: 127px;
      padding-bottom: 120px;
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      color: #702070;
    }
    .five-content {
      display: flex;
      justify-content: center;
      .details {
        display: flex;
        width: 1200px;
        justify-content: space-between;
      }
      .five-item {
        text-align: center;
        // width: 417px;
        width: 383px;
        padding: 166px 56px 30px 57px;
        border-radius: 10px;
        box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.08);
        background-color: #fff;
        position: relative;
        img {
          position: absolute;
          top: -33px;
          left: 80px;
          width: 224px;
          height: 156px;
          &.v2 {
            left: 120px;
            width: 149px;
            height: 166px;
          }
          &.v3 {
            left: 120px;
            width: 136px;
            height: 182px;
          }
        }
        .p-content {
          font-size: 29px;
          font-weight: 500;
          text-align: center;
          color: #1f2533;
          margin-top: 15px;
          margin-bottom: 12px;
        }
        .s-content {
          width: 285px;
          text-align: center;
          color: #354052;
          font-size: 16px;
        }
      }
    }
  }
  .home-content-six {
    height: 240px;

    text-align: center;
    background: #170633;
    position: relative;
    z-index: 2;
    .reg-img {
      left: calc(50% - 600px);
      width: 1279px;
      height: 436px;
      position: absolute;
      top: -200px;
    }
    .six-content {
      height: 150px;
      width: 1200px;
      padding: 64px 0 87px;
      position: absolute;
      bottom: 3px;
      left: calc(50% - 600px);
      // background: rgba(0, 0, 0, 0.3);
    }
    //  background: url("../../assets/svg/bg.svg") 0 0 repeat;
    .six-content-title {
      height: 150px;
      margin-top: -30px;
      line-height: 1;
      text-align: center;
      color: #ffffff;
      font-size: 22px;
      margin-bottom: 30px;
      img {
        display: inline-block;
        width: 118px;
        height: 68px;
        vertical-align: middle;
        margin-right: 25px;
      }
      .text-content {
        display: inline-block;
        vertical-align: middle;
        margin: 0 50px 0 10px;
        max-width: 600px;
        text-align: left;
        .text-v1 {
          display: block;
          text-align: left;
          margin-bottom: 10px;
        }
        .text-v3 {
          font-size: 34px;
        }
      }
      .text {
        font-size: 26px;
        color: #fff;
        font-weight: normal;
      }
      .goRegsiter {
        width: 202.3px;
        height: 59.1px;
        padding: 12.5px 73.3px 10.6px 73px;
        border-radius: 13px;
        opacity: 1;
        background-image: linear-gradient(115deg, #eb0885 3%, #7b2bc0 104%);
      }
    }
  }
  .regsiter-content {
    margin: 42px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      display: inline-block;
      width: 370px;
      height: 46px;
      font-size: 16px;
      line-height: 46px;
      font-weight: 500;
      padding: 12px 0px 12px 17px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      background-color: #ffffff;
      &::placeholder {
        color: #dddee1;
      }
    }
    .goRegsiter {
      border-radius: 2px;
      cursor: pointer;
      vertical-align: middle;
      background: #c61b3d;
      text-align: center;
      font-size: 16px;
      display: inline-block;
      width: 130px;
      line-height: 46px;
      height: 46px;
      color: white;
      box-sizing: border-box;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .trade-btn {
      border-radius: 2px;
      cursor: pointer;
      vertical-align: middle;
      background: #c61b3d;
      text-align: center;
      font-size: 16px;
      display: inline-block;
      width: 130px;
      line-height: 46px;
      height: 46px;
      color: white;
      box-sizing: border-box;
    }
  }

  .el-table {
    padding: 0px 0px 0 10px;
    .el-table__header {
      thead {
        .cell {
          font-size: 14px;
          color: #262b36;
          font-weight: bolder;
        }
      }
    }
    tbody {
      .operateV2 {
        width: 95px;
        height: 28px;
        padding: 4px 0px;
        text-align: center;
        border-radius: 14px !important;
        border: none;
        background-image: linear-gradient(115deg, #eb0885 3%, #7b2bc0 104%);
        font-weight: 400 !important;
        font-size: 14px;
        box-sizing: border-box;
        span {
          font-weight: 400 !important;
          color: #ffffff !important;
        }
      }
      .mrket-img {
        height: 30px;
      }

      .cell {
        .coin-content {
          display: inline-block;
          .coin-img {
            width: 30px;
            height: 30px;
            vertical-align: middle;
          }
        }
        .red {
          color: #c61b3d;
        }
        .green {
          color: #03ad79;
        }
        .active {
          color: #c61b3d;
          font-size: 18px;
          margin-top: 4px;
          margin-left: -3px;
        }
        .el-icon-star-on,
        .el-icon-star-off {
          text-align: left;
          cursor: pointer;
          margin-right: 10px;
        }
        p,
        span,
        code {
          color: #1f2533;
          // font-weight: 600;
        }
        .el-table__expand-icon {
          display: none;
        }
        .dark-color {
          color: #9aa1b1;
          font-size: 12px;
        }
      }
    }

    .table-expand {
      min-height: 228px;
      padding: 10px;
      display: flex;
      cursor: auto;
      .expand-detail {
        flex: 1;
        display: inline-block;
        margin-right: 32px;
      }
      .el-form {
        display: inline-block;
        width: 300px;
        .el-form-item {
          margin-bottom: 0px;
          padding: 0 20px;
          .el-form-item__label {
            padding: 0;
          }
          &:nth-child(2n + 1) {
            background-color: #f5f5f7;
          }
        }
        .el-form-item__content {
          display: inline-block;
          float: right;
        }
      }
    }
  }
  .el-tabs__item {
    font-size: 16px;
    margin-bottom: 10px;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #bec1c8 !important;
    font-weight: 500;
  }
}
</style>
