<template>
  <div class="menu-outer">
    <SideMenuInner v-if="defaultPage"></SideMenuInner>
    <SideMenuInnerBv v-if="is8V"></SideMenuInnerBv>
    <!-- <IndexInner v-if="is8V && darkMode == 1"></IndexInner> -->
  </div>
</template>
<script>
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
import { translateTitle } from "@/assets/js/i18n";
import SideMenuInner from "@/pages/ucenter/components/SideMenu-inner.vue";
import SideMenuInnerBv from "@/pages/ucenter/components/SideMenu-inner-bv.vue";
export default {
  mixins: [],

  data() {
    return {
      is8V: false,
      defaultPage: false
    };
  },
  components: { SideMenuInner, SideMenuInnerBv },
  computed: {},
  created() {
    if (VUE_UNION_STATION == "8V") {
      this.is8V = true;
    } else {
      this.defaultPage = true;
    }
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle
  }
};
</script>
<style lang="scss">
.menu-outer {
  padding: 0 0;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%);
  z-index: 999;
  height: auto;
  float: left;
}
</style>
