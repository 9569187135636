<template>
  <div class="coin-manager">
    <div class="trade-container">
      <div class="header">
        <div class="header-top">
          <!-- <top-title :title="translateTitle('资产管理')">
          </top-title> -->
          <div class="content-title">
            <h2>{{ translateTitle("资产管理") }}</h2>
            <div class="assets-details">
              <div class="details" v-if="advanced == 1">
                <span class="btc-num"
                  >{{ btc_num || "--" }}
                  {{ systemCurrency.toUpperCase() }}</span
                >
                <span class="usd-num">≈ {{ usd_num || "--" }} USDT</span>
              </div>
              <div class="details" v-else>
                <span class="btc-num"
                  >**** {{ systemCurrency.toUpperCase() }}</span
                >
                <span class="usd-num">≈ **** USDT</span>
              </div>
              <div v-if="!is8V">
                <img
                  class="close eye"
                  v-if="advanced == 2"
                  @click="handlePassword(1)"
                  src="../../../../assets/img/login/eye-off.png"
                />
                <img
                  v-else
                  class="open eye"
                  @click="handlePassword(2)"
                  src="../../../../assets/img/login/eye-open.png"
                />
              </div>
              <div v-if="is8V">
                <img
                  class="eye_new"
                  v-if="advanced == 2"
                  @click="handlePassword(1)"
                  src="../../../../assets/img/login/close_eye.png"
                />
                <img
                  v-else
                  class="eye_new"
                  @click="handlePassword(2)"
                  src="../../../../assets/img/login/open_eye.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="app-uncter-content">
          <!-- 列表 -->
          <div class="filter-box">
            <div class="left-filter">
              <div class="nav">
                <div class="list-type-tab">
                  <div
                    v-for="item in listType"
                    :key="item.val"
                    class="tab"
                    :class="{ active: item.val == active }"
                    @click="onTypeChange(item.val)"
                  >
                    <span>{{ translateTitle(item.text) }}</span>
                  </div>
                </div>
              </div>
              <el-checkbox v-model="isChecked" @change="onFilter">{{
                translateTitle("隐藏小额资产")
              }}</el-checkbox>
            </div>
            <div class="right-filter">
              <el-input
                clearable
                :placeholder="translateTitle('搜索币种')"
                v-model="item_name"
                maxlength="10"
                @input="onFilter"
              ></el-input>
            </div>
          </div>
          <el-table :data="dataList" class="g-table" v-loading="tableLoading">
            <el-table-column
              class="coin-cell"
              sortable
              :sort-method="
                (a, b) => {
                  return a.item_name.localeCompare(b.item_name);
                }
              "
              min-width="120px"
              align="left"
              prop="itemDetail.logic_name"
              :label="translateTitle('币种')"
            >
              <template slot-scope="{ row }">
                <div
                  class=""
                  style="display:flex;align-items:center;padding-left:20px;"
                >
                  <img class="coin-ing" :src="row.coin_url" />
                  <div>
                    <div class="text">{{ row.item_name }}</div>
                    <!-- <div class="display-name">{{ row.display_name }}</div> -->
                  </div>
                </div>
              </template>
            </el-table-column>

            <!-- <el-table-column
              sortable
              :sort-method="
                (a, b) => {
                  return a.all - b.all;
                }
              "
              prop="orderBy"
              min-width="100px"
              align="right"
              :label="translateTitle('全部')"
            >
              <template slot-scope="{ row }">
                <span class="DINPro">{{ row.orderBy }}</span>
              </template>
            </el-table-column> -->

            <el-table-column
              sortable
              :sort-method="
                (a, b) => {
                  return a.all - b.all;
                }
              "
              prop="coin_num"
              min-width="100px"
              align="right"
              :label="translateTitle('全部')"
            >
              <template slot-scope="{ row }">
                <span class="DINPro">{{
                  advanced != 1 ? "****" : row.all
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              sortable
              :sort-method="
                (a, b) => {
                  return a.over - b.over;
                }
              "
              prop="over"
              min-width="130px"
              :label="translateTitle('可用')"
              align="right"
            >
              <template slot-scope="{ row }">
                <span class="DINPro">{{
                  advanced != 1 ? "****" : row.over
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              sortable
              :sort-method="
                (a, b) => {
                  return a.lock - b.lock;
                }
              "
              prop="lock"
              min-width="130px"
              :label="translateTitle('冻结')"
              align="right"
            >
              <template slot-scope="{ row }">
                <span class="DINPro">{{
                  advanced != 1 ? "****" : row.lock
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              sortable
              :sort-method="
                (a, b) => {
                  return (
                    a.valuation[systemCurrency] - b.valuation[systemCurrency]
                  );
                }
              "
              prop="valuation"
              class="valuation"
              min-width="170px"
              :label="translateTitle('估值')"
              align="right"
            >
              <template slot-scope="{ row }">
                <div class="DINPro cny">
                  {{ advanced != 1 ? "****" : row.valuation[systemCurrency] }}
                  {{ systemCurrency.toUpperCase() }}
                </div>
                <div class="DINPro usd">
                  ≈ {{ advanced != 1 ? "****" : row.valuation["btc"] }} BTC
                </div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="220px"
              :label="translateTitle('操作')"
              align="right"
            >
              <template slot-scope="{ row }">
                <span v-if="active == 1">
                  <span
                    v-if="row.in_status == 1"
                    class="operation"
                    @click="rechargeClick(row)"
                    >{{ translateTitle("充值") }}</span
                  >
                  <span v-else class="operation-ban">{{
                    translateTitle("充值")
                  }}</span>
                  <span
                    v-if="!canWithdraw"
                    @click="onWithdrawClick"
                    class="operation-ban"
                    >{{ translateTitle("提现") }}</span
                  >
                  <span
                    v-else-if="row.out_status == 1"
                    class="operation"
                    @click="withdrawalClick(row)"
                    >{{ translateTitle("提现") }}</span
                  >
                  <span v-else class="operation-ban">{{
                    translateTitle("提现")
                  }}</span>
                </span>
                <span v-else>
                  <span
                    class="operation-ban"
                    style="cursor: pointer;"
                    @click="onClickCurrency(1)"
                    >{{ translateTitle("充值") }}</span
                  >
                  <span
                    class="operation-ban"
                    style="cursor: pointer;"
                    @click="onClickCurrency(2)"
                    >{{ translateTitle("提现") }}</span
                  >
                </span>
                <span
                  v-if="row.type == 1 && row.is_trade"
                  @click="onTrade(row)"
                  class="operation"
                  >{{ translateTitle("交易") }}</span
                >
                <span v-else class="operation-ban">{{
                  translateTitle("交易")
                }}</span>
              </template>
            </el-table-column>
            <no-data
              slot="empty"
              v-show="!tableLoading && dataList.length == 0"
            />
          </el-table>
          <!-- <div class="common-table-pages">
            <p>
              {{ translateTitle("每页") }}
              <span>{{ pageInfo.pageSize }}</span>
              {{ translateTitle(`条，总共`) }}
              <span>{{ pageInfo.pageCount }}</span>
              {{ translateTitle("页") }}
            </p>
            <el-pagination
              class="mt20"
              background
              layout="prev, pager, next"
              :total="pageInfo.pageTotal"
              :page-size="pageInfo.pageSize"
              :current-page.sync="pageInfo.pageCurrent"
              @current-change="onPageChange"
            ></el-pagination>
          </div> -->
        </div>
      </div>
    </div>
    <el-dialog
      :title="translateTitle('安全验证')"
      custom-class="confirm-dialog"
      :visible.sync="safetyVerification"
      width="440px"
      :lock-scroll="false"
    >
      <span style="font-size: 16px;">{{
        translateTitle(
          "为保障您的资金安全，请先在个人中心—>安全设置中绑定谷歌、手机或者邮箱"
        )
      }}</span>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="safetyVerification = false">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="onConfirmV2">{{
          translateTitle("确定")
        }}</span>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import NoData from "@/components/common/NoData";
import { apiGetAssets } from "@/model/exchange/index";
import { apiGetStaticTableInfo } from "@/model/settings";
import commonMixin from "@/components/common/commonMixin.vue";
import { showMessage } from "@/components/dialog/message";
import { mapGetters } from "vuex";
import * as Account from "@/model/user/account";
import routeSplicing from "@/assets/js/routeSplicing";
import {
  getStaticCoinList as getCoinList,
  getAssetsList,
  apiGetUserItems,
} from "@/model/assets/index";
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
const refreshTime = 5000;
export default {
  mixins: [commonMixin],
  data() {
    return {
      coinData: {}, // 币种数据列表
      assetsList: {}, // 资产数据列表
      symbolList: {}, // 交易币种表
      active: 1,
      advanced: 1,
      contractMap: {},
      btc_num: "",
      usd_num: "",
      item_name: "",
      assetsShow: "",
      dataList: [],
      isChecked: false,
      oldTableList: [],
      userInfo: {},
      assetsShowCurrency: {},
      digitalCoinList: {
        list: {},
      }, //加密货币
      legalCoinList: { list: {} }, //法币
      listType: [
        { text: "数字货币", val: 1 },
        // { text: "币币详情", val: 2 },
        { text: "法币", val: 2 },
      ],
      tableLoading: true,
      canWithdraw: false,
      safetyVerification: false,
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      },
      voucherInterval: null,
      assetsListTimer: null,
      is8V: false,
      defaultPage: false,
    };
  },
  components: {
    // AssetSnippet,
    // TopTitle,
    NoData,
    // History
  },
  async created() {
    if (VUE_UNION_STATION == "8V") {
      this.is8V = true;
    } else {
      this.defaultPage = true;
    }
    this.getUserInfoInit();
    await this.getAssetsData();
    await this.getItemList();
    await this.getUserItems();
    // await this.getAssetsList();
    await this.getStaticTableInfo(55);
    this.getAllItemData(this.active);
  },
  mounted() {
    this.advanced = localStorage.getItem("advanced")
      ? localStorage.getItem("advanced")
      : 1;
    this.loopRefreshAssets();
  },
  destroyed() {
    clearInterval(this.voucherInterval);
    clearInterval(this.assetsListTimer);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
    clearInterval(this.assetsListTimer);
  },
  computed: {
    ...mapGetters({
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    sortCoinData() {
      return this.sortObj(this.coinData);
    },
  },
  watch: {
    systemCurrency: function(old, newd) {
      this.getAssetsData();
    },
  },
  methods: {
    routeSplicing,
    // 获取用户信息
    async getUserInfoInit() {
      // const { status, data } = await Account.apiGetUserInfo();
      this.userInfo = JSON.parse(localStorage.getItem("userData"));
      const { userInfo, authInfo } = this.userInfo;
      if (authInfo) {
        if (authInfo.is_bind_security == 1) {
          this.canWithdraw = false;
        } else {
          this.canWithdraw = true;
        }
      }
    },
    onWithdrawClick() {
      this.safetyVerification = true;
    },
    onConfirmV2() {
      this.$router.push(
        this.routeSplicing(this.language, "ucenter/safe-setting")
      );
    },
    onClickCurrency(type) {
      if (type == 1) {
        showMessage({
          message: this.translateTitle(`暂时无法充值，请联系客服`),
        });
      } else {
        showMessage({
          message: this.translateTitle(`暂时无法提现，请联系客服`),
        });
      }
    },
    async getItemList() {
      const req = {
        __version: "3.4.3",
        __channel: "2",
      };
      const { status, data } = await getCoinList(req);
      if (status == 200) {
        this.coinData = data;
      }
    },
    async getUserItems() {
      const req = {
        type: "withdraw_settings",
      };
      const { status, data } = await apiGetUserItems(req);
      if (status == 200) {
        let tempList = data.withdraw_settings;
        for (let item in this.coinData) {
          this.coinData[item].in_status = this.coinData[
            item
          ].itemDetail.in_status;
          this.coinData[item].out_status = this.coinData[
            item
          ].itemDetail.out_status;
          tempList.forEach(ele => {
            if (this.coinData[item].item_id == ele.item_id) {
              this.coinData[item].in_status = ele.in_status;
              this.coinData[item].out_status = ele.out_status;
            }
          });
        }
      }
    },
    loopRefreshAssets() {
      this.assetsListTimer = setInterval(
        () => this.getAssetsData(),
        refreshTime
      );
    },
    async getAssetsData() {
      // this.tableLoading = true;
      let params = {
        type: "remain",
      };
      const { status, data } = await apiGetAssets(params);
      if (status == 200) {
        this.assetsList = data.remain;
        this.usd_num = data.remain.all_u_valuation["usdt"];
        this.btc_num = data.remain.all_u_valuation[this.systemCurrency];
        // this.getAllItemData(this.active); //表格数据定时调用
      }
    },
    // async getAssetsData() {
    //   // this.tableLoading = true;
    //   const { status, data } = await getAssetsList();
    //   if (status == 200) {
    //     this.assetsList = data;
    //     this.usd_num = data.usd;
    //     this.btc_num = data.btc;
    //   }
    // },
    handlePassword(type) {
      this.advanced = type;
      localStorage.setItem("advanced", this.advanced);
    },
    rechargeClick(row) {
      if (row.in_status === 0) {
        let coinName = row.item_name.toUpperCase();
        showMessage({
          message: coinName + this.translateTitle(`暂不支持充币`),
        });
      } else {
        // sessionStorage.setItem("currentCoin", JSON.stringify(row));
        localStorage.setItem("currentCoin", JSON.stringify(row));
        this.$router.push({
          path: this.routeSplicing(
            this.language,
            "ucenter/assets/recharge/" + row.item_name.toLowerCase()
          ),
        });
      }
    },
    // 提币
    withdrawalClick(row) {
      if (row.out_status === 0) {
        let coinName = row.item_name.toUpperCase();
        showMessage({
          message: coinName + this.translateTitle(`暂不支持提币`),
        });
      } else {
        // sessionStorage.setItem("currentCoin", JSON.stringify(row));
        localStorage.setItem("currentCoin", JSON.stringify(row));
        this.$router.push({
          path: this.routeSplicing(
            this.language,
            "ucenter/assets/withdrawal/" + row.item_name.toLowerCase()
          ),
        });
      }
    },

    onTrade(row) {
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${row.item_name.toLowerCase()}_${"usdt"}`
      );
      window.open(tempUrl);
    },

    // onChangeAssets() {
    //   if (this.isChecked) {
    //     this.dataList = [];
    //     this.oldTableList.forEach((ele) => {
    //       if (+ele.cny > 0) {
    //         this.dataList.push(ele);
    //       }
    //     });
    //   } else {
    //     this.dataList = this.oldTableList;
    //   }
    // },

    // onChangeItem(val) {
    //   if (val) {
    //     this.dataList = [];
    //     this.oldTableList.forEach((ele) => {
    //       let item_name = ele.item_name.toUpperCase();
    //       if (item_name.indexOf(val.toUpperCase()) != -1) {
    //         this.dataList.push(ele);
    //       }
    //     });
    //   } else {
    //     this.dataList = this.oldTableList;
    //   }
    // },
    // 根据筛选条件返回结果
    onFilter() {
      if (this.item_name && this.isChecked) {
        this.dataList = [];
        this.oldTableList.forEach(ele => {
          let item_name = ele.item_name.toUpperCase();
          if (
            +ele.all > 0 &&
            item_name.indexOf(this.item_name.toUpperCase()) != -1
          ) {
            this.dataList.push(ele);
          }
        });
      } else if (this.isChecked && !this.item_name) {
        this.dataList = [];
        this.oldTableList.forEach(ele => {
          if (+ele.all > 0) {
            this.dataList.push(ele);
          }
        });
      } else if (!this.isChecked && this.item_name) {
        this.dataList = [];
        this.oldTableList.forEach(ele => {
          let item_name = ele.item_name.toUpperCase();
          if (item_name.indexOf(this.item_name.toUpperCase()) != -1) {
            this.dataList.push(ele);
          }
        });
      } else {
        this.dataList = this.oldTableList;
      }
    },
    /**
      资产管理表数据
       @param null
       @returns list
     */
    getAllItemData(val) {
      // this.tableLoading = true;
      let sortDataMap = {};
      let tableList = [];
      let tableMap = {};
      let tempAssets = {};
      tempAssets = this.assetsList.list;
      sortDataMap = this.sortObj(this.coinData);

      for (let ele in tempAssets) {
        for (let item in sortDataMap) {
          if (
            sortDataMap[item].item_id == tempAssets[ele].item_id &&
            sortDataMap[item].type == val
          ) {
            tableMap = {
              item_id: sortDataMap[item].item_id,
              item_name: sortDataMap[item].item_name.toUpperCase(),
              coin_url: sortDataMap[item].itemDetail.coin_url,
              in_status: sortDataMap[item].in_status,
              out_status: sortDataMap[item].out_status,
              coin_num: tempAssets[ele].coin_num,
              lock: tempAssets[ele].lock,
              over: tempAssets[ele].over,
              cny: tempAssets[ele].cny,
              usd: tempAssets[ele].usd,
              type: sortDataMap[item].type,
              is_trade: sortDataMap[item].is_trade,
              display_name: sortDataMap[item].itemDetail.language.display_en,
              all: tempAssets[ele].all,
              valuation: tempAssets[ele].valuation,
              orderBy: tempAssets[ele].order_by,
            };
            tableList.push(tableMap);
          }
        }
      }
      let tempTableList = tableList.sort((a, b) => {
        let aValue = parseFloat(a.valuation[this.systemCurrency]);
        let bValue = parseFloat(b.valuation[this.systemCurrency]);
        if (aValue == bValue) {
          return parseInt(a.orderBy) > parseInt(b.orderBy) ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });

      // localStorage.setItem("webItemList", JSON.stringify(tableList));
      this.oldTableList = tempTableList;
      this.dataList = tempTableList;
      this.onFilter();
      this.tableLoading = false;

      // this.pageInfo.pageTotal = tableList.length;
      // const pagelength = tableList.length;
      // this.pageInfo.pageCount = Math.ceil(pagelength / this.pageInfo.pageSize);

      // this.dataList = tableList.slice(
      //   (this.pageInfo.pageCurrent - 1) * this.pageInfo.pageSize,
      //   this.pageInfo.pageCurrent * this.pageInfo.pageSize
      // );

      // this.oldTableList = this.dataList;
    },

    // 获取资产列表
    getAssetsList() {
      let params = {
        type: "all",
      };
      apiGetAssets(params).then(({ status, data }) => {
        if (status === 200) {
          this.assetsShow = data.assets.u_valuation;
          this.assetsShowCurrency = data.assets.all_u_valuation;
        }
      });
    },
    // getStaticTableList() {
    //   getStaticTableList().then((res) => {
    //     if (res.status == 200) {
    //     }
    //   });
    // },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.symbolList = JSON.parse(JSON.stringify(data));
        for (let item in this.coinData) {
          this.coinData[item].is_trade = false;
          for (let ele in this.symbolList) {
            if (this.coinData[item].item_id == this.symbolList[ele].coin_from) {
              this.coinData[item].is_trade = true;
            }
          }
        }
      }
    },
    sortObj(obj) {
      if (obj[10007]) {
        obj[10007].order_by = -1;
      }
      const keys = Object.keys(obj).sort(function(a, b) {
        return obj[a].order_by - obj[b].order_by;
      });
      var newObj = [];
      for (var i = 0; i < keys.length; i++) {
        const key = keys[i];
        newObj.push(obj[key]);
      }
      return newObj;
    },
    onTypeChange(val) {
      this.active = val;
      this.getAllItemData(this.active);
      this.onFilter(this.item_name);
      // this.onChangeAssets();
      // this.onChangeItem(this.item_name);
    },
    onPageChange() {
      this.getAllItemData();
    },
  },
};
</script>
<style lang="scss">
.coin-manager {
  .cny {
    color: #354052;
    font-size: 14px;
    font-weight: 500;
  }
  .usd {
    color: #9aa1b1;
    font-size: 12px;
    font-weight: 500;
  }
  .content-title {
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    user-select: none;
    // border-radius: 4px;
    h2 {
      font-size: 26px;
      font-weight: 600;
      color: #354052;
      text-align: left;
      height: 80px;
      line-height: 80px;
      padding-left: 24px;
      // padding: 21px 0 21px 24px;
    }
  }
  .trade-container {
    .header {
      border-radius: 4px;
      .nav {
        display: flex;
        flex: 1;
        justify-content: space-between;
        font-size: 14px;
        align-items: center;
        .list-type-tab {
          .tab {
            user-select: none;
            min-width: 80px;
            height: 40px;
            line-height: 40px;
            margin-right: 0px;
            display: inline-block;
            padding: 0px 5px;
            background: #f1f2f5;
            border-radius: 3px;
            // border: solid 1px #e2e2e4;
            cursor: pointer;
            text-align: center;
            box-sizing: border-box;
            box span {
              color: #354052;
              font-size: 14px;
              font-weight: 500;
            }
            &:hover {
              color: #354052;
              &:after {
                content: "";
                height: 2px;
                width: 80%;
                background-color: #354052;
              }
            }
            &.active {
              background-color: #d60c30;
              color: white;
              border: 0;
              span {
                color: #fff;
              }
            }
          }
        }
      }
      .eye {
        cursor: pointer;
        height: 12px;
        margin-left: 10px;
      }
      .eye_new {
        cursor: pointer;
        height: 18px;
        margin-left: 10px;
        margin-top: 6px;
      }
      .close {
        height: 16px;
      }
      .header-top {
        // position: relative;
        .assets-details {
          // position: absolute;
          display: flex;
          align-items: center;
          margin-left: 15px;
          .details {
            display: flex;
            align-items: center;
          }
          .btc-num {
            color: #354052;
            font-weight: 500;
            font-size: 20px;
          }
          .usd-num {
            color: #354052;
            font-size: 14px;
            font-weight: 500;
            margin-left: 10px;
          }
        }
      }

      .assets {
        margin-top: 16px;
        background-color: #fff;
        min-height: 854px;
      }
    }

    .app-uncter-content {
      min-height: 800px;
      padding: 0px 20px;
      background: #ffffff;
      margin: 20px;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
      .el-table {
        min-height: 757px;
        th:first-child > .cell {
          padding-right: 30px;
          padding-left: 30px;
        }
        th > .cell {
          padding-right: 0px;
          padding-left: 0px;
        }
        th:nth-last-child(2) > .cell {
          padding-right: 80px;
          padding-left: 80px;
        }
      }
      .no-data-box {
        min-height: 600px;
      }
      .el-table__row {
        height: 50px !important;
        .cell {
          color: #354052;
          font-size: 14px;
          // display: flex;
          align-items: center;
        }
      }
      .coin-ing {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        vertical-align: middle;
        user-select: none;
      }
      .text {
        vertical-align: middle;
        color: #354052;
        font-size: 14px;
        font-weight: 500;
      }
      .display-name {
        color: #9aa1b1;
        font-size: 12px;
        font-weight: 500;
      }
      .filter-box {
        display: flex;
        justify-content: space-between;
        .left-filter {
          display: flex;
          align-items: center;
        }
      }
      .filter-box {
        padding: 20px 0px 15px 0px;
        .el-input {
          width: 200px;

          .el-input__inner {
            border: solid 1px #e2e2e4;
            box-sizing: border-box;
            border-radius: 3px;
          }
        }
        .el-checkbox {
          margin-left: 20px;
          .el-checkbox__label {
            font-size: 14px;
            color: #354052;
            font-weight: 400;
          }
        }
      }
      .operation {
        user-select: none;
        font-size: 14px;
        color: #3a7dff;
        font-weight: 400;
        cursor: pointer;
        padding: 0px 20px 0px 0px;
      }
      .operation-ban {
        user-select: none;
        font-size: 14px;
        color: #9aa1b1;
        font-weight: 400;
        padding: 0px 20px 0px 0px;
      }
    }
  }
  .confirm-dialog {
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      text-align: left;
      color: #354052;
      padding: 20px 30px 30px;
      font-size: 16px;
      font-weight: normal;
      line-height: 2;
    }
  }
}
</style>
