<template>
  <div class="service-agreement">
    <div v-if="language == 'zh_CN'">
      <p class="header">
        <strong>服务协议</strong>
      </p>
      <p>
        8V
        该公司及其关联公司（以下合称为“公司”或8V”）联合运营网站：http://8v.com及相关移动应用（以下简称“平台”或“本平台”），并基于该平台为用户提供数字货币之间的交易及相关服务。就本协议而言，关联公司系指直接或间接通过一个或多个中间方控制8V
        GLOBAL
        LIMITED、受其控制或与其处于共同控制之下的另一实体。若您注册成为本平台用户即表示您同意接受本协议并受平台发布的所有协议和网站公布的所有政策的约束（以下简称“协议”）。若您不接受以下协议，请立即停止注册，停止访问网站，及/或停止使用本平台的服务、产品或内容。
      </p>
      <p>
        <strong>第一章 协议签署与修订</strong>
      </p>
      <p>
        1.1您声明并确保您已年满18岁，具有法律规定的完全民事行为能力，且没有被剥夺过使用我们的服务的权利。若您不符合上述条件，请不要注册我们的网站或平台，否则公司有权随时暂停或终止您的帐户。
      </p>
      <p>
        1.2您订立并履行本协议不受您所属、所居住、开展经营活动或其他业务、或对您有税务管辖权的国家或地区法律法规的禁止。若您不具备前述条件，您应立即终止注册或停止使用本平台服务。
      </p>
      <p>
        1.3您按照平台页面上的提示完成全部的注册程序或后续每一次使用平台的相关服务，即表示您完全理解并接受本协议项下的全部内容（包括后续不时作出并在平台上发布的修订版协议内容）。本协议内容包括本协议项下全部协议及本平台已经发布的或将来可能发布的各类规则。所有已发布或将来可能发布的各类规则均明确纳入并构成本协议不可分割的一部分，与协议正文具有同等法律效力。
      </p>
      <p>
        1.4本平台有权根据需要不时地修改本协议或根据本协议制定、修改各类具体规则并在本平台相关系统板块发布，无需另行单独通知您。您应不时地注意本协议及具体规则的变更，若您在本协议及具体规则内容公告变更后继续使用本服务，则视为您已充分阅读、理解并接受修改后的协议和具体规则内容，也将遵循修改后的协议和具体规则使用本平台的服务。
      </p>
      <p>
        1.5接受本协议或根据本协议及平台有关规则及指示操作您的账户（无论是亲自操作或通过他人操作）访问本平台的相关服务，即视为本协议在您和8V之间产生法律效力。本协议不涉及您与本平台的其他用户之间因网络服务或交易而产生的法律关系或法律纠纷（如有）。
      </p>
      <p>
        1.6您同意8V可以通过发出通知自行决定立即终止您对本平台和帐户的访问，包括但不限于以下权利：限制、暂停或终止服务和用户帐户，禁止访问平台及其内容、服务和工具，延迟或删除内容，采取技术和法律措施使侵权或者违反本协议和平台政策的用户脱离平台，以及任何进一步措施以弥补任何造成的损失或损害，一旦我们自行判定您可能违反任何法律、法规、第三方的权利、或任何本协议或平台政策内容。8V对因行使本条款下之权利而给您或任何第三方造成之任何损失或损害概不负责。
      </p>
      <p>
        <strong>第二章 服务内容及您的权利和义务</strong>
      </p>
      <p>
        2.1
        8V为您提供数字资产交易服务。本平台并不作为买家或卖家与用户直接交易，且不提供任何国家法定货币充入和提取的相关服务。
      </p>
      <p>
        2.1.1您有权在平台浏览数字货币实时行情及交易信息、有权通过平台提交数字货币交易指令并完成数字货币交易。
      </p>
      <p>
        2.1.2您有权在平台查看您账号下的信息，有权应用平台提供的功能进行操作。
      </p>
      <p>
        2.1.3您有权按照平台发布的活动规则参与平台组织的网站活动，以及平台承诺为您提供的其他服务。
      </p>
      <p>
        2.2您理解并同意，平台可自行根据实际情况随时调整平台上的服务内容、服务种类及服务形式。对于因平台调整给您或任何第三方造成的负面影响或损失，本平台概不负责。
      </p>
      <p>
        2.3根据您所在的国家/地区，您可能无法使用本网站或平台的所有功能。我们有权暂停为特定国家的用户提供某些服务，但确保在访问本网站、平台和使用我们的服务的过程中遵守法律和法规（包括您所居住的及/或您访问本网站时所在的国家/地区的法律和法规）是您的责任。
      </p>
      <p>2.4为了访问和使用我们的服务，您必须使用8V创建一个帐户。您同意：</p>
      <p>（1）创建帐户时提供准确，最新和完整的信息；</p>
      <p>（2）维护并及时更新您的帐户信息，以使其准确，完整和最新；</p>
      <p>
        （3）维护您的登录凭据的安全性和机密性，并限制对您的帐户和计算机的访问；
      </p>
      <p>（4）如果您发现或怀疑与平台有关的任何安全漏洞，应立即通知8V；</p>
      <p>（5）对您帐户下发生的所有活动负责，并承担未经授权访问的所有风险；</p>
      <p>
        2.5为向您提供服务时，平台将可能合理使用您的用户个人信息、非个人信息及第三方提供的信息（以下合称“用户信息”）。您一旦注册、登录、使用本平台服务，将视为您完全了解、同意并接受本公司通过包括但不限于收集、统计、分析、等方式合理使用用户信息。
      </p>
      <p>
        2.6您确认，您在本平台上按本平台服务流程所确认的交易状态将成为本平台为您进行相关交易或操作的明确指令。您同意本平台有权按相关指令依据本协议和/或有关文件和规则对相关事项进行处理。
      </p>
      <p>
        2.7因您未能及时对交易状态进行修改或确认或未能提交相关申请所引起的任何纠纷或损失由您本人负责，本平台不承担任何责任。
      </p>
      <p>
        <strong>第三章 业务风险提示与确认</strong>
      </p>
      <p>3.1借币以及杠杆交易风险提示与确认</p>
      <p>3.1.1 8V推出借币服务，为您提供做多/做空杠杆交易服务。</p>
      <p>3.1.2您的最高借币数量由最高借币倍数决定。</p>
      <p>
        3.1.3
        8V为借币您的杠杆交易提供风险管理服务，对您在8V的杠杆账户进行监控和风险管理。
      </p>
      <p>
        3.1.4当您使用杠杆交易时，即默认您无条件授权8V在您的借币到期或杠杆账户发生风险时采取代为减仓，乃至完全平仓等风险控制的操作，而无需对您承担任何责任。
      </p>
      <p>
        3.1.5当您使用杠杆交易时，应当遵守国家相关法律，保证交易资产的来源合法合规。您不得利用杠杆交易从事任何违反您所属、所居住、开展经营活动或其他业务、或对您有税务管辖权的国家或地区的法律法规的行为。有关违法行为包括但不限于洗钱。
      </p>
      <p>
        3.1.6当您使用杠杆交易时，应当充分认识到数字资产投资的风险以及杠杆交易的风险，谨慎操作，量力而行。
      </p>
      <p>
        3.1.7您同意在8V所进行的所有投资操作代表您的真实投资意愿，并无条件接受投资决策所带来的潜在风险和收益。
      </p>
      <p>
        3.1.8您理解在借币及杠杆交易时可能产生手续费等相关费用，并同意按照平台不时公布的要求缴纳相应费用。
      </p>
      <p>
        3.1.9您同意8V在杠杆账户发生风险时保留自行对账户实施减仓，平仓，自动还款等风险控制操作的权利与权限且无需对您或任何第三方承担任何责任，并无条件接受最终的成交结果。
      </p>
      <p>
        3.1.10
        8V保留暂停，终止借币、杠杆交易业务的权限，在必要的时候，平台可以随时暂停，终止借币、杠杆交易业务。
      </p>
      <p>3.2永续合约风险提示与确认</p>
      <p>
        3.2.1数字资产自身存在风险。数字资产价格易产生波动，无涨跌停限制，并在全球范围内一周7天、一天24小时无休止交易，其价格容易受到庄家控制以及全球新闻事件、各国政策、市场需求等多种因素影响，可能出现一天价格涨几倍的情况也可能出现一天内价格跌去一半的情况，由于永续合约的高杠杆性，您可能遭受较大的损失，因而我们强烈建议您在自身所能承受的风险范围内参与交易。
      </p>
      <p>
        3.2.2在意外因素的影响下，如系统故障、网络原因、拒绝服务攻击（DDoS）和其他黑客攻击、异常成交、行情中断、或其他可能的异常情况，我们有权根据实际情况自行取消异常成交结果，以及回滚某一段时间的所有成交，而无需对您或任何第三方承担任何责任。
      </p>
      <p>
        3.2.3我们严厉禁止任何不正当的交易行为。我们有权对所有恶意操纵价格，恶意影响交易系统等不道德行为进行警告，并在有必要的时候，自行采用限制交易、暂停交易、取消交易、逆向取消已达成交易、冻结账户、回滚时段交易等手段以消除不良影响，而无需对您或任何第三方承担任何责任。
      </p>
      <p>
        3.2.4当您的仓位保证金无法满足平台的要求时将被强制平仓，如果价格波动剧烈，系统在使用所有风险控制方法后仍发生亏损，我们有权向您追回损失。
      </p>
      <p>
        3.2.5当您的持仓数量或委托数量过大，我们认为可能对系统和其他用户产生严重风险时，您理解并同意我们有权要求您采用撤单，平仓等风控措施。此外，在我们认为有必要的时候，有权自行对个别账户采用限制总仓位数量、限制总委托数量、限制开仓、撤单或强行平仓等措施进行风险控制，而无需对您或任何第三方承担任何责任。
      </p>
      <p>
        <strong>第四章 交易管理及费用</strong>
      </p>
      <p>
        4.1本平台将为您的交易提供服务，并在服务过程中根据有关文件、协议和/或本平台页面的相关规则、说明等收取必要的服务或管理费用，详情请参阅本协议、本平台相关页面的有关文件、规则和说明。上述内容据此明确纳入本条款，并可能不时被修订。您同意，本平台有权不时调整前述服务或管理费用的类型或金额等具体事项并根据本协议和相关规则进行公告、修改。如您继续接受本平台服务即视为您同意更新后的有关条款。
      </p>
      <p>
        <strong>第五章 服务变更、中断或终止</strong>
      </p>
      <p>
        5.1除非本平台单方面终止本协议或者您申请终止本协议且经本平台同意，否则本协议始终有效。若您违反了本协议、相关规则、任何法律或法规，或我们合理怀疑你在使用我们的服务的过程中涉及参与非法或不当行为，或在政府部门的要求下，本平台有权自行终止本协议、关闭您的账户或者限制您使用本平台，而无需对您承担任何责任。但本平台的终止行为不能免除您根据本协议或在本平台生成的其他协议项下的还未履行完毕的义务。
      </p>
      <p>
        5.2若您发现有第三人冒用或盗用您的用户账户及密码，或其他任何未经合法授权的情形，应立即以有效方式通知本平台，要求本平台暂停相关服务。因使用您的帐户（无论是否属于授权使用）而产生或与之有关的一切责任、损失、损害、索赔、费用或开支，均由您承担。
      </p>
      <p>
        5.3鉴于网络服务的特殊性，您同意本平台有权随时变更、中断或终止部分或全部的网络服务，而无需另行通知您，也无需对任何您或任何第三方承担任何责任。
      </p>
      <p>
        5.4您理解，本平台需要定期或不定期地对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，如因此类情况而造成网络服务在合理时间内的中断，本平台会尽可能及时发出通知，但无需承担由此而引起的任何损失、损害、赔偿或责任。
      </p>
      <p>
        5.5本平台有权自行判断决定暂停、中断或终止向您提供本协议项下的全部或部分服务，将注册资料移除或删除，或采取任何补救或临时措施（包括但不限于取消、撤销交易和账户冻结），无需作出通知，也无需对您或任何第三方承担任何责任。在不影响前述权利广泛性的前提下，我们可能会在下列情况下行使该权利：
      </p>
      <p>（1）本平台认为您提供的个人资料不具有真实性、有效性或完整性；</p>
      <p>（2）本平台发现或怀疑您存在异常交易、非法交易或不寻常的活动时；</p>
      <p>
        （3）本平台认为您的账户涉嫌洗钱、套现、传销、被冒用或其他本平台认为有风险之情形；
      </p>
      <p>（4）本平台认为您已经违反本协议；</p>
      <p>（5）您在使用收费网络服务时未按规定向本平台支付相应的服务费；</p>
      <p>
        （6）本平台发现您的帐户遭到未经授权的访问，或您的帐户受到政府程序、刑事/监管调查或任何未决诉讼的限制；
      </p>
      <p>
        （7）本平台自行判断的其他需暂停、中断或终止向您提供本协议项下的全部或部分服务并将注册资料移除或删除的其他情形。
      </p>
      <p>
        5.6您同意，对您账户的暂停、中断、终止或我们基于前述条款采取的其他任何措施，将不会解除您的责任，您仍应对您使用本平台服务期间的行为承担可能的违约、损害赔偿或任何其他责任，同时本平台仍可保有您的相关信息。
      </p>
      <p>
        5.7如您注册的免费网络服务的账号在任何连续90日内未实际使用，则本平台有权删除该账号并停止为您提供相关的网络服务。
      </p>
      <p>
        <strong>第六章 使用规则</strong>
      </p>
      <p>6.1【账户资料内容规范】</p>
      <p>
        （1）您在申请使用平台服务时，应按照本平台相关规则要求提供准确的个人资料，如个人资料有任何变动，您应及时更新。
      </p>
      <p>
        （2）您不应将其账号、密码转让或出借予他人使用。如您发现其账号遭他人非法使用，应立即通知平台。因病毒、黑客行为或您的保管疏忽导致账号、密码遭他人非法使用，本平台不承担任何责任。
      </p>
      <p>6.2【服务运营规范】</p>
      <p>
        6.2.1您应当遵守法律法规、规章、规范性文件及政策要求的规定，保证账户中所有数字货币来源的合法性。除非法律允许或本平台书面许可，您使用平台及相关服务过程中不得从事下列行为：
      </p>
      <p>
        （1）利用本平台服务进行任何可能对互联网或移动网络正常运转造成不利影响的行为；
      </p>
      <p>
        （2）利用平台提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽、暴力的或其他任何违反法律法规规定的信息资料、言论；
      </p>
      <p>（3）利用平台服务系统进行任何不利于本平台的行为；</p>
      <p>
        （4）侵害第三方名誉权、肖像权、知识产权、商业秘密等合法权利或侵犯任何人的商业利益；
      </p>
      <p>
        （5）
        诱导其他用户点击链接页面或分享信息。未经平台书面许可利用平台账号和任何功能，以及第三方运营平台进行推广，或发布非经本平台许可的商业广告；
      </p>
      <p>
        （6）制作、发布与以上行为相关的方式、工具，或对此方式、工具进行运营或传播，无论这些行为是否为商业目的；
      </p>
      <p>
        （7）其他违反或可能违反法律法规、侵犯任何第三方权利、干扰平台正常运营的行为。
      </p>
      <p>6.2.2您承诺遵守所有本平台数字货币交易规则，包括但不限于：</p>
      <p>（1）浏览交易信息</p>
      <p>
        您在本平台浏览数字货币交易信息时，应当仔细阅读交易信息中包含的全部内容，包括但不限于数字货币价格、委托量、手续费、买入或卖出方向，您完全接受交易信息中包含的全部内容后方可点击按钮进行交易。
      </p>
      <p>（2）提交委托</p>
      <p>
        您在浏览完交易信息确认无误之后可以提交交易委托。您提交交易委托后，即您授权本平台代理您进行相应的交易撮合，本平台在有满足您委托价格的交易时将会自动完成撮合交易而无需提前通知您。
      </p>
      <p>（3）查看交易明细</p>
      <p>您可以通过账户交易明细查看相应的成交记录，确认自己的详情交易记录。</p>
      <p>（4）撤销/修改委托</p>
      <p>在匹配交易未达成之前，您有权随时撤销或修改委托。</p>
      <p>
        6.3本平台有权对您使用平台服务的情况进行审查和监督（包括但不限于对您存储在平台的内容进行审核)，如您在使用平台服务时违反任何上述规定，本平台有权要求您改正或补救（如有可能），也有权直接采取一切必要的措施（包括但不限于更改或删除您张贴的内容、暂停或终止您使用网络服务的权利），以减轻您的行为造成的影响。
      </p>
      <p>
        6.4如果市场价格在您的订单范围之内，通常在正常操作期间您在8V下的订单将被执行。但是，即使市场价格在您下达或以其他方式打开时的价格在您的范围之内，我们也不保证能够执行您的订单。若您在8V计划内或计划外停机期间在8V下单，一旦我们恢复运营将在商业上合理的基础上进行处理。
        8V保留拒绝或取消停机期间已下订单和/或未下订单的权利。
      </p>
      <p>
        6.5订单可能受制于8V无法控制的延误、困难和/或条件而影响到订单的传输或执行，包括但不限于机械或电子故障或市场拥堵，8V对此不承担任何责任。
      </p>
      <p>
        <strong>第七章 知识产权</strong>
      </p>
      <p>
        7.1
        除另有规定外，本平台上的所有内容均为8V的财产，受版权、专利、商标及其他适用法律保护。
      </p>
      <p>
        7.2
        本平台使用之8V商标、商品名称、服务商标及公司徽标均为8V及其各自所有者的财产。本网站及平台所使用的软件、应用程序、文字、图像、图形、数据、价格、交易、图表、图形、视听资料均属于8V所有。本平台商标和其他内容不得以任何形式或方式被复制、再制、修改、重印、上载、张贴、传送、抓取、收集或散布，无论是通过自动或手动实现。
      </p>
      <p>
        7.3严禁在任何其他网站或网络计算机环境中，为任何其他目的使用我们平台的任何内容；任何此类未经授权的使用都可能违反版权、专利、商标和任何其他适用法律，并可能招致刑事或民事处罚。
      </p>
      <p>
        7.4 8V为本平台的商标，未经平台的书面授权，任何用户或者第三方不得使用。
      </p>
      <p>
        7.5
        8V支持知识产权保护。如果您希望就您持有的有效注册商标或服务标志的侵权行为提出商标主张，或希望就您持有真正版权的材料提出版权主张，请发送电子邮件至support@topcreditbank.com。
      </p>
      <p>
        <strong>第八章 隐私保护</strong>
      </p>
      <p>
        您一旦注册、登录、使用本平台服务，将视为您完全了解、同意并接受本平台隐私协议。
      </p>
      <p>
        <strong>第九章 免责声明</strong>
      </p>
      <p>
        您理解本平台允许您使用我们的服务，向您提供相关服务，使您能够购买、出售或存储数字资产，均不构成也不应被您理解为向您提供投资建议。本平台不提供投资建议、税务建议，法律咨询，或其他专业建议。我们不推荐和/或支持你购买或出售数字资产和/或任何投资。在从事任何交易或投资活动之前，你应咨询合格的专业人士。
      </p>
      <p>
        我们通过8V向您提供的服务是严格按照“按现状”、“如有”及“已有”的基础提供。公司不就8V或其中包含的信息或服务的准确性、完整性、及时性、非侵权性、可销售性、针对特定用途的适用性作出陈述或保证。在任何情况下，本公司概不对您或任何其他人因使用8V及其服务或其中的信息而采取的任何决定或行动造成的损失或损害承担责任。无论该情况是因：信息不准确或不完整，延迟、中断、错误或遗漏而造成的损失；或因疏忽或意外情况造成公司在控制范围之外信息提取、编译、解释、计算、报告、或交付而造成的任何损失或损害；或因本平台所载的数据或向用户提供的产品或服务有所遗漏而导致的任何损失或损害，而不论造成该等成因的情况是否在本公司。
      </p>
      <p>
        本公司概不会对用户或任何其他人士就直接、特殊、间接、相应或附带损害或任何形式的其他任何损害（包括直接或间接利润损失）承担任何责任（不论与侵权或合约有关），即使本公司或任何其他有关人士已获告知其可能性。此责任限制包括但不限于传播可能感染用户设备的任何病毒、机械或电子设备或通讯线路故障、电话或其他互连问题、未经授权进入、盗窃、操作员错误、罢工或其他劳工问题或任何不可抗力。公司无法亦不会保证您能够持续、不间断或安全地进入8V。
      </p>
      <p>
        <strong>第十章 风险提示</strong>
      </p>
      <p>10.1数字货币市场是全新的、未经确认的，而且可能不会增长；</p>
      <p>
        10.2数字货币主要由投机者大量使用，零售和商业市场使用相对较少，数字货币交易存在极高风险，其24小时不间断交易、无涨跌限制，价格容易受政府政策等因素影响而大幅波动；
      </p>
      <p>
        10.3因各国法律、法规和规范性文件的制定或者修改，数字货币交易随时可能被暂停或被禁止；
      </p>
      <p>
        10.4数字货币交易有极高风险，您了解和理解此投资有可能导致部分损失或全部损失，所以您应该以能承受的损失程度来决定投资的金额。您了解和理解数字货币会产生衍生风险，所以如有任何疑问，建议先寻求理财顾问的协助。此外，除了上述提及过的风险以外，还会有未能预测的风险存在。您应慎重考虑并用清晰的判断能力去评估自己的财政状况及上述各项风险而作出任何买卖数字货币的决定，并承担由此产生的全部损失，本平台对此不承担任何责任。
      </p>
      <p>
        <strong>第十一章 违约赔偿</strong>
      </p>
      <p>
        如因您违反有关法律、规则、法规、第三方权利、本协议项下的任何内容或因您使用平台提供的全部或部分服务而引致或造成任何索赔、要求、诉讼、损害、损失、成本或费用（包括但不限于合理的律师费），均由您承担，您同意就此向本平台作出赔偿、为本平台抗辩并维护本平台免受损害。
      </p>
      <p>
        <strong>第十二章 通知送达</strong>
      </p>
      <p>
        12.1
        本协议项下的通知如以公示方式作出，一经在本平台公示即视为已经送达。除此之外，其他向您个人发布的具有专属性的通知将由本平台向您在注册时提供的电子邮箱，或本平台在您的个人账户中为您设置的站内消息系统栏，或您在注册后在本平台绑定的手机发送，一经发送即视为已经送达。请您密切关注您的电子邮箱、站内消息系统栏中的邮件、信息及手机中的短信信息。
      </p>
      <p>
        12.2
        您同意本平台出于向您提供服务之目的，可以向您的电子邮箱、站内消息系统栏和手机发送有关通知或提醒；若您不愿意接收，请在本平台相应系统板块进行设置。但您同时同意并确认，若您设置了不接收有关通知或提醒，则您有可能收不到该等通知信息，您不得以您未收到或未阅读该等通知信息主张相关通知未送达于您。
      </p>
      <p>
        <strong>第十三章 向法定机构和认可金融机构进行披露</strong>
      </p>
      <p>
        13.1在以下情况下，我们可能会与执法机构、数据保护机构、政府官员或其他机构分享您的个人数据：
      </p>
      <p>13.1.1 根据法律规定；</p>
      <p>13.1.2 受传票、法院命令或其他法律程序所迫；</p>
      <p>13.1.3 我们认为披露信息对于防止损害或财务损失是必要的；</p>
      <p>13.1.4 举报涉嫌违法行为需要披露；</p>
      <p>13.1.5 披露对于调查违反本协议的行为是必要的。</p>
      <p>
        <strong>第十四章 适用法律和管辖</strong>
      </p>
      <p>
        本条款受相关法律管辖并按其解释。法院拥有专属管辖权解决任何因本协议所引起的或与之相关的任何争议、纠纷、分歧或索赔，包括协议的存在、效力、解释、履行、违反或终止，或因本协议引起的或与之相关的任何非合同性争议。。
      </p>
      <p>
        <strong>第十五章 其他约定</strong>
      </p>
      <p>
        本平台对本协议在法律许可的范围内拥有最终的解释权。本协议及本平台有关页面的相关名词可互相引用参照，如有不同理解，则以本协议协议为准。此外，若本协议的部分协议被认定为无效或者无法实施时，本协议中的其他协议仍然有效。
      </p>
      <p>
        如果这些条款与您可能与8V达成的任何其他协议之间存在任何冲突，则仅当这些条款被明确标识并声明被其他协议所取代时，该另一协议的条款才有效。
      </p>
      <p>
        任何8V未能执行或延迟执行本条款或行使本协议项下的权利，均不应被视为对我方对权利的放弃。
      </p>
      <p>如本协议内容与英文版本有任何冲突，以英文版本为准。</p>
    </div>
    <div v-else>
      <p class="header">
        <strong>Service Agreement</strong>
      </p>
      <p>
        8V and its affiliates (hereinafter referred to as “8V Website” or “8V")
        operates http://8v.com and mobile applications (hereinafter referred to
        as “platform” or "8V APP") in which provides users with transactions and
        digital currency services. The affiliates refer to those that directly
        or indirectly manage 8V GLOBAL LIMITED through the third parties, or are
        under control. If you register as a user of 8V, you agree to accept this
        agreement and all policies published by the platform and website
        (hereinafter referred to as the "Agreement"). If you do not accept the
        following agreement, please stop to register, stop to access the
        website, and/or stop to use the services, products or content of the
        platform.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Chapter 1 Agreement and Amendment</strong>
      </p>
      <p>
        1.1 You declare that you are at least 18 years old and have full
        capacity for civil conduct as required by law, and have not been
        deprived of the rights to use our services. If you do not meet the above
        conditions, please do not register our website or platform, otherwise 8V
        reserves the right to suspend or terminate your account at any time.
      </p>
      <p>
        1.2 The agreement is not prohibited by the laws and regulations of
        countries or regions that you belong to, or where you live, or business
        activities is conducted, or tax jurisdiction over you. If you do not
        meet the aforementioned conditions, you should terminate the
        registration or stop using the platform services.
      </p>
      <p>
        1.3 As you follow the steps on the platform to complete the registration
        procedures or related services, which means that you fully understand
        and accept the agreement (including amendments made and published on the
        platform). This agreement includes all the rules that have been
        published or may be published in the future by this platform. All the
        rules that have been published or may be published in the future
        constitute an inseparable part of this agreement, and have the same
        legal effect.
      </p>
      <p>
        1.4 The platform reserves the right to modify this agreement from time
        to time as needed, or modify the rules in accordance with this agreement
        and publish them in the platform without notifying you. You should pay
        attention to the changes in this agreement and rules from time to time.
        If you continue to use the services after the announcement of the
        changes, it will be deemed that you have fully read, understood and
        accepted the amendments and the rules of the platform.
      </p>
      <p>
        1.5 Accepting this agreement or operating your account in accordance
        with this agreement and the relevant rules and instructions of the
        platform (whether operated in person or by others) to access the
        relevant services of this platform, it will be deemed that this
        agreement has legal effect between you and 8V. This agreement does not
        involve in legal relationships or legal disputes (if any) between you
        and other users of this platform due to network services or
        transactions.
      </p>
      <p>
        1.6 You agree that 8V can terminate your access to the platform and
        account by issuing a notice, including but not limited to the following
        rights: restrict, suspend or terminate the service and user account,
        prohibit access to the platform and its content, services and tools,
        delay or Delete content, take technical and legal measures to remove
        users from infringing or violating this agreement and platform policies,
        and any further measures to make up for any loss or damage caused, once
        we determine by ourselves that you may violate any laws, regulations,
        and third-party’s rights, or any content of this agreement or platform
        policy. 8V is not responsible for any loss or damage caused to you or
        any third party due to the practice of the rights under this clause.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Chapter 2 Rights and Obligations</strong>
      </p>
      <p>
        2.1 8V provides you with digital asset trading services. This platform
        does not act as a buyer or seller for the trading services, and does not
        provide any foreign currency for deposit and withdrawal related
        services.
      </p>
      <p>
        2.1.1 You have the right to browse the real-time digital currency market
        and transaction information on the platform, and the right to submit
        digital currency transaction request and complete it through the
        platform.
      </p>
      <p>
        2.1.2 You have the right to view the information under your account on
        the platform, and to use the features provided by the platform.
      </p>
      <p>
        2.1.3 You have the right to participate in website activities organized
        by the platform in accordance with the rules published by the platform,
        as well as services that the platform promises to provide with.
      </p>
      <p>
        2.2 You understand and agree that the platform can adjust the service
        content, service types and service forms at any time according to the
        actual situation. This platform is not responsible for any negative
        impact or loss caused by platform adjustments to you or any third party.
      </p>
      <p>
        2.3 Depending on your country/region, you may not be able to use all the
        features of this website or platform. We reserve the right to suspend
        the provision of certain services to users in specific countries, but
        ensure that we comply with laws and regulations (based on where you live
        and/or where you are when you visit this website) regarding the use of
        website/platform services.
      </p>
      <p>
        2.4 In order to access and use our services, you must create an account
        with 8V. You agree:
      </p>
      <p>
        (1) Provide accurate, up-to-date and complete information when creating
        an account;
      </p>
      <p>
        (2) Maintain and update your account information to make it accurate,
        complete and up-to-date;
      </p>
      <p>
        (3) Maintain the security and confidentiality of your login credentials,
        and restrict access to your account and computer;
      </p>
      <p>
        (4) If you find or suspect any security vulnerabilities related to the
        platform, you should notify 8V immediately;
      </p>
      <p>
        (5) Responsible for all activities that occur under your account, and
        bear all risks of unauthorized access;
      </p>
      <p>
        2.5 In order to provide you with services, the platform may use your
        user personal information, non-personal information and information
        provided by third parties (hereinafter referred to as "user
        information"). Once you register, log in, and use the services of this
        platform, you will be deemed to fully understand, agree and accept the
        use of user information, including but not limited to collection,
        statistics, analysis, etc.
      </p>
      <p>
        2.6 You confirm that the your transaction in accordance with the service
        process is according to the clear instruction of the platform. You agree
        that the platform reserve the right to deal with the matters in
        accordance with this agreement and/or relevant documents and rules based
        on the instructions.
      </p>
      <p>
        2.7 You are responsible for any disputes or losses caused by your
        failure to modify or confirm the transaction status in a timely manner
        or fail to submit the relevant application, and this platform does not
        assume any responsibility.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Chapter 3 Risk Warning</strong>
      </p>
      <p>
        <strong>3.1 Currency Loans and Margin Trading</strong>
      </p>
      <p>
        3.1.1 8V launches currency loan service with margin trading services.
      </p>
      <p>
        3.1.2 Your maximum loan amount is determined by the highest multiplier
        of loans.
      </p>
      <p>
        3.1.3 8V provides risk management services for leveraged transactions
        with currency loans, and monitors super leverage accounts.
      </p>
      <p>
        3.1.4 When you use super leverage trading, it is assumed that you
        authorize 8V to take the risk control of the operations such as
        lightening up your position on your behalf or even closing your position
        completely when your loan expires or when risks occur in your Super
        Margin Account, without bearing any responsibility.
      </p>
      <p>
        3.1.5 When you use super leverage trading, you should abide by relevant
        national laws and ensure that the source of trading assets is legal and
        compliant. You must not use super leveraged transactions to engage in
        any behavior that violates the laws and regulations of the country or
        region where you belong, where you live, conduct business activities or
        other businesses, or have tax jurisdiction over you. Relevant illegal
        acts include but are not limited to money laundering.
      </p>
      <p>
        3.1.6 When you use super leveraged transactions, you should fully
        recognize the risks of digital asset investment and the risks of super
        leveraged transactions with caution.
      </p>
      <p>
        3.1.7 You agree that all investment performed in 8V represent your true
        investment intentions, and you unconditionally accept the potential
        risks and benefits brought about by investment decisions.
      </p>
      <p>
        3.1.8 You understand that handling fees and other related fees may be
        incurred during currency loans and super leveraged transactions, and
        agree to pay corresponding fees in accordance with the requirements
        announced by the platform from time to time.
      </p>
      <p>
        3.1.9 You agree that 8V reserves the right and authority to perform risk
        management such as lightening, liquidation, and automatic payment of the
        account when there is a risk in the super margin account without bearing
        any responsibility to you or any third party, and have you
        unconditionally accepted the final auction results.
      </p>
      <p>
        3.1.10 8V reserves the right to suspend and terminate currency loans and
        super leveraged trading business. When necessary, the platform can
        suspend and terminate currency loans and super leveraged trading
        business at any time.
      </p>
      <p>
        <strong>3.2 Perpetual Contracts</strong>
      </p>
      <p>
        3.2.1 Digital assets have their own risks. The price of digital assets
        is easy to fluctuate, there is no limit on ups and downs, and it is
        traded 24 hours a day, 7 days a week, on a global scale. Its price is
        easily affected by various factors such as the control of market makers,
        global news events, national policies, market demand, etc. , The price
        may rise several times a day, or the price may fall by half within a
        day. Due to the high leverage of perpetual contracts, you may suffer a
        greater loss. Therefore, we strongly recommend that you participate in
        transactions within the risk range.
      </p>
      <p>
        3.2.2 Under the influence of unexpected factors, such as system
        failures, network reasons, denial of service attacks (DDoS) and other
        hacking attacks, abnormal transactions, market interruptions, or other
        possible abnormal situations, we reserve the right to cancel the
        abnormalities according to the actual situation. Transaction results,
        and roll back all transactions in a certain period of time, without any
        responsibility to you or any third party.
      </p>
      <p>
        3.2.3 We strictly prohibit any improper trading behavior. We reserve the
        right to warn against all unethical behaviors such as price manipulation
        and influence on the trading system, and when necessary, we shall use
        restrictions on trading, suspension of trading, cancellation of
        transactions, reverse cancellation of completed transactions, freezing
        of accounts, and rollback periods when necessary to eliminate adverse
        effects without having to bear any responsibility to you or any third
        party.
      </p>
      <p>
        3.2.4 When your position margin cannot meet the requirements of the
        platform, you will be forced to close your position. If the price
        fluctuates drastically and the system still incurs a loss after using
        all risk control methods, we reserve the right to recover the loss from
        you.
      </p>
      <p>
        3.2.5 When the number of your positions or the number of orders is too
        large, and we believe that it may cause serious risks to the system and
        other users, you understand and agree that we reserve the right to
        require you to take risk control measures such as order cancellation and
        liquidation. In addition, when we deem it necessary, we reserve the
        right to use measures such as limiting the total number of positions,
        limiting the total number of orders, restricting open positions,
        canceling orders, or forcibly closing positions on individual accounts
        for risk control without having to impose any responsibility on you or
        any third parties.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Chapter 4 Transaction Management and Fees</strong>
      </p>
      <p>
        4.1 This platform will provide services for your transactions, and will
        charge necessary service or management fees in accordance with relevant
        documents, agreements and/or rules and instructions of the platform. For
        details, please refer to platform related documents, rules and
        instructions of the agreement. The abovementioned content is hereby
        incorporated into the agreement and may be revised from time to time.
        You agree that this platform reserves the right to adjust specific
        matters such as the type or amount of the aforementioned services or
        management fees from time to time and make announcements and
        modifications in accordance with this agreement and relevant rules. If
        you continue to accept the services of this platform, you will be deemed
        to agree to the updated terms.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong
          >Chapter 5 Change, Interruption or Termination of Services</strong
        >
      </p>
      <p>
        5.1 Unless the platform terminates this agreement or you apply to
        terminate this agreement and the platform agrees, otherwise this
        agreement will remain valid. If you violate the agreement, relevant
        rules, any laws or regulations, or we suspect that you are involved in
        illegal or improper behavior in the process of using our services, or at
        the request of government, the platform reserves the right to terminate
        the agreement, close your account or restrict your use of the platform
        without bearing any responsibility. However, the termination of the
        platform cannot exempt you from unfulfilled obligations under this
        agreement or other agreements generated on the platform.
      </p>
      <p>
        5.2 If you find a third person has fraudulently used or misappropriated
        your user account and password, or any other unauthorized use, you
        should immediately notify the platform and request the platform to
        suspend related services. All liabilities, losses, damages, claims,
        expenses or expenses arising from or related to the use of your account
        (regardless of whether it is authorized or not) will be borne by you.
      </p>
      <p>
        5.3 You agree that the platform has the right to change, interrupt or
        terminate some or all of the network services at any time, without prior
        notice to you, and without any liability to you or any third party.
      </p>
      <p>
        5.4 You understand that the platform has to overhaul or maintain the
        platforms that provide network services (such as websites, mobile, etc.)
        or related device on a regular or irregular basis, if such circumstances
        cause network services to be interrupted within a reasonable time, the
        platform will issue notices as soon as possible, but it does not need to
        bear any loss, damage, compensation or liability arising therefrom.
      </p>
      <p>
        5.5 This platform reserves the right to decide at its sole discretion to
        suspend or terminate the provision of all or part of the services under
        this agreement, remove or delete registration data, or take any remedial
        or temporary measures (including but not limited to cancel or withdraw
        transactions and freeze accounts), no notice is required, and no
        responsibility is required to you or any third party. Without affecting
        the generality of the aforementioned rights, we may exercise this right
        under the following circumstances:
      </p>
      <p>
        (1) The platform believes that the personal information you provide is
        not authentic, valid or complete;
      </p>
      <p>
        (2) When the platform finds or suspects that you have abnormal
        transactions, illegal transactions or unusual activities;
      </p>
      <p>
        (3) The platform believes that your account is suspected of money
        laundering, cash out, pyramid schemes, fraudulent use, or other
        situations that the platform believes to be risky;
      </p>
      <p>
        (4) This platform believes that you have violated this agreement;
      </p>
      <p>
        (5) You fail to pay the corresponding service fee to the platform when
        using the paid network service;
      </p>
      <p>
        (6) The platform finds that your account has been accessed without
        authorization, or your account is restricted by government, criminal or
        supervisory investigations or any pending litigation;
      </p>
      <p>
        (7) Other situations in which this platform determines by its sole
        discretion to suspend, interrupt or terminate the provision of all or
        part of the services under this agreement and to remove or delete the
        registration data.
      </p>
      <p>
        5.6 You agree that the suspension, interruption, termination of your
        account, or any other measures we take based on the foregoing
        statements, will not relieve you of your responsibility, and you shall
        still be liable for possible breach of contract as well as compensation
        for damages during your use of the platform services or any other
        responsibilities, and the platform can still keep your relevant
        information.
      </p>
      <p>
        5.7 If your registered free network service account is not actually used
        for any consecutive 90 days, the platform reserves the right to delete
        the account and stop providing you with relevant network services.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Chapter 6 Terms of Use</strong>
      </p>
      <p><strong>6.1&nbsp;</strong><strong>Account Information</strong></p>
      <p>
        (1) When you apply for platform services, you should provide accurate
        personal information in accordance with the relevant rules of the
        platform. If there is any change in your personal information, you
        should update it in time.
      </p>
      <p>
        (2) You should not transfer or lend your account number and password to
        others for use. If you find that your account has been illegally used by
        others, you should notify the platform immediately. The platform does
        not assume any responsibility for the illegal use of accounts and
        passwords by others due to viruses, hacking or your negligence in
        custody.
      </p>
      <p><strong>6.2&nbsp;</strong><strong>Service Operations</strong></p>
      <p>
        6.2.1 You should abide by laws, regulations, regulatory documents and
        policy requirements to ensure the legality of all digital currencies in
        your account. Unless permitted by law or written permission by this
        platform, you must not engage in the following behaviors during the use
        of the platform and related services:
      </p>
      <p>&nbsp;</p>
      <p>
        (1) Use the services of the platform to conduct any behavior that may
        affect the normal operation of the website or mobile network;
      </p>
      <p>
        (2) Use the network services provided by the platform to upload, display
        or disseminate any false, harassing, slanderous, abusive, intimidating,
        vulgar, obscene, violent, or any other information that violates laws
        and regulations, speech
      </p>
      <p>
        (3) Use the platform service to conduct any behavior that is not
        conducive to the platform;
      </p>
      <p>
        (4) Infringe upon the legal rights of third parties such as reputation
        rights, portrait rights, intellectual property rights, and trade
        secrets, or infringe anyone's commercial interests;
      </p>
      <p>
        (5) Induce other users to click on linked pages or share information.
        Use platform accounts and any functions, and third-party platforms for
        promotion without the written permission of the platform, or publish
        commercial advertisements that are not permitted by the platform;
      </p>
      <p>
        (6) Making and publishing methods and tools related to the above
        behaviors, or operations or dissemination such methods and tools,
        regardless of whether these behaviors are for commercial purposes;
      </p>
      <p>
        (7) Other behaviors that violate or may violate laws and regulations,
        infringe the rights of any third party, and interfere with operation of
        the platform.
      </p>
      <p>
        6.2.2 You promise to abide by all the digital currency trading rules of
        the platform, including but not limited to:
      </p>
      <p>(1) View transaction information</p>
      <p>
        When you view the digital currency transaction information on the
        platform, you should carefully read all the content contained in the
        transaction information, including but not limited to digital currency
        prices, entrusted amounts, handling fees, buying or selling directions,
        and you fully accept that the transaction information. You can click the
        button for the transaction as you confirm all the contents contained in
        this agreement.
      </p>
      <p>(2) Submit the entrustment</p>
      <p>
        You can submit the entrustment after viewing the transaction information
        and confirming that it is correct. After you submit the transaction
        entrustment, you authorize the platform to act on your behalf for the
        corresponding transaction matching. The platform will complete the
        matching of transaction automatically when there is a transaction that
        meets your entrusted price without notifying you in advance.
      </p>
      <p>(3) View transaction details</p>
      <p>
        You can view the corresponding transaction history through transaction
        details to confirm your transaction history.
      </p>
      <p>(4) Revoke/modify the entrustment</p>
      <p>
        You have the right to revoke or modify the order at any time before the
        matching of the transaction has met.
      </p>
      <p>
        6.3 The platform reserves the right to review and supervise your use of
        platform services (including but not limited to reviewing the content on
        the platform). If you violate any of the above regulations when using
        platform services, the platform reserves the right to request for
        correction, and the right to directly take all necessary measures
        (including but not limited to changing or deleting the content you
        posted, suspending or terminating your right to use network services) to
        lessen the impact of your behavior.
      </p>
      <p>
        6.4 If the market price is within the range of your order, your order
        under 8V will be executed of the operation process. However, even if the
        market price is within your range when you place it or open it in other
        ways, we cannot guarantee that your order will be executed. If you place
        an order at 8V during a planned or unplanned shutdown of 8V, once we
        resume operations, we will handle it on a commercially reasonable
        efforts. 8V reserves the right to reject or cancel orders placed and/or
        orders not placed during the shutdown.
      </p>
      <p>
        6.5 Orders may be subject to delays, difficulties and/or conditions
        beyond 8V's control that affect the transmission or execution of orders,
        including but not limited to mechanical or electronic failures or
        congestion in market. 8V will not be liable for this.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Chapter 7 Intellectual Property</strong>
      </p>
      <p>
        7.1 Unless otherwise specified, all content on the platform is the
        property of 8V and is protected by copyright, patents, trademarks and
        other applicable laws.
      </p>
      <p>
        7.2 The 8V trademarks, product titles, service marks and company logos
        used on the platform is the property of 8V and respective owners. The
        software, applications, text, images, graphics, data, prices,
        transactions, charts, graphics, and audio-visual materials used on this
        website and platform belongs to 8V. The trademarks and content of the
        platform shall not be copied, reproduced, modified, reprinted, uploaded,
        posted, transmitted, captured, collected or distributed in any form or
        method, whether it is achieved automatically or manually.
      </p>
      <p>
        7.3 It is strictly forbidden to use any content of the platform for any
        other purpose in any other website or network computer environment; any
        such unauthorized use may violate copyright, patent, trademark and any
        other applicable laws, and may result in criminal or civil penalties.
      </p>
      <p>
        7.4 8V is a trademark of the platform, and no user or third party may
        use it without the written authorization of the platform.
      </p>
      <p>
        7.5 8V supports intellectual property protection. If you wish to make a
        claim for the infringement of registered trademark or service mark, or
        if you wish to make a claim for your copyright, please send an email to
        support@topcreditbank.com.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Chapter 8 Privacy Protection</strong>
      </p>
      <p>
        Once you register, log in, and use the services of the platform, it will
        be deemed that you fully understand, agree and accept the privacy
        agreement of the platform.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Chapter 9 Disclaimer</strong>
      </p>
      <p>
        You understand that the platform allows you to use our services, provide
        you with related services, and enable you to buy, sell or store digital
        assets, which does not constitute and should not be construed by you as
        providing you with investment advice. This platform does not provide
        investment advice, tax advice, legal advice, or other professional
        advice. We do not recommend and/or support you to buy or sell digital
        assets and/or any investments. Before engaging in any trading or
        investment activities, you should consult a qualified professional.
      </p>
      <p>
        The services we provide to you through 8V are provided strictly on the
        basis of "as is", "if any" and "existing". The company does not make
        representations or warranties regarding the accuracy, completeness,
        timeliness, non-infringement, marketability, and suitability of 8V or
        the information or services contained therein. Under no circumstances
        will the company be liable for any loss or damage caused by any
        decisions or actions taken by you or any other person due to the use of
        8V and its services or the information contained therein. Regardless of
        whether the situation is due to: inaccurate or incomplete information,
        delays, interruptions, errors or omissions; or due to negligence or
        unexpected circumstances, the company’s information extraction,
        compilation, interpretation, calculation, reporting, or any loss or
        damage caused by delivery; or any loss or damage caused by the omission
        of the data contained in this platform or the products or services
        provided to users, regardless of whether the circumstances that caused
        such cases are in the company.
      </p>
      <p>
        The company will not be liable for any direct, special, indirect,
        consequential or incidental damages or any other damages of any kind
        (including direct or indirect loss of profits) to the user or any other
        person (whether related to torts or contract), even if the company or
        any other relevant person has been informed of the possibility. This
        limitation of liability includes, but is not limited to, the
        transmission of any viruses that may infect user equipment, mechanical
        or electronic equipment or communication line failures, telephone or
        other interconnection issues, unauthorized access, theft, operator
        error, strikes or other labor issues, or any force majeure. The company
        cannot and will not guarantee that you can log in 8V continuously,
        uninterrupted or safely.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Chapter 10 Risk Warning</strong>
      </p>
      <p>
        10.1 The digital currency market is new, unconfirmed, and may not grow;
      </p>
      <p>
        10.2 Digital currencies are mainly used by speculators in large numbers,
        and are relatively less used in retail and commercial markets. Digital
        currency transactions is extremely risky in which 24/7 uninterrupted
        online transactions and no restrictions on fluctuations make the prices
        susceptible to large fluctuations due to government policies and other
        factors;
      </p>
      <p>
        10.3 Due to the formulation or modification of laws by country,
        regulations and regulatory documents, digital currency transactions may
        be suspended or prohibited at any time;
      </p>
      <p>
        10.4 Digital currency transactions are extremely risky. You understand
        that this investment may cause partial or total loss, so you should
        decide the amount of investment based on the degree of loss that you can
        bear. You understand that digital currency will generate derivative
        risks, so if you have any questions, it is recommended to seek the
        assistance of a financial advisor first. In addition to the risks
        mentioned above, there will also be unpredictable risks. You should
        carefully consider and use clear judgment to evaluate your financial
        situation and the above risks before making any decision to buy or sell
        digital currencies, and bear all the losses arising therefrom. This
        platform does not bear any responsibility for this matter.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Chapter 11 Breach of Contract</strong>
      </p>
      <p>
        If you violate relevant laws, rules, regulations, third-party rights,
        any content under this agreement, or use all or part of the services
        provided by the platform, any claims, requirements, lawsuits, damages,
        losses, costs, or all expenses (including but not limited to reasonable
        attorney fees) are borne by you, and you agree to compensate this
        platform, defend this platform, and protect this platform from damage.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Chapter 12 Service of Notices</strong>
      </p>
      <p>
        12.1 If the notice under this agreement is made by publicity, it will be
        deemed to have been served once it is publicized on this platform. In
        addition, other exclusive notices issued to you will be provided by the
        platform to email address you provided during registration, or message
        system in your personal account, or mobile phone after registration.
        Once sent, it will be deemed to have been delivered. Please pay close
        attention to your e-mail address, message system, and SMS messages in
        your mobile phone.
      </p>
      <p>
        12.2 You agree that the platform can send relevant notifications or
        reminders to your e-mail address, message system and mobile phone; if
        you are unwilling to receive it, please proceed to corresponding
        set-ups. However, if you are unwilling to receive relevant notifications
        or reminders, you may not receive such notifications. You may not claim
        that the relevant notifications have not been delivered because you have
        not read this agreement.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong
          >Chapter 13 Disclosure to statutory bodies and recognized financial
          institutions</strong
        >
      </p>
      <p>
        13.1 Under the following circumstances, we may share your personal data
        with law enforcement agencies, data protection agencies, government
        officials or other agencies:
      </p>
      <p>13.1.1 According to the law;</p>
      <p>
        13.1.2 Forced by subpoenas, court orders or other legal procedures;
      </p>
      <p>
        13.1.3 We believe that the disclosure of information is necessary to
        prevent damage or financial loss;
      </p>
      <p>
        13.1.4 Reporting of suspected violations requires disclosure;
      </p>
      <p>
        13.1.5 Disclosure is necessary to investigate violations of this
        agreement.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Chapter 14 Applicable Law and Jurisdiction</strong>
      </p>
      <p>
        This clause is governed by and interpreted in accordance with relevant
        laws. The court has exclusive jurisdiction to resolve any disputes,
        disagreements or claims arising out of or related to this agreement,
        including the existence, validity, interpretation, performance,
        violation or termination, or any non-contractual disputes related to it.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Chapter 15 Other Agreements</strong>
      </p>
      <p>
        This platform has the final right to interpret this agreement within the
        scope permitted by law. The relevant terms of this agreement and the
        relevant pages of this platform can be referred to each other. If there
        is a different understanding, the agreement shall prevail. In addition,
        if some of the agreements in this agreement are deemed invalid or
        unimplementable, the other agreements in this agreement are still valid.
      </p>
      <p>
        If there is any conflict between these terms and any other agreement you
        may reach with 8V, the terms of the other agreement will only be
        effective if these terms are clearly identified and declared to be
        superseded by other agreements.
      </p>
      <p>
        Any failure or delay in the implementation of this clause or the
        exercise of the rights under this agreement by 8V shall not be regarded
        as a waiver of our rights.
      </p>
      <p>
        If there is any conflict between the content of this agreement and the
        English version, the English version shall prevail.
      </p>
      <p>&nbsp;</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
};
</script>

<style lang="scss">
.service-agreement {
  width: 1200px;
  margin: 0px auto 40px;
  background: #fff;
  padding: 0px 30px 30px;
  .header {
    font-size: 24px;
    text-align: center;
    padding-top: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
    strong {
      margin-top: 0;
    }
  }
  strong {
    display: block;
    margin: 20px 0 10px 0;
  }
  p {
    margin: 10px 0;
  }
}
</style>
