<template>
  <div class="contract-position">
    <div class="position-content" v-loading="cardLoading">
      <div class="card-container">
        <div v-for="item in contractList" :key="item.position_order_id">
          <div class="card">
            <div class="top">
              <div class="left">
                <span class="symbol"
                  >{{ item.open_item_name || "--" }}/{{
                    item.settlement_item_name || "--"
                  }}</span
                >
                <span
                  :class="Number(item.open_side == 1) ? 'green' : 'red'"
                  class="side"
                  >{{ sideMap[item.open_side] }}·{{ item.open_leverage }}X</span
                >
              </div>
              <div class="right">
                <span>当前价格 54212.98</span>
              </div>
            </div>
            <div class="middle">
              <div class="earnings">
                <div class="title">收益({{ item.settlement_item_name }})</div>
                <div
                  :class="Number(item.profit_loss) > 0 ? 'green' : 'red'"
                  class="num"
                >
                  {{ item.profit_loss }}
                </div>
              </div>
              <div class="earnings rate">
                <div class="title">收益率</div>
                <div
                  :class="Number(item.profit_loss_ratio) > 0 ? 'green' : 'red'"
                  class="num"
                >
                  {{ item.profit_loss_ratio }}%
                </div>
              </div>
            </div>
            <div class="bottom">
              <div>
                <div class="trade-item">
                  <div class="item-details alignment left">
                    <div class="title">开仓价</div>
                    <div class="num">{{ item.open_price }}</div>
                  </div>
                  <div class="item-details alignment middle-center">
                    <div class="title">
                      本金({{ item.settlement_item_name }})
                    </div>
                    <div class="num">{{ item.bail_number }}</div>
                  </div>
                  <div class="item-details alignment right">
                    <div class="title">持仓数量({{ item.open_item_name }})</div>
                    <div class="num">{{ item.open_interest }}</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="trade-item">
                  <div class="item-details alignment left">
                    <div class="title">强平价</div>
                    <div class="num">{{ item.liquidation_price }}</div>
                  </div>
                  <div class="item-details alignment middle-center">
                    <div class="title">止损价</div>
                    <div class="num">{{ item.stop_loss_price }}</div>
                  </div>
                  <div class="item-details alignment right">
                    <div class="title">止盈价</div>
                    <div class="num DINPro">{{ item.stop_profit_price }}</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="trade-item">
                  <div class="item-details left">
                    <div class="title">操作</div>
                    <div class="operation-btns">
                      <el-button @click="onOpenAppendMoney(item)" class="btns"
                        >追加本金</el-button
                      >
                      <el-button @click="onProfitPriceEdit(item)" class="btns"
                        >止盈止损</el-button
                      >
                      <el-button
                        @click="onOpenConfirm(item.position_order_id)"
                        class="btns close"
                        >平仓</el-button
                      >
                    </div>
                  </div>
                  <div class="item-details right">
                    <div class="title">开仓时间</div>
                    <div class="num">{{ parseTime(item.create_time) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <no-data
        slot="empty"
        style="display: none"
        v-show="!contractList.length > 0"
      ></no-data>
    </div>

    <div class="pages" v-if="contractList.length > 0">
      <p>
        {{ translateTitle("每页") }}
        <span>{{ pageInfo.pageSize }}</span>
        {{ translateTitle("条，总共") }}
        <span>{{ pageInfo.pageCount }}</span>
        {{ translateTitle("页") }}
      </p>
      <el-pagination
        class="mt20"
        background
        layout="prev, pager, next"
        :total="pageInfo.pageTotal"
        :page-size="pageInfo.pageSize"
        :current-page.sync="pageInfo.pageCurrent"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      class="unwindDiolog"
      width="400px"
    >
      <span style="font-size: 14px; font-weight: bolder; text-align: center"
        >确定平仓此订单？</span
      >
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="dialogVisible = false"
          >取消</span
        >
        <span class="dialog-confirm-btn" @click="onClosePosition">确定</span>
      </span>
    </el-dialog>
    <el-dialog
      custom-class="append-money-dialog"
      title="追加本金"
      :visible.sync="appendMoneydialog"
      :before-close="appendMoneydialogClose"
      width="500px"
    >
      <p class="first-content">
        <span class="left-item">追加金额</span>
        <span
          >可用 {{ assetsInfoShow }} {{ rowData.settlement_item_name }}
          <span class="common-operate">充值</span></span
        >
      </p>
      <div class="second-content">
        <InputNumber
          v-model="bail_number"
          placeholder="请输入追加金额"
          maxlength="10"
          decimals="2"
          :suffix="rowData.settlement_item_name"
        ></InputNumber>
        <span class="all-btn common-operate" @click="onClickAll">全部</span>
      </div>
      <div class="three-content">
        <div class="item-one">
          <div class="left-item">
            <span class="black">{{ beforeBailNumber }}</span>
            <span>当前本金</span>
          </div>
          <span
            ><img src="../../../../../../assets/img/tran-to.png" alt=""
          /></span>
          <div class="right-item">
            <span class="red">{{ afterBailNumberShow }}</span>
            <span>追加后本金</span>
          </div>
        </div>
        <div class="item-two">
          <div class="left-item">
            <span class="black">{{ beforeLiquidationPrice }}</span>
            <span>当前强平价</span>
          </div>
          <span
            ><img src="../../../../../../assets/img/tran-to.png" alt=""
          /></span>
          <div class="right-item">
            <span class="red">{{ afterLiquidationPriceShow }}</span>
            <span>追加后强平价</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="onCloseAppend">取消</span>
        <span class="dialog-confirm-btn" @click="onAppendMoney">确定</span>
      </span>
    </el-dialog>
    <el-dialog
      custom-class="set-profitPrice-dialog"
      title="设置止盈止损"
      :visible.sync="setProfitPricedialog"
      :before-close="setProfitPricedialogClose"
      width="500px"
    >
      <div class="profitPrice-three-content">
        <div class="item-one">
          <div class="left-item">
            <!-- <span class="black">{{ rowMarketInfoShow.last || "--" }}</span> -->
            <span>当前价</span>
          </div>
          <div class="center-item">
            <span class="black">{{ rowData.open_price }}</span>
            <span>开仓价</span>
          </div>
          <div class="right-item">
            <span class="black">{{ rowData.liquidation_price }}</span>
            <span>强平价</span>
          </div>
        </div>
      </div>
      <p class="profitPrice-first-content">
        <span class="left-item">止盈价</span>
        <span class="right-item"
          >预计盈利
          <span class="green">
            {{ expectedProfit }} {{ rowData.settlement_item_name }}({{
              expectedProfitPercent
            }}%)</span
          ></span
        >
      </p>
      <div class="profitPrice-second-content">
        <InputNumber
          v-model="inputProfitPrice"
          placeholder="请输入止盈价"
          maxlength="10"
          decimals="2"
        ></InputNumber>
        <span class="all-btn common-operate" @click="onClearAll">清除</span>
      </div>
      <p class="profitPrice-first-content V2">
        <span class="left-item">止损价</span>
        <span class="right-item"
          >预计亏损
          <span class="red"
            >{{ expectedLoss }} {{ rowData.settlement_item_name }}({{
              expectedLossPercent
            }}%)</span
          ></span
        >
      </p>
      <div class="profitPrice-second-content">
        <InputNumber
          v-model="inputLossPrice"
          placeholder="请输入止损价"
          maxlength="10"
          decimals="2"
        ></InputNumber>
        <span class="all-btn common-operate" @click="onClearAllV2">清除</span>
      </div>

      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="setProfitPricedialogClose"
          >取消</span
        >
        <span class="dialog-confirm-btn" @click="onSetPositionProfitLoss"
          >确定</span
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiGetPositionList } from "@/model/ucenter/trade";
import { showMessage } from "@/components/dialog/message.js";
import { apiGetAssets } from "@/model/exchange/index";
import { apiGetStaticTableInfo } from "@/model/settings";
import InputNumber from "@/components/common/InputNumber";
import { parseTime } from "@/utils/index.js";
import NoData from "@/components/common/NoData";
import { translateTitle } from "@/assets/js/i18n";
import {
  apiGetOpenPositionList,
  apiGetPlanOrderList,
  apiRevokePlan,
  apiAllClosePosition,
  apiAppendInterest,
  apiClosePosition,
  apiSetPositionProfitLoss,
} from "@/model/contractExchange/order.js";
export default {
  data() {
    return {
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 4,
        pageCurrent: 1,
      },
      cardLoading: true,
      itemList: [],
      bail_number: "",
      position_order_id: "",
      dialogVisible: false,
      appendMoneydialog: false,
      setProfitPricedialog: false,
      contractList: [],
      beforeBailNumber: "",
      afterBailNumber: "--",
      inputProfitPrice: "",
      inputLossPrice: "",
      beforeLiquidationPrice: "",
      afterLiquidationPrice: "--",
      symbolList: {},
      apiAssetsInfo: {},
      rowData: {},
      contractProfitMap: {},
      assetsInfo: {
        type: Object,
        default: function() {
          return {
            remain: {
              all_u_valuation: {
                usdt: "--",
              },
              list: [],
            },
          };
        },
      },
      sideMap: {
        1: "买",
        2: "卖",
      },
    };
  },
  components: { InputNumber, NoData },
  created() {
    this.getItemList();
    this.getTradePairList();
    this.getAssets();
    this.getPositionList();
    this.startTimer();
  },
  methods: {
    translateTitle,
    parseTime,
    onClearAll() {
      this.inputProfitPrice = "";
    },
    onClearAllV2() {
      this.inputLossPrice = "";
    },
    onPageChange() {
      this.getPositionList();
    },
    getItemList() {
      let settingList = JSON.parse(localStorage.getItem("settingList"));
      this.itemList = settingList[13];
    },
    startTimer() {
      this.getPositionList().then(() => {
        const timer = setTimeout(() => {
          this.startTimer();
        }, 500);
        this.$once("hook:beforeDestroy", () => {
          clearTimeout(timer);
        });
      });
    },

    async getPositionList() {
      const req = {
        position_status: 1,
        page: this.pageInfo.pageCurrent,
        page_size: this.pageInfo.pageSize,
      };
      const { status, data } = await apiGetPositionList(req);
      this.cardLoading = false;
      if (status == 200) {
        data.list.forEach(ele => {
          for (let item in this.itemList) {
            if (ele.open_item_id == this.itemList[item].item_id) {
              ele.open_item_name = this.itemList[item].item_name.toUpperCase();
            }
            if (ele.settlement_item_id == this.itemList[item].item_id) {
              ele.settlement_item_name = this.itemList[
                item
              ].item_name.toUpperCase();
            }
          }
        });
        this.contractList = data.list;
        this.pageInfo = data.pageInfo;
      }
    },
    onClosePosition() {
      let params = {
        position_order_id: this.position_order_id,
      };
      apiClosePosition(params).then(res => {
        if (res.status == 200) {
          this.dialogVisible = false;
          showMessage({ message: "平仓成功", type: "success" });
          // this.initData(this.checked);
        }
      });
    },
    onAppendMoney() {
      let params = {
        position_order_id: this.position_order_id,
        bail_number: this.bail_number,
      };
      apiAppendInterest(params).then(res => {
        if (res.status == 200) {
          showMessage({ message: "追加成功", type: "success" });
          this.appendMoneydialog = false;
          this.initData(this.checked);
        }
      });
    },
    onProfitPriceEdit(row) {
      this.rowData = row;
      this.row_swap_symbol_id = row.symbol_id;
      this.setProfitPricedialog = true;
    },
    appendMoneydialogClose() {
      this.bail_number = "";
      this.appendMoneydialog = false;
    },
    onCloseAppend() {
      this.bail_number = "";
      this.appendMoneydialog = false;
    },
    onOpenConfirm(position_order_id) {
      this.position_order_id = position_order_id;
      this.dialogVisible = true;
    },
    setProfitPricedialogClose() {
      this.inputLossPrice = "";
      this.inputProfitPrice = "";
      this.setProfitPricedialog = false;
    },
    onOpenAppendMoney(item) {
      this.position_order_id = item.position_order_id;
      this.beforeBailNumber = item.bail_number;
      this.afterBailNumber = item.bail_number;
      this.beforeLiquidationPrice = item.liquidation_price;
      this.afterLiquidationPrice = item.liquidation_price;
      this.rowData = item;
      // this.symbol_id = item.symbol_id
      this.appendMoneydialog = true;
    },
    onClickAll() {
      this.bail_number = this.assetsInfoShow;
    },
    onSetPositionProfitLoss() {
      let params = {
        position_order_id: this.rowData.position_order_id,
        market_price: this.rowMarketInfoShow.last,
        stop_profit_price: this.inputProfitPrice,
        stop_loss_price: this.inputLossPrice,
      };
      apiSetPositionProfitLoss(params).then(res => {
        if (res.status == 200) {
          showMessage({ message: "设置成功", type: "success" });
          this.setProfitPricedialogClose();
          this.initData(this.checked);
        }
      });
    },
    async getAssets() {
      let params = {
        type: "all",
      };
      const { status, data } = await apiGetAssets(params);
      if (status == 200) {
        this.apiAssetsInfo = data;
        this.contractProfitMap = data.contract;
      }
    },
    async getTradePairList() {
      const { status, data } = await apiGetStaticTableInfo(49);
      if (status == 200) {
        this.symbolList = data;
      }
    },
  },
  computed: {
    // 现有U资产
    assetsInfoShow() {
      let tempObj = "0";
      if (
        JSON.stringify(this.assetsInfo) != "{}" &&
        this.assetsInfo.remain.list.length > 0
      ) {
        this.assetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.to_item_id) {
            tempObj = ele.over;
          }
        });
      } else if (
        JSON.stringify(this.apiAssetsInfo) != "{}" &&
        this.apiAssetsInfo.remain.list.length > 0
      ) {
        this.apiAssetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.to_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = "--";
      }
      return tempObj;
    },
    afterLiquidationPriceShow() {
      // 强平价 = 开仓价 - (1 做多 | -1做空)) ×(保证金 × (1 − 强平⻛险率) - 平仓手续费-开仓手续费)/持仓数量
      let tempValue = "";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.bail_number != "") {
        tempValue = (
          this.rowData.open_price -
          (tempSide *
            (this.afterBailNumberShow *
              (1 -
                this.symbolList[this.rowData.symbol_id].liquidation_risk_rate /
                  10000) -
              this.rowData.close_position_fee -
              this.rowData.open_position_fee)) /
            this.rowData.open_interest
        ).toFixed(2);
      } else {
        tempValue = this.afterLiquidationPrice;
      }
      return tempValue;
    },
    afterBailNumberShow() {
      return (Number(this.afterBailNumber) + Number(this.bail_number)).toFixed(
        2
      );
    },
    // 平仓盈亏 = (inputProfitPrice-开仓价) × (1 做多 | -1做空)) × 持仓数量  - 平仓手续费
    expectedProfit() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
        tempValue = (
          (this.inputProfitPrice - this.rowData.open_price) *
            tempSide *
            this.rowData.open_interest -
          this.rowData.close_position_fee
        ).toFixed(2);
      }
      // inputProfitPrice
      return tempValue;
    },
    expectedProfitPercent() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
        tempValue = (
          ((this.inputProfitPrice - this.rowData.open_price) *
            tempSide *
            this.rowData.open_interest -
            this.rowData.close_position_fee) /
          this.rowData.bail_number
        ).toFixed(2);
        if (Number(tempValue) > 0) {
          tempValue = "+" + tempValue;
        }
      }
      // inputProfitPrice
      return tempValue;
    },
    // 平仓盈亏 = (inputLossPrice-开仓价) × (1 做多 | -1做空)) × 持仓数量  - 平仓手续费
    expectedLoss() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
        tempValue = (
          (this.inputLossPrice - this.rowData.open_price) *
            tempSide *
            this.rowData.open_interest -
          this.rowData.close_position_fee
        ).toFixed(2);
      }
      // inputLossPrice
      return tempValue;
    },
    expectedLossPercent() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
        tempValue = (
          ((this.inputLossPrice - this.rowData.open_price) *
            tempSide *
            this.rowData.open_interest -
            this.rowData.close_position_fee) /
          this.rowData.bail_number
        ).toFixed(2);
      }

      // inputLossPrice
      return tempValue;
    },
  },
};
</script>
<style lang="scss">
.contract-position {
  height: 100%;
  .position-content {
    height: 660px;
  }
  .card-container {
    margin: 0px 20px 40px 20px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .green {
      color: #03ad79;
    }
    .red {
      color: #c61b3d;
    }

    .card {
      width: 492px;
      height: 300px;
      border-radius: 4px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06);
      border: solid 1px #e6e6e6;
      background-color: #ffffff;
      box-sizing: border-box;
      margin-bottom: 10px;
      .top {
        display: flex;
        justify-content: space-between;
        padding: 0px 20px;
        height: 50px;
        line-height: 50px;
        border-radius: 4px;
        border-bottom: solid 1px #e6e6e6;
        box-sizing: border-box;
        background: rgb(243, 247, 252, 0.5);
        .left {
          .symbol {
            color: #354052;
            font-size: 16px;
            font-weight: 500;
          }
          .side {
            margin-left: 5px;
            font-weight: 600;
            font-size: 16px;
          }
        }
        .right {
          span {
            color: #354052;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .middle {
        margin: 0px 20px;
        display: flex;
        justify-content: space-between;
        height: 65px;
        align-items: center;
        border-bottom: 1px solid #f3f5f8;
        .earnings {
          .title {
            color: #9aa1b1;
            font-size: 14px;
            font-weight: 400;
          }
          &.rate {
            text-align: right;
          }
          .num {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
      .bottom {
        padding: 15px 20px 20px 20px;
        .trade-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;

          .item-details {
            &.alignment {
              width: 150px;
            }
            .title {
              color: #9aa1b1;
              font-size: 14px;
              font-weight: 400;
            }
            .num {
              color: #354052;
              font-size: 14px;
              font-weight: 500;
            }
            .operation-btns {
              margin-top: 3px;
              .btns {
                width: 82px;
                height: 26px;
                padding: 6px 13px;
                border-radius: 2px;
                border: solid 1px #3a7dff;
                color: #3a7dff;
                font-size: 14px;
                font-weight: 400;
                box-sizing: border-box;
              }
              .close {
                width: 54px;
              }
            }
          }
          .left {
            text-align: left;
          }
          .right {
            text-align: right;
          }
          .middle-center {
            text-align: center;
          }
        }
      }
    }
  }
  .pages {
    margin: 0px 20px;
    border-top: 1px solid #f3f5f8;
  }
  .append-money-dialog {
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      padding: 30px 30px 5px;
    }
    .first-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;
      font-size: 12px;
      .available-assets {
        color: #bec1c8;
      }
      .left-item {
        color: #354052;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .second-content {
      position: relative;
      .el-input__suffix {
        line-height: 40px;
        margin-right: 40px;
        color: #354052;
      }
      .all-btn {
        position: absolute;
        right: 10px;
        top: 9px;
      }
    }
    .three-content {
      width: 460px;
      height: 120px;
      margin: 20px 30px 20px 0px;
      padding: 14px 15px;
      border-radius: 4px;
      background-color: #f8f8f8;
      .item-one,
      .item-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          display: block;
          margin-bottom: 2px;
          color: #9aa1b1;
          font-size: 12px;
          &.black {
            font-size: 14px;
            color: #354052;
          }
          &.red {
            font-size: 14px;
            color: #f04a5a;
          }
        }
        .left-item {
          width: 150px;
        }
        .right-item {
          width: 150px;
          text-align: right;
        }
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
  .set-profitPrice-dialog {
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      padding: 0px 30px 5px;
    }
    .profitPrice-first-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .left-item {
        color: #354052;
        font-size: 16px;
        font-weight: 500;
      }
      .right-item {
        .green {
          color: #03ad79;
        }
        .red {
          color: #c61b3d;
        }
      }
      &.V2 {
        margin-top: 15px;
      }
    }
    .profitPrice-second-content {
      position: relative;
      .el-input__suffix {
        line-height: 40px;
        margin-right: 40px;
        color: #354052;
      }
      .all-btn {
        position: absolute;
        right: 10px;
        top: 9px;
      }
    }
    .profitPrice-three-content {
      width: 440px;
      height: 65px;
      margin: 20px 30px 20px 0px;
      padding: 14px 15px;
      border-radius: 4px;
      background-color: #f8f8f8;
      .item-one,
      .item-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          display: block;
          margin-bottom: 2px;
          color: #7f8490;
          font-size: 12px;
          &.black {
            font-size: 14px;
            color: #354052;
          }
          &.red {
            font-size: 14px;
            color: #f04a5a;
          }
        }
        .left-item {
          width: 150px;
        }
        .center-item {
          width: 150px;
          text-align: center;
        }
        .right-item {
          width: 150px;
          text-align: right;
        }
      }
    }
  }
  .unwindDiolog {
    .el-dialog__body {
      text-align: center;
    }
  }
  .no-data-box {
    // text-align: center;
    min-height: 660px !important;
    .no-data-content {
      text-align: center;
    }
  }
}
</style>
