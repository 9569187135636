<template>
  <div class="add-edit-api-key">
    <el-form
      ref="form"
      label-position="left"
      label-width="100px"
      :rules="rules"
      :model="form"
    >
      <el-form-item :label="translateTitle('名称')" prop="name">
        <el-input
          v-model="form.name"
          :placeholder="translateTitle('请输入名称')"
          :maxlength="10"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="translateTitle('状态')"
        prop="status"
        v-if="currType != 1"
      >
        <el-radio-group v-model="form.status">
          <el-radio border :label="1">{{ translateTitle("开启") }}</el-radio>
          <el-radio border :label="2">{{ translateTitle("禁用") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="translateTitle('IP列表')" prop="ip_list">
        <el-input
          v-model="form.ip_list"
          type="textarea"
          :show-word-limit="true"
          :placeholder="
            translateTitle('请输入需要加白的IP地址,以英文逗号进行分隔。')
          "
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="translateTitle('谷歌码')"
        prop="google_code"
        v-if="currType != 1"
      >
        <el-input
          v-model="form.google_code"
          :placeholder="translateTitle('请输入谷歌码')"
          :maxlength="6"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <span class="btn-reset ml20" @click="onCancel">{{
          translateTitle("取消")
        }}</span>
        <span class="btn-search" @click="onAdd">{{
          translateTitle("确认")
        }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { showMessage } from "@/components/dialog/message";
import { translateTitle } from "@/assets/js/i18n";
import { apiApiSecretAdd, apiApiSecretEdit } from "@/model/ucenter/billCenter";
export default {
  name: "add-edit-api-key",
  props: {
    currRow: {
      type: Object,
      default: function () {
        return {};
      },
    },
    currType: {
      type: [Number],
    },
  },

  data() {
    return {
      form: {
        name: "",
        ip_list: "",
        status: 1,
      },
      rules: {
        google_code: [
          {
            required: true,
            trigger: "blur",
            message: this.translateTitle("谷歌码不能为空"),
          },
        ],
        status: [
          {
            required: true,
            trigger: "blur",
            message: this.translateTitle("状态不能为空"),
          },
        ],
        name: [
          {
            required: true,
            trigger: "blur",
            message: this.translateTitle("名称不能为空"),
          },
        ],
      },
    };
  },
  created() {
    if (this.currType != 1) {
      this.form.name = this.currRow.name;
      this.form.ip_list = this.currRow.ip_list.join(",");
    }
  },
  methods: {
    translateTitle,
    onCancel() {
      this.$emit("update-cancel");
    },
    onAdd() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        const req = {
          ...this.form,
        };
        if (this.currType == 1) {
          apiApiSecretAdd(req).then((rs) => {
            if (rs.status == 200) {
              //   this.$message("添加成功", "success");
              showMessage({
                message: this.translateTitle(`添加成功`),
                type: "success",
              });
              this.$emit("update-success");
            }
          });
        } else {
          req.id = this.currRow.id;
          apiApiSecretEdit(req).then((rs) => {
            if (rs.status == 200) {
              showMessage({
                message: this.translateTitle(`编辑成功`),
                type: "success",
              });
              this.$emit("update-success");
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.add-edit-api-key {
  padding: 0 30px 30px 30px;
  .detail {
    margin: 20px 0;
    p {
      font-size: 10px;
      font-weight: 200;
      color: #c0c4cc;
      line-height: 1.5;
    }
  }
  .ml20 {
    margin-right: 20px;
  }
  .btn-search {
    user-select: none;
    cursor: pointer;
    width: 120px;
    height: 16px;
    padding: 10px 21px;
    border-radius: 3px;
    background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
    color: #fff;
    font-size: 14px;
  }
  .btn-reset {
    user-select: none;
    cursor: pointer;
    width: 120px;
    height: 16px;
    padding: 9px 20px;
    border-radius: 3px;
    color: #354052;
    font-size: 14px;
    border: solid 1px #c2c7d0;
    box-sizing: border-box;
  }
}
</style>
