<template>
  <div style="min-height:100vh;">
    <SituationInner v-if="defaultPage"></SituationInner>
    <SituationInnerBv v-if="is8V"></SituationInnerBv>
    <!-- <IndexInner v-if="is8V && darkMode == 1"></IndexInner> -->
  </div>
</template>
<script>
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
import { translateTitle } from "@/assets/js/i18n";
import SituationInner from "@/pages/ucenter/situation-inner.vue";
import SituationInnerBv from "@/pages/ucenter/situation-inner-bv.vue";
export default {
  mixins: [],

  data() {
    return {
      is8V: false,
      defaultPage: false,
    };
  },
  components: { SituationInner, SituationInnerBv },
  computed: {},
  created() {
    if (VUE_UNION_STATION == "8V") {
      this.is8V = true;
    } else {
      this.defaultPage = true;
    }
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
