import api from "@/assets/js/api";
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";
//读取消息
export function apiReadMessage(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/Common/readMessage`,
    data,
    type: "POST",
  });
}

//获取站内行列表
export function apiGetMessageList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/Common/getMessageList`,
    data,
    type: "POST",
  });
}
