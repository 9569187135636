<template>
  <div class="component-trade">
    <div class="trade-header">{{ translateTitle("币币交易") }}</div>
    <div class="trade-content">
      <div class="left-item">
        <div class="item-header" v-if="userInfo">
          {{ translateTitle("可用:") }}
          <span>{{ assetsInfoShow }}</span>
          {{ to_symbol.toUpperCase() }}
        </div>
        <div class="item-header V2" v-else></div>
        <div class="trade-item">
          <div class="trade-type">
            <div
              class="item-tabs"
              :class="{ active: currChainValue == item.value }"
              v-for="(item, index) in tabsOptions"
              @click="onChainChange(item.value)"
              :key="index"
            >
              {{ translateTitle(item.label) }}
            </div>
          </div>

          <!--市价买入价 -->
          <InputNumber
            v-if="currChainValue == '1'"
            class="buy-price market-price"
            autocomplete="new-password"
            maxlength="10"
            decimals="8"
            disabled
            :placeholder="translateTitle('以市场上最优价格买入')"
            :frontSuffix="translateTitle('买入价')"
            :suffix="to_symbol.toUpperCase()"
            ref="buyPrice"
          ></InputNumber>
          <!--限价买入价 -->
          <InputNumber
            v-else
            class="buy-price"
            v-model="buyPrice"
            @input="onBuyNumChange"
            autocomplete="new-password"
            maxlength="20"
            :decimals="price_precision"
            :frontSuffix="translateTitle('买入价')"
            :suffix="to_symbol.toUpperCase()"
            ref="buyPrice"
          ></InputNumber>
          <!--市价交易额 -->
          <InputNumber
            v-if="currChainValue == '1'"
            class="buy-price"
            @input="onBuyTotalChange"
            v-model="buyTransactionAmount"
            autocomplete="new-password"
            :decimals="number_precision"
            :frontSuffix="translateTitle('买入数量')"
            :suffix="from_symbol.toUpperCase()"
            ref="buyVolume"
          ></InputNumber>

          <!--限价买入数量6 -->
          <InputNumber
            v-else
            class=" buy-price"
            v-model="buyNum"
            @input="onBuyNumChange"
            autocomplete="new-password"
            :decimals="number_precision"
            :frontSuffix="translateTitle('买入数量')"
            :suffix="from_symbol.toUpperCase()"
            ref="buyPrice"
          ></InputNumber>

          <div class="block">
            <el-slider
              @input="onBuySliderChange"
              v-model="buySliderValue"
              :disabled="!userInfo"
              :format-tooltip="formatTooltip"
              :step="1"
              :marks="marks"
            >
            </el-slider>
          </div>

          <!-- 10 -->

          <!-- 买入数量4-->
          <InputNumber
            v-if="currChainValue == '1'"
            class="buy-price buy-amount-self"
            @input="onBuyAmountChange"
            v-model="buyAmount"
            autocomplete="new-password"
            maxlength="10"
            :decimals="price_precision"
            :frontSuffix="translateTitle('买入金额')"
            :suffix="to_symbol.toUpperCase()"
            ref="buyPrice"
          ></InputNumber>
          <InputNumber
            v-if="currChainValue == '2'"
            @input="onBuyNumChangeSelf"
            class="buy-price buy-amount-self"
            v-model="buyTransactionAmountSelf"
            autocomplete="new-password"
            maxlength="10"
            :decimals="number_precision"
            :frontSuffix="translateTitle('买入金额')"
            :suffix="to_symbol.toUpperCase()"
            ref="buyPrice"
          ></InputNumber>
          <div class="trade-btn">
            <el-button
              :loading="buyBtnLoading"
              @click="onTrade(1)"
              v-if="userInfo"
              class="trade-buy btn"
            >
              {{ translateTitle("买入") }}
              {{ from_symbol.toUpperCase() }}</el-button
            >
            <span v-else class="trade-buy btn login">
              <span @click="goLogin(1)" class="common-operate">{{
                translateTitle("登录")
              }}</span>
              <span class="or-text"> {{ translateTitle("或") }} </span>
              <span @click="goLogin(2)" class="common-operate">{{
                translateTitle("注册")
              }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="right-item">
        <div class="item-header" v-if="userInfo">
          {{ translateTitle("可用:") }} <span>{{ assetsInfoShowSell }}</span>
          {{ from_symbol.toUpperCase() }}
        </div>
        <div class="item-header V2" v-else></div>
        <div class="trade-item">
          <div class="trade-type">
            <div
              class="item-tabs"
              :class="{ active: currChainValue == item.value }"
              v-for="(item, index) in tabsOptions"
              @click="onChainChange(item.value)"
              :key="index"
            >
              {{ translateTitle(item.label) }}
            </div>
          </div>
          <InputNumber
            v-if="currChainValue == '1'"
            class="buy-price market-price"
            autocomplete="new-password"
            maxlength="10"
            decimals="2"
            disabled
            :placeholder="translateTitle('以市场上最优价格卖出')"
            :frontSuffix="translateTitle('卖出价')"
            :suffix="to_symbol.toUpperCase()"
            ref="buyPrice"
          ></InputNumber>
          <InputNumber
            v-else
            class="sell-price"
            v-model="sellPrice"
            @input="onSellPriceChange"
            autocomplete="new-password"
            maxlength="20"
            :decimals="price_precision"
            :frontSuffix="translateTitle('卖出价')"
            :suffix="to_symbol.toUpperCase()"
            ref="sellPrice"
          ></InputNumber>
          <InputNumber
            v-if="JSON.stringify(coinInfoMap) != {}"
            class="sell-price"
            v-model="sellNum"
            @input="onSellNumChange"
            autocomplete="new-password"
            :decimals="number_precision"
            :frontSuffix="translateTitle('卖出数量')"
            :suffix="from_symbol.toUpperCase()"
            ref="sellPrice"
          ></InputNumber>
          <div class="block">
            <el-slider
              v-model="sellSliderValue"
              @input="onSellSliderChange"
              :disabled="!userInfo"
              :format-tooltip="formatTooltip"
              :step="1"
              :marks="marks"
            >
            </el-slider>
          </div>

          <InputNumber
            v-if="currChainValue == '1'"
            class="buy-amount-self buy-price"
            v-model="sellTransactionAmount"
            @input="onSellTransactionAmountChange"
            autocomplete="new-password"
            maxlength="10"
            :decimals="price_precision"
            :frontSuffix="translateTitle('卖出金额')"
            :suffix="to_symbol.toUpperCase()"
            ref="buyVolume"
          ></InputNumber>
          <!-- 11 -->
          <InputNumber
            v-if="currChainValue == '2'"
            class="buy-amount-self buy-price"
            @input="onSellAmoutSelfChange"
            v-model="sellTransactionAmountSelf"
            autocomplete="new-password"
            :decimals="price_precision"
            :frontSuffix="translateTitle('卖出金额')"
            :suffix="to_symbol.toUpperCase()"
            ref="buyPrice"
          ></InputNumber>
          <div class="trade-btn">
            <el-button
              :loading="sellBtnLoading"
              @click="onTrade(2)"
              v-if="userInfo"
              class="trade-sell btn"
            >
              {{ translateTitle("卖出") }}
              {{ from_symbol.toUpperCase() }}</el-button
            >
            <span v-else class="trade-sell btn login">
              <span @click="goLogin(1)" class="common-operate">{{
                translateTitle("登录")
              }}</span>
              <span class="or-text"> {{ translateTitle("或") }} </span>
              <span @click="goLogin(2)" class="common-operate">{{
                translateTitle("注册")
              }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputNumber from "@/components/common/InputNumber";
import { mapGetters } from "vuex";
import { apiTrustStore, apiGetAssets } from "@/model/exchange/index";
import { apiGetStaticTableInfo } from "@/model/settings";
import BigNumber from "bignumber.js";
import { showMessage } from "@/components/dialog/message";
import Dispatcher from "@/assets/js/SocketDispatcher";
import commonMixin from "@/components/common/commonMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  components: { InputNumber },
  mixins: [commonMixin],
  props: {
    assetsInfoList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    marketList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    assetsInfo: {
      type: Object,
      default: function() {
        return {
          remain: {
            all_u_valuation: {
              usdt: "--",
            },
            list: [],
          },
        };
      },
    },
    swap_symbol_id: {
      type: Number,
      default: function() {
        22;
      },
    },
    from_symbol: {
      type: [String, Number],
    },
    to_symbol: {
      type: [String, Number],
    },
    from_item_id: {
      type: [String, Number],
    },
    to_item_id: {
      type: [String, Number],
    },
  },
  computed: {
    ...mapGetters({
      language: "user/language",
      userInfo: "user/userInfo",
    }),
    apiAssetsRemain() {
      let tempObj = 0;
      if (
        JSON.stringify(this.apiAssetsInfo) != "{}" &&
        this.apiAssetsInfo.remain.list.length > 0
      ) {
        this.apiAssetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.to_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = 0;
      }
      return tempObj;
    },
    assetsInfoWs() {
      let tempObj = 0;
      if (
        JSON.stringify(this.assetsInfo) != "{}" &&
        this.assetsInfo.remain.list.length > 0
      ) {
        this.assetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.to_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = 0;
      }
      return tempObj;
    },
    apiAssetsRemainSell() {
      let tempObj = 0;
      if (
        JSON.stringify(this.apiAssetsInfo) != "{}" &&
        this.apiAssetsInfo.remain.list.length > 0
      ) {
        this.apiAssetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.from_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = 0;
      }
      return tempObj;
    },
    assetsInfoWsSell() {
      let tempObj = 0;
      if (
        JSON.stringify(this.assetsInfo) != "{}" &&
        this.assetsInfo.remain.list.length > 0
      ) {
        this.assetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.from_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = 0;
      }
      return tempObj;
    },
    // 现有U资产
    assetsInfoShow() {
      if (this.apiAssetsBtn) {
        return this.apiAssetsRemain;
      } else {
        return this.assetsInfoWs;
      }
    },
    // 现有币资产
    assetsInfoShowSell() {
      if (this.apiAssetsBtn) {
        return this.apiAssetsRemainSell;
      } else {
        return this.assetsInfoWsSell;
      }
    },
    // 市场现在交易价格
    marketInfoShow() {
      let marketInfo = {
        last: "",
        currency: { cny: "" },
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    // 买入交易量
    tradingVolume() {
      let tempVal = "0";
      if (this.buyAmount == "0") {
        tempVal = "0";
      } else if (this.buyAmount != "" && this.marketInfoShow.last) {
        let bigBuyAmount = new BigNumber(this.buyAmount);
        let bigMarketInfoShow = new BigNumber(
          JSON.parse(JSON.stringify(this.marketPrice))
        );
        tempVal = bigBuyAmount
          .div(bigMarketInfoShow)
          .toNumber()
          .toFixed(this.number_precision);
      } else {
        tempVal = "0";
      }
      return tempVal;
    },
    // 买入交易额
    transactionAmount() {
      let tempVal = "0";
      if (this.currChainValue == "1") {
        if (this.buyNum == "0") {
          tempVal = "0";
        } else if (this.buyNum != "" && this.marketInfoShow.last) {
          let bigBuyNum = new BigNumber(this.buyNum);
          let bigMarketInfoShow = new BigNumber(this.marketInfoShow.last);
          tempVal = bigBuyNum
            .times(bigMarketInfoShow)
            .toNumber()
            .toFixed(this.price_precision);
        } else {
          tempVal = "0";
        }
      } else if (this.currChainValue == "2") {
        if (this.buyNum == "0") {
          tempVal = "0";
        } else if (this.buyNum != "" && this.buyPrice) {
          let bigBuyNum = new BigNumber(this.buyNum);
          let bigMarketInfoShow = new BigNumber(this.buyPrice);
          let tempValV2 = bigBuyNum.times(bigMarketInfoShow).toNumber();
          tempVal = this.ceilNumber(tempValV2, this.price_precision);
        } else {
          tempVal = "0";
        }
      }
      return tempVal;
    },
  },
  watch: {
    swap_symbol_id() {
      this.buySliderValue = 0;
      this.buyNum = "";
      this.buyPrice = "";
      this.buyAmount = "";
      this.sellSliderValue = 0;
      this.sellPrice = "";
      this.sellNum = "";
      this.number_precision = this.coinInfoMap[
        this.swap_symbol_id
      ].number_precision;
      this.price_precision = this.coinInfoMap[
        this.swap_symbol_id
      ].price_precision;
    },
  },
  data() {
    return {
      coinInfoMap: {},
      apiAssetsInfo: {},
      currChainValue: "1",
      sellTransactionAmountSelf: "",
      sellTransactionAmount: "",
      buyTransactionAmount: "",
      buyTransactionAmountSelf: "",
      buySliderValue: 0,
      sellSliderValue: 0,
      marketPrice: "",
      buyPrice: "",
      sellPrice: "",
      buyNum: "",
      buyAmount: "", //
      sellNum: "",
      buyTriggerPrice: "",
      sellTriggerPrice: "",
      number_precision: "",
      price_precision: "",
      // from_item_id: "",
      // to_item_id: "",
      marks: {
        25: "25%",
        50: "50%",
        75: "75%",
      },
      coinInfoList: [],
      tabsOptions: [
        {
          label: "市价",
          value: 1,
        },
        {
          label: "限价",
          value: 2,
        },
        // {
        //   label: "止盈止损",
        // },
      ],
      inputControl: false,
      buyBtnLoading: false,
      sellBtnLoading: false,
      apiAssetsBtn: false,
    };
  },
  async created() {
    await this.getStaticTableInfo(55);
    await this.getAssets();
    Dispatcher.addEventListener("onPriceClick", this.onPriceClick, this);
  },
  methods: {
    routeSplicing,
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all",
      };
      const { status, data } = await apiGetAssets(params);
      if (status == 200) {
        this.apiAssetsBtn = true;
        this.apiAssetsInfo = data;
        setTimeout(() => {
          this.apiAssetsBtn = false;
        }, 15000);
      }
    },
    onPriceClick(row) {
      if (this.currChainValue == "2") {
        this.buyPrice = row[0];
        this.onBuyNumChange();
        this.sellPrice = row[0];
        this.onSellPriceChange();
      }
    },
    // 买入滑块变化
    onBuySliderChange(value) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));

      if (this.currChainValue == "1") {
        if (value && !this.inputControl) {
          // 买入交易额
          this.buyAmount = ((value / 100) * this.assetsInfoShow).toFixed(
            this.price_precision
          );
          this.buyTransactionAmount = (
            ((value / 100) * this.assetsInfoShow) /
            this.marketPrice
          ).toFixed(this.number_precision);
        } else if (value == 0 && !this.inputControl) {
          this.buyAmount = "";
          this.buyTransactionAmount = "";
        }
      } else if (this.currChainValue == "2") {
        if (this.buyPrice && !this.inputControl) {
          this.buyTransactionAmountSelf = (
            (value / 100) *
            this.assetsInfoShow
          ).toFixed(this.price_precision);
          this.buyNum = (
            ((value / 100) * this.assetsInfoShow) /
            this.buyPrice
          ).toFixed(this.number_precision);
        }
        if (value == 0 && !this.inputControl) {
          this.buyNum = "";
          this.buyTransactionAmountSelf = "";
        }
      }
    },
    // 卖出滑块变化
    onSellSliderChange(value) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      let tempMarketPrice = new BigNumber(this.marketPrice);
      if (value == 0) {
        this.sellNum = "";
        this.sellTransactionAmount = "";
        this.sellTransactionAmountSelf = "";
        return;
      }

      if (this.currChainValue == "1") {
        if (value && !this.inputControl) {
          if (value == 100) {
            this.sellNum = this.assetsInfoShowSell
              .toString()
              .substring(
                0,
                this.assetsInfoShowSell.toString().indexOf(".") +
                  this.number_precision +
                  1
              );
          } else {
            this.sellNum = (value * 0.01 * this.assetsInfoShowSell).toFixed(
              this.number_precision
            );
          }

          if (Number(this.sellNum) > Number(this.assetsInfoShowSell)) {
            this.sellNum = this.assetsInfoShowSell;
          }
          let tempSellNum = new BigNumber(this.sellNum);
          let tempValue3 = tempSellNum.times(tempMarketPrice).toNumber();
          if (tempValue3.toString().indexOf(".") > 0) {
            this.sellTransactionAmount = tempValue3
              .toString()
              .substring(
                0,
                tempValue3.toString().indexOf(".") + this.price_precision + 1
              );
          } else {
            this.sellTransactionAmount = tempValue3;
          }
        } else if (value == 0 && !this.inputControl) {
          this.sellNum = "";
          this.sellTransactionAmount = "";
        }
      } else if (this.currChainValue == "2") {
        if (!this.inputControl) {
          if (value == 100) {
            this.sellNum = this.assetsInfoShowSell
              .toString()
              .substring(
                0,
                this.assetsInfoShowSell.toString().indexOf(".") +
                  this.number_precision +
                  1
              );
          } else {
            this.sellNum = ((value / 100) * this.assetsInfoShowSell).toFixed(
              this.number_precision
            );
          }
          if (Number(this.sellNum) > Number(this.assetsInfoShowSell)) {
            this.sellNum = this.assetsInfoShowSell;
          }
          let tempSellNum = new BigNumber(this.sellNum);
          let tempSellPrice = new BigNumber(this.sellPrice);

          let tempValue3 = tempSellNum.times(tempSellPrice);
          if (this.sellPrice) {
            if (tempValue3.toFixed(this.price_precision).indexOf(".") > 0) {
              this.sellTransactionAmountSelf = tempValue3
                .toFixed(this.price_precision)
                .substring(
                  0,
                  tempValue3.toString().indexOf(".") + this.price_precision + 1
                );
            } else {
              this.sellTransactionAmountSelf = tempValue3.toFixed(
                this.price_precision
              );
            }
          }
        } else {
          // this.sellNum = "";
          // this.sellTransactionAmountSelf = "";
        }
      }
    },
    // 市价交易额变化
    onBuyAmountChange(val) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      this.inputControl = true;
      let tempInput = new BigNumber(val);
      let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow);
      let tempVal = tempInput.div(tempAssetsInfoShow).toNumber();
      let tempVal2 = JSON.parse(JSON.stringify(tempVal)) * 100;
      if (tempVal2 > 100) {
        tempVal2 = 100;
      }
      this.buySliderValue = Number(tempVal2.toFixed(0));

      let tempValV3 = "0";
      if (this.buyAmount == "0") {
        tempValV3 = "0";
      } else if (this.buyAmount != "" && this.marketInfoShow.last) {
        let bigBuyNum = new BigNumber(this.buyAmount);
        let bigMarketInfoShow = new BigNumber(this.marketInfoShow.last);
        tempValV3 = bigBuyNum
          .div(bigMarketInfoShow)
          .toNumber()
          .toFixed(this.number_precision);
      } else {
        tempValV3 = "0";
      }

      this.buyTransactionAmount = tempValV3;

      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 限价买入量变化
    onBuyNumChange(val) {
      //买入金额6 buyTransactionAmountSelf
      // 买入价2  buyPrice
      if (!this.buyPrice || !this.buyNum) {
        this.buySliderValue = 0;
        this.buyTransactionAmountSelf = "";
        return;
      }

      this.inputControl = true;
      let tempInput = new BigNumber(this.buyNum);
      let tempPrice = new BigNumber(this.buyPrice);
      let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow);
      let tempR = tempInput.times(tempPrice);

      let tempAmout = tempR.toNumber();

      this.buyTransactionAmountSelf = this.ceilNumber(
        tempAmout,
        this.price_precision
      );

      let tempValue = (tempR.toNumber() / tempAssetsInfoShow) * 100;
      this.buySliderValue = Number(tempValue.toFixed(2));
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 限价卖出量变化
    onSellNumChange(val) {
      if (!val) {
        this.inputControl = true;
        this.sellSliderValue = 0;
        this.sellTransactionAmount = "";
        this.sellTransactionAmountSelf = "";
        return;
      }

      //卖出金额10 sellTransactionAmount
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      let tempMarketPrice = new BigNumber(this.marketPrice);
      let tempInput = new BigNumber(val);
      if (this.userInfo) {
        this.inputControl = true;
        let tempAssetsInfoShow = new BigNumber(this.assetsInfoShowSell);
        let tempValue = tempInput.div(tempAssetsInfoShow);
        this.sellSliderValue = Number((tempValue.toNumber() * 100).toFixed(0));
      }
      if (this.currChainValue == "1") {
        let tempValue2 = tempInput.times(tempMarketPrice).toNumber();

        if (tempValue2.toString().indexOf(".") > 0) {
          this.sellTransactionAmount = tempValue2
            .toString()
            .substring(
              0,
              tempValue2.toString().indexOf(".") + this.price_precision + 1
            );
        } else {
          this.sellTransactionAmount = tempValue2;
        }
      } else {
        if (this.sellPrice) {
          let tempSellPrice = new BigNumber(this.sellPrice);
          let tempValue3 = tempInput.times(tempSellPrice);
          if (tempValue3.toString().indexOf(".") > 0) {
            this.sellTransactionAmountSelf = tempValue3
              .toFixed(this.price_precision)
              .substring(
                0,
                tempValue3.toFixed(this.price_precision).indexOf(".") +
                  this.price_precision +
                  1
              );
          } else {
            this.sellTransactionAmountSelf = tempValue3.toFixed(
              this.price_precision
            );
          }
        }
      }
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    onSellPriceChange() {
      // 卖出价格 sellPrice   卖出数量 sellNum  卖出金额 sellTransactionAmountSelf
      if (this.sellPrice && this.sellNum) {
        let tempValue = this.sellPrice * this.sellNum;
        if (tempValue.toString().indexOf(".") > 0) {
          this.sellTransactionAmountSelf = tempValue
            .toString()
            .substring(
              0,
              tempValue.toString().indexOf(".") + this.price_precision + 1
            );
        } else {
          this.sellTransactionAmountSelf = tempValue;
        }
      }
    },
    // 市价买入数量变化
    onBuyTotalChange(val) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      this.inputControl = true;

      // 买入 预计开仓数量
      let tempValV2 = "0";

      if (this.buyTransactionAmount == "0" || this.buyTransactionAmount == "") {
        tempValV2 = "0";
        this.buyAmount = "";
      } else if (this.buyTransactionAmount != "" && this.marketInfoShow.last) {
        let bigBuyAmount = new BigNumber(this.buyTransactionAmount);
        let bigMarketInfoShow = new BigNumber(
          JSON.parse(JSON.stringify(this.marketPrice))
        );
        tempValV2 = bigBuyAmount.times(bigMarketInfoShow).toNumber();
        this.buyAmount = tempValV2.toFixed(this.price_precision);
      } else {
        tempValV2 = "0";
        this.buyAmount = "";
      }

      let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow);
      let tempBuyAmount = new BigNumber(this.buyAmount);
      let tempVal = tempBuyAmount.div(tempAssetsInfoShow).toNumber();
      let tempVal2 = JSON.parse(JSON.stringify(tempVal)) * 100;
      if (tempVal2 > 100) {
        tempVal2 = 100;
      }
      this.buySliderValue = Number(tempVal2.toFixed(2));

      // 买入滑块
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    onBuyNumChangeSelf() {
      // 限价买入价格2 buyPrice
      //限价买入金额6 buyTransactionAmountSelf
      let tempValV3 = "0";
      this.inputControl = true;
      let bigBuyNum = new BigNumber(this.buyTransactionAmountSelf);
      if (this.buyTransactionAmountSelf == "0") {
        tempValV3 = "0";
      } else if (this.buyTransactionAmountSelf != "" && this.buyPrice) {
        let bigBuyPrice = new BigNumber(this.buyPrice);
        let tempValV4 = bigBuyNum.div(bigBuyPrice).toNumber();
        tempValV3 = this.ceilNumber(tempValV4, this.number_precision);
      } else {
        tempValV3 = "0";
      }
      let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow);
      let tempVal = bigBuyNum.div(tempAssetsInfoShow).toNumber();
      let tempVal2 = JSON.parse(JSON.stringify(tempVal)) * 100;
      if (tempVal2 > 100) {
        tempVal2 = 100;
      }
      this.buySliderValue = Number(tempVal2.toFixed(2));
      this.buyNum = tempValV3;

      // 买入滑块
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    onSellTransactionAmountChange(val) {
      // 卖出数量9 sellNum
      //卖出金额10 sellTransactionAmount
      //可用数量 assetsInfoShowSell
      let tempVal = "0";
      this.inputControl = true;
      if (this.sellTransactionAmount == "" || this.sellTransactionAmount == 0) {
        tempVal = "0";
      } else {
        let tempPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));

        let bigMarketInfoShow = new BigNumber(tempPrice);
        let tempSellTransactionAmount = new BigNumber(
          this.sellTransactionAmount
        );
        let tempAssetsInfoShow = new BigNumber(this.assetsInfoShowSell);
        tempVal = tempSellTransactionAmount.div(bigMarketInfoShow).toNumber();
        let tempVal2 = tempSellTransactionAmount
          .div(bigMarketInfoShow)
          .div(tempAssetsInfoShow);

        this.sellNum = tempVal
          .toString()
          .substring(
            0,
            tempVal.toString().indexOf(".") + this.number_precision + 1
          );
        this.sellSliderValue = Number((tempVal2 * 100).toFixed(2));
      }

      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    onSellAmoutSelfChange(val) {
      let tempVal = "0";
      this.inputControl = true;
      if (
        this.sellTransactionAmountSelf == "" ||
        this.sellTransactionAmountSelf == 0 ||
        this.sellPrice == "" ||
        this.sellPrice == 0
      ) {
        tempVal = "0";
      } else {
        let tempPrice = JSON.parse(JSON.stringify(this.sellPrice));

        let bigMarketInfoShow = new BigNumber(tempPrice);
        let tempSellTransactionAmount = new BigNumber(
          this.sellTransactionAmountSelf
        );
        let tempAssetsInfoShow = new BigNumber(this.assetsInfoShowSell);
        tempVal = tempSellTransactionAmount.div(bigMarketInfoShow).toNumber();
        let tempVal2 = tempSellTransactionAmount
          .div(bigMarketInfoShow)
          .div(tempAssetsInfoShow);

        this.sellNum = tempVal
          .toString()
          .substring(
            0,
            tempVal.toString().indexOf(".") + this.number_precision + 1
          );
        this.sellSliderValue = Number((tempVal2 * 100).toFixed(2));
      }
    },
    formatTooltip(val) {
      return val + "%";
    },

    onTrade(type) {
      // 1买入 2卖出
      if (type == 1) {
        if (this.currChainValue == "1") {
          if (this.buyAmount == "" || Number(this.buyAmount == 0)) {
            showMessage({
              message: this.translateTitle("请输入交易额"),
              type: "error",
            });
            return;
          }
          if (Number(this.buyAmount) > Number(this.assetsInfoShow)) {
            showMessage({
              message: this.translateTitle("可用余额不足"),
              type: "error",
            });
            return;
          }
          this.buyBtnLoading = true;
          let params = {
            symbol_id: this.swap_symbol_id,
            side: "buy",
            trust_type: "1", // 1-普通|2-立即取消
            assets_type: "1", // 1-币币|2-杠杆|3-杠杆爆仓
            type: "2", //  1-限价单|2-市价单
            total: this.buyAmount, // 购买数量
          };
          apiTrustStore(params).then(res => {
            if (res.status == 200) {
              showMessage({
                message: this.translateTitle("下单成功"),
                type: "success",
              });
              this.buyBtnLoading = false;
              this.reSetData();
              this.getAssets();
              this.$emit("on-trade-success", "2");
            } else {
              this.buyBtnLoading = false;
            }
          });
        } else if (this.currChainValue == "2") {
          if (this.buyPrice == "" || Number(this.buyPrice == 0)) {
            showMessage({
              message: this.translateTitle("请输入买入价格"),
              type: "error",
            });
            return;
          }
          if (this.buyNum == "" || Number(this.buyNum == 0)) {
            showMessage({
              message: this.translateTitle("请输入买入量"),
              type: "error",
            });
            return;
          }
          if (
            Number(this.buyTransactionAmountSelf) > Number(this.assetsInfoShow)
          ) {
            showMessage({
              message: this.translateTitle("可用余额不足"),
              type: "error",
            });
            return;
          }

          this.buyBtnLoading = true;
          let params = {
            symbol_id: this.swap_symbol_id,
            side: "buy",
            trust_type: "1", // 1-普通|2-立即取消
            assets_type: "1", // 1-币币|2-杠杆|3-杠杆爆仓
            type: "1", //  1-限价单|2-市价单
            num: this.buyNum, // 购买数量
            price: this.buyPrice,
          };
          apiTrustStore(params).then(res => {
            if (res.status == 200) {
              showMessage({
                message: this.translateTitle("下单成功"),
                type: "success",
              });

              this.buyBtnLoading = false;
              this.getAssets();
              this.reSetData();
              this.$emit("on-trade-success", "1");
            } else {
              this.buyBtnLoading = false;
            }
          });
        }
      } else {
        // 2 卖出

        if (this.currChainValue == "1") {
          if (this.sellNum == "" || Number(this.sellNum == 0)) {
            showMessage({
              message: this.translateTitle("请输入卖出量"),
              type: "error",
            });
            return;
          }

          if (Number(this.sellNum) > Number(this.assetsInfoShowSell)) {
            showMessage({
              message: this.translateTitle("可用余额不足"),
              type: "error",
            });
            return;
          }

          this.sellBtnLoading = true;
          let params = {
            symbol_id: this.swap_symbol_id,
            side: "sell",
            trust_type: "1", // 1-普通|2-立即取消
            assets_type: "1", // 1-币币|2-杠杆|3-杠杆爆仓
            type: "2", //  1-限价单|2-市价单
            num: this.sellNum, // 购买数量
          };
          apiTrustStore(params).then(res => {
            if (res.status == 200) {
              showMessage({
                message: this.translateTitle("下单成功"),
                type: "success",
              });
              this.reSetData();
              this.getAssets();
              this.$emit("on-trade-success", "2");
            } else {
              this.sellBtnLoading = false;
            }
            this.sellBtnLoading = false;
          });
        } else if (this.currChainValue == "2") {
          if (this.sellPrice == "" || Number(this.sellPrice == 0)) {
            showMessage({
              message: this.translateTitle("请输入卖出价"),
              type: "error",
            });
            return;
          }
          if (this.sellNum == "" || Number(this.sellNum == 0)) {
            showMessage({
              message: this.translateTitle("请输入卖出量"),
              type: "error",
            });
            return;
          }

          if (Number(this.sellNum) > Number(this.assetsInfoShowSell)) {
            showMessage({
              message: this.translateTitle("可用余额不足"),
              type: "error",
            });
            return;
          }

          this.sellBtnLoading = true;
          let params = {
            symbol_id: this.swap_symbol_id,
            side: "sell",
            trust_type: "1", // 1-普通|2-立即取消
            assets_type: "1", // 1-币币|2-杠杆|3-杠杆爆仓
            type: "1", //  1-限价单|2-市价单
            num: this.sellNum, // 购买数量
            price: this.sellPrice,
          };
          apiTrustStore(params).then(res => {
            if (res.status == 200) {
              showMessage({
                message: this.translateTitle("下单成功"),
                type: "success",
              });
              this.sellBtnLoading = false;
              this.reSetData();
              this.getAssets();
              this.$emit("on-trade-success", "1");
            } else {
              this.sellBtnLoading = false;
            }
            this.sellBtnLoading = false;
          });
        }
      }
    },
    goLogin(type) {
      if (type == 1) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: { redirect: this.$route.fullPath },
        });
      } else {
        this.$router.push(this.routeSplicing(this.language, "register"));
      }
    },
    onChainChange(value) {
      this.inputControl = true;
      this.sellSliderValue = 0;
      this.buySliderValue = 0;
      this.currChainValue = value;
      this.buyNum = "";
      this.buyPrice = "";
      this.buyAmount = "";
      this.sellPrice = "";
      this.sellNum = "";
      this.sellTransactionAmountSelf = "";
      this.sellTransactionAmount = "";
      this.buyTransactionAmount = "";
      this.buyTransactionAmountSelf = "";
      setTimeout(() => {
        this.inputControl = false;
      }, 300);
    },
    reSetData() {
      this.inputControl = true;
      this.sellSliderValue = 0;
      this.buySliderValue = 0;
      this.buyNum = "";
      this.buyPrice = "";
      this.buyAmount = "";
      this.sellPrice = "";
      this.sellNum = "";
      this.sellTransactionAmountSelf = "";
      this.sellTransactionAmount = "";
      this.buyTransactionAmount = "";
      this.buyTransactionAmountSelf = "";
      setTimeout(() => {
        this.inputControl = false;
      }, 300);
    },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.coinInfoMap = JSON.parse(JSON.stringify(data));
        if (this.swap_symbol_id) {
          this.number_precision = this.coinInfoMap[
            this.swap_symbol_id
          ].number_precision;
          this.price_precision = this.coinInfoMap[
            this.swap_symbol_id
          ].price_precision;
        }
        // this.coinInfoList = JSON.parse(JSON.stringify(data));

        // for (const key in this.coinInfoList) {
        //   if (this.coinInfoList[key].swap_symbol_id == this.swap_symbol_id) {
        //     this.from_item_id = this.coinInfoList[key].from_item_id;
        //     this.to_item_id = this.coinInfoList[key].to_item_id;
        //   }
        // }
      }
    },
  },
};
</script>
<style lang="scss">
.component-trade {
  // z-index: 2001;
  background-color: #ffffff;
  margin-top: 8px;
  user-select: none;
  // height: 340px;
  position: relative;
  .trade-header {
    display: flex;
    height: 32px;
    line-height: 32px;
    padding-left: 15px;
    background-color: #f5f5f7;
    font-size: 14px;
    font-weight: 600;
    color: #1f2533;
  }
  .trade-content {
    display: flex;
    justify-content: space-evenly;
    height: 300px;
    .left-item,
    .right-item {
      width: 50%;
      // margin: 10px 15px;
      .item-header {
        font-size: 12px;
        color: #bec1c8;
        height: 26px;
        &.V2 {
          height: 8px;
        }
      }
    }
    .left-item {
      padding: 10px 15px;
      border-right: 1px solid #f1f2f5;
      .el-slider__bar {
        background-color: #03ad79;
      }
      .el-slider__button {
        border: 2px solid #03ad79;
      }
      .el-slider {
        .disabled {
          .el-slider__button {
            border-color: #c0c4cc;
          }
        }
      }
    }
    .right-item {
      padding: 10px 15px;
      .el-slider__bar {
        background-color: #c61b3d;
      }
      .el-slider__button {
        border: 2px solid #c61b3d;
      }
      .el-slider {
        .disabled {
          .el-slider__button {
            border-color: #c0c4cc;
          }
        }
      }
    }
    .trade-item {
      .buy-amount-self {
        margin-top: 30px !important;
        margin-bottom: 15px;
      }
      .trade-type {
        display: flex;
        justify-content: flex-start;
        .item-tabs {
          box-sizing: border-box;
          font-size: 14px;
          display: inline-block;
          margin-right: 10px;
          width: 31%;
          height: 28px;
          border-radius: 2px;
          color: #354052;
          line-height: 28px;
          text-align: center;
          border: solid 1px #dfdfdf;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            color: #3a7dff;
            // background-color: rgba(39, 198, 131, 0.1);
            border: solid 1px #3a7dff;
          }
        }
      }

      .buy-price,
      .sell-price {
        margin-top: 8px;
        .el-input__inner::-moz-placeholder {
          color: #1f2533 !important;
          font-size: 14px;
          font-weight: 400 !important;
        }

        .el-input__inner::placeholder {
          color: #1f2533 !important;
          font-size: 14px;
          font-weight: 400 !important;
        }
        .el-input__inner::-webkit-input-placeholder {
          color: #1f2533 !important;
          font-size: 14px;
          font-weight: 400 !important;
        }
        .el-input__inner {
          height: 32px;
          line-height: 32px;
          background: #f1f2f5;
          padding-left: 50px;
          padding-right: 60px;
          text-align: right;
          color: #354052;
          border-radius: 2px;
          border: none;
          &:hover {
            border: none !important;
            background: #ebeced !important;
          }
          &:active {
            border: solid 1px #3a7dff !important;
            background-color: #f5f5f6 !important;
          }
          &:focus {
            border: solid 1px #3a7dff !important;
            background-color: #f5f5f6 !important;
          }
        }
        .el-input__prefix,
        .el-input__suffix {
          line-height: 32px;
        }
      }
      .market-price {
        .el-input__inner {
          &:hover {
            border: none !important;
            background: #f1f2f5 !important;
          }
        }
      }
      .block {
        .el-slider {
          height: 28px;
          padding: 0 5px;
        }
      }
      .trade-amount {
        font-size: 12px;
        color: #7f8490;
        height: 26px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .b-color {
          color: #354052;
          font-weight: 500;
        }
      }
      .trade-btn {
        // position: absolute;
        // bottom: 10px;
        .btn {
          cursor: pointer;
          vertical-align: middle;
          text-align: center;
          font-size: 14px;
          display: inline-block;
          width: 130px;
          // line-height: 36px;
          color: white;
          box-sizing: border-box;
          width: 100%;
          height: 36px;
          padding: 0;
          border-radius: 2px;
          border: none;
          &.trade-buy {
            background-color: #03ad79;
          }
          &.trade-sell {
            background-color: #c61b3d;
          }
          &.login {
            line-height: 36px;
            cursor: auto;
            background-color: #fff;
            border: 1px solid #e2e2e4;
            .or-text {
              color: #7f8490;
            }
            .common-operate {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>
