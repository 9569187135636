<template>
  <div>
    <!-- <MaskHomeContent v-if="isMask"></MaskHomeContent> -->
    <!-- <HomeContent v-else></HomeContent> -->
    <Aboutus8v v-if="is8v"></Aboutus8v>
  </div>
</template>

<script>
import Aboutus8v from "./aboutus-8v.vue";
import { VUE_APP_ID, VUE_UNION_STATION } from "@/assets/js/stationSetting";
export default {
  components: {
    Aboutus8v
  },

  data() {
    return {
      VUE_APP_ID,
      is8v: false
    };
  },
  created() {
    if (VUE_UNION_STATION == "8V") {
      this.is8v = true;
    }
  }
};
</script>

<style></style>
