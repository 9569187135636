<template>
  <div class="bv-home-page">
    <div class="home-content-one">
      <div class="swiper-sider-content">
        <div
          class="swiper-content"
          @mouseenter="on_bot_enter"
          @mouseleave="on_bot_leave"
        >
          <swiper ref="topSwiper" class="swiper" :options="swiperOptionTop">
            <swiper-slide>
              <div class="index-top-swiper1">
                <video muted="true" loop="" autoplay="autoplay" class="bgvideo">
                  <source
                    type="video/mp4"
                    src="../../assets/img/bv/night-banner.mp4"
                  />
                </video>
                <div class="banner-c">
                  <img src="../../assets/img/bv/banner-logo.png" class="cm" />
                  <h1 class="ct">
                    {{ translateTitle("全球首家元宇宙生态数字资产交易所") }}
                  </h1>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="index-top-swiper-o">
                <div class="index-top-swiper">
                  <div class="content">
                    <h2 class="title">
                      {{ translateTitle("安心信赖全球领先社群交易平台") }}
                    </h2>
                    <p class="subtitle">
                      {{ translateTitle("流动深度、稳定安全、高收益率") }}
                    </p>
                    <div class="ml">
                      <img
                        src="../../assets/img/bv/banner11.png"
                        class="m"
                        alt="asset exchange icon"
                      />
                      <img
                        src="../../assets/img/bv/banner12.png"
                        class="m"
                        alt="asset security icon"
                      />
                      <img
                        src="../../assets/img/bv/banner13.png"
                        class="m"
                        alt="asset growth icon"
                      />
                    </div>
                  </div>
                  <div class="img">
                    <img
                      src="../../assets/img//bv/banner-b.png"
                      class="i"
                      alt="decorative 8v exchange lady justia lady justice in gold with scales signifying fairness"
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <!-- <swiper-slide>Slide 3</swiper-slide> -->
            <div class="swiper-pagination" slot="pagination"></div>
            <!-- <div class="swiper-next" slot="button-next">
            <img src="../../assets/img/right-arrow-bv.png" alt="" />
          </div> -->
          </swiper>
        </div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </div>
    <div class="home-content-spot">
      <div class="l">
        <div
          class="item"
          v-for="(ele, index) in tableDataComputed6"
          :key="index"
        >
          <div class="np">
            <span class="n">{{ ele.from_symbol + "/" + ele.to_symbol }}</span
            ><span
              class="p"
              :class="Number(ele.price_change_percent) > 0 ? 'green' : 'red'"
            >
              {{ Number(ele.price_change_percent) > 0 ? "+" : "" }}
              {{ ele.price_change_percent }} %</span
            >
          </div>
          <span class="up">{{ ele.last }}</span>
          <span class="cp"
            >{{ systemCurrencyMaps[systemCurrency]
            }}{{ ele.currency[systemCurrency] }}</span
          >
        </div>
      </div>
    </div>
    <div class="home-content-two" :class="imgList.length == 0 ? '' : ''">
      <div class="swiper-sider-content">
        <div class="swiper-content">
          <div v-if="imgList.length > 4" class="swiper-prev" slot="button-prev">
            <img src="../../assets/img/left-arrow.png" alt="" />
          </div>
          <div class="old-img" v-if="imgList.length <= 4">
            <ul v-for="(item, index) in oldImgList" :key="index">
              <li>
                <span @click="jumpToUrl(item.param.url)">
                  <img :src="item.language['img_url_' + language]" alt="" />
                </span>
              </li>
            </ul>
          </div>

          <swiper
            v-else
            :options="swiperOption"
            ref="mySwiper"
            class="swiper-container"
          >
            <swiper-slide
              class="swiper-item"
              v-for="(item, index) of imgList"
              :key="index"
            >
              <img
                class="swiper-img"
                :src="item.language['img_url_' + language]"
                :data-href="
                  item.language
                    ? item.language[`url_${language}`]
                    : item.param.url
                "
                alt=""
              />
            </swiper-slide>
          </swiper>

          <div v-if="imgList.length > 4" class="swiper-next" slot="button-next">
            <img src="../../assets/img/right-arrow.png" alt="" />
          </div>
        </div>
      </div>

      <div class="tips-massage-content">
        <i class="iconfont iconxiaoxi"></i>
        <div class="left">
          <a :href="firstArticles.html_url" target="_blank">
            <span class="article-title">
              {{ firstArticles.title || "--" }}</span
            >
          </a>
        </div>
        <div class="left-driver"></div>
        <div class="bottom">
          <a :href="secondArticles.html_url" target="_blank">
            <span class="article-title">
              {{ secondArticles.title || "--" }}</span
            >
          </a>
        </div>
        <div class="right-driver"></div>
        <div @click="onClickMore" class="right">
          <span>{{ translateTitle("更多") }}</span>
          <i class="iconfont icongengduo"></i>
        </div>
      </div>

      <div class="content-two-title">
        <h2>{{ translateTitle("市场行情") }}</h2>
        <div @click="onLookMarket" class="more">
          <span class="more-title">{{ translateTitle("更多") }}</span>
        </div>
      </div>

      <div class="quotes-content">
        <el-table
          v-loading="tableLoading"
          :data="tableDataComputed"
          row-key="symbol_id"
          style="width: 100%"
          class="trade-table"
          @row-click="onClickRow"
        >
          <el-table-column
            :label="translateTitle('交易对')"
            align="left"
            min-width="140"
            prop="from_symbol"
          >
            <template slot-scope="{ row }">
              <span
                class="coin-content"
                @click="onSymbolClick(row)"
                style="cursor: pointer"
              >
                <img class="coin-img" :src="row.coin_url" alt="" />
                <span
                  class="left-symbol"
                  style="margin-left: 10px; font-size: 14px"
                  >{{ row.from_symbol }}</span
                >
                <span class="dark-color"> / {{ row.to_symbol }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('最新价')"
            align="left"
            prop="usd"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro left-row"> {{ row.last }} </span>
                <span class="dark-color DINPro"
                  >/ {{ systemCurrencyMaps[systemCurrency]
                  }}{{ row.currency[systemCurrency] }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('24H涨跌幅')"
            align="center"
            prop="info.price_change_percent"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <span
                class="left-row DINPro"
                :class="Number(row.price_change_percent) > 0 ? 'green' : 'red'"
                ><span
                  class="green"
                  v-show="Number(row.price_change_percent) > 0"
                  style="margin-right: -3px"
                >
                  + </span
                >{{ row.price_change_percent }} %</span
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('行情')"
            align="center"
            prop="info.high"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <img
                class="mrket-img"
                v-if="Number(row.price_change_percent) > 0"
                src="../../assets/img/markets/markt-up.png"
              />
              <img
                class="mrket-img"
                v-else
                src="../../assets/img/markets/markt-download.png"
              />
            </template>
          </el-table-column>

          <el-table-column
            :label="translateTitle('操作')"
            align="center"
            min-width="50"
          >
            <template slot-scope="{ row }">
              <el-button class="operate" @click="onSymbolClick(row)">{{
                translateTitle("交易")
              }}</el-button>
            </template>
          </el-table-column>
          <no-data
            slot="empty"
            v-show="!tableLoading && tableDataComputed.length"
          ></no-data>
        </el-table>
      </div>
    </div>

    <div class="home-content-four" id="downloadArea">
      <div class="content-title">
        <h2>
          {{ translateTitle("随时随地在任何平台交易") }}
        </h2>
      </div>
      <p class="content-title-v2">
        <span>
          {{ translateTitle("相容于多装置，现在就开始安全可靠的交易") }}
        </span>
      </p>
      <div class="four-content">
        <div class="right-content">
          <img src="../../assets/img/bv/bv-exchange.png" alt="" />
        </div>
        <div class="left-content">
          <!-- <p class="left-content-title"></p> -->
          <ul class="tips-details">
            <li>
              <img
                src="../../assets/img/bv/bv-success.png"
                class="icon-right"
              />
              <span>{{ translateTitle("即时查看最新市场行情") }}</span>
            </li>
            <li>
              <img
                src="../../assets/img/bv/bv-success.png"
                class="icon-right"
              />
              <span>{{
                translateTitle("便捷买卖BTC, ETH, DOGE 还有其他数字资产")
              }}</span>
            </li>
            <li>
              <img
                src="../../assets/img/bv/bv-success.png"
                class="icon-right"
              />
              <span>{{ translateTitle("取得加密货币即时价格通知") }}</span>
            </li>
            <li>
              <img
                src="../../assets/img/bv/bv-success.png"
                class="icon-right"
              />
              <span>
                {{ translateTitle("查询现货、期货及各种衍生商品报价") }}</span
              >
            </li>
            <!-- <li>
              <img
                src="../../assets/img/bv/bv-success.png"
                class="icon-right"
              />
              <span>{{ translateTitle("比较各交易所加密货币价格") }}</span>
            </li> -->
          </ul>
          <div class="download-content">
            <div class="qr-code">
              <img :src="appDownLoadImg" alt="" />
            </div>

            <div class="b-download">
              <!-- <div class="download-item">
                <a
                  :href="appstore_url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="../../assets/img/bv/bv-app-store-new.png" alt="" />
                </a>
              </div> -->
              <div class="download-item">
                <a target="_blank" rel="noopener noreferrer">
                  <img
                    v-if="language == 'zh_CN' || language == 'zh_HK'"
                    src="../../assets/img/bv/bv-app-store-new-stop.png"
                    alt=""
                  />
                  <img
                    v-else
                    src="../../assets/img/bv/bv-app-store-new-stop-en.png"
                    alt=""
                  />
                </a>
              </div>
              <div class="download-item">
                <a
                  :href="android_url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="../../assets/img/bv/bv-android-apk-new.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-content-finances">
      <div class="content-title">
        <h2>{{ translateTitle("Finances") }}</h2>
        <div @click="onLookFinances" class="more">
          <span class="more-title">{{ translateTitle("更多") }}</span>
        </div>
      </div>
      <div class="list-content">
        <div class="type-list">
          <div class="list-type">
            <div
              v-for="(item, index) in listType"
              :key="index"
              class="tab"
              :class="{ active: item.val === active }"
              @click="onTypeChange(item.val)"
            >
              <span>{{ translateTitle(item.text) }} </span>
              <i class="type-indicator"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="finances-content" v-loading="pageLoading"> -->
      <div class="finances-content-outer">
        <div class="finances-content" v-loading="pageLoading">
          <div>
            <div
              class="finances-item"
              v-for="item in dataList"
              :class="'status' + '_' + item.status"
              :key="item.product_id"
            >
              <p class="title-content">
                <img v-if="active == 4" :src="item.to_item_url" alt="" />
                <img v-else :src="item.item_url" alt="" />
                <span class="title">{{ item.product_name }}</span>
              </p>
              <div
                class="type-content"
                v-if="
                  (item.status == 1 || item.status == 2) &&
                  item.start_time != 0 &&
                  item.remain_time > 0
                "
              >
                <span class="type-1" v-if="item.status == 1">
                  {{ translateTitle("募集预热中") }}
                </span>
                <div class="type-progress" v-else>
                  <span class="quant-tag" style="margin-left: 7px">
                    <span
                      class="type-7"
                      v-if="
                        item.quant_tag != 0 &&
                        item.status > 3 &&
                        item.status != 5
                      "
                    >
                      {{ translateTitle(quantTagMaps.label[item.quant_tag]) }}
                    </span>
                    <span
                      :class="'quant-type' + '-' + item.quant_tag"
                      v-else-if="item.quant_tag != 0"
                    >
                      {{ translateTitle(quantTagMaps.label[item.quant_tag]) }}
                    </span>
                  </span>
                  <!-- v-if="remain_time" -->
                  <Countdown
                    :key="item.product_id"
                    :time="item.remain_time / 1000"
                    format="hh:mm:ss"
                    @on-end="onCountDownEnd()"
                  >
                    <template slot-scope="{ time }">
                      <span class="DINPro">{{ time }}</span>
                      {{ translateTitle("后开幕") }}
                    </template>
                  </Countdown>
                </div>
              </div>
              <div
                class="type-content"
                v-else-if="
                  (item.status == 1 || item.status == 2 || item.status == 3) &&
                  item.remain_end_time
                "
              >
                <div class="type-progress V2">
                  <span class="quant-tag" style="margin-left: 7px">
                    <span
                      class="type-7"
                      v-if="
                        item.quant_tag != 0 &&
                        item.status > 3 &&
                        item.status != 5
                      "
                    >
                      {{ translateTitle(quantTagMaps.label[item.quant_tag]) }}
                    </span>
                    <span
                      :class="'quant-type' + '-' + item.quant_tag"
                      v-else-if="item.quant_tag != 0"
                    >
                      {{ translateTitle(quantTagMaps.label[item.quant_tag]) }}
                    </span>
                  </span>
                  <Countdown
                    class="progress-count"
                    v-if="item.remain_end_time"
                    :key="item.product_id"
                    :time="item.remain_end_time / 1000"
                    format="hh:mm:ss"
                    @on-end="onCountDownEnd()"
                  >
                    <template slot-scope="{ time }">
                      <span class="DINPro">{{ time }}</span>
                      {{ translateTitle("后募集结束") }}
                    </template>
                  </Countdown>
                </div>
              </div>
              <p class="type-content" v-else>
                <span
                  class="type-7"
                  v-if="
                    item.quant_tag != 0 && item.status > 3 && item.status != 5
                  "
                >
                  {{ translateTitle(quantTagMaps.label[item.quant_tag]) }}
                </span>
                <span
                  :class="'quant-type' + '-' + item.quant_tag"
                  v-else-if="item.quant_tag != 0"
                >
                  {{ translateTitle(quantTagMaps.label[item.quant_tag]) }}
                </span>
                <span
                  class="type-3"
                  v-if="
                    item.union_info.length > 0 ||
                    (item.period == 2 &&
                      (item.status == 1 ||
                        item.status == 2 ||
                        item.status == 3))
                  "
                >
                  {{ translateTitle("随时可存") }}
                </span>
                <span
                  class="type-red common-btn"
                  v-if="
                    item.product_type != 2 &&
                    item.period != 2 &&
                    item.status == 3
                  "
                >
                  {{ translateTitle("募集中") }}
                </span>
                <span
                  :class="'type-' + `${item.status}`"
                  v-if="item.status > 3 && item.product_type == 4"
                >
                  {{
                    translateTitle(financialStatusMapsTwo.label[item.status])
                  }}
                </span>
                <span
                  :class="'type-' + `${item.status}`"
                  v-if="item.status > 3 && item.product_type != 4"
                >
                  {{ translateTitle(financialStatusMaps.label[item.status]) }}
                </span>
              </p>

              <p
                class="center-content"
                :class="item.product_type == 3 ? 'V2' : ''"
              >
                <span v-if="item.product_type == 4">
                  <span class="number-item">
                    {{ item.to_item_id_price }}
                    <span
                      style="
                        font-size: 14px;
                        display: inline-block;
                        margin-left: -10px;
                      "
                      >{{ item.item_name }}</span
                    >
                  </span>

                  <span class="text-item" style="height: 20px">
                    {{ translateTitle("价格") }}
                  </span>
                </span>
                <span v-else>
                  <span class="number-item" v-if="item.union_info.length > 0">
                    {{ annualRateComputed(item.active_annual_rate) }}%
                  </span>
                  <span class="number-item" v-else>
                    {{ annualRateComputed(item.annual_rate) }}%
                  </span>
                  <span
                    class="text-item"
                    v-if="
                      ((item.product_type == 2 || item.product_type == 3) &&
                        item.rate_type == 1) ||
                      item.product_type == 1
                    "
                  >
                    {{ translateTitle("固定年化") }}
                  </span>
                  <span v-else class="text-item">
                    {{ translateTitle("历史最高年化") }}
                  </span>
                </span>
                <span
                  class="lock-period-content"
                  v-if="
                    (item.product_type == 1 || item.product_type == 3) &&
                    item.union_info.length > 0
                  "
                >
                  <span
                    v-for="prod in item.union_info"
                    :key="prod.product_id"
                    class="lock-period"
                    @click="onLockPeriodChange(prod, item)"
                    :class="
                      item.active_lock_period_id == prod.product_id
                        ? 'active'
                        : ''
                    "
                    >{{ prod.lock_period }}{{ translateTitle("天") }}</span
                  >
                </span>
                <span
                  class="text-item V2"
                  v-else-if="
                    item.product_type != 2 &&
                    item.product_type != 4 &&
                    (item.remain_time > 0 || item.status == 1) &&
                    item.amount > 0
                  "
                >
                  {{ translateTitle("总额") }}
                  {{ Number(item.remaining) }} {{ item.item_name }}</span
                >

                <span class="text-item V2" v-else-if="item.product_type == 2">
                  <!-- {{ Number(item.min_amount) }} {{ item.item_name }}
              {{ translateTitle("起存") }} -->
                </span>
                <span
                  class="text-item V2"
                  v-else-if="item.product_type != 2 && item.amount > 0"
                >
                  <span
                    v-if="item.remaining == item.amount"
                    style="display: inline-block"
                  >
                    {{ translateTitle("总额") }}
                    {{ Number(item.remaining) }} {{ item.item_name }}
                  </span>
                  <span v-else style="display: inline-block">
                    {{ translateTitle("剩余") }}
                    {{ Number(item.remaining) }} {{ item.item_name }}
                  </span>
                  <span
                    style="display: inline-block; margin-left: 4px"
                    v-if="item.remaining == item.amount"
                    >(100%)</span
                  >
                  <span style="display: inline-block; margin-left: 4px" v-else>
                    ({{ remainingPercent(item.remaining, item.amount) }}%)
                    <!-- ({{
                  (()
                    .toString()
                    .substring(
                      0,
                      ((item.remaining * 100) / item.amount)
                        .toString()
                        .indexOf(".") +
                        2 +
                        1
                    )
                }}) -->
                  </span>
                </span>
                <span class="text-item V2" v-else-if="item.amount == 0">
                  {{ translateTitle("不限额") }}
                </span>
                <span class="text-item V2" v-else></span>

                <span
                  class="date-item"
                  v-if="item.product_type != 2 && item.remain_end_earning_day"
                >
                  {{ translateTitle("距离到期") }}
                  {{ item.remain_end_earning_day
                  }}{{ translateTitle("天") }}</span
                >
                <span class="date-item" v-else-if="item.union_info.length == 0">
                  {{ Number(item.min_amount) }} {{ item.item_name }}
                  {{ translateTitle("起存") }}
                </span>
                <span v-else style="height: 37px"></span>
                <span
                  class="common-btn disabled-btn"
                  v-if="item.status > 3 && item.status < 5"
                  @click="goToBuy(item)"
                  >{{ translateTitle("募集结束") }}</span
                >
                <span
                  class="common-btn disabled-btn"
                  v-else-if="item.status == 5 && item.product_type == 4"
                  @click="goToBuy(item)"
                  >{{ translateTitle("交付中") }}</span
                >
                <span
                  class="common-btn disabled-btn"
                  v-else-if="item.status == 5 && item.product_type != 4"
                  @click="goToBuy(item)"
                  >{{ translateTitle("计息中") }}</span
                >
                <span
                  class="common-btn disabled-btn"
                  v-else-if="item.status == 6"
                  @click="goToBuy(item)"
                  >{{ translateTitle("本息发放中") }}</span
                >
                <span
                  class="common-btn disabled-btn"
                  v-else-if="item.status == 7 && item.product_type == 4"
                  @click="goToBuy(item)"
                  >{{ translateTitle("交付完成") }}</span
                >
                <span
                  class="common-btn disabled-btn"
                  v-else-if="item.status == 7 && item.product_type != 4"
                  @click="goToBuy(item)"
                  >{{ translateTitle("本息已到账") }}</span
                >
                <span
                  class="common-btn disabled-btn"
                  v-else-if="item.status == 8"
                  @click="goToBuy(item)"
                  >{{ translateTitle("募集失败") }}</span
                >

                <span class="common-btn btn" v-else @click="goToBuy(item)">
                  {{ translateTitle("存入") }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-content-faq">
      <h2 class="content-title">
        {{ translateTitle("常见问题") }}
      </h2>
      <div class="faq-content">
        <a :href="faq1" target="_blank" class="pic1">
          <div class="c">
            <span class="t">{{ translateTitle("现货交易新手指引") }}</span>
            <span class="more"
              >{{ translateTitle("更多") }}
              <i class="iconfont icongengduo i"></i
            ></span>
          </div>
        </a>
        <a :href="faq2" target="_blank" class="pic2">
          <div class="c">
            <span class="t">{{ translateTitle("8V理财产品介绍") }}</span>
            <!-- <span class="te">{{ translateTitle("介绍") }}</span> -->
            <span class="more"
              >{{ translateTitle("更多") }}
              <i class="iconfont icongengduo i"></i
            ></span>
          </div>
        </a>
        <a :href="faq3" target="_blank" class="pic3">
          <div class="c">
            <span class="t">{{ translateTitle("如何提高账户安全性") }}</span>
            <!-- <span class="te">{{ translateTitle("交易规则") }}</span> -->
            <span class="more"
              >{{ translateTitle("更多") }}
              <i class="iconfont icongengduo i"></i
            ></span>
          </div>
        </a>
      </div>
      <div class="faq-content-tips">
        <a :href="faq4" target="_blank" class="it">
          <img src="../../assets/img/bv/tips.png" class="i" /><span class="t">{{
            translateTitle("如何注册8V账户")
          }}</span>
        </a>
        <a :href="faq5" target="_blank" class="it">
          <img src="../../assets/img/bv/tips.png" class="i" /><span class="t">{{
            translateTitle("如何使用8V在线客服系统")
          }}</span>
        </a>
        <a :href="faq6" target="_blank" class="it">
          <img src="../../assets/img/bv/tips.png" class="i" /><span class="t">{{
            translateTitle("充值/收款数字货币")
          }}</span>
        </a>
      </div>

      <div
        @click="goAboutUs()"
        class="faq-content-aboutus"
        v-if="language != 'zh_CN' && language != 'zh_HK'"
      >
        <video
          muted="true"
          loop=""
          autoplay="autoplay"
          class="bgvideo"
          alt="8V Your global digital asset exchange embracing the metaverse"
        >
          <source
            type="video/mp4"
            src="../../assets/img/bv/to_About_us_En.mp4"
          />
        </video>
      </div>
      <div
        @click="goAboutUs()"
        class="faq-content-aboutus"
        v-if="language == 'zh_CN'"
      >
        <video
          muted="true"
          loop=""
          autoplay="autoplay"
          class="bgvideo"
          alt="8V Your global digital asset exchange embracing the metaverse"
        >
          <source
            type="video/mp4"
            src="../../assets/img/bv/to_About_us_Cn.mp4"
          />
        </video>
      </div>
      <div
        @click="goAboutUs()"
        class="faq-content-aboutus"
        v-if="language == 'zh_HK'"
      >
        <video
          muted="true"
          loop=""
          autoplay="autoplay"
          class="bgvideo"
          alt="8V Your global digital asset exchange embracing the metaverse"
        >
          <source
            type="video/mp4"
            src="../../assets/img/bv/to_About_us_Cn_T.mp4"
          />
        </video>
      </div>
    </div>
    <div class="home-content-join">
      <div class="content-title">
        {{ translateTitle("加入我们") }}
      </div>
      <div
        class="join-content"
        v-if="language != 'zh_CN' && language != 'zh_HK'"
      >
        <a
          v-if="language != 'tr'"
          href="https://t.me/global8vchat"
          target="_blank"
          class="it"
          @click="joinActive('tg')"
          @mouseenter="joinActive('tg')"
        >
          <img
            src="../../assets/img/bv/join-us/tg.png"
            class="i"
            v-if="!flag || flag != 'tg'"
          />
          <img src="../../assets/img/bv/join-us/tg-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 'tg' ? 'on' : ''"
            >Telegram</span
          >
        </a>
        <a
          v-else
          href="https://t.me/Turkiye8V"
          target="_blank"
          class="it"
          @click="joinActive('tg')"
          @mouseenter="joinActive('tg')"
        >
          <img
            src="../../assets/img/bv/join-us/tg.png"
            class="i"
            v-if="!flag || flag != 'tg'"
          />
          <img src="../../assets/img/bv/join-us/tg-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 'tg' ? 'on' : ''"
            >Telegram</span
          >
        </a>
        <a
          href="https://www.facebook.com/8V.Global/"
          @click="joinActive('fb')"
          @mouseenter="joinActive('fb')"
          target="_blank"
          class="it"
        >
          <img
            src="../../assets/img/bv/join-us/fb.png"
            class="i"
            v-if="!flag || flag != 'fb'"
          />
          <img src="../../assets/img/bv/join-us/fb-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 'fb' ? 'on' : ''"
            >Facebook</span
          >
        </a>
        <a
          href="https://twitter.com/8vGlobal"
          target="_blank"
          class="it"
          @click="joinActive('tt')"
          @mouseenter="joinActive('tt')"
        >
          <img
            src="../../assets/img/bv/join-us/tt.png"
            class="i"
            v-if="!flag || flag != 'tt'"
          />
          <img src="../../assets/img/bv/join-us/tt-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 'tt' ? 'on' : ''"
            >Twitter</span
          >
        </a>
        <a
          href="https://www.instagram.com/8v.global/"
          @click="joinActive('in')"
          @mouseenter="joinActive('in')"
          target="_blank"
          class="it"
        >
          <img
            src="../../assets/img/bv/join-us/in.png"
            class="i"
            v-if="!flag || flag != 'in'"
          />
          <img src="../../assets/img/bv/join-us/in-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 'in' ? 'on' : ''"
            >Instagram</span
          >
        </a>
        <a
          href="https://medium.com/@8vofficial"
          target="_blank"
          class="it"
          @click="joinActive('me')"
          @mouseenter="joinActive('me')"
        >
          <img
            src="../../assets/img/bv/join-us/me.png"
            class="i"
            v-if="!flag || flag != 'me'"
          />
          <img src="../../assets/img/bv/join-us/me-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 'me' ? 'on' : ''"
            >Medium</span
          >
        </a>
        <a
          href="https://discord.gg/yQusCKf3tN"
          @click="joinActive('di')"
          @mouseenter="joinActive('di')"
          target="_blank"
          class="it"
        >
          <img
            src="../../assets/img/bv/join-us/di.png"
            class="i"
            v-if="!flag || flag != 'di'"
          />
          <img src="../../assets/img/bv/join-us/di-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 'di' ? 'on' : ''"
            >Discord</span
          >
        </a>
        <!-- <a
          href="https://www.reddit.com/user/8V_Global"
          target="_blank"
          class="it"
        > -->
        <a
          href="https://www.reddit.com/r/8Voffical_group/"
          @click="joinActive('re')"
          @mouseenter="joinActive('re')"
          target="_blank"
          class="it"
        >
          <img
            src="../../assets/img/bv/join-us/re.png"
            class="i"
            v-if="!flag || flag != 're'"
          />
          <img src="../../assets/img/bv/join-us/re-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 're' ? 'on' : ''"
            >Reddit</span
          >
        </a>
        <!-- <a
          href="https://www.instagram.com/8v.global/"
          target="_blank" class="it">
          <img src="../../assets/img/bv/join-us/yt.png" class="i" />
          <span class="t">Youtube</span>
        </a> -->

        <!-- <div class="it">
          <img src="../../assets/img/bv/join-us/ti.png" class="i" />
          <span class="t">Tik Tok</span>
        </div> -->
      </div>

      <div
        class="join-content"
        v-if="language == 'zh_CN' || language == 'zh_HK'"
      >
        <a
          href="https://t.me/official8V_chat"
          @click="joinActive('tg')"
          @mouseenter="joinActive('tg')"
          target="_blank"
          class="it"
        >
          <img
            src="../../assets/img/bv/join-us/tg.png"
            class="i"
            v-if="!flag || flag != 'tg'"
          />
          <img src="../../assets/img/bv/join-us/tg-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 'tg' ? 'on' : ''"
            >Telegram</span
          >
        </a>
        <a
          href="https://t.me/official8V_group"
          @click="joinActive('tg1')"
          @mouseenter="joinActive('tg1')"
          target="_blank"
          class="it"
        >
          <img
            src="../../assets/img/bv/join-us/tg.png"
            class="i"
            v-if="!flag || flag != 'tg1'"
          />
          <img src="../../assets/img/bv/join-us/tg-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 'tg1' ? 'on' : ''"
            >Telegram{{ translateTitle("交流群") }}</span
          >
        </a>
        <a
          href="https://www.facebook.com/8V.Asia"
          @click="joinActive('fb')"
          @mouseenter="joinActive('fb')"
          target="_blank"
          class="it"
        >
          <img
            src="../../assets/img/bv/join-us/fb.png"
            class="i"
            v-if="!flag || flag != 'fb'"
          />
          <img src="../../assets/img/bv/join-us/fb-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 'fb' ? 'on' : ''"
            >Facebook</span
          >
        </a>
        <!-- <a href="https://twitter.com/8vGlobal" target="_blank" class="it">
          <img src="../../assets/img/bv/join-us/tt.png" class="i" />
          <span class="t">Twitter</span>
        </a> -->
        <a
          href="https://www.instagram.com/8v.asia/"
          @click="joinActive('in')"
          @mouseenter="joinActive('in')"
          target="_blank"
          class="it"
        >
          <img
            src="../../assets/img/bv/join-us/in.png"
            class="i"
            v-if="!flag || flag != 'in'"
          />
          <img src="../../assets/img/bv/join-us/in-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 'in' ? 'on' : ''"
            >Instagram</span
          >
        </a>
        <a
          href="https://www.youtube.com/channel/UCiB2p7qbFcGMII1_1YKUOYQ"
          @click="joinActive('yt')"
          @mouseenter="joinActive('yt')"
          target="_blank"
          class="it"
        >
          <img
            src="../../assets/img/bv/join-us/yt.png"
            class="i"
            v-if="!flag || flag != 'yt'"
          />
          <img src="../../assets/img/bv/join-us/yt-on.png" class="i" v-else />
          <span class="t" :class="flag && flag == 'yt' ? 'on' : ''"
            >Youtube</span
          >
        </a>
        <!-- <a href="https://discord.gg/yQusCKf3tN" target="_blank" class="it">
          <img src="../../assets/img/bv/join-us/di.png" class="i" />
          <span class="t">Discord</span>
        </a> -->
        <!-- <div class="it">
          <img src="../../assets/img/bv/join-us/ti.png" class="i" />
          <span class="t">Tik Tok</span>
        </div> -->
      </div>
    </div>
    <el-dialog
      custom-class="guide"
      :visible.sync="guide"
      :modal="true"
      :lock-scroll="false"
    >
      <img
        src="../../assets/img/bv/guide-close.png"
        class="guide-close"
        @click="closeGuide()"
      />
      <div class="swiper-sider-content">
        <div class="swiper-content">
          <swiper
            class="swiper"
            :options="guideSwiperOption"
            v-if="language == 'zh_CN'"
          >
            <swiper-slide>
              <img src="../../assets/img/bv/guide/1.png" class="m" />
            </swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/2.png" class="m"
            /></swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/3.png" class="m"
            /></swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/4.png" class="m"
            /></swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/5.png" class="m"
            /></swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/6.png" class="m"
            /></swiper-slide>
          </swiper>
          <swiper
            class="swiper"
            :options="guideSwiperOption"
            v-if="language == 'zh_HK'"
          >
            <swiper-slide>
              <img src="../../assets/img/bv/guide/01_C_T.png" class="m" />
            </swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/02_C_T.png" class="m"
            /></swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/03_C_T.png" class="m"
            /></swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/04_C_T.png" class="m"
            /></swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/05_C_T.png" class="m"
            /></swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/06_C_T.png" class="m"
            /></swiper-slide>
          </swiper>
          <swiper
            class="swiper"
            :options="guideSwiperOption"
            v-if="language != 'zh_HK' && language != 'zh_CN'"
          >
            <swiper-slide>
              <img src="../../assets/img/bv/guide/01_En.png" class="m" />
            </swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/02_En.png" class="m"
            /></swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/03_En.png" class="m"
            /></swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/04_En.png" class="m"
            /></swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/05_En.png" class="m"
            /></swiper-slide>
            <swiper-slide
              ><img src="../../assets/img/bv/guide/06_En.png" class="m"
            /></swiper-slide>
          </swiper>
        </div>
        <div class="swiper-pagination" slot="pagination"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import Socket from "@/assets/js/socket.js";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { apiGetStaticTableInfo } from "@/model/settings";
import NoData from "@/components/common/NoData";
import Cookies from "js-cookie";
import { apiGetBannerList } from "@/model/home/index";
import { mapActions, mapGetters } from "vuex";
// import { getCoinList } from "@/model/assets/index";
import { apiWsUrl, apiGetSpotList } from "@/model/exchange/index";
import { systemCurrencyMaps } from "@/assets/js/keymap.js";
import QrcodeVue from "qrcode.vue";
import axios from "axios";
var vm = null;
import { VUE_APP_ID, VUE_APP_APP } from "@/assets/js/stationSetting";
import { apiGetProductListV2 } from "@/model/finance/finance";
import {
  quantTagMaps,
  financialStatusMaps,
  financialStatusMapsTwo,
} from "@/assets/js/keymap.js";
import Countdown from "@/components/common/countdown.vue";
import BigNumber from "bignumber.js";
import { floorNumber, ceilNumber } from "@/assets/js/digital";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  components: {
    swiper,
    swiperSlide,
    // QrcodeVue,
    Countdown,
    NoData,
  },

  data() {
    return {
      detailsUrl: "",
      systemCurrencyMaps,
      token: "",
      wsUrl: "",
      appDownLoadImg: "",
      activeName: "1",
      // realIndex:0,
      sideLen: 4,
      isResiter: false,
      resiterVal: "",
      resiterValue: "",
      isMobile: false,
      isEmail: false,
      showDownLoadImg: false,
      android_url: "",
      appstore_url: "",
      doanGoogleUrl: "https://www.topcreditbank.com/app_download/",
      doanIosUrl: "https://apps.apple.com/app/id1525081932",
      tipUrl: "",

      firstArticles: {},
      secondArticles: {},
      swiperOption: {
        initialSlide: 0,

        // 箭头配置
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
        // on: {
        //   click: function() {
        //     // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
        //     // console.log(this); // -> Swiper
        //     // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
        //     const realIndex = this.realIndex;
        //     vm.handleClickSlide(realIndex);
        //   }
        // },
        on: {
          click: function (e) {
            window.open(e.target.dataset.href);
          },
        },
        // on: {
        //   click: function() {
        //     // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
        //     // console.log(this); // -> Swiper
        //     // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
        //     const realIndex = this.realIndex;
        //     vm.handleClickSlide(realIndex);
        //   }
        // },

        // slidesPerView: 1,
        spaceBetween: 24,
        slidesPerView: 4,
        loopedSlides: 8,
        slidesPerGroup: 1,

        // slidesPerView: 'auto',
        // // spaceBetween: 10,
        // // slidesPerView: "auto",
        // loopedSlides: 1,
        // slidesPerGroup: 'auto',
        loopFillGroupWithBlank: true,
        infinite: 1, // 无限滚动前后遍历数
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 4000,
        },
        autoplayDisableOnInteraction: false,
        // //循环
        loop: true,
        // // //每张播放时长3秒，自动播放
        // autoplay: 2000,
        // // //滑动速度
        // speed: 1000,
      },
      oldImgList: [
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-1.png"),
            img_url_en: require("../../assets/img/home/banner-1.png"),
            img_url_ja: require("../../assets/img/home/banner-1.png"),
            img_url_ko: require("../../assets/img/home/banner-1.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-1.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-1.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-2.png"),
            img_url_en: require("../../assets/img/home/banner-2.png"),
            img_url_ja: require("../../assets/img/home/banner-2.png"),
            img_url_ko: require("../../assets/img/home/banner-2.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-2.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-2.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-3.png"),
            img_url_en: require("../../assets/img/home/banner-3.png"),
            img_url_ja: require("../../assets/img/home/banner-3.png"),
            img_url_ko: require("../../assets/img/home/banner-3.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-3.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-3.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-4.png"),
            img_url_en: require("../../assets/img/home/banner-4.png"),
            img_url_ja: require("../../assets/img/home/banner-4.png"),
            img_url_ko: require("../../assets/img/home/banner-4.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-4.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-4.png"),
          },
          param: { url: "" },
        },
      ],
      imgList: [],
      articlesList: [],
      tableDataComputed: [],
      tableDataComputed6: [],
      exchangeCoinMap: {},
      coinInfoList: {},
      tableLoading: true,
      tableData: [],
      activeSymbol: "1",
      collectSymbol: [22],
      ws: null,
      logoImg: "",
      tgUrl: "",
      rateUrl: "",
      timer: null,
      voucherIntervalV2: null,
      tempMarkList: [],
      ///////////////////////////////增加/////////////////////////////////
      swiperOptionTop: {
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      active: 0,
      productTypeList: [],
      listType: [
        { text: "全部", val: 0 },
        // { text: "量化理财", val: 3 },
        // { text: "活期", val: 2 },
        // { text: "定期", val: 1 },
        // { text: "汇信池", val: 4 },
      ],
      dataList: [],
      quantTagMaps,
      financialStatusMapsTwo,
      financialStatusMaps,
      pageLoading: false,
      guide: false,
      guideSwiperOption: {
        autoplay: {
          disableOnInteraction: false,
          delay: 2500,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      faq1: "",
      faq2: "",
      faq3: "",
      faq4: "",
      faq5: "",
      faq6: "",
      flag: "",
    };
  },
  mounted() {
    this.faqLink();
  },
  async created() {
    let userMsg = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userMsg && !localStorage.getItem("a" + userMsg.user_id.toString())) {
      this.guide = true;
      localStorage.setItem("a" + userMsg.user_id.toString(), 1);
    }
    await this.getExchangeCoinMap();

    // if (this.language == "zh_CN" || this.language == "zh_HK") {
    //   let titleTag = document.getElementById("pageTitle");
    //   titleTag.innerHTML =
    //     "8v交易所_比特币交易平台_元宇宙虚拟币交易平台 - 8v官网";
    //   let metaTag = document.getElementById("description");
    //   metaTag.name = "description";
    //   metaTag.content =
    //     "8v交易所是专注数字加密货币交易、理财货币交易、比特币交易平台、元宇宙、虚拟币交易网站。以最安全，最高效的数字理财平台；即刻交易BTC、ETH等上百种数字资产、可信赖安全有保障。";
    //   let metaTag2 = document.getElementById("keywords");
    //   metaTag2.name = "keywords";
    //   metaTag2.content =
    //     "8v交易所,8v官网,比特币交易,比特币交易平台,比特币交易网站,比特币交易所,元宇宙,虚拟币,虚拟币交易,虚拟币平台,虚拟币交易平台,虚拟币交易所";
    // } else {
    //   let titleTag = document.getElementById("pageTitle");
    //   titleTag.innerHTML =
    //     "8v Exchange_Bitcoin Trading Platform_Metaverse Virtual Currency Trading Platform - 8v Official Website";
    //   let metaTag = document.getElementById("description");
    //   metaTag.name = "description";
    //   metaTag.content =
    //     "8v Exchange is a website focusing on digital cryptocurrency trading, wealth management currency trading, Bitcoin trading platform, Metaverse, and virtual currency trading. With the safest and most efficient digital wealth management platform; instantly trade hundreds of digital assets such as BTC, ETH, etc., reliable and safe.";
    //   let metaTag2 = document.getElementById("keywords");
    //   metaTag2.name = "keywords";
    //   metaTag2.content =
    //     "8V exchange,8v official website,bitcoin,bitcoin trading,bitcoin price,dogecoin,crypto,crypto trading,NFT,exchange blockchain,trade coin binance,exchange binance";
    // }

    let qrcode_download = JSON.parse(sessionStorage.getItem("qrcode_download"));
    let about = JSON.parse(sessionStorage.getItem("about"));
    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    this.appDownLoadImg = qrcode_download.url;

    if (zendesk_notice.language) {
      this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`];
      this.detailsUrl =
        zendesk_notice.language[`articles_url_${this.language}`];
    } else {
      this.tipUrl = zendesk_notice.notice_url;
      this.detailsUrl = zendesk_notice.articles_url;
    }

    let othersList = JSON.parse(sessionStorage.getItem("others"));
    this.rateUrl = othersList[0].url;
    let qrcode = JSON.parse(sessionStorage.getItem("qrcode"));
    this.tgUrl = qrcode.ori_url;
    this.getBannerData();
    await this.getStaticTableInfo(1);
    this.getDeatilsData();
    let tempCookies = Cookies.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      await this.initURL();
      this.initWS();
    } else {
      this.token = Cookies.get("topcredit_exchange_t");
      await this.initURL();
      this.initWS();
    }
    vm = this;
    // await this.getItemList();
    this.getTradePairList();
    this.voucherInterval = setInterval(() => {
      this.getBannerData();
      this.getDeatilsData();
    }, 5000 * 60 * 6);
    /////////////////////////////////////////新增/////////////////////
    this.getProductlist(1);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
    clearInterval(this.voucherIntervalV2);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
    clearInterval(this.voucherIntervalV2);
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),

    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
  },

  methods: {
    routeSplicing,
    on_bot_enter() {
      this.$refs.topSwiper.swiper.autoplay.stop();
    },
    on_bot_leave() {
      this.$refs.topSwiper.swiper.autoplay.start();
    },
    jumpToUrl(url) {
      if (url != "") {
        window.open(url);
      }
    },
    jumpToUrlV2(item) {
      if (item.language) {
        window.open(item.language[`url_${this.language}`]);
      } else {
        window.open(item.param.url);
      }
    },
    faqLink() {
      switch (this.language) {
        case "zh_HK":
          this.faq1 = "https://info.8v.com/hc/zh-tw/categories/4413699059737";
          this.faq2 =
            "https://info.8v.com/hc/zh-tw/articles/4409499411353-8V 理財產品介紹";
          this.faq3 =
            "https://info.8v.com/hc/zh-tw/articles/4409499292313-如何使提高賬戶安全性";
          this.faq4 =
            "https://info.8v.com/hc/zh-tw/articles/4409499187737-如何註冊 8V 賬戶";
          this.faq5 =
            "https://info.8v.com/hc/zh-tw/articles/4409499312537-如何使用 8V 在線客服系統";
          this.faq6 =
            "https://info.8v.com/hc/zh-tw/articles/4409485729049-充值-收款數字貨幣";
          break;
        case "zh_CN":
          this.faq1 =
            "https://info.8v.com/hc/zh-cn/articles/4409490980249-现货交易新手指引";
          this.faq2 =
            "https://info.8v.com/hc/zh-cn/articles/4409499411353-8V 理财产品介绍";
          this.faq3 =
            "https://info.8v.com/hc/zh-cn/articles/4409499292313-如何使提高账户安全性";
          this.faq4 =
            "https://info.8v.com/hc/zh-cn/articles/4409499187737-如何注册 8V 账户";
          this.faq5 =
            "https://info.8v.com/hc/zh-cn/articles/4409499312537-如何使用 8V 在线客服系统";
          this.faq6 =
            "https://info.8v.com/hc/zh-cn/articles/4409485729049-充值-收款数字货币";
          break;

        default:
          this.faq1 =
            "https://info.8v.com/hc/en-001/articles/4409490980249-How-to-Trade-Spot-on-8V";
          this.faq2 =
            "https://info.8v.com/hc/en-001/articles/4409499411353-8V-Financial-Products";
          this.faq3 =
            "https://info.8v.com/hc/en-001/articles/4409499292313-How-to-Improve-Account-Security";
          this.faq4 =
            "https://info.8v.com/hc/en-001/articles/4409499187737-How-to-Register-8V-APP";
          this.faq5 =
            "https://info.8v.com/hc/en-001/articles/4409499312537-24-7-Live-Chat-Support";
          this.faq6 =
            "https://info.8v.com/hc/en-001/articles/4409485729049-Crypto-Deposit-Withdrawal";
          break;
      }
    },
    closeGuide() {
      this.guide = false;
    },
    goAboutUs() {
      window.open(`/aboutus/${this.language}`);
      // this.$router.push(`/aboutus/${this.language}`);
    },
    async getExchangeCoinMap() {
      const { status, data } = await apiGetStaticTableInfo(55);
      if (status == 200) {
        let tempObj = {};
        for (const key in data) {
          if (data[key].symbol_type == 1 && data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        this.exchangeCoinMap = JSON.parse(JSON.stringify(tempObj));
      }
    },

    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
        this.android_url = data.android_url;
        this.appstore_url = data.appstore_url;
      }
    },
    handleClick() {},
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     sessionStorage.setItem("itemList", JSON.stringify(data));
    //   }
    // },
    onResiter(type) {
      var name = "";
      var inputVal = "";
      if (type == 1) {
        inputVal = this.resiterValue;
      } else {
        inputVal = this.resiterVal;
      }
      var emailReg = new RegExp(
        "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
      ); //正则表达式
      var numReg = /^\d{9,}$/;
      if (inputVal) {
        if (emailReg.test(inputVal)) {
          name = "email";
        } else if (numReg.test(inputVal)) {
          name = "phone";
        } else {
          name = "account";
        }
      } else {
        name = "";
        inputVal = "";
      }
      this.$router.push({
        name: "Register",
        params: {
          name: name,
          val: inputVal,
        },
      });
    },
    handleClickSlide(index) {
      console.log("當前點擊索引：", index);
    },
    onClickMore() {
      window.open(this.tipUrl);
    },
    onLookMarket() {
      window.open(this.routeSplicing(this.language, "markets"));
      // this.$router.push("/markets" + "/" + this.language);
    },
    onLookFinances() {
      window.open(this.routeSplicing(this.language, `finances`));
      // this.$router.push("/finances" + "/" + this.language);
    },
    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },

    async getBannerData() {
      const req = {
        type: 1,
        title: "main",
      };
      const { status, data } = await apiGetBannerList(req);
      if (status == 200) {
        let len = data.length;
        if (len <= 4) {
          data.forEach((ele, index) => {
            this.oldImgList[index] = ele;
          });
        } else {
          this.imgList = data;
        }
        this.sideLen = len >= 4 ? 4 : len;
      }
    },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.coinInfoList = JSON.parse(JSON.stringify(data));
      }
    },

    async getDeatilsData() {
      var that = this;
      axios
        .get(this.detailsUrl)
        .then(function (response) {
          that.articlesList = response.data.articles;
          that.firstArticles = response.data.articles[0];
          that.secondArticles = response.data.articles[1];
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getTradePairList() {
      apiGetSpotList().then((res) => {
        if (res.status == 200) {
          let tempArr = res.data;
          tempArr.forEach((ele) => {
            ele.lastV2 = Number(ele.last);
            ele.currency.usdV2 = Number(ele.currency.usd);
            ele.price_change_percentV2 = Number(ele.price_change_percent);
            for (const key in this.exchangeCoinMap) {
              if (ele.symbol_id == key) {
                ele["from_symbol"] = this.exchangeCoinMap[key].name
                  .split("_")[0]
                  .toUpperCase();
                ele["to_symbol"] = this.exchangeCoinMap[key].name
                  .split("_")[1]
                  .toUpperCase();
                ele["info"] = this.exchangeCoinMap[key];
                ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
                this.tempMarkList.push(ele);
              }
            }
            for (const key in this.coinInfoList) {
              if (ele.from_symbol) {
                if (
                  this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
                ) {
                  ele.coin_url = this.coinInfoList[key].coin_url;
                }
              }
            }
          });
          this.tableLoading = false;
          let tempList = this.tempMarkList.filter((ele) => {
            return ele.from_symbol != undefined && ele.web_recommend == 1;
          });
          this.tableDataComputed = tempList.sort(
            this.compareOrderBy("order_by")
          );
          this.tableDataComputed6 = this.tableDataComputed.slice(0, 5);
        }
      });
    },
    initWS() {
      this.tableLoading = true;
      let wsUrl = this.wsUrl;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "marketM",
            app_id: VUE_APP_ID,
          },
        },
        callback: this.onResponse,
      };
      window.USDT_WS = this.ws = new Socket(option).getInstance();
      setTimeout(() => {}, 1200);
      // this.getSubData();
    },
    onResponse(data) {
      this.tableLoading = false;
      if (data.action == "Pushdata.marketM") {
        this.tableData = data.data;

        if (this.tableData == null || this.tableData.length == 0) {
          return;
        }
        this.tableData.forEach((ele) => {
          for (const key in this.exchangeCoinMap) {
            if (key == ele.symbol_id) {
              ele["from_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[0]
                .toUpperCase();
              ele["to_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[1]
                .toUpperCase();
              ele["info"] = this.exchangeCoinMap[key];
              ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
            }
          }
          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (
                this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
              ) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
        });
        let tempList = this.tableData.filter((ele) => {
          return ele.from_symbol != undefined && ele.web_recommend == 1;
        });
        this.tableDataComputed = tempList.sort(this.compareOrderBy("order_by"));
        this.tableDataComputed6 = this.tableDataComputed.slice(0, 4);
      }
    },
    compareOrderBy(property) {
      return function (a, b) {
        if (
          a &&
          b &&
          a["info"] &&
          b["info"] &&
          a["info"][property] &&
          b["info"][property]
        ) {
          let value1 = a["info"][property];
          let value2 = b["info"][property];
          if (value1 - value2 != 0) {
            return value1 - value2;
          } else {
            let temp1 = a["from_symbol"];
            let temp2 = b["from_symbol"];
            if (temp1 < temp2) {
              // 按某种排序标准进行比较, a 小于 b
              return -1;
            }
            if (temp1 > temp2) {
              return 1;
            }
            return 0;
          }
        } else {
          return 0;
        }
      };
    },
    onTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
    },
    onSymbolClick(row) {
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      );
      window.open(tempUrl);
    },
    onClickRow(row, column, event) {
      // let tempUrl = this.routeSplicing(
      //   this.language,
      //   `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      // );
      // window.open(tempUrl);
    },
    ////////////////////////////////////新增///////////////////////////
    getProductlist(timer) {
      this.pageLoading = true;
      let tempProductType = 0;
      if (this.active == 3) {
        tempProductType = 3;
      } else if (this.active == 2) {
        tempProductType = 2;
      } else if (this.active == 1) {
        tempProductType = 1;
      } else if (this.active == 4) {
        tempProductType = 4;
      }
      let params = {
        item_type: 1, //货币类型，1为数字货币，2为法币
        product_type: tempProductType, //产品类型，1定期 2活期 3量化 4汇信池，-1为全部产品列表
        page: 1,
        page_size: 100,
      };
      apiGetProductListV2(params).then((res) => {
        if (res.status == 200) {
          let tempList = res.data.list;
          const now = Date.parse(new Date());
          tempList.forEach((ele) => {
            this.productTypeList.push(ele.product_type);

            ele.to_item_id_price = Number(ele.to_item_id_price).toFixed(2);
            if (ele.to_item_url == "") {
              ele.to_item_url = require("../../assets/img/holder-coin-ico.png");
            }
            if (
              ele.stop_time != 0 &&
              now < ele.stop_time * 1000 &&
              ele.stop_time * 1000 - now < 86400000
            ) {
              ele.remain_end_time = ele.stop_time * 1000 - now;
            }
            if (ele.start_time != 0) {
              ele.remain_time = ele.start_time * 1000 - now;
            } else {
              ele.timeCount = 0;
            }
            if (ele.union_info.length > 0) {
              ele.active_lock_period_id = ele.union_info[0].product_id;
              ele.active_annual_rate = ele.union_info[0].annual_rate;
            }
          });
          if (timer) {
            if (this.productTypeList.indexOf(3) > -1) {
              this.listType.push({ text: "量化理财", val: 3 });
            }
            if (this.productTypeList.indexOf(2) > -1) {
              this.listType.push({ text: "活期", val: 2 });
            }
            if (this.productTypeList.indexOf(1) > -1) {
              this.listType.push({ text: "定期", val: 1 });
            }

            if (this.productTypeList.indexOf(4) > -1) {
              if (
                VUE_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" ||
                VUE_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w" ||
                VUE_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T"
              ) {
                // 汇信
                this.listType.push({ text: "汇信池", val: 4 });
              } else {
                this.listType.push({ text: "Launchpad", val: 4 });
              }
            }
          }
          this.dataList = tempList.slice(0, 4);
        }
        this.pageLoading = false;
      });
    },
    // 选项卡切换
    onTypeChange(val) {
      this.active = val;
      if (val == 3) {
        this.confirmDialogTitle = this.translateTitle(`量化理财`);
      } else if (val == 2) {
        this.confirmDialogTitle = this.translateTitle(`活期存入`);
      } else if (val == 1) {
        this.confirmDialogTitle = this.translateTitle(`定期存入`);
      } else if (val == 4) {
        if (
          VUE_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" ||
          VUE_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w" ||
          VUE_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T"
        ) {
          // 汇信
          this.confirmDialogTitle = this.translateTitle(`汇信池`);
        } else {
          this.confirmDialogTitle = "Launchpad";
        }
      }
      this.getProductlist();
    },
    goToBuy(item) {
      let productId = 0;
      if (item.union_info.length > 0) {
        productId = item.active_lock_period_id;
      } else {
        productId = item.product_id;
      }
      // window.location.href =
      //   "/finances" + "/" + this.language + "?relation_id=" + productId;
      this.$router.push({
        // path: "/finances",
        path: this.routeSplicing(this.language, `finances`),
        query: {
          relation_id: productId,
        },
      });
    },
    onCountDownEnd() {
      this.getProductlist();
    },
    annualRateComputed(annual_rate) {
      let tempRate = new BigNumber(annual_rate);
      let time = new BigNumber(100);
      return tempRate.times(time).toString();
    },
    onLockPeriodChange(prod, item) {
      item.active_lock_period_id = prod.product_id;
      item.active_annual_rate = prod.annual_rate;
    },
    remainingPercent(remaining, amount) {
      let tempVal = floorNumber((remaining * 100) / amount, 2);
      return tempVal;
    },
    joinActive(flag) {
      this.flag = flag;
    },
  },
};
</script>
<style lang="scss">
@keyframes a {
  0% {
    transform: translate(0);
  }

  to {
    transform: translateY(-6px);
  }
}
@keyframes b {
  0% {
    transform: translate(0);
  }

  to {
    transform: translateY(6px);
  }
}
.ar
  .bv-home-page
  .home-content-five
  .five-content
  .details
  .left
  .text-item
  .pointer {
  margin-right: 0;
  margin-left: 20px;
}
.ar .bv-home-page .home-content-two {
  direction: ltr !important;
}
.zh_CN {
  .bv-home-page .home-content-one .home-sign {
    display: flex;
    justify-content: center;
    top: 90px;
  }
}
.zh_CN {
  .bv-home-page .home-content-one .home-sign {
    display: flex;
    justify-content: center;
    top: 90px;
  }
}
.zh_HK {
  .bv-home-page .home-content-one .home-sign {
    display: flex;
    justify-content: center;
    top: 90px;
  }
}
.ar .bv-home-page .home-content-four .left-content .tips-details li i {
  margin-right: 0;
  margin-left: 6px;
}
.ar .bv-home-page .regsiter-content input {
  margin: 0 0 0 10px;
  padding: 12px 17px 12px 0;
}
.ar {
  .bv-home-page
    .home-content-five
    .five-items-content
    .five-content-items
    .text-title {
    text-align: right;
  }
  .bv-home-page
    .home-content-five
    .five-items-content
    .five-content-items
    .text-item
    .pointer {
    margin-right: 0;
    margin-left: 20px;
  }
  .bv-home-page .home-content-five .five-content .details .right {
    direction: ltr !important;
  }
  .bv-home-page .el-table tbody .cell .coin-content {
    // float: right;
    margin-right: 80px;
    .coin-img {
      margin-left: 10px;
    }
  }

  .bv-home-page .home-content-four .left-content .tips-details li i {
    margin-left: 6px;
    margin-right: 0;
  }
  .bv-home-page .home-content-four .left-content .download-content {
    direction: ltr;
  }
  .bv-home-page .home-content-four .left-content .download-code {
    height: 160px;
    .qr_code_canvas {
      margin-right: 0;
    }
  }
}
.bv-home-page {
  margin-top: 0px;
  display: block;
  user-select: none;
  padding: 0;
  flex: 1;
  background-color: #fff;
  .el-loading-mask {
    background-color: transparent;
  }
  [v-cloak] {
    display: none;
  }
  .fiv-svg {
    position: relative;
    i {
      z-index: 900;
      position: absolute;
      font-size: 60px;
      top: -2px;
      left: 110px;
    }
  }
  .hor-line {
    width: 74px;
    height: 6px;
    border-radius: 3px;
    background-image: linear-gradient(89deg, #00cf88 0%, #0084d6 100%);
    margin: 0 auto;
    display: block;
  }
  .is-resiger {
    height: 340px;
  }
  .no-resiger {
    height: 340px;
  }

  .swiper-img {
    cursor: pointer;
    width: 100%;
    height: 144px;
    border-radius: 6px;
    cursor: pointer;
  }

  .home-content-one {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #111a25;
    .swiper-sider-content {
      display: flex;
      justify-content: center;
      position: relative;
      .swiper-content {
        // width: 1100px;
        width: 100vw;
        .swiper-pagination {
          .swiper-pagination-bullet {
            display: inline-block;
            width: 16px;
            height: 13px;
            margin: 0 7px;
            border-radius: 6px;
            background-color: #a3a3a3;
            opacity: 1;
          }
          .swiper-pagination-bullet-active {
            width: 68px;
            height: 13px;
            border-radius: 6px;
            background-color: #f7b239;
          }
        }
        .index-top-swiper-o {
          display: flex;
          justify-content: center;
        }
        .index-top-swiper {
          width: 1100px;
          // height: 458.3px;
          height: 338px;
          display: flex;

          .content {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            .title {
              width: 560px;
              font-family: Georgia;
              font-size: 26px;
              font-weight: bold;
              line-height: 1.48;
              letter-spacing: 2.38px;
              color: #f7b239;
            }
            .subtitle {
              margin-top: 16px;
              width: 588px;
              font-family: Georgia;
              font-size: 14px;
              line-height: 1.57;
              letter-spacing: 1px;
              color: #fff;
            }
            .ml {
              margin-top: 40.5px;
              margin-left: 20px;
              width: 480px;
              display: flex;
              // justify-content: space-between;
              justify-content: flex-start;
              .m {
                width: 64px;
                height: 64px;
                margin: 0 10px;
                border-radius: 50%;
              }
            }
          }
          .img {
            display: inline-block;
            width: 50%;
            height: 100%;
            .i {
              width: 100%;
              height: 100%;
            }
          }
        }
        .index-top-swiper1 {
          width: 100vw;
          // height: 458.3px;
          height: 338px;
          position: relative;
          .bgvideo {
            width: 100%;
            height: 100%;
            // object-fit: fill;
            object-fit: cover;
          }
          .banner-c {
            position: absolute;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .cm {
              margin-top: -30px;
              width: auto;
              height: 140px;
            }
            .ct {
              margin-top: 20px;
              font-size: 20px;
              letter-spacing: 2px;
              color: #fff;
            }
          }
        }
      }
      // 轮播箭头
      .swiper-button-next {
        color: #f7b239;
        cursor: pointer;
        display: flex !important;
        position: absolute;
        right: 15vw;
      }
      .swiper-button-prev {
        color: #f7b239;
        cursor: pointer;
        display: flex !important;
        position: absolute;
        left: 15vw;
      }
    }
  }
  .home-content-spot {
    position: relative;
    width: 100%;
    z-index: 1;
    .l {
      left: 50%; // 设置左边距为父元素宽度的50%
      transform: translateX(-50%);
      position: absolute;
      background-color: #fff;
      width: 1200px;
      height: 132px;
      margin: 0 auto;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 5px;
      margin-top: -31px;
      .item {
        width: 16.67%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        .np {
          display: flex;
          .n {
            font-size: 16px;
          }
          .p {
            font-size: 15px;
            margin-top: 1px;
            margin-left: 5px;
          }
          .red {
            color: #fe4343;
          }
          .green {
            color: #34be88;
          }
        }
        .up {
          margin-top: 6px;
          font-size: 16px;
          color: #112132;
        }
        .cp {
          margin-top: 8px;
          font-size: 10.6px;
          color: #112132;
        }
      }
    }
  }
  .home-content-two {
    margin-top: 50px;
    min-height: 602px;
    background: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
    &.V2 {
      min-height: 588px;
    }
    .swiper-sider-content {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      position: static;
      .swiper-next {
        cursor: pointer;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        z-index: 99;
        width: 30px;
        height: 144px;
        top: 0px;
        outline: none;
        position: absolute;
        background-color: rgb(0, 0, 0, 0.4);
        right: 0px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        img {
          height: 17px;
          width: 10px;
        }
      }
      .swiper-prev {
        cursor: pointer;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        z-index: 99;
        width: 30px;
        height: 144px;
        top: 0px;
        outline: none;
        position: absolute;
        background-color: rgb(0, 0, 0, 0.4);
        left: 0px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        img {
          height: 17px;
          width: 10px;
        }
      }
      .swiper-content {
        display: flex;
        position: relative;
        align-items: center;
        width: 1200px;
        .button-prev {
          margin-right: 13px;
        }
        i {
          &:focus {
            border: 0px;
            outline: 0px;
          }
        }
        .iconfont {
          font-size: 28px;
          color: #747a7e;
          outline: none;
          cursor: pointer;
          border: 0px;
        }
        .swiper-container {
          width: 1200px;
          height: 144px;
          position: relative;
          border-radius: 6px;
          .swiper-wrapper {
            text-align: center !important;
          }

          img {
            height: 144px;
            box-sizing: border-box;
            opacity: 1;
            &:hover {
              opacity: 1;
            }
          }
          .swiper-slide {
            margin-left: 0px !important;
          }
        }
      }
      .old-img {
        display: flex;
        width: 1200px;
        ul {
          &:last-child {
            li img {
              margin-right: 0;
            }
          }
          li {
            cursor: pointer;
          }
        }

        img {
          width: 282px;
          height: 144px;
          margin-right: 24px;
          border-radius: 5px;
        }
      }
    }
    .swiper-pagination {
      position: relative !important;
      margin-top: 15px;
      span {
        margin: 0px 10px;
      }
    }

    .tips-massage-content {
      width: 1200px;
      margin: 0 auto;
      padding: 20px 0px;
      font-size: 14px;
      color: #080c34;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 25px;
        color: #354052;
        margin-right: 5px;
      }
      .left-driver {
        width: 1px;
        height: 18px;
        margin: 2px 15px 0;
        background-color: #354052;
      }
      .bottom {
        text-align: left;
      }
      .article-title {
        color: #354052 !important;
        min-width: 250px;
      }
      .right-driver {
        width: 1px;
        height: 18px;
        margin: 2px 20px 0 15px;
        background-color: #354052;
      }
      .right {
        cursor: pointer;
        color: #354052;
        i {
          font-size: 14px;
          margin-top: 2px;
        }
      }
    }
    .content-two-title {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      font-family: DINPro;
      font-size: 18px;
      color: #354052;
      margin-top: 20px;
      margin-bottom: 0px;
      .more {
        width: 90px;
        height: 30px;
        border-radius: 15px;
        background-color: #f7b239;
        display: flex;
        align-items: center;
        justify-content: center;
        .more-title {
          font-size: 14px;
          color: #fff;
        }
      }
    }

    .quotes-content {
      width: 1200px;
      margin: 0 auto;
      padding: 20px 10px 0px;
      border-radius: 6px;
      background-color: #ffffff;
      .left-symbol {
        color: #354052;
        font-size: 14px;
        font-weight: 500;
      }
      .left-row {
        color: #354052;
        font-size: 14px;
        font-weight: 600;
      }

      .el-table {
        min-height: 430px !important;
        tr {
          cursor: pointer;
        }
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #f1f5f6;
      }

      .el-table {
        min-height: 350px;
        .el-icon-star-off {
          cursor: pointer;
        }
      }
      .el-table::before {
        height: 0;
      }
    }
  }
  .home-content-finances {
    min-height: 515px;
    background-color: #fff;
    padding-top: 40px;
    .content-title {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      font-family: DINPro;
      font-size: 18px;
      color: #354052;
      margin-top: 20px;
      margin-bottom: 0px;
      .more {
        width: 90px;
        height: 30px;
        border-radius: 15px;
        background-color: #f7b239;
        display: flex;
        align-items: center;
        justify-content: center;
        .more-title {
          font-size: 14px;
          color: #fff;
        }
      }
    }
    .list-content {
      width: 100%;
      border-radius: 4px;
      color: #112132;
      .type-list {
        width: 1200px;
        height: 35px;
        line-height: 35px;
        margin: 0px auto;
        position: relative;
        padding-left: 60px;
        margin-top: 10px;
        .list-type {
          display: flex;
          margin-bottom: 20px;
          user-select: none;
          .tab {
            position: relative;
            margin-right: 30px;
            line-height: 35px;
            cursor: pointer;
            span {
              font-size: 16px;
              font-weight: 400;
            }
            &:hover {
              &:after {
                content: "";
                height: 2px;
                width: 80%;
                background-color: #f7b239;
              }
            }
            &.active {
              span {
                color: #f7b239;
                font-size: 16px;
                font-weight: 500;
              }
              &:after {
                content: "";
                height: 2px;
                width: 54px;
                background-color: #f7b239;
                border-radius: 1.5px;
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
    }
    .finances-content-outer {
      width: 100%;
      background-color: #f9f9f9;
    }
    .finances-content {
      width: 1200px;
      margin: 0px auto;
      padding: 30px 0;
      min-height: 300px;
      .el-loading-mask {
        min-height: 200px;
      }

      .finances-item {
        user-select: none;
        width: 285px;
        height: 315px;
        margin: 0px 20px 0px 0;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        cursor: pointer;
        border: 1px solid white;
        display: inline-block;
        transition: all 0.5s;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:hover {
          border: solid 1px #3a7dff;
        }
        &.status_8,
        &.status_6,
        &.status_7 {
          .title {
            color: #9aa1b1;
          }
          .center-content .number-item,
          .text-item,
          .date-item {
            color: #9aa1b1 !important;
          }
        }
        .title-content {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: -3px;
          display: inline-block;
          vertical-align: middle;
          font-size: 19px;
        }

        img {
          width: 35px;
          height: 35px;
          margin: 0 7px 3px 0;
          vertical-align: middle;
        }

        .type-content {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 22px;
          .type-progress {
            &.V2 {
              width: fit-content;
            }
            .countdown {
              width: fit-content;
              height: 20px;
              margin: 3px 0px 36px 7px;
              padding: 2.2px 5.1px 1.8px 5.6px;
              border-top-left-radius: 5px;
              border-bottom-right-radius: 5px;
              font-size: 12px;
              color: white;
              background-image: linear-gradient(
                to right,
                #f78700 0%,
                #ffb152 100%
              );
              display: inline-block;
            }
            .progress-count {
              background-image: linear-gradient(
                to right,
                #ff486d 0%,
                #c61b3d 100%
              );
            }
          }
          .type-1,
          .type-5 {
            display: inline-block;
            height: 20px;
            margin: 3px 5px 36px 4px;
            padding: 2.2px 5.1px 1.8px 5.6px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background-image: linear-gradient(
              to right,
              #f7ad00 0%,
              #ffd452 100%
            );
            font-size: 12px;
            color: white;
            &.V2 {
              width: fit-content;
            }
          }
          .type-6,
          .type-8,
          .type-4,
          .type-7 {
            width: 58px;
            height: 20px;
            margin: 3px 0px 0px 7px;
            padding: 2.2px 5.1px 1.8px 5.6px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: #c2c7d0;
            font-size: 12px;
            color: white;
          }
          .quant-type-1 {
            display: inline-block;
            padding: 2.2px 4.4px 1.8px 5.6px;
            width: fit-content;
            height: 20px;
            border-top-left-radius: 5px;
            font-size: 12px;
            color: white;
            border-bottom-right-radius: 5px;
            background-image: linear-gradient(265deg, #8871ff 86%, #735af2 0%);
          }
          .quant-type-2 {
            display: inline-block;
            padding: 2.2px 7px 1.8px 7px;
            width: fit-content;
            height: 20px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background-image: linear-gradient(
              266deg,
              #579fff 35%,
              #0068f3 120%
            );
            font-size: 12px;
            color: white;
          }
          .quant-type-3 {
            display: inline-block;
            padding: 2px 5px;
            width: 50px;
            height: 20px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
            font-size: 12px;
            color: white;
          }
          .type-red {
            width: 50px;
            height: 20px;
            line-height: 17px;
            display: inline-block;
            box-sizing: border-box;
            margin-left: 5px;
            margin-top: -2px;
            vertical-align: middle;
            padding: 2.2px 4.6px 1.8px 5.6px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            font-size: 12px;
            color: white;
          }
          .type-3 {
            width: fit-content;
            height: 20px;
            margin-left: 7px;
            padding: 2.2px 5.1px 1.8px 4.1px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
            font-size: 12px;
            color: white;
          }

          .type-2 {
            width: 44px;
            height: 20px;
            nav-right: 5px;
            padding: 2.2px 4.4px 1.8px 5.6px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background-image: linear-gradient(
              266deg,
              #579fff 35%,
              #0068f3 120%
            );
            font-size: 12px;
            color: white;
          }
        }
        .center-content {
          margin-top: 10px;
          text-align: center;
          &.V2 {
            margin-top: 10px;
          }
          .lock-period-content {
            width: fit-content;
            height: 28px;
            margin: 2px auto 3px;
            border-radius: 3px;
            border: solid 1px #c2c7d0;
            .lock-period {
              display: inline-block;
              height: 28px;
              line-height: 28px;
              font-size: 14px;
              padding: 0px 16px;
              border-radius: 3px;
              &.active {
                border: solid 1px #c61b3d;
                padding: 0px 14px;
                margin-top: -1px;
                background-color: rgba(198, 27, 61, 0.1);
              }
            }
            .lock-period:first-child {
              &.active {
                margin-left: -1px;
              }
            }
            .lock-period:last-child {
              &.active {
                margin-right: -1px;
              }
            }
          }
          span {
            display: block;
          }
          .number-item {
            font-size: 36px;
            font-weight: normal;
            color: #ff6565;
            margin-bottom: 10px;
          }
          .text-item {
            font-size: 14px;
            color: #354052;
            margin-bottom: 10px;
            &.V2 {
              margin-top: 4px;
              color: #9aa1b1;
              display: block;
              height: 20px;
            }
          }
          .date-item {
            font-size: 18px;
            font-weight: normal;
            color: #354052;
            height: 38px;
            line-height: 38px;
          }
          .btn {
            width: 240px;
            margin: 7px auto 20px;
            cursor: pointer;
            background-image: linear-gradient(
              68deg,
              #f7b239,
              #f7b239
            ) !important;
            border-radius: 5px;
          }
          .disabled-btn {
            cursor: pointer;
            width: 200px;
            margin: 7px auto 20px;
            background-color: #c2c7d0;
            background-image: none;
          }
          .progess-btn {
            cursor: pointer;
            width: 200px;
            margin: 7px auto 20px;
            background-image: linear-gradient(
              to right,
              #f78700 0%,
              #ffb152 100%
            );
          }
        }
      }
    }
  }
  .home-content-faq {
    min-height: 602px;
    background-color: #fff;
    margin-top: 31px;
    .content-title {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      font-family: DINPro;
      font-size: 18px;
      color: #354052;
      margin-bottom: 24px;
      .more {
        width: 90px;
        height: 30px;
        border-radius: 15px;
        background-color: #f7b239;
        display: flex;
        align-items: center;
        justify-content: center;
        .more-title {
          font-size: 14px;
          color: #fff;
        }
      }
    }
    .faq-content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .pic1 {
        width: 32%;
        height: 199.2px;
        background: url("../../assets/img/bv/faq1.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .pic2 {
        width: 32%;
        height: 199.2px;
        background: url("../../assets/img/bv/faq2.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .pic3 {
        width: 32%;
        height: 199.2px;
        background: url("../../assets/img/bv/faq3.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      a:hover {
        opacity: 0.8;
      }
      .c {
        height: 100%;
        margin-left: 8.5%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .t {
          font-size: 18px;
          width: 125px;
          letter-spacing: 2.5px;
          color: #fff;
        }
        .te {
          font-size: 23px;
          letter-spacing: 2.5px;
          color: #fff;
        }
        .more {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 20px;
          border-radius: 13.5px;
          background-color: #fff;
          color: #f7b239;
          font-size: 12px;
          .i {
            margin-top: 2px;
          }
        }
      }
    }
    .faq-content-tips {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;
      .it {
        display: flex;
        align-items: center;
        .i {
          width: 24px;
          height: 24px;
        }
        .t {
          margin-left: 5px;
          font-size: 16px;
          line-height: 2.75;
          color: #112132;
        }
      }
    }
    .faq-content-aboutus {
      cursor: pointer;
      display: block;
      width: 1200px;
      height: auto;
      margin: 0 auto;
      margin-top: 30px;
      background-color: #fff;
      // border-radius: 50px;
      .bgvideo {
        border-radius: 7px;
        width: 100%;
        height: 100%;
        // object-fit: fill;
        object-fit: cover;
      }
    }
  }
  .home-content-join {
    min-height: 210px;
    background-color: #fff;
    padding-top: 40px;
    .content-title {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      font-family: DINPro;
      font-size: 18px;
      color: #354052;
      margin-top: 20px;
      margin-bottom: 21px;
    }
    .join-content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      .it {
        margin: 0 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .i {
          width: 40px;
          height: 40px;
        }
        .t {
          margin-top: 6px;
          font-size: 12px;
          color: #112132;
          // &.on {
          //   color: #f7b238;
          // }
        }
      }
    }
  }

  .home-content-four {
    height: 500px;
    text-align: center;
    padding-bottom: 50px;
    color: #112132;
    .four-content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
    .content-title-v2 {
      width: 1200px;
      font-family: DINPro;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 38px;
      span {
        display: block;
        width: 1200px;
        font-size: 18px;
        // margin-left: 150px;
      }
    }
    .content-title {
      font-weight: 600;
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      padding: 0px 0 7px;
      font-family: DINPro;
      h2 {
        display: block;
        width: 1200px;
        font-size: 30px;
        font-weight: 600;
        // margin-left: 150px;
      }
    }
    .hor-line {
      margin-bottom: 50px;
    }
    .left-content {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      position: relative;
      width: 450px;
      margin-left: 60px;
      .tips-details {
        li {
          display: flex;
          align-items: center;
          font-size: 14px;
          // color: #fff;
          img {
            width: 22px;
            height: 22px;
            margin-top: 3px;
            margin-right: 6px;
            color: #c61b3d;
          }
        }
      }
      li {
        margin-bottom: 14px;
        color: #354052;
      }
      img {
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
      .download-content {
        margin-top: 40px;
        display: flex;
        margin-left: 53px;
        height: 115px;
        position: relative;
        .b-download {
          margin-left: 23px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          .download-item {
            img {
              width: 187px;
              height: 53px;
            }
          }
        }
        .qr-code {
          width: 116px;
          height: 116px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 2px 5px #555;
          border-radius: 10px;
          img {
            text-align: center;
            width: 100px;
            height: 100px;
            margin: 0;
          }
        }
      }
    }
    .right-content {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 467px;
      height: 318px;
      .home-bg-logo {
        position: absolute;
        top: 21px;
        left: 6px;
        img {
          height: 16px;
        }
      }
    }
  }

  .home-content-six {
    height: 240px;
    text-align: center;
    background-color: #17232c;
    .six-content {
      height: 240px;
      width: 100%;
      padding: 64px 0 87px;
    }
    .six-content-title {
      text-align: center;
      color: #ffffff;
      font-size: 22px;
      font-weight: 600;
    }
  }
  .text-content {
    font-size: 16px;
    color: #333;
    position: absolute;
    bottom: 83px;
    left: calc(50% - 96px);
    padding-bottom: 3px;
    text-decoration: underline;
  }
  .regsiter-content {
    position: absolute;
    bottom: 103px;
    left: calc(50% - 255px);
    margin: 42px 0px;
    background: white;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      display: inline-block;
      width: 370px;
      height: 46px;
      margin: 0 10px 0 0;
      font-size: 16px;
      line-height: 46px;
      font-weight: 500;
      padding: 12px 0px 12px 17px;
      border-radius: 2px;
      background-color: #ffffff;
      &::placeholder {
        color: #dddee1;
      }
    }
    .goRegsiter {
      border-radius: 2px;
      cursor: pointer;
      vertical-align: middle;
      background: #c61b3d;
      text-align: center;
      font-size: 16px;
      display: inline-block;
      width: 130px;
      line-height: 46px;
      height: 46px;
      color: white;
      box-sizing: border-box;
    }
    .trade-btn {
      border-radius: 2px;
      cursor: pointer;
      vertical-align: middle;
      background: #c61b3d;
      text-align: center;
      font-size: 16px;
      display: inline-block;
      width: 130px;
      line-height: 46px;
      height: 46px;
      color: white;
      box-sizing: border-box;
    }
  }

  .el-table {
    margin-left: -10px;
    .el-table__header {
      thead {
        .cell {
          font-size: 14px;
          color: #7f8490;
        }
      }
    }
    tbody {
      .operate {
        width: 70px;
        height: 28px;
        padding: 4px 0px;
        text-align: center;
        border-radius: 2px !important;
        font-weight: 400 !important;
        font-size: 14px;
        border-radius: 3px;
        background-color: #f7b239 !important;
        color: #fff !important;
        border: solid 0px #15bfb5 !important;
        border-radius: 5px;
        span {
          font-weight: 400 !important;
          color: #fff !important;
        }
        &:hover {
          background-color: #f7b239 !important;
          color: #fff !important;
          span {
            color: white !important;
          }
        }
      }
      .mrket-img {
        height: 30px;
      }

      .cell {
        .coin-content {
          display: inline-block;
          .coin-img {
            width: 30px;
            height: 30px;
            vertical-align: middle;
          }
        }
        .red {
          color: #c61b3d;
        }
        .green {
          color: #03ad79;
        }
        .active {
          color: #c61b3d;
          font-size: 18px;
          margin-top: 4px;
          margin-left: -3px;
        }
        .el-icon-star-on,
        .el-icon-star-off {
          text-align: left;
          cursor: pointer;
          margin-right: 10px;
        }
        p,
        span,
        code {
          color: #1f2533;
        }
        .el-table__expand-icon {
          display: none;
        }
        .dark-color {
          color: #838383;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .table-expand {
      min-height: 228px;
      padding: 10px;
      display: flex;
      cursor: auto;
      .expand-detail {
        flex: 1;
        display: inline-block;
        margin-right: 32px;
      }
      .el-form {
        display: inline-block;
        width: 300px;
        .el-form-item {
          margin-bottom: 0px;
          padding: 0 20px;
          .el-form-item__label {
            padding: 0;
          }
          &:nth-child(2n + 1) {
            background-color: #f5f5f7;
          }
        }
        .el-form-item__content {
          display: inline-block;
          float: right;
        }
      }
    }
  }
  .el-tabs__item {
    font-size: 16px;
    margin-bottom: 10px;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #bec1c8 !important;
    font-weight: 500;
  }
  .guide {
    user-select: none;
    z-index: 999;
    width: 600px;
    min-height: 405px;
    display: flex;
    color: #112132;
    background: transparent;
    box-shadow: none;
    // position: relative;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -90%);
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
    }
    .guide-close {
      width: 35px;
      height: 35px;
      position: absolute;
      right: -41.25px;
      top: -20px;
    }
    .swiper {
      width: 600px;
      height: 360px;
    }
    .m {
      width: 600px;
      height: 360px;
    }
    .swiper-sider-content {
      position: relative;
    }
    .swiper-pagination {
      margin-top: 11.25px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      .swiper-pagination-bullet {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin: 0 7.5px;
        border-radius: 50%;
        background-color: #a3a3a3;
        opacity: 1;
      }
      .swiper-pagination-bullet-active {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #f7b239;
      }
    }
  }
}
</style>
