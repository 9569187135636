<template>
  <!-- true false -->
  <!-- 0 不需要验证 1 需要验证 -->
  <div class="security-setting">
    <div class="setting-content">
      <template>
        <top-title :title="translateTitle('安全设置')"></top-title>
        <div class="app-uncter-content">
          <div class="setting-items">
            <setting-item
              :title="translateTitle('登录密码')"
              :desc="
                translateTitle(
                  '登录时使用，请妥善保管您的登录密码，建议您定期修改'
                )
              "
              :btnText="translateTitle('修改')"
              :btnTColor="true"
              @settingBtnClick="onChangePassWord"
            />
            <!-- 绑定/修改手机 -->
            <setting-item
              :title="
                mobileBind
                  ? translateTitle('修改手机')
                  : translateTitle('绑定手机')
              "
              :desc="
                translateTitle('用于登录、提币、修改安全设置时进行安全验证')
              "
              :isMobile="true"
              :btnTColor="mobileBind"
              :fieldVal="globalFun.userDecryption(accountInfo.mobile, 'mobile')"
              :btnText="
                mobileBind ? translateTitle('修改') : translateTitle('绑定')
              "
              @settingBtnClick="onBindMobileClick"
            >
              <template v-slot:tag>
                <status-tag
                  :status="
                    mobileBind
                      ? translateTitle('已绑定')
                      : translateTitle('未绑定')
                  "
                  :has-point="!mobileBind"
                />
              </template>
            </setting-item>
            <!-- 绑定/解绑邮箱 -->
            <setting-item
              :title="
                emailBind
                  ? translateTitle('邮箱验证')
                  : translateTitle('绑定邮箱')
              "
              :desc="
                translateTitle('用于登录、提币、修改安全设置时进行安全验证')
              "
              :isMobile="false"
              :fieldVal="globalFun.userDecryption(accountInfo.email, 'email')"
              :btnText="emailBind ? '' : translateTitle('绑定')"
              :btnTColor="emailBind"
              @settingBtnClick="onBindEmailClick"
            >
              <template v-slot:tag>
                <status-tag
                  :status="
                    emailBind
                      ? translateTitle('已绑定')
                      : translateTitle('未绑定')
                  "
                  :has-point="!emailBind"
                />
              </template>
            </setting-item>
            <!-- 绑定/修改谷歌认证 -->
            <setting-item
              :title="translateTitle('谷歌认证')"
              :desc="
                translateTitle('用于登录、提币、修改安全设置时进行安全验证')
              "
              :btnText="
                googleStatus != 0
                  ? translateTitle('修改')
                  : translateTitle('绑定')
              "
              :btnTColor="googleStatus != 0"
              :btnRight="googleBtnText"
              @settingBtnClick="onBindGoogleClick"
              @btnRightClick="closeGoogle"
              :googleVerifyStatus="googleVerifyStatus"
            >
              <template v-slot:tag>
                <status-tag
                  :status="
                    googleStatus != 0
                      ? translateTitle('已绑定')
                      : translateTitle('未绑定')
                  "
                  :has-point="googleStatus === 0"
                />
              </template>
            </setting-item>
          </div>
        </div>
      </template>
      <el-dialog
        class="g-dialog change-pwd"
        @open="onDialogOpen"
        :title="
          `${
            googleStatus == 0
              ? translateTitle('绑定谷歌验证器')
              : translateTitle('修改谷歌验证器')
          }  `
        "
        :visible.sync="openSetGoogle"
        :destroy-on-close="true"
        width="500px"
        :close-on-click-modal="false"
      >
        <GoogleBind
          :accountInfo="accountInfo"
          :emailStatus="emailStatus"
          :mobileStatus="mobileStatus"
          :googleStatus="googleStatus"
          @backClick="openSetGoogle = false"
          @subSuccess="subGoogleSuccess"
        ></GoogleBind>
      </el-dialog>

      <!-- TODO dialog单独抽取 // 修改密码 -->
      <el-dialog
        class="g-dialog change-pwd"
        @open="onDialogOpen"
        :title="translateTitle('修改密码')"
        :visible.sync="pwdDialogVisible"
        :destroy-on-close="true"
        width="500px"
        :close-on-click-modal="false"
      >
        <template>
          <change-pwd v-if="pwdDialogVisible" ref="changePwd_ref"></change-pwd>
          <d-bind
            :accountInfo="accountInfo"
            :isChangePwd="true"
            @checkPwdStatus="checkPwdStatus"
            ref="mobile_ref"
            v-if="mobileStatus"
            key="1"
            type="mobile"
            type_num="16"
            :disabled="true"
          ></d-bind>
          <d-bind
            :accountInfo="accountInfo"
            :isChangePwd="true"
            @checkPwdStatus="checkPwdStatus"
            ref="email_ref"
            v-if="emailStatus"
            key="2"
            type="email"
            type_num="15"
            :disabled="true"
          ></d-bind>
          <d-google
            v-if="openGoogle"
            :label="translateTitle('谷歌验证码')"
            ref="google_ref"
          ></d-google>
          <div slot:footer class="footer">
            <g-button
              class="cancelBtn"
              :text="translateTitle('取消')"
              @click="onCancel"
            ></g-button>
            <el-button
              class="confrimBtn"
              @click="onOk"
              :loading="confirmLoading"
            >
              {{ translateTitle("确定") }}</el-button
            >
            <!-- <g-button plain text="确定" @click="onOk"></g-button> -->
          </div>
        </template>
      </el-dialog>

      <!-- 绑定 手机/邮箱 -->
      <el-dialog
        @open="onDialogOpen"
        width="500px"
        class="change-mobile-email"
        :destroy-on-close="true"
        :top="
          mobileStatus && emailStatus
            ? '8vh'
            : mobileStatus || emailStatus
            ? '15vh'
            : '24vh'
        "
        :title="
          bindType === 'mobile'
            ? mobileBind
              ? translateTitle('修改手机')
              : translateTitle('绑定手机')
            : emailBind
            ? translateTitle('邮箱验证')
            : translateTitle('绑定邮箱')
        "
        :visible.sync="bindDialogVisible"
        :close-on-click-modal="false"
        :before-close="onCancel"
        v-if="bindDialogVisible"
      >
        <template>
          <!-- 未绑定手机 -->
          <div v-if="bindType === 'mobile' && !mobileBind">
            <d-bind
              :accountInfo="accountInfo"
              key="1"
              type="mobile"
              ref="mobile_ref"
              type_num="3"
            ></d-bind>
            <d-bind
              :accountInfo="accountInfo"
              v-if="accountEmailFlag"
              key="2"
              type="email"
              ref="email_ref"
              type_num="13"
              :disabled="true"
            ></d-bind>
          </div>
          <!-- 已绑定手机(修改手机) -->
          <div v-if="bindType === 'mobile' && mobileBind">
            <d-bind
              :accountInfo="accountInfo"
              v-if="mobileStatus"
              key="1"
              type="mobile"
              ref="mobile_ref"
              type_num="4"
              :disabled="true"
            ></d-bind>
            <!-- 新手机号 -->
            <d-bind
              :accountInfo="accountInfo"
              key="2"
              type="mobile"
              type_num="3"
              :changeData="true"
              ref="mobileChange_ref"
            ></d-bind>
            <d-bind
              :accountInfo="accountInfo"
              v-if="emailStatus"
              key="3"
              type="email"
              ref="email_ref"
              type_num="20"
              :disabled="true"
            ></d-bind>
          </div>
          <!-- 未绑定邮箱 -->
          <div v-if="bindType === 'email' && !emailBind">
            <d-bind
              :accountInfo="accountInfo"
              v-if="accountMobileFlag"
              type="mobile"
              type_num="14"
              ref="mobile_ref"
              :disabled="true"
            ></d-bind>
            <d-bind
              :accountInfo="accountInfo"
              type="email"
              type_num="6"
              ref="email_ref"
            ></d-bind>
          </div>
          <!-- 已绑定邮箱（） -->
          <div v-if="bindType === 'email' && emailBind">
            <d-bind
              :accountInfo="accountInfo"
              v-if="emailStatus"
              type="email"
              type_num="6"
              ref="email_ref"
              :disabled="true"
            ></d-bind>
            <d-bind
              :accountInfo="accountInfo"
              type="email"
              type_num="6"
              ref="email_ref"
            ></d-bind>
            <d-bind
              :accountInfo="accountInfo"
              type="mobile"
              type_num="14"
              ref="mobile_ref"
              :disabled="true"
            ></d-bind>
          </div>
          <!-- 谷歌 -->
          <d-google
            v-if="openGoogle"
            :label="translateTitle('谷歌验证码')"
            ref="google_ref"
          ></d-google>
          <div slot:footer class="footer common-dialog-footer">
            <g-button
              class="cancelBtn dialog-cancel-btn"
              :text="translateTitle('取消')"
              @click="onCancel"
            ></g-button>
            <el-button
              class="confrimBtn dialog-confirm-btn"
              @click="onOk"
              :loading="confirmLoading"
            >
              {{ translateTitle("确定") }}
            </el-button>
            <!-- <g-button plain text="确定" @click="onOk"></g-button> -->
          </div>
        </template>
      </el-dialog>

      <!-- 谷歌二次验证 -->
      <el-dialog
        class="verification"
        width="500px"
        @open="onDialogOpen"
        :destroy-on-close="true"
        :visible.sync="verificationVisible"
        :close-on-click-modal="false"
        :before-close="onCancel"
      >
        <GoogleDialog
          :accountInfo="accountInfo"
          :emailStatus="emailStatus"
          :mobileStatus="mobileStatus"
          :googleStatus="googleStatus"
          @onCancel="onCancel"
          @subSuccess="subGoogleSuccess"
        ></GoogleDialog>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import TopTitle from "./components/Title";
import SettingItem from "./components/SettingItem";
import StatusTag from "./components/StatusTag";
import ChangePwd from "./components/DChangePwd";
import DBind from "./components/DBind";
import DGoogle from "./components/DGoogle";
import GButton from "./components/Button";
import GoogleBind from "./components/google/GoogleBind";
import GoogleDialog from "./components/google/GoogleDialog";
import { showMessage } from "@/components/dialog/message";
import * as Account from "@/model/user/account";
import { getUserInfo } from "@/model/ucenter/ucenter";
import commonMixin from "@/components/common/commonMixin.vue";
import md5 from "js-md5";
import {
  updatePassword,
  bindMobile,
  bindEmail,
  undataMobile,
  bingGoogleVerifyStatus,
} from "@/model/ucenter/security";
import globalFun from "@/assets/js/globalFun.js";

export default {
  mixins: [commonMixin],
  data() {
    return {
      pwdDialogVisible: false,
      bindDialogVisible: false,
      verificationVisible: false,
      bindType: "mobile", // 绑定/修改 类型
      googleVerifyStatus: true,
      accountEmailFlag: true,
      accountMobileFlag: true,

      mobileBind: false, // 手机绑定状态
      emailBind: false, // 邮箱绑定状态
      mobileStatus: false, // 手机验证状态 false:
      emailStatus: false, // 邮箱验证状态
      openGoogle: false, // 谷歌验证状态
      googleStatus: 1, // 谷歌验证状态
      all_areacode: {}, // 所有区号
      openSetGoogle: false, // 打开设置谷歌

      googleValidator: [
        { img: "./../../assets/img/AppleStore.png", link: "" },
        { img: "./../../assets/img/AppleStore.png", link: "" },
        { img: "./../../assets/img/AppleStore.png", link: "" },
      ],

      accountInfo: {},
      authInfo: {},
      isBindEmail: false, // 是否在绑定邮箱
      changeDBindMobile: false, // 是否修改
      confirmLoading: false,
      is_bind_security: 0,
      voucherInterval: null,
      globalFun,
    };
  },
  async created() {
    // await this.getGooglStatus();
    this.getUserInfo();
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  methods: {
    onDialogOpen() {
      this.getUserInfo();
    },
    onChangePassWord() {
      if (this.is_bind_security == 1) {
        showMessage({
          message: this.translateTitle(`请先绑定谷歌、手机或者邮箱`),
          type: "warning",
        });
      } else {
        this.pwdDialogVisible = true;
      }
    },
    async getUserInfo() {
      // let { authInfo, accountInfo } = await Account.getUserInfo();
      let { authInfo, accountInfo } = sessionStorage.userInfo
        ? JSON.parse(sessionStorage.userInfo)
        : {};
      this.accountInfo = accountInfo;
      this.authInfo = authInfo;
      if (authInfo) {
        this.is_bind_security = authInfo.is_bind_security;
        this.mobileStatus = authInfo.open_mobile === 0 ? false : true;
        this.emailStatus = authInfo.open_email === 0 ? false : true;
        this.openGoogle = authInfo.open_google === 0 ? false : true;
        this.googleStatus = authInfo.open_google;
        if (
          this.openGoogle == true &&
          this.mobileStatus == false &&
          this.emailStatus == false
        ) {
          this.googleVerifyStatus = false;
        } else {
          this.googleVerifyStatus = true;
        }
      }
      if (accountInfo) {
        this.mobileBind = accountInfo.mobile ? true : false;
        this.emailBind = accountInfo.email ? true : false;
      }
    },
    async getGooglStatus() {
      let { status, data, msg } = await bingGoogleVerifyStatus();
      if (status !== 200) {
        return;
      }
      this.googleStatus = data.bing_google_status;
    },
    // 绑定/修改手机
    onBindMobileClick() {
      let { mobileBind } = this;
      this.bindType = "mobile";
      let username = this.accountInfo.username;
      let open_email = this.authInfo.open_email;
      if (username && open_email === 0) {
        this.accountEmailFlag = false;
        this.accountMobileFlag = true;
      }
      this.$nextTick(() => (this.bindDialogVisible = true));
      if (mobileBind) {
        this.changeDBindMobile = true;
      }
    },
    // 绑定邮箱
    onBindEmailClick() {
      this.bindType = "email";
      let username = this.accountInfo.username;
      let open_mobile = this.authInfo.open_mobile;
      if (username && open_mobile === 0) {
        this.accountMobileFlag = false;
        this.accountEmailFlag = true;
      }
      this.$nextTick(() => (this.bindDialogVisible = true));
      this.isBindEmail = true;
    },
    // 打开设置绑定谷歌
    onBindGoogleClick() {
      this.openSetGoogle = true;
    },
    // 关闭谷歌认证
    closeGoogle() {
      this.verificationVisible = true;
    },
    //
    async subGoogleSuccess() {
      this.getGooglStatus();
      await this.newGetUserInfo();
      this.openSetGoogle = false;
    },
    onCancel() {
      if (this.$refs.changePwd_ref) {
        this.$refs.changePwd_ref.clearCode();
      }
      if (this.$refs.google_ref) {
        this.$refs.google_ref.clearCode();
      }
      this.pwdDialogVisible = false;
      this.bindDialogVisible = false;
      this.verificationVisible = false;
      this.changeDBindMobile = false;
      this.isBindEmail = false;
    },
    // 确认提交
    async onOk() {
      let params = {};
      let changePwd_status,
        mobile_status,
        change_mobile_status,
        email_status,
        google_status;

      changePwd_status = this.$refs.changePwd_ref
        ? this.$refs.changePwd_ref.submitForm()
        : true;
      mobile_status = this.$refs.mobile_ref
        ? this.$refs.mobile_ref.submitForm()
        : true;
      change_mobile_status = this.$refs.mobileChange_ref
        ? this.$refs.mobileChange_ref.submitForm()
        : true;
      email_status = this.$refs.email_ref
        ? this.$refs.email_ref.submitForm()
        : true;
      google_status = this.$refs.google_ref
        ? this.$refs.google_ref.submitForm()
        : true;

      if (
        !(
          changePwd_status &&
          mobile_status &&
          change_mobile_status &&
          email_status &&
          google_status
        )
      )
        return;

      /* 密码 */
      if (this.$refs.changePwd_ref) {
        let data = this.$refs.changePwd_ref.submitForm();
        params = { ...data };
        delete params.confirmNewPwd;
        params.old_password = md5(params.old_password);
        params.password = md5(params.password);
      }
      /* 手机 */
      if (this.$refs.mobile_ref) {
        let data = this.$refs.mobile_ref.submitForm();
        params = Object.assign(params, { ...data });
      }
      /* 新手机 */
      if (this.$refs.mobileChange_ref) {
        let data = this.$refs.mobileChange_ref.submitForm();
        data.code = data.mobile_code;
        delete data.mobile_code;
        params = Object.assign(params, { ...data });
      }
      /* 邮箱 */
      if (this.$refs.email_ref) {
        let data = this.$refs.email_ref.submitForm();
        params = Object.assign(params, { ...data });
      }
      /* 谷歌 */
      if (this.$refs.google_ref) {
        let data = this.$refs.google_ref.submitForm();
        params = Object.assign(params, { ...data });
      }

      /* 提交请求 */
      // 修改密码
      if (this.$refs.changePwd_ref) {
        await updatePassword(params).then(res =>
          this.successTip(res, this.translateTitle(`密码修改成功`))
        );
        return;
      }
      // 绑定手机
      if (!this.mobileStatus && !this.isBindEmail) {
        //!this.mobileStatus && this.bindDialogVisible
        await bindMobile(params).then(res =>
          this.successTip(res, this.translateTitle(`手机绑定成功`))
        );
        return;
      }
      // 修改手机
      if (this.$refs.mobileChange_ref) {
        params["old_mobile_code"] = params["mobile_code"];
        params["mobile_code"] = params["code"];
        await undataMobile(params).then(res =>
          this.successTip(res, this.translateTitle(`修改手机号成功`))
        );
        return;
      }
      // 绑定邮箱
      if (this.$refs.email_ref && this.isBindEmail) {
        await bindEmail(params).then(res =>
          this.successTip(res, this.translateTitle(`邮箱绑定成功`))
        );
        return;
      }
    },
    // 密码修改状态判断
    checkPwdStatus(callback) {
      let changePwd_status = this.$refs.changePwd_ref
        ? this.$refs.changePwd_ref.submitForm()
        : true;
      let status = changePwd_status ? true : false;
      callback(status);
    },
    // 成功提醒
    successTip(res, message) {
      this.confirmLoading = false;
      if (res.status === 200) {
        this.onCancel();
        showMessage({ message, type: "success" });
        this.newGetUserInfo();
      } else if (res.status === -6071004) {
        this.$refs.mobile_ref ? this.$refs.mobile_ref.clearCode() : null;
        this.$refs.mobileChange_ref
          ? this.$refs.mobileChange_ref.clearCode()
          : null;
        this.$refs.email_ref ? this.$refs.email_ref.clearCode() : null;
      } else {
        showMessage({ message: res.msg ? res.msg : res.status });
      }
    },
    // 获取新的用户信息
    async newGetUserInfo() {
      // 从新获取用户信息
      await getUserInfo().then(({ data, msg, status }) => {
        if (status === 200) {
          if (data != null) {
            sessionStorage.setItem("userInfo", JSON.stringify(data));
          }
          this.getUserInfo();
        } else {
          showMessage({ message: msg ? msg : status });
        }
      });
    },
  },
  computed: {
    googleBtnText() {
      let tempVal = "";
      let { googleStatus } = this;
      switch (googleStatus) {
        case 1:
          tempVal = this.translateTitle(`关闭认证`);
        case 2:
          tempVal = this.translateTitle(`关闭`);
      }
      return tempVal;
    },
  },
  components: {
    TopTitle,
    SettingItem,
    StatusTag,
    ChangePwd,
    GButton,
    DBind,
    DGoogle,
    GoogleBind,
    GoogleDialog,
  },
};
</script>

<style lang="scss">
.setting-item-r {
  button {
    user-select: none;
  }
}
input {
  user-select: auto;
}
textarea {
  user-select: auto;
}
.change-mobile-email {
  .el-dialog {
    /* display: flex;
    flex-direction: column;
    margin: 0 !important; */
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px); */
  }
  .el-dialog__header {
    height: 0px !important;
    padding: 19px 0px 30px 30px !important;
  }
  .el-dialog__body {
    padding: 0px 30px 30px 30px !important ;
  }
}
.security-setting {
  min-height: 1010px !important;
  border-radius: 4px;
  overflow: hidden;
  .el-select-dropdown__list {
    width: 420px;
  }

  .setting-content {
    // height: 1010px;
    .app-uncter-content {
      .setting-items {
        margin: 20px 10px;
        display: flex;
        flex-wrap: wrap;
        .setting-item {
          background: #ffffff;
          padding: 0px 20px;
          min-width: 766px;
          flex: 1;
          border-radius: 4px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
          height: 100px;
          margin: 0px 10px 20px 10px;
        }
      }
    }
  }

  &-items {
    margin-left: 60px;
  }

  // dialog
  .g-dialog {
    .el-dialog__header {
      height: 0px !important;
      padding: 19px 0px 30px 30px !important;
    }
    .el-dialog__body {
      padding: 0px 30px 30px 30px !important ;
    }
    .el-dialog {
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      position: absolute;
      /* top: 50%;
      left: 50%; */
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      /*height:600px;*/
      max-height: calc(100% - 30px);
      max-width: calc(100% - 30px);
    }
    &.change-pwd {
      // .el-dialog{
      //   margin-top: 8vh !important;
      // }
      .el-form-item {
        margin-bottom: 15px !important;
      }
    }
    .el-dialog__header {
      height: 68px;
      padding: 19px 0;
      padding-left: 31px;
      box-sizing: border-box;
      text-align: left;
    }
    .el-dialog__body {
      // padding: 2px 50px 39px 30px;
      padding: 2px 30px 30px 30px;
      .footer {
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
      .el-form-item__label {
        text-align: left;
      }
      button {
        width: 160px;
        height: 40px;
      }
      .cancelBtn {
        margin-right: 10px;
        border-radius: 3px;
        border: solid 1px #c2c7d0;
        color: #354052 !important;
        font-size: 14px;
        font-weight: 400;
        &:hover {
          color: #354052 !important;
          background: #ffffff;
          border: solid 1px #c2c7d0;
        }
      }
      .confrimBtn {
        color: #fff;
        border-radius: 3px;
        border: 0px;
        font-size: 14px;
        font-weight: 400;
        background-image: linear-gradient(282deg, #ee244c 21%, #c61b4e 82%);
        &:hover {
          color: #fff;
          background-image: linear-gradient(282deg, #ee244c 21%, #c61b4e 82%);
        }
      }
    }
  }
  .verification {
    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
      .common-save {
        padding: 0 !important;
      }
    }
  }
  .open-google {
    width: 100%;
    font-size: 14px;
    line-height: 42px;
    &:hover {
      background-color: rgba(39, 198, 131, 0.8);
    }
  }
  input {
    border-radius: 2px;
  }
}
</style>
