<template>
  <div class="dbind">
    <el-form
      class="g-form-v2"
      label-position="top"
      ref="dBindForm"
      :rules="formRules"
      :model="formData"
    >
      <div class="area-mobile" v-if="isMobile">
        <!-- 手机区号 -->
        <el-form-item
          prop="area"
          :label="translateTitle('手机区号')"
          class="area"
        >
          <!-- <el-select
            v-model="formData.area"
            :disabled="disabled"
            filterable
            ref="searchSelect"
            @input.native="filterData"
            :popper-append-to-body="false"
          > -->
          <el-select
            id="areaSelect"
            v-model="formData.area"
            :disabled="disabled"
            filterable
            ref="searchSelect"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="(item, index) in all_areacode"
              :key="index"
              :value="item.phone_code"
              :label="
                '+' +
                  item.phone_code +
                  `                ${item.iso} ${item.iso3} ${item.jp} ${item.kr} ${item.zh_CN} ${item.zh_HK}`
              "
            >
              <p class="option-p">
                <span>{{ item[language] }}</span>
                <span>{{ item.phone_code }}</span>
              </p>
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 手机号 -->
        <el-form-item
          prop="mobile"
          :label="translateTitle('手机号')"
          class="mobile"
        >
          <!-- <el-input
            v-model="formData.mobile"
            :placeholder="translateTitle('请输入手机号')"
            maxlength="20"
            oninput="value=value.replace(/[^\d]/g, '')"
            :disabled="disabled"
          ></el-input> -->
          <el-input
            v-if="disabled"
            :value="globalFun.userDecryption(formData.mobile, 'mobile')"
            :placeholder="translateTitle('请输入手机号')"
            maxlength="20"
            oninput="value=value.replace(/[^\d]/g, '')"
            :disabled="true"
          ></el-input>
          <el-input
            v-else
            v-model="formData.mobile"
            :placeholder="translateTitle('请输入手机号')"
            maxlength="20"
            oninput="value=value.replace(/[^\d]/g, '')"
            :disabled="false"
          ></el-input>
        </el-form-item>
      </div>
      <!-- 手机验证码 -->
      <el-form-item
        class="mobile-code"
        prop="mobile_code"
        :label="translateTitle('手机验证码')"
        v-if="isMobile"
      >
        <div
          :class="{ common: true, verificate: verificate, bgColor: bgColor }"
        >
          <el-input
            autocomplete="off"
            v-model="formData.mobile_code"
            :placeholder="translateTitle('请输入手机验证码')"
            maxlength="6"
            oninput="value=value.replace(/[^\d]/g, '')"
          ></el-input>
          <div class="code">
            <span @click="getCode4Mobile" v-if="countDown == -1">
              {{ translateTitle("获取验证码") }}</span
            >
            <!-- <span @click="getCode" v-if="countDown == -1">
              {{ translateTitle("获取验证码") }}</span
            > -->
            <span class="disable-code" v-else>{{ countDown }}s</span>
          </div>
        </div>
        <Geetest
          v-if="type_num == 3 && !disabled"
          :isGeet="isShowGeet"
          :isRegister="false"
          @geetParam="success"
        />
      </el-form-item>
      <!-- 邮箱 -->
      <el-form-item
        prop="email"
        :label="translateTitle('邮箱')"
        v-if="!isMobile && !disabled"
      >
        <div>
          <el-input
            maxlength="50"
            v-model.trim="formData.email"
            :placeholder="translateTitle('请输入邮箱账号')"
            :disabled="disabled"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        class="email-code"
        prop="email_code"
        :label="
          disabled
            ? translateTitle('请输入') +
              ' ' +
              globalFun.userDecryption(formData.email, 'email') +
              ' ' +
              translateTitle('收到的验证码') +
              ':'
            : translateTitle('邮箱验证码')
        "
        v-if="!isMobile"
      >
        <div
          :class="{ common: true, verificate: verificate, bgColor: bgColor }"
        >
          <el-input
            autocomplete="off"
            v-model="formData.email_code"
            :placeholder="translateTitle('请输入邮箱验证码')"
            maxlength="6"
            oninput="value=value.replace(/[^\d]/g, '')"
          ></el-input>
          <div class="code">
            <span @click="getCode" v-if="countDown == -1">{{
              translateTitle("获取验证码")
            }}</span>
            <span class="disable-code" v-else>{{ countDown }}s</span>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { showMessage } from "@/components/dialog/message";
import { apiGetStaticTableInfo, SETTING_SV_ID } from "@/model/settings";
import {
  bindMobileCode,
  changeMobileCode,
  verifyMobileCode,
  bindEmailCode,
  changeEmailCode,
  verifyEmailCode,
  transferMobileCode,
  transferEmailCode,
  sendSmsCode,
  sendEmailCode,
} from "@/model/assets/index";
import { mapActions, mapGetters } from "vuex";
import { checkMobile } from "@/assets/js/checkInput";
import commonMixin from "@/components/common/commonMixin.vue";
import globalFun from "@/assets/js/globalFun.js";
export default {
  mixins: [commonMixin],
  // 用户已绑定的账号数据，验证码类型，手机/邮箱，是否禁用，修改数据，验证码位置，背景色
  components: { Geetest: () => import("@/components/Geetest.vue") },
  props: [
    "accountInfo",
    "isChangePwd",
    "type_num",
    "type",
    "disabled",
    "changeData",
    "verificate",
    "bgColor",
  ],
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  data() {
    const isMobile = this.type === "mobile";
    let area = +this.accountInfo.area || 86;
    let mobile, email;
    if (!this.changeData) {
      mobile = this.accountInfo.mobile;
      email = this.accountInfo.email;
    }

    const validatorMobild = (rule, value, callback) => {
      const result = checkMobile(value);
      if (result && result.tip) {
        callback(new Error(result.tip));
      } else {
        callback();
      }
    };

    return {
      isFetchCode: false,
      countDown: -1, // 倒计时
      countDownTimer: null, // 倒计时容器
      numLabel: isMobile ? "手机号码" : "邮箱账号",
      codeLabel: isMobile ? "手机验证码" : "邮箱验证码",
      isMobile, // 是否用于手机号输入框
      all_areacode: {}, // 所有区号
      formData: {
        area,
        mobile,
        email,
        mobile_code: "",
        email_code: "",
      },
      formRules: {
        area: [
          {
            required: true,
            message: this.translateTitle(`请选择区号`),
            trigger: "change",
          },
        ],
        // mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { validator: validatorMobild, trigger: 'blur' }],
        mobile: [
          {
            required: true,
            message: this.translateTitle(`请输入手机号`),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.translateTitle(`请输入邮箱`),
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/,
            message: this.translateTitle(`邮箱格式不正确`),
            trigger: "blur",
          },
        ],
        mobile_code: [
          {
            required: true,
            message: this.translateTitle(`请输入手机验证码`),
            trigger: "blur",
          },
          {
            max: 6,
            message: this.translateTitle(`验证码应为 6 位数字`),
            trigger: "blur",
          },
        ],
        email_code: [
          {
            required: true,
            message: this.translateTitle(`请输入邮箱验证码`),
            trigger: "blur",
          },
          {
            max: 6,
            message: this.translateTitle(`验证码应为 6 位数字`),
            trigger: "blur",
          },
        ],
      },
      checkPwdStatus: false,
      isShowGeet: false,
      geetParam: null,
      globalFun,
    };
  },
  async created() {
    await this.getAreaCode();
  },
  mounted() {
    this.dom();
  },
  methods: {
    dom() {
      if (this.isMobile) {
        const input = document.querySelector("#areaSelect");
        input.setAttribute("maxLength", 5);
      }
    },

    // 清除验证码
    clearCode() {
      this.formData.mobile_code = "";
      this.formData.email_code = "";
    },
    // 获取用户信息
    async getUserInfo() {},
    // 获取手机区号
    async getAreaCode() {
      await apiGetStaticTableInfo(SETTING_SV_ID.static_sms_country).then(
        res => {
          if (res.status === 200) {
            this.all_areacode = res.data;
          } else {
            showMessage({ message: res.msg });
          }
        }
      );
    },
    changePwdStatus(status) {
      return status;
    },
    filterData(val) {
      var str = this.$refs.searchSelect.$data.selectedLabel;
      if (str.length > 5) {
        this.$refs.searchSelect.$data.selectedLabel = str.substr(0, 4);
        // this.$refs.searchSelect.$data.selectedLabel = str.subString(0,5)
      }
    },
    getCode4Mobile() {
      if (this.type_num == 3) {
        this.isShowGeet = !this.isShowGeet;
      } else {
        this.getCode();
      }
    },
    success(geetParam) {
      this.geetParam = geetParam;
      this.getCode();
    },
    // 获取验证码
    async getCode() {
      if (this.isFetchCode) return;
      this.isFetchCode = true;

      let type = this.type_num;
      let {
        isChangePwd,
        isMobile,
        formData: { mobile, area, email },
      } = this;

      // if (isChangePwd) {
      //   this.$emit("checkPwdStatus", status => (this.checkPwdStatus = status));
      //   if (!this.checkPwdStatus) {
      //     this.isFetchCode = false;
      //     return;
      //   }
      // }
      // 手机

      if (isMobile) {
        if (!mobile || /^\s*$/.test(mobile)) {
          this.isFetchCode = false;
          this.$refs.dBindForm.validateField("mobile");
          return;
        }
        // await sendSmsCode({ type, mobile, area }).then(res => {
        //   this.sendSuccess(res);
        // });
        if (type == 3) {
          await bindMobileCode({ type, mobile, area, ...this.geetParam }).then(
            res => {
              this.sendSuccess(res);
            }
          );
        } else if (type == 4) {
          await changeMobileCode().then(res => {
            this.sendSuccess(res);
          });
        } else if (type == 25) {
          await transferMobileCode().then(res => {
            this.sendSuccess(res);
          });
        } else {
          await verifyMobileCode().then(res => {
            this.sendSuccess(res);
          });
        }
      } else {
        if (!email || /^\s*$/.test(email)) {
          this.isFetchCode = false;
          this.$refs.dBindForm.validateField("email");
          return;
        }
        if (type == 6) {
          await bindEmailCode({ type, email }).then(res => {
            this.sendSuccess(res);
          });
        } else if (type == 26) {
          await transferEmailCode().then(res => {
            this.sendSuccess(res);
          });
        } else {
          await verifyEmailCode().then(res => {
            this.sendSuccess(res);
          });
        }
        // await sendEmailCode({ type, email }).then(res => {
        //   this.sendSuccess(res);
        // });
      }
    },
    // 发送成功
    sendSuccess(res) {
      if (res.status === 200) {
        this.countDown = 60;
        this.countDownTimer = setInterval(() => {
          if (this.countDown <= 0) {
            clearInterval(this.countDownTimer);
            this.isFetchCode = false;
            this.countDown = -1;
            return;
          }
          this.countDown -= 1;
        }, 1000);
        showMessage({
          message: this.translateTitle(`发送成功`),
          type: "success",
        });
      } else {
        this.isFetchCode = false;
        showMessage({ message: res.msg });
      }
    },
    // 登录
    submitForm() {
      // dBindForm
      let statusData;
      let { isMobile, formData } = this;
      this.$refs.dBindForm.validate(valid => {
        if (valid) {
          if (isMobile) {
            delete formData.email;
            delete formData.email_code;
          } else {
            delete formData.area;
            delete formData.mobile;
            delete formData.mobile_code;
          }
          statusData = formData;
        } else {
          statusData = false;
        }
      });

      return JSON.parse(JSON.stringify(statusData));
    },
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.countDownTimer);
    next();
  },
};
</script>
<style lang="scss">
.g-form-v2 {
  input:focus {
    border-color: #c61b3d;
  }

  /* .el-select .el-input.is-focus .el-input__inner {
    border-color: #030303;
  } */

  .el-form-item {
    position: relative;
    margin-bottom: 15px !important;
  }

  /* 区号和手机号 */
  .area-mobile {
    // display: flex;
    .el-scrollbar {
      // width: 100%;
    }
    .is-empty {
      width: 100px;
    }
    .el-select-dropdown__empty {
      padding: 0px !important;
    }

    .area {
      width: 99px !important;
      height: 41px;
      margin-right: 10px;
      .el-select {
        .el-select-dropdown__item {
          p {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    .mobile {
      flex: 1;
    }
  }

  /* 验证码 */
  .mobile-code,
  .email-code {
    label {
      float: none !important;
    }
    .common {
      color: #3a7dff;
      position: relative;
      .el-input {
        flex: 1;
      }
      .code {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        box-sizing: border-box;
        span {
          cursor: pointer;
          font-size: 14px;
          padding: 0 10px;
          box-sizing: border-box;
        }
        .disable-code {
          color: #9aa1b1;
          cursor: not-allowed;
        }
      }
      &.verificate {
        position: relative;
        .code {
          position: absolute;
          right: 0px;
          top: 50%;
        }
      }
      // &.bgColor {
      //   .code {
      //     span {
      //       color: #fff;
      //       background-color: rgba(39, 198, 131, 1);
      //       &:hover {
      //         background-color: rgba(39, 198, 131, 0.8);
      //       }
      //       &.disable-code {
      //         color: #a2a5ae;
      //         background-color: #e7e7e7;
      //         border-color: #e7e7e7;
      //         cursor: not-allowed;
      //       }
      //     }
      //   }
      // }
    }
  }
}
</style>
