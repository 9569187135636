import api from "@/assets/js/api";
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

export function apiGetTradePairList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/TradeCoin/getTradePairList`,
    data,
    type: "POST",
  });
}
export function apiGetSpotList(data = {}) {
  return api({
    url: VUE_APP_APP + `/MarketV2/getContractList`,
    data,
    type: "POST",
  });
}
// U本位
export function apiGetContractList(data = {}) {
  return api({
    url: VUE_APP_APP + `/MarketV2/getContractList`,
    data,
    type: "POST",
  });
}
