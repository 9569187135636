<template>
  <div class="affilliate-program">
    <div class="content">
      <div class="banner">
        <div class="left">
          <div class="title">
            {{ translateTitle("Join MaskEX Affiliate Program") }}
          </div>
          <p>
            {{ translateTitle("Get your daily commission and monthly bonus!") }}
          </p>
          <a
            :href="routeSplicing(language, `affiliate-program`)"
            target="_self"
          >
            {{ translateTitle("Apply now") }}
          </a>
        </div>
        <div class="right">
          <img src="@/assets/img/affiliate.webp" alt="" />
        </div>
      </div>
    </div>
    <div class="choose">
      <div class="title">
        {{ translateTitle("Why Choose MaskEX Affiliate Program?") }}
      </div>
      <ul>
        <li>
          <div>
            <img src="@/assets/img/program1.webp" alt="" />
          </div>
          <p>{{ translateTitle("Industry Leading Commission Rate") }}</p>
        </li>
        <li>
          <div>
            <img src="@/assets/img/program2.webp" alt="" />
          </div>
          <p>{{ translateTitle("Boost your traffic") }}</p>
        </li>
        <li>
          <div>
            <img src="@/assets/img/program3.webp" alt="" />
          </div>
          <p>{{ translateTitle("Personal Branding") }}</p>
        </li>
        <li>
          <div>
            <img src="@/assets/img/program4.webp" alt="" />
          </div>
          <p>{{ translateTitle("Dedicated Service Manager") }}</p>
        </li>
        <li>
          <div>
            <img src="@/assets/img/program5.webp" alt="" />
          </div>
          <p>{{ translateTitle("Live Privileges") }}</p>
        </li>
        <li>
          <div>
            <img src="@/assets/img/program5.webp" alt="" />
          </div>
          <p>{{ translateTitle("Prioritized Activities") }}</p>
        </li>
      </ul>
    </div>
    <div class="commission">
      <div class="title">{{ translateTitle("How To Earn Commission?") }}</div>
      <div class="item-content">
        <div class="left">
          <p>{{ translateTitle("Submit Application") }}</p>
          <div>
            {{
              translateTitle(
                "Submit your application to us if you’re KOL,Blogger, Youtubers, Crypto Academy etc."
              )
            }}
            <br />{{
              translateTitle(
                "Register a MaskEX Account and submit your information here"
              )
            }}[ {{ translateTitle("link to form") }}]
          </div>
        </div>
        <div class="right">
          <img src="@/assets/img/commission1.webp" alt="" />
        </div>
        <label>1</label>
        <span></span>
      </div>
      <div class="item-content">
        <div class="left">
          <img src="@/assets/img/commission2.webp" alt="" />
        </div>
        <div class="right">
          <p>{{ translateTitle("Share your referral link") }}</p>
          <div>
            {{
              translateTitle(
                "Start promoting your referral link over your social media platform."
              )
            }}
          </div>
        </div>
        <label>2</label>
        <span></span>
      </div>
      <div class="item-content">
        <div class="left">
          <p>{{ translateTitle("Sit back and wait for your commission!") }}</p>
          <div>
            {{
              translateTitle(
                "Our Affiliate team will get back to you as soon as possible."
              )
            }}
          </div>
        </div>
        <div class="right">
          <img src="@/assets/img/commission3.webp" alt="" />
        </div>
        <label>3</label>
      </div>
    </div>
    <div class="footer">
      <div class="title">
        {{ translateTitle("Click to become a MaskEX Affiliate Now") }}
      </div>
      <p>
        {{ translateTitle("Earn passive income and enjoy a life of freedom") }}
      </p>
      <a class="btn" :href="routeSplicing(language, `affiliate-program`)">
        {{ translateTitle("Start to earn") }}
      </a>
    </div>
  </div>
</template>
<script>
import { apiGetStaticTableInfo } from "@/model/settings";
// import { getStaticCoinList as getCoinList } from "@/model/assets/index";
import { mapGetters } from "vuex";
import commonMixin from "@/components/common/commonMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  data() {
    return {
      marketing: 0,
      imageUrl: "",
      programForm: {
        name: "",
        email: "",
        contact: "",
        uid: "",
        tid: "",
        marketing: "",
        area: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language",
    }),
  },
  watch: {},
  async created() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isType = file.type === "image/jpg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isType) {
        this.$message.error(
          this.translateTitle("上传头像图片只能是 JPG ｜ PNG 格式!")
        );
      }
      if (!isLt2M) {
        this.$message.error(
          this.translateTitle("上传头像图片大小不能超过 2MB!")
        );
      }
      return isType && isLt2M;
    },
    routeSplicing,
    goLogin() {
      if (!this.userInfo) {
        this.$message({
          message: this.translateTitle("请先登录"),
          type: "warning",
          customClass: "zZindex",
        });
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
        });
      }
    },
    chooseMarketing(index) {
      this.marketing = index;
    },
    goToRegister() {
      this.$router.push(this.routeSplicing(this.language, "register"));
    },
    async submit() {
      const req = {
        name: this.programForm.name,
        email: this.programForm.email,
        mobile: this.programForm.area + "" + this.programForm.contact,
        telegram: this.programForm.tid,
        is_exceeded_1_million: this.marketing,
        pre_exchange_platform: this.programForm.marketing,
        screenshot: this.imageUrl,
      };
      this.btnLoading = true;
      const { data, status } = await apiBrokersProgram(req);
      if (status == 200) {
        this.$message(this.translateTitle("添加成功"), "success");
      }
      this.btnLoading = false;
    },
  },
};
</script>
<style lang="scss">
.affilliate-program {
  .content {
    width: 1200px;
    margin: 0 auto;
    .banner {
      display: flex;
      flex: 1;
      .left {
        .title {
          margin-top: 192px;
          width: 650px;
          font-size: 50px;
          font-weight: bold;
          color: #15192a;
        }
        p {
          margin-top: 30px;
          font-size: 24px;
          width: 380px;
          color: #171f2a;
        }
        a {
          margin-top: 50px;
          width: 200px;
          height: 48px;
          display: block;
          flex-grow: 0;
          gap: 20px;
          padding: 10px 50px;
          border-radius: 6px;
          background-color: #15beb4;
          font-size: 18px;
          font-weight: 500;
          color: #fff;
        }
      }
      .right {
        margin-top: 105px;
        margin-bottom: 77px;
      }
    }
  }
  .choose {
    width: 100%;
    background-color: #f7f9fc;
    .title {
      padding-top: 84px;
      font-size: 36px;
      font-weight: bold;
      text-align: center;
      color: #15192a;
    }
    ul {
      width: 1200px;
      margin: 80px auto;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 80px;
      li {
        width: 400px;
        display: flex;
        margin-top: 40px;
        p {
          margin-top: 20px;
          margin-left: 20px;
          line-height: 1.2;
        }
      }
    }
  }
  .commission {
    width: 1200px;
    margin: 0 auto;
    .title {
      font-size: 36px;
      font-weight: bold;
      color: #15192a;
      text-align: center;
      margin-bottom: 60px;
    }
    .item-content {
      display: flex;
      position: relative;
      label {
        width: 36px;
        height: 36px;
        background-color: #15beb4;
        border-radius: 50%;
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        line-height: 36px;
        left: 0;
        position: absolute;
        top: 50%;
      }
      span {
        width: 1px;
        height: 80px;
        position: absolute;
        background-color: #dee1e3;
        top: 90%;
        left: 17px;
      }
      .left {
        width: 50%;
        text-align: left;
        padding: 100px;
        p {
          font-size: 30px;
          font-weight: bold;
          color: #000;
          width: 340px;
          margin-top: 100px;
        }
        div {
          margin-top: 20px;
          font-weight: normal;
          width: 430px;
          color: #666;
        }
      }
      .right {
        width: 50%;
        img {
          float: right;
        }
        div {
          font-weight: normal;
          color: #666;
          width: 430px;
          margin-top: 20px;
        }
        p {
          font-size: 30px;
          width: 340px;
          margin-top: 100px;
          font-weight: bold;
          color: #000;
        }
      }
    }
  }
  .footer {
    width: 1200px;
    background-color: #f7f9fc;
    margin: 100px auto;
    border-radius: 10px;
    text-align: center;
    padding-top: 50px;
    .title {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      color: #15192a;
    }
    p {
      margin-top: 30px;
      font-size: 24px;
      text-align: center;
      color: #666;
    }
    a {
      display: inline-block;
      padding: 10px 50px;
      border-radius: 6px;
      background-color: #15beb4;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      margin: 50px auto;
    }
  }
}
</style>
