<template>
  <div class="stock-page-exchange">
    <div class="layout-block-first">
      <market
        ref="marketComponent"
        :marketList="marketList"
        @onAddOption="onMarketAddOption"
        @on-symbol-change="onSymbolChange"
      ></market>
    </div>
    <div class="layout-block-second">
      <panel
        ref="panelComponent"
        :swap_symbol_id="swap_symbol_id"
        :tradePairArray="tradePairArray"
        :marketList="marketList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
        @onAddOption="onAddOption"
        @onMultipleNumClick="onMultipleNumClick"
      ></panel>
      <kline
        ref="klineComponent"
        :symbol="symbol"
        :coin-from="coinFrom"
        :coin-to="coinTo"
        :depthList="depthList"
      ></kline>
      <trade
        ref="tradeComponent"
        @onMultipleNumClick="onMultipleNumClick"
        :swap_symbol_id="swap_symbol_id"
        :assetsInfo="assetsInfo"
        :marketList="marketList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
        @on-trade-success="onTradeSuccess"
        @isShowExchange="isShowExchange"
        :from_item_id="from_item_id"
        :to_item_id="to_item_id"
      ></trade>
    </div>
    <!-- <div class="layout-block-six">
      <div class="block-six-header">
        <ul>
          <li
            class="classify-area"
            :class="{ active: currIndex == item.value }"
            v-for="item of tabList"
            :key="item.value"
            @click="onAreaChange(item)"
          >
            {{ translateTitle(item.label) }}
            <i v-if="currIndex == item.value" class="item-bar"></i>
          </li>
        </ul>
      </div>
      <depth
        v-if="currIndex == 1"
        :showTitle="false"
        :depthList="depthList"
        :swap_symbol_id="swap_symbol_id"
        :marketList="marketList"
        :tradePair="tradePair"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
      ></depth>
      <deal
        :showTitle="false"
        v-if="currIndex == 2"
        :orderbookList="orderbookList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
      ></deal>
    </div> -->
    <!-- <div class="layout-block-third">
      <depth
        :depthList="depthList"
        :swap_symbol_id="swap_symbol_id"
        :marketList="marketList"
        :tradePair="tradePair"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
      ></depth>
    </div> -->
    <div class="layout-block-fourth">
      <deal
        :orderbookList="orderbookList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
      ></deal>
    </div>
    <div class="layout-block-five">
      <history-order
        :marketList="marketList"
        :assetsInfo="assetsInfo"
        :swap_symbol_id="swap_symbol_id"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
        :from_item_id="from_item_id"
        :to_item_id="to_item_id"
        @on-symbol-change="onSymbolChangeV2"
        ref="historyOrder"
      ></history-order>
    </div>

    <el-dialog
      :title="translateTitle('调整杠杆')"
      custom-class="multiple-dialog"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div>
        <span
          class="symbol-item"
          @click="onMinClick"
          :class="multipleNum == 1 ? 'disabled' : ''"
          >-</span
        >
        <InputNumber
          :disabled="!userInfo"
          v-model="multipleNum"
          @input="onInputChange"
          class="buy-price"
          autocomplete="new-password"
          maxlength="3"
          decimals="0"
          :placeholder="translateTitle('请输入倍数')"
          ref="buyPrice"
        ></InputNumber>
        <span
          class="symbol-item"
          @click="onPlusClick"
          :class="multipleNum == 300 ? 'disabled' : ''"
          >+</span
        >
      </div>
      <div class="block">
        <!-- :disabled="!userInfo" -->
        <el-slider
          :disabled="!userInfo"
          @input="onSliderChange"
          :show-tooltip="false"
          v-model="sliderValue"
          :min="1"
          :max="300"
          :format-tooltip="formatTooltip"
          :step="1"
          :marks="marks[swap_symbol_id]"
        >
        </el-slider>
      </div>
      <span slot="footer" class="dialog-footer" v-if="userInfo">
        <span class="cancel-btn" @click="dialogVisible = false">{{
          translateTitle("取消")
        }}</span>
        <span class="confirm-btn" @click="onMultipleNumConfirm">{{
          translateTitle("确定")
        }}</span>
      </span>
      <span slot="footer" class="dialog-footer-v2" v-else>
        {{ translateTitle("请") }}
        <span class="common-operate" @click="goToLogin">{{
          translateTitle("登录")
        }}</span>
        {{ translateTitle("或") }}
        <span class="common-operate" @click="goToRegister">{{
          translateTitle("注册")
        }}</span>
        {{ translateTitle("调整杠杆") }}
      </span>
    </el-dialog>
    <!-- 汇兑 -->
    <el-dialog
      :title="translateTitle('汇兑')"
      custom-class="exchange-dialog"
      :visible.sync="exchangeShow"
      :show-close="false"
      width="500px"
      :close-on-click-modal="true"
    >
      <div slot="title" class="header-title">
        <span class="title-name">汇兑</span>
        <i class="iconfont iconicon-jilu record"></i>
      </div>
      <div class="pay-area">
        <div class="pay-msg">
          <div class="pay-name">支付</div>
          <div class="balance">
            可用 0 CNY
            <span class="rech">充值</span>
          </div>
        </div>
        <div class="pay-select">
          <div class="pay-select-amount">
            <el-select
              @click.native="isShowCurrency"
              :popper-append-to-body="false"
              :style="{
                background:
                  'url(' +
                  'https://imgs-dev.aevwjlae.com/admin/20201113/03397693702.png' +
                  ') no-repeat 6px center #fff',
                animation: isSwitch ? 'pay-switch 2s' : '',
              }"
              style="width: 109px; height: 30px; border-radius: 15px"
              :value="'USDT'"
            >
            </el-select>
            <div class="pay-value">
              <el-input
                placeholder="请输入支付金额"
                v-model="payValue"
              ></el-input>
            </div>
          </div>
          <div class="pay-all-amount">全部</div>
        </div>
        <div class="pay-limit">单笔限额 100 - 50000 CNY</div>
      </div>
      <div class="currency-switch">
        <img
          class="switch-img"
          :style="{
            transform: !exchangeShow
              ? ''
              : isSwitchImg
              ? 'rotate(180deg)'
              : 'rotate(0deg)',
            transition: 'transform 2s',
          }"
          :class="isSwitch ? 'img-onlyRead' : ''"
          src="../../assets/img/currency-switch.png"
          @click="doSwitch"
        />
      </div>
      <div class="income-area">
        <div class="income-msg">
          <div class="income-name">得到</div>
        </div>
        <div class="income-select">
          <div class="income-select-amount">
            <el-select
              :popper-append-to-body="false"
              :style="{
                background:
                  'url(' +
                  'https://imgs-dev.aevwjlae.com/admin/20201113/03397693702.png' +
                  ') no-repeat 6px center #fff',
                animation: isSwitch ? 'income-switch 2s' : '',
              }"
              style="width: 109px; height: 30px; border-radius: 15px"
              :value="'USDT'"
            >
            </el-select>
            <div class="income-value">
              <el-input
                placeholder="请输入得到金额"
                v-model="incomeValue"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="exchange-rate">
        <div class="exchange-rate-content">
          <div class="proposed-rice">参考价格:</div>
          <div class="exchange-rate-ratio">1 USD = 888.88 CNY</div>
          <i class="icon iconfont iconhuidui-qiehuan"></i>
        </div>
      </div>
      <div class="chart-area">
        <div class="chart-title">
          <div class="dual-currency">
            USD/CNY（<span class="color-green">-0.12%</span>）
          </div>
          <div class="tooltip">
            <i class="cricle-green"></i>买价<i class="cricle-red"></i>卖价
          </div>
        </div>
        <!-- echart -->
        <div class="echart-line">
          <v-chart
            :options="currencyOptions"
            id="currency-chart"
            ref="currencychart"
          />
        </div>
      </div>
      <div class="exchange-dialog-btn">极速兑换</div>
      <el-dialog
        width="440px"
        title="inner Dialog"
        custom-class="inner-dialog"
        :visible.sync="currencyShow"
        :close-on-click-modal="true"
        append-to-body
        :modal="false"
      >
        <el-input placeholder="搜索名称" v-model="currencyValue" clearable>
        </el-input>
        <div
          class="currency-value"
          :key="item"
          v-for="item of [1, 2, 3, 4, 5, 6, 7, 8]"
        >
          <div class="currency-icon-name">
            <img
              class="icon"
              src="https://imgs-dev.aevwjlae.com/admin/20201113/03397693702.png"
            />
            <div class="name">USDT</div>
          </div>
          <div class="currency-count">13.00998</div>
        </div>
      </el-dialog>
    </el-dialog>
    <!-- 确认汇兑 -->
    <el-dialog
      title="换汇确认"
      :visible.sync="exchangeSureShow"
      width="440px"
      custom-class="exchange-sure-dialog"
    >
      <div class="exchange-sure-dialog-content">
        <div class="content-text" v-if="beforeSure">
          是否确认按市场价格将 <span class="text-color">100.99</span> CNY 换为
          <span class="text-color">USD</span> ？
        </div>
        <div slot="footer" class="dialog-footer" v-if="beforeSure">
          <a class="cancel-btn" @click="dialogVisible = false">取 消</a>
          <a class="confirm-btn" @click="dialogVisible = false">确 定</a>
        </div>
        <div class="details-text" v-if="!beforeSure">
          <div class="details-item">
            <div class="item-name">付出金额</div>
            <div class="item-value">3468.23 CNY</div>
          </div>
          <div class="details-item">
            <div class="item-name">汇率</div>
            <div class="item-value">1 HKD = 1.23CNY</div>
          </div>
          <div class="details-item">
            <div class="item-name">得到金额</div>
            <div class="item-value">4324 HKD</div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer" v-if="!beforeSure">
          <a class="cancel-btn" @click="dialogVisible = false">取 消</a>
          <a class="confirm-btn" @click="dialogVisible = false">查看法币资产</a>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Socket from "@/assets/js/socket.js";
import Kline from "./components/stock-kline.vue";
// import Depth from "./components/stock-depth.vue";
import Deal from "./components/stock-deal.vue";
import Market from "./components/stock-market.vue";
import Trade from "./components/stock-trade.vue";
import Panel from "./components/stock-panel.vue";
import InputNumber from "@/components/common/InputNumber";
import { mapGetters } from "vuex";
import store from "@/store/index";
import Cookies from "js-cookie";
import HistoryOrder from "./components/stock-history-order.vue";
import { apiGetOrderStatistic } from "@/model/contractExchange/order.js"; //FIXME
import { apiWsUrl, apiGetDefaultToken } from "@/model/contractExchange/index"; //FIXME
import { apiGetStaticTableInfo } from "@/model/settings";
import { showMessage } from "@/components/dialog/message";
import ECharts from "vue-echarts";
import commonMixin from "@/components/common/commonMixin.vue";
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],

  name: "StockExchange",
  components: {
    Deal,
    Kline,
    // Depth,
    Market,
    Panel,
    Trade,
    InputNumber,
    HistoryOrder,
    "v-chart": ECharts,
  },
  data() {
    return {
      isSwitchImg: false,
      isSwitch: false,
      currencyValue: null,
      payValue: null,
      incomeValue: null,
      exchangeSureShow: false, //换汇确认
      currencyShow: false, //是否显示币种选择
      exchangeShow: false, //是否显示汇兑弹框
      beforeSure: false,
      tabList: [
        {
          value: "1",
          label: "盘口",
        },
        {
          value: "2",
          label: "实时成交",
        },
      ],
      currIndex: "1",
      multipleNum: 0,
      mixMultipleNum: 1,
      maxMultipleNum: 300,
      dialogVisible: false,
      sliderValue: 0,
      symbol: this.$route.params.symbol || "btcusdt",
      coinFrom: "--",
      coinTo: "--",
      coinInfoMap: {},
      coinItemIdMap: {},
      tradePair: [],
      tradePairArray: [],
      marketList: [],
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--",
          },
          list: [],
        },
      },
      assetsInfoList: [],
      orderbookList: [
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
      ],
      klineList: [],
      depthList: {
        a: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
        b: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
      },
      swap_symbol_market: {},
      swap_symbol_id: "",
      old_swap_symbol_id: "",
      from_item_id: "",
      to_item_id: "",
      wsUrl: "",
      token: "",
      pageLoading: false,
      onload: false,
      voucherInterval: null,
      historyDataLength: 0,
      wsContentTime: 0,
      marks: {},
      maxNumber: {},
      marketInfo: {
        last: "",
        currency: { cny: "" },
      },
      currencyOptions: {
        grid: {
          containLabel: true,
          left: "0%",
          right: "1%",
          bottom: "3%",
          top: "5%",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#c2c7d0",
            fontSize: "10",
          },
          data: [
            "2020-06-28",
            "",
            "2020-07-19",
            "",
            "2020-07-27",
            "",
            "2020-08-27",
          ],
        },
        yAxis: {
          type: "value",
          min: "dataMin",
          max: "dataMax",
          position: "right",
          axisLabel: {
            fontSize: "10",
          },
          splitLine: {
            lineStyle: {
              color: "#f7f8fa",
            },
          },
        },
        series: [
          {
            data: [
              70454.49, 61829.13, 33203.78, 43578.42, 23953.07, 54327.71,
              34327.71,
            ],
            type: "line",
            symbol: "none",
            itemStyle: {
              normal: {
                color: "rgba(2,204,135,0.8)",
              },
            },
            areaStyle: {
              normal: {
                color: "rgba(2,204,135,0.1)",
              },
            },
          },
          {
            data: [
              80454.49, 71829.13, 43203.78, 53578.42, 33953.07, 64327.71,
              44327.71,
            ],
            type: "line",
            symbol: "none",
            itemStyle: {
              normal: {
                color: "rgba(198,27,61,0.8)",
              },
            },
            areaStyle: {
              normal: {
                color: "rgba(198,27,61,0.1)",
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      language: "user/language",
      userInfo: "user/userInfo",
    }),

    title() {
      let title = "数字货币银行";
      if (VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
        title = "数字理财平台";
      } else {
        title = "数字货币银行";
      }
      let val =
        this.marketInfoShow.last +
        "  " +
        this.coinFrom.toUpperCase() +
        "  " +
        this.translateTitle("永续") +
        "/" +
        this.coinTo.toUpperCase() +
        " " +
        this.translateTitle(title);
      return val;
    },
    marketInfoShow() {
      let tempMarketInfo = {
        last: "",
        currency: { cny: "" },
      };
      this.marketList.forEach((ele) => {
        if (ele.symbol_id == this.swap_symbol_id) {
          tempMarketInfo = ele;
        }
      });
      return tempMarketInfo;
    },
  },
  async created() {
    // await this.getTradePairList();
    await this.getUrlSymbol();
    await this.getExchangeItemId();
    let tempCookies = Cookies.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      // await this.getToken();
      await this.initURL();
      this.initWs();
    } else {
      this.token = Cookies.get("topcredit_exchange_t");
      await this.initURL();
      this.initWs();
    }
    this.resolveCoin();
    // this.voucherInterval = setInterval(() => {
    //   document.title = this.title;
    // }, 500);
  },
  mounted() {},
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  watch: {
    async userInfo(newValue, oldValue) {
      if (this.userInfo == null) {
        await this.getToken();
        await this.initURL();
        this.initWs();
      } else {
        let tempUserInfo = sessionStorage.getItem("userInfo");
        if (tempUserInfo) {
          return;
        }
        this.token = Cookies.get("topcredit_exchange_t");
        await this.initURL();
        this.initWs();
      }
    },
  },
  methods: {
    routeSplicing,
    doSwitch() {
      let that = this;
      this.isSwitchImg = !this.isSwitchImg;
      this.isSwitch = true;
      setTimeout(function () {
        that.isSwitch = false;
      }, 2000);
    },
    isShowCurrency() {
      this.currencyShow = true;
    },
    isShowExchange() {
      this.exchangeShow = true;
      this.$nextTick(() => {
        let max_series0 = Math.max(
          ...this.currencyOptions.series[0].data,
          ...this.currencyOptions.series[1].data
        );
        let min_series0 = Math.min(
          ...this.currencyOptions.series[0].data,
          ...this.currencyOptions.series[1].data
        );
        this.currencyOptions.yAxis.interval = (max_series0 - min_series0) / 5;
        this.$refs.currencychart.setOption(this.currencyOptions);
      });
    },
    onClosePosition() {
      this.$confirm(
        this.translateTitle("确认平仓该订单?"),
        this.translateTitle("平仓"),
        {
          confirmButtonText: this.translateTitle("确定"),
          cancelButtonText: this.translateTitle("取消"),
        }
      ).then(() => {});
    },
    onMinClick() {
      this.multipleNum -= 1;
      if (this.multipleNum <= 1) {
        this.multipleNum = 1;
      }
    },
    onPlusClick() {
      this.multipleNum += 1;
      if (this.multipleNum >= 300) {
        this.multipleNum = 300;
      }
    },
    goToLogin() {
      this.$router.push({
        path: this.routeSplicing(this.language, "login"),
        query: { redirect: this.$route.fullPath },
      });
    },
    goToRegister() {
      this.$router.push(this.routeSplicing(this.language, "register"));
    },
    formatTooltip(val) {
      return val + "X";
    },
    onInputChange(value) {
      if (Number(value) > Number(this.maxNumber[this.swap_symbol_id])) {
        value = this.maxNumber[this.swap_symbol_id];
      }
      this.sliderValue = Number(value);
      this.multipleNum = value;
    },
    onSliderChange(value) {
      this.multipleNum = value;
    },
    handleClose() {
      this.multipleNum = 20;
      this.dialogVisible = false;
    },
    async onMultipleNumClick(value) {
      this.sliderValue = Number(value);
      this.multipleNum = value;
      let params = {
        symbol_id: this.swap_symbol_id,
      };
      const { status, data, msg } = await apiGetOrderStatistic(params);
      if (status == 200) {
        this.historyDataLength =
          data.contract_position_info.total_num +
          data.contract_plan_info.total_num;
        if (this.historyDataLength == 0) {
          this.dialogVisible = true;
        } else {
          showMessage({
            message: this.translateTitle(
              "当前有进行中的仓位或委托单，不能修改杠杆倍数"
            ),
            type: "error",
          });
        }
      } else {
        showMessage({
          message: msg,
          type: "error",
        });
      }
    },
    onMultipleNumConfirm() {
      // localStorage.setItem("stock-multiple-num", Number(this.multipleNum));
      // this.resetSetItem("stock-multiple-num", Number(this.multipleNum));
      this.$refs.panelComponent.setMultipleNum(Number(this.multipleNum));
      this.$refs.tradeComponent.setMultipleNum(Number(this.multipleNum));
      this.dialogVisible = false;
    },
    onAddOption() {
      this.$refs.marketComponent.getOptionList();
    },
    onMarketAddOption() {
      this.$refs.panelComponent.getOptionList();
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },

    onTradeSuccess(type) {
      this.$refs.historyOrder.reset(type);
    },
    async getExchangeItemId() {
      const { status, data } = await apiGetStaticTableInfo(13);
      if (status == 200) {
        this.coinItemIdMap = JSON.parse(JSON.stringify(data));
      }
    },
    async getUrlSymbol() {
      const { status, data } = await apiGetStaticTableInfo(49);
      if (status == 200) {
        this.coinInfoMap = JSON.parse(JSON.stringify(data));

        let tempSymbol = this.$route.params.symbol;
        if (!tempSymbol) {
          tempSymbol = "btcusdt";
          this.symbol = "btcusdt";
        } else {
          this.symbol = tempSymbol;
        }

        for (const key in this.coinInfoMap) {
          let tempArr = [];
          tempArr = this.coinInfoMap[key].leverage_list.split(",");
          this.maxMultipleNum = tempArr[tempArr.length - 1];
          let tempBase = tempArr[tempArr.length - 1] / 5;
          this.marks[key] = {};
          // this.maxNumber[key] = {};
          this.maxNumber[key] = tempArr[tempArr.length - 1];
          tempArr.forEach((ele, index) => {
            this.marks[key][1] = "1X";
            this.marks[key][tempBase * (index + 1)] =
              tempBase * (index + 1) + "X";
          });

          this.tradePairArray.push(this.coinInfoMap[key].symbol.toLowerCase());

          if (this.coinInfoMap[key].symbol.toLowerCase() == tempSymbol) {
            this.swap_symbol_id = this.coinInfoMap[key].symbol_id;
            this.from_item_id = this.coinInfoMap[key].change_item_id;
            this.to_item_id = this.coinInfoMap[key].base_item_id;
          }
        }
      }
    },
    onSymbolChangeV2(params) {
      this.$refs.marketComponent.onSymbolChange(params, "1");
    },

    onSymbolChange(item) {
      if (this.swap_symbol_id == item.symbol_id) {
        return;
      }
      this.old_swap_symbol_id = this.swap_symbol_id;
      window.ws.sub({
        action: "Topic.unsub",
        data: {
          type: "order",
          symbol: this.swap_symbol_id,
        },
      });
      this.orderbookList = [];
      window.ws.sub({
        action: "Topic.sub",
        data: {
          type: "order",
          symbol: item.symbol_id,
        },
      });
      window.ws.sub({
        action: "Topic.unsub",
        data: {
          type: "depth",
          symbol: this.swap_symbol_id,
        },
      });
      this.depthList = {
        a: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
        b: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
      };
      window.ws.sub({
        action: "Topic.sub",
        data: {
          type: "depth",
          symbol: item.symbol_id,
        },
      });
      this.$refs.klineComponent.onChangeSymbol(item);
      this.swap_symbol_id = item.symbol_id;
      this.from_item_id = item.change_item_id;
      this.to_item_id = item.base_item_id;
      this.resolveCoin();
      // this.$refs.historyOrder.resetV2();
    },

    resolveCoin() {
      this.pageLoading = true;
      let tempSymbol = this.$route.params.symbol;
      if (!tempSymbol) {
        tempSymbol = "btcusdt";
        this.swap_symbol_id = 100901;
      }

      let trade_pair = tempSymbol;
      if (this.tradePairArray.indexOf(trade_pair.toLowerCase()) == -1) {
        tempSymbol = "btcusdt";
        this.swap_symbol_id = 100901;
      }
      this.coinFrom =
        this.coinItemIdMap[this.from_item_id].item_name.toUpperCase();
      this.coinTo = this.coinItemIdMap[this.to_item_id].item_name.toUpperCase();
      // const [coinFrom, coinTo] = tempSymbol.split("_");
      // this.coinFrom = coinFrom;
      // this.coinTo = coinTo;
      setTimeout(() => {
        this.pageLoading = false;
      }, 300);
    },
    async getToken() {
      const { status, data } = await apiGetDefaultToken();
      if (status == 200) {
        this.token = data.token;
      }
    },
    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWs() {
      const me = this;
      const option = {
        url: this.wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "kline",
            resolution: "15",
            symbol: me.swap_symbol_id,
          },
        },
        callback,
        onConnect,
        mounted,
      };

      window.ws = new Socket(option).getInstance();
      // 重新连接
      function onConnect() {
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        setTimeout(() => {
          me.wsContentTime++;
          me.initWs();
        }, 3000);
      }
      function mounted() {
        window.ws.sub({
          action: "Topic.sub",
          data: {
            type: "market",
            symbol: me.swap_symbol_id,
          },
        });
        window.ws.sub({
          action: "Topic.sub",
          data: {
            type: "depth",
            symbol: me.swap_symbol_id,
            // resolution: "D",
          },
        });
        window.ws.sub({
          action: "Topic.sub",
          data: {
            type: "order",
            symbol: me.swap_symbol_id,
          },
        });

        window.ws.sub({
          action: "Topic.sub",
          data: {
            // type: "contractM",
            type: "stock",
            app_id: VUE_APP_ID,
          },
        });
        if (Cookies.get("topcredit_exchange_t")) {
          window.ws.sub({
            action: "Topic.sub",
            data: {
              type: "assets",
            },
          });
        }
      }
      function callback(data) {
        const { action } = data;
        if (action == "Pushdata.stock") {
          // if (action == "Pushdata.market") {
          if (data.data) {
            let tempArr = data.data;
            tempArr.forEach((ele) => {
              ele.lastV2 = Number(ele.last);
              if (!ele["currency"]) {
                ele["currency"] = {};
              }
              ele.currency.usdV2 =
                JSON.stringify(ele.currency) != "{}"
                  ? Number(ele.currency.usd)
                  : 0;
              ele.price_change_percentV2 = Number(ele.price_change_percent);
            });
            me.marketList = tempArr;
          }
        }
        if (action == "Pushdata.kline") {
          window.resolveKlineDataV3(data);
        }
        // 资产
        if (action == "Pushdata.assets") {
          if (data.data == null || data.data instanceof Array) {
            return;
          }
          me.assetsInfo = data.data;
        }
        if (action == "Pushdata.orderbook") {
          if (data.data) {
            if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
              return;
            }
            me.orderbookList.unshift(...data.data);
            if (me.orderbookList.length > 28) {
              me.orderbookList = me.orderbookList.slice(0, 28);
            }
          }
        }
        if (action == "Pushdata.depth") {
          if (data.data) {
            if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
              return;
            }
            let tempObj = data.data;
            if (tempObj.a.length > 0) {
              tempObj.c = 0;
              tempObj.d = 0;
              tempObj.a.forEach((ele) => {
                tempObj.c += Number(ele[1]);
              });
              tempObj.b.forEach((ele) => {
                tempObj.d += Number(ele[1]);
              });
            }
            me.depthList = tempObj;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.stock-page-exchange {
  // max-width: 1790px;
  @keyframes pay-switch {
    0% {
      top: 0px;
    }
    50% {
      top: 75px;
    }
    100% {
      top: 0px;
    }
  }
  @keyframes income-switch {
    0% {
      top: 0px;
    }
    50% {
      top: -72px;
    }
    100% {
      top: 0px;
    }
  }
  switch-img-right {
    transform: rotate(180deg);
  }
  switch-img-left {
    transform: rotate(0deg);
  }
  .img-onlyRead {
    pointer-events: none;
  }
  @media screen and (min-width: 600px) and (max-width: 1860px) {
    .layout-block-six {
      display: block;
    }
    .layout-block-third,
    .layout-block-fourth {
      display: none;
    }
  }
  @media screen and (min-width: 1860px) {
    .layout-block-six {
      display: none;
    }
    .layout-block-third,
    .layout-block-fourth {
      display: block;
    }
  }
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
  background: #ecedf1;
  .layout-block-first {
    width: 310px;
    height: 804px;
    margin-right: 8px;
  }
  .layout-block-second {
    flex: 1;
    height: 804px;
    min-width: 700px;
  }
  .layout-block-third {
    max-width: 328px;
    background: #fff;
    height: 804px;
    margin-left: 8px;
    overflow: hidden;
  }

  .layout-block-fourth {
    max-width: 328px;
    background: #fff;
    height: 804px;
    margin-left: 8px;
  }
  .layout-block-five {
    width: 100%;
    // height: 280px;
    flex-basis: 100%;
    background: #ecedf1;
  }
  .layout-block-six {
    max-width: 328px;
    background: #fff;
    height: 804px;
    margin-left: 8px;
    overflow: hidden;
    .block-six-header {
      background-color: #f5f5f7;
      ul {
        display: flex;
      }
      .classify-area {
        padding: 0 20px;
        cursor: pointer;
        height: 32px;
        font-size: 14px;
        font-weight: 600;
        color: #1f2533;
        line-height: 32px;
        position: relative;
        &.active {
          background-color: #ffffff;
          color: #000;
        }
        .item-bar {
          position: absolute;
          width: 24px;
          height: 2px;
          background-color: #1f2533;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }
      }
    }
  }
  .el-dialog.exchange-sure-dialog {
    .el-dialog__header {
      padding-bottom: 0px;
    }
    .el-dialog__body {
      padding: 0;
      .exchange-sure-dialog-content {
        padding: 25px 0 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .content-text {
          min-height: 26px;
          font-size: 16px;
          font-weight: 500;
          color: #354052;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .text-color {
            color: #e81b40;
          }
        }
        .details-text {
          min-height: 120px;
          .details-item {
            min-width: 380px;
            height: 40px;
            border-bottom: solid 1px #f1f2f5;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .item-name {
              color: #354052;
              font-size: 14px;
            }
            .item-value {
              font-size: 14px;
              font-weight: 500;
              color: #354052;
            }
          }
        }
        .dialog-footer {
          width: 330px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 35px;
          .cancel-btn {
            width: 160px;
            height: 40px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            border: solid 1px #c2c7d0;
            font-size: 14px;
            color: #354052;
          }
          .confirm-btn {
            width: 160px;
            height: 40px;
            background-image: linear-gradient(74deg, #c61b4e 21%, #ee244c 82%);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            font-size: 14px;
            color: #ffffff;
          }
        }
      }
    }
  }
  .el-dialog.exchange-dialog {
    .el-dialog__header {
      padding-bottom: 0px;
      .header-title {
        font-size: 20px;
        font-weight: 500;
        color: #354052;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .record {
          font-size: 22px;
        }
      }
    }
    .el-dialog__body {
      padding: 20px 30px;
      .pay-area {
        width: 440px;
        height: 115px;
        padding: 11px 15px 15px;
        border-radius: 6px;
        background-color: #f7f8fa;
        .pay-msg {
          width: 100%;
          height: 25px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .pay-name {
            width: 32px;
            height: 25px;
            font-size: 16px;
            font-weight: 500;
            color: #354052;
          }
          .balance {
            font-size: 12px;
            color: #354052;
            .rech {
              color: #3a7dff;
              cursor: pointer;
              display: inline-block;
              margin-left: 3px;
            }
          }
        }
        .pay-select {
          width: 100%;
          height: 30px;
          margin-top: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .pay-select-amount {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .el-select {
              background-size: 20px 20px !important;
              .el-select-dropdown.el-popper {
                display: none;
              }
              .el-input--suffix {
                width: 109px;
                .el-input__icon {
                  line-height: 30px;
                  color: #2f3848;
                  font-weight: 600;
                }
                .el-input__inner {
                  padding-left: 30px;
                  // padding-right: 10px;
                  background-color: transparent;
                  height: 30px;
                  line-height: 30px;
                  font-size: 14px;
                  border-radius: 15px;
                }
              }
            }
            .pay-value {
              width: 145px;
              .el-input {
                .el-input__inner {
                  border: 0px;
                  background-color: transparent;
                  font-size: 16px;
                }
                .el-input__inner::placeholder {
                  font-size: 16px;
                  color: #c2c7d0;
                }
              }
            }
          }
          .pay-all-amount {
            cursor: pointer;
            color: #3a7dff;
            font-size: 13px;
            padding-bottom: 3px;
          }
        }
        .pay-limit {
          width: 100%;
          height: 20px;
          margin-top: 8px;
          font-size: 12px;
          font-weight: 500;
          color: #354052;
        }
      }
      .income-area {
        width: 440px;
        height: 87px;
        padding: 11px 15px 15px;
        border-radius: 6px;
        background-color: #f7f8fa;
        .income-msg {
          width: 100%;
          height: 25px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .income-name {
            width: 32px;
            height: 25px;
            font-size: 16px;
            font-weight: 500;
            color: #354052;
          }
        }
        .income-select {
          width: 100%;
          height: 30px;
          margin-top: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .income-select-amount {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .el-select {
              background-size: 20px 20px !important;
              .el-select-dropdown.el-popper {
                display: none;
              }
              .el-input--suffix {
                width: 109px;
                .el-input__icon {
                  line-height: 30px;
                  color: #2f3848;
                  font-weight: 600;
                }
                .el-input__inner {
                  padding-left: 30px;
                  // padding-right: 10px;
                  background-color: transparent;
                  height: 30px;
                  line-height: 30px;
                  font-size: 14px;
                  border-radius: 15px;
                }
              }
            }
            .income-value {
              width: 145px;
              .el-input {
                .el-input__inner {
                  border: 0px;
                  background-color: transparent;
                  font-size: 16px;
                }
                .el-input__inner::placeholder {
                  font-size: 16px;
                  color: #c2c7d0;
                }
              }
            }
          }
        }
      }
      .currency-switch {
        width: 440px;
        height: 32px;
        padding: 4px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .switch-img {
          width: 24px;
          height: 24px;
        }
      }
      .exchange-rate {
        width: 100%;
        height: 30px;
        margin-top: 20px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        .exchange-rate-content {
          padding: 7px 17px;
          min-width: 210px;
          border-radius: 15px;
          background-color: #f7f8fa;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .proposed-rice {
            color: #354052;
            font-size: 12px;
            height: 16px;
            border-bottom: 1px dashed #c2c7d0;
            margin-right: 8px;
          }
          .exchange-rate-ratio {
            height: 16px;
            font-size: 12px;
            font-weight: 500;
            color: #28ba98;
            margin-right: 8px;
          }
          .icon {
            // width: 14px;
            // height: 14px;
            // border-radius: 100%;
            // background-color: #28ba98;
            font-size: 17px;
            color: #28ba98;
          }
        }
      }
      .chart-area {
        width: 100%;
        height: 192px;
        margin-bottom: 29px;
        .echart-line {
          width: 100%;
          height: 160px;
          margin-top: 15px;
        }
        .chart-title {
          width: 100%;
          height: 17px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .dual-currency {
            font-size: 12px;
            font-weight: 500;
            color: #354052;
            .color-green {
              color: #28ba98;
            }
            .color-red {
              color: #e81b40;
            }
          }
          .tooltip {
            font-size: 12px;
            font-weight: 500;
            color: #354052;
            display: flex;
            align-items: center;
            .cricle-green {
              width: 6px;
              height: 6px;
              background-color: #28ba98;
              border-radius: 100%;
              margin-right: 5px;
            }
            .cricle-red {
              width: 6px;
              height: 6px;
              background-color: #e81b40;
              border-radius: 100%;
              margin-right: 5px;
              margin-left: 10px;
            }
          }
        }
      }
      .exchange-dialog-btn {
        width: 100%;
        height: 48px;
        border-radius: 4px;
        background-image: linear-gradient(83deg, #c61b4e 21%, #ee244c 82%);
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .multiple-dialog {
    .el-dialog__header {
      .el-dialog__title {
        font-size: 20px;
        font-weight: 500;
        color: #354052;
      }
    }
    .el-dialog__footer {
      padding: 10px 20px 30px;
    }
    .el-dialog__body {
      padding: 30px;
      .symbol-item {
        text-align: center;
        color: #c2c7d0;
        line-height: 13px;
        cursor: pointer;
        &.disabled {
          cursor: not-allowed;
        }
      }
      span {
        width: 50px;
        height: 40px;
        display: inline-block;
        border-radius: 2px;
        background-color: #fff;
        border: solid 1px #e2e2e4;
        padding: 12px 17px;
        vertical-align: middle;
      }

      .buy-price {
        width: 330px;
        display: inline-block;
        border-radius: 2px;
        background-color: #fff;
        vertical-align: middle;
        margin: 0 5px;
        .el-input__inner {
          background-color: #fff;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: #354052;
        }
      }
      .el-slider__marks {
        .el-slider__marks-text:last-child {
          left: 93% !important;
          transform: translateX(0%);
        }
      }
      .el-slider__bar {
        background-color: #c61b3d;
      }
      .el-slider__button {
        border: 2px solid #c61b3d;
      }
      .el-slider {
        .disabled {
          .el-slider__button {
            border-color: #c0c4cc;
          }
        }
      }
    }
    .dialog-footer {
      display: flex;
      justify-content: center;
      span {
        display: inline-block;
        width: 160px;
        height: 40px;
        border-radius: 2px;
        padding: 12px 15px;
        font-size: 14px;
        text-align: center;
        line-height: 15px;
        cursor: pointer;
      }
      .cancel-btn {
        color: #354052;
        border: solid 1px #bec1c8;
        margin-right: 5px;
      }
      .confirm-btn {
        color: white;
        margin-left: 5px;
        background-image: linear-gradient(264deg, #ff486d 10%, #c61b3d 40%);
        border: solid 1px #ff486d;
      }
    }
    .dialog-footer-v2 {
      display: block;
      width: 280px;
      height: 40px;
      margin: 0 auto;
      padding: 7px 6px 9px 6px;
      border-radius: 2px;
      border: solid 1px #bec1c8;
      font-size: 14px;
      text-align: center;
      color: #354052;
    }
  }
  #currency-chart {
    width: 100%;
    height: 160px;
  }
}
.el-popup-parent--hidden {
  .el-dialog__wrapper {
    .el-dialog.inner-dialog {
      margin-top: 37.8vh !important;
      .el-dialog__header {
        display: none;
      }
      .el-dialog__body {
        padding: 20px;
        .el-input {
          margin-bottom: 10px;
          .el-input__inner:focus {
            border-color: #e81b40 !important;
          }
          .el-input__suffix {
            width: 20px;
            right: 16px;
            .el-input__suffix-inner {
              .el-icon-circle-close:before {
                content: "";
              }
              .el-icon-circle-close {
                background-image: url("../../assets/img/currency-value.png");
                background-repeat: no-repeat;
                background-size: 20px 20px;
                background-position: center;
                margin-right: 16px;
              }
            }
          }
        }
      }
      .currency-value {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .currency-icon-name {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .icon {
            width: 20px;
            height: 20px;
            margin-right: 9px;
          }
          .name {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
          }
        }
        .currency-count {
          font-size: 14px;
          font-weight: 500;
          color: #354052;
        }
      }
    }
  }
}
</style>
