import api from "@/assets/js/api";
import * as http from "@/assets/js/http";

import { VUE_APP_UCENTER, VUE_APP_APP } from "@/assets/js/stationSetting";
//静态表设置
export const SETTING_SV_ID = {
  static_coin: 1,
  static_sms_country: 2,
  otc_static_coin: 3,
  payment: 4,
  static_item: 13,
  distribute_type: 16,
};

//获取所有静态表
//type
//file
export const fileUpload = (params) => {
  return http.fileUpload(
    `${VUE_APP_UCENTER}/UCenter/Third/pushPicture`,
    params
  );
};

export const kycFileUpload = (params) => {
  return http.fileUpload(`${VUE_APP_APP}/UCenter/Identity/pushIdPhoto`, params);
};
export const kycFileUploadVideo = (params) => {
  return http.fileUpload(`${VUE_APP_APP}/UCenter/Identity/pushVideo`, params);
};
// export const kycFileUpload = (params) => {
//     return http.fileUpload(`${VUE_APP_APP}/UCenter/Identity/pushIdPhoto`, params);
//   };
export function getRandNo(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Identity/getIDRandNo`,
    data,
  });
}
export function apiApplyKyc(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Identity/applyKyc`,
    data,
  });
}
export function apiKycInfo(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Identity/kycInfo`,
    data,
  });
}
