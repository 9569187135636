<template>
  <div class="suggest scrollbar-default">
    <div class="banner">
      <div class="content">
        <div class="left">
          <p>{{ translateTitle("Your Voice Matters") }}</p>
          <div>{{ translateTitle("We’ll take it seriously") }}</div>
        </div>
        <img
          src="@/assets/img/siteLogo/suggest-banner.webp"
          class="apc-banner"
        />
      </div>
    </div>
    <div class="content">
      <div class="advice">
        {{ translateTitle("We will listen carefully to each of your advice") }}
      </div>
      <div class="colleagues">
        {{
          translateTitle(
            "How likely are you to recommend MaskEX to your friends or colleagues?"
          )
        }}
      </div>
      <ul class="list">
        <li
          v-for="item in satisfactionList"
          v-text="item - 1"
          :class="programForm.recommend_level == item - 1 ? 'acitve' : ''"
          :key="item"
          @click="programForm.recommend_level = item - 1"
        ></li>
      </ul>
      <div class="direction">
        {{ translateTitle("Advice direction") }}
      </div>
      <div class="direction-list">
        <ul>
          <li
            v-for="item in directionList"
            :class="programForm.direction == item ? 'active' : ''"
            v-text="item"
            :key="item"
            @click="programForm.direction = item"
          ></li>
        </ul>
      </div>
      <div class="detail">
        {{ translateTitle("Advice detail") }}
      </div>
      <el-input
        type="textarea"
        :rows="5"
        class="textarea"
        :placeholder="
          translateTitle(
            'Please enter your feedback, no more than 500 characters'
          )
        "
        v-model="programForm.detail"
      >
      </el-input>
      <div class="picture">
        {{ translateTitle("Upload picture (optional)") }}
      </div>
      <div>
        <el-upload
          class="avatar-uploader"
          action="/UCenter/Third/pushPicture"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="programForm.picture"
            :src="programForm.picture"
            class="avatar"
          />
          <div v-else class="up-load">
            <img src="@/assets/img/photo-camera.png" class="tubiao" alt="" />
            <div>
              <div>{{ translateTitle("Upload a Picture & Video") }}</div>
              <p>{{ translateTitle("Max.2m jpg;png;Max.30M mp4, mov") }}</p>
            </div>
          </div>
        </el-upload>
      </div>
      <div class="picture">
        {{ translateTitle("Contact details (optional)") }}
      </div>
      <el-input
        v-model="contact"
        :placeholder="translateTitle('Please enter contact details')"
        class="contact"
      ></el-input>
      <div class="submit-btn" @click="submit" :loading="btnLoading">
        {{ translateTitle("Submit") }}
      </div>
    </div>
    <div class="footer">
      <div class="footer-title">{{ translateTitle("How does it work?") }}</div>
      <ul class="content">
        <li>
          <img src="@/assets/img/idea.webp" alt="" />
          <p>{{ translateTitle("Give a feedback") }}</p>
          <div></div>
        </li>
        <li>
          <img src="@/assets/img/review.webp" alt="" />
          <p>{{ translateTitle("Team review") }}</p>
          <div></div>
        </li>
        <li>
          <img src="@/assets/img/group.webp" alt="" />
          <p>{{ translateTitle("Product improvements") }}</p>
        </li>
      </ul>
      <div class="footer-detail">
        {{
          translateTitle(
            "MaskEX serves global users, and we regard the safe and stable development of the platform as our top priority. Looking forward to your valuable advice, we will improve product quality together and create a better future together."
          )
        }}
      </div>
    </div>
  </div>
</template>
<script>
import { apiFormSubmitAdvice } from "@/model/recruitment/recruitment";

import { mapGetters } from "vuex";
import commonMixin from "@/components/common/commonMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  data() {
    return {
      satisfactionList: 11,
      index: 10,
      directionList: [
        "Safety advice",
        "Feature advice",
        "Design advice",
        "Operational advice",
        "Other advice",
      ],
      direction: "Safety advice",
      detail: "",
      imageUrl: "",
      btnLoading: false,
      marketing: 0,
      contact: "",
      programForm: {
        recommend_level: 10,
        direction: "Safety advice",
        detail: "",
        picture: "",
        contact: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language",
    }),
  },
  watch: {},
  async created() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    routeSplicing,
    goLogin() {
      if (!this.userInfo) {
        this.$message({
          message: this.translateTitle("请先登录"),
          type: "warning",
          customClass: "zZindex",
        });
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
        });
      }
    },
    chooseMarketing(index) {
      this.marketing = index;
    },
    goToRegister() {
      this.$router.push(this.routeSplicing(this.language, "register"));
    },
    handleAvatarSuccess(res, file) {
      this.programForm.picture = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isType =
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "video/mp4" ||
        file.type === "video/mov";
      let isLt2M = false;
      if (file.type === "image/jpg" || file.type === "image/png") {
        isLt2M = file.size / 1024 / 1024 < 2;
      }
      if (file.type === "video/mp4" || file.type === "video/mov") {
        isLt2M = file.size / 1024 / 1024 < 30;
      }
      if (!isType) {
        this.$message.error("上传文件只能是 JPG ｜ PNG | MP4 | MOV 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件过大");
      }
      return isType && isLt2M;
    },
    async submit() {
      const req = {
        ...this.programForm,
      };
      this.btnLoading = true;
      const { data, status } = await apiFormSubmitAdvice(req);
      this.btnLoading = false;
      if (status == 200) {
        this.programForm = {
          recommend_level: 10,
          direction: "",
          detail: "",
          picture: "",
          contact: "",
        };
        return this.$message("反馈成功", "sucess");
      }
    },
  },
};
</script>
<style lang="scss">
.ar {
  .suggest .quickly-rechange-center .quickly-rechange-center-content {
    .purchasing-price-text {
      direction: rtl;
      .step {
        margin-left: 6.6px;
      }
    }
    .checkbox-out .statement-checkbox {
      direction: rtl;
    }
    .statement {
      direction: rtl;
    }
  }
  .suggest {
    .recharge-content {
      direction: rtl;
      .left-content {
        .step {
          margin-right: 0;
          margin-left: 6.6px;
        }
      }
      .right-content {
        margin-left: 0;
        margin-right: 60px;
      }
    }
    .bottom-content .purchasing-price-text {
      direction: rtl;

      .step {
        margin-right: 0;
        margin-left: 6.6px;
      }
    }
    .quickly-rechange-footer .quickly-rechange-footer-content {
      direction: rtl;
    }
  }
}
.suggest {
  //   background-color: #f7f9fc;
  //   padding: 52px 0 73px;
  //   display: flex;
  //   justify-content: center;
  .banner {
    height: 455px;
    width: 100%;
    background: linear-gradient(rgb(255, 255, 255), #defdff);
    text-align: justify;
    .content {
      width: 1000px;
      margin: 0 auto;
      display: flex;
      height: 455px;
      position: relative;
      .left {
        margin-top: 184px;
        p {
          font-size: 54px;
          white-space: nowrap;
          font-weight: bold;
          line-height: 1.26;
          color: #15beb4;
        }
        div {
          font-size: 32px;
          white-space: nowrap;
          line-height: 2.13;
          color: #15192a;
        }
      }
      img {
        position: absolute;
        bottom: 0;
        right: -210px;
      }
    }
  }
  .content {
    width: 1000px;
    margin: 0 auto;
    .advice {
      font-size: 24px;
      color: #15192a;
      margin-top: 100px;
    }
    .colleagues {
      font-size: 18px;
      font-weight: bold;
      color: #15192a;
      margin-top: 38px;
    }
    .list {
      margin-top: 22px;
      display: flex;
      li {
        &.acitve {
          border: solid 1px #15beb4;
          color: #15beb4;
        }
        margin-right: 10px;
        width: 44px;
        height: 38px;
        border-radius: 3px;
        text-align: center;
        line-height: 38px;
        border: solid 1px rgba(21, 25, 42, 0.2);
        font-size: 20px;
        color: #15192a;
        cursor: pointer;
      }
    }
    .direction {
      margin-top: 34px;
      font-size: 18px;
      font-weight: bold;
      color: #15192a;
    }
    .direction-list {
      ul {
        display: flex;
        margin-top: 22px;
        li {
          &.active {
            color: #15bfb5;
            background-color: #e3f6f5;
          }
          padding: 10px 24px;
          background-color: #f1f1f1;
          font-size: 14px;
          font-weight: 500;
          color: #999;
          margin-right: 20px;
          border-radius: 3px;
          cursor: pointer;
        }
      }
    }
    .detail {
      margin-top: 34px;
      font-size: 18px;
      font-weight: bold;
      color: #15192a;
    }
    .textarea {
      margin-top: 22px;
    }
    .picture {
      margin-top: 34px;
      font-size: 18px;
      font-weight: bold;
      color: #15192a;
    }
    .avatar-uploader .el-upload {
      margin-top: 22px;
      border: 1px dashed rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      width: 176px;
      height: 139px;
      display: flex;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      .up-load {
        text-align: center;
        padding: 10px;
        .tubiao {
          width: 40px;
          height: 40px;
          margin: 10px auto;
        }
        p {
          font-size: 10px;
          color: rgba(0, 0, 0, 0.4);
          margin-top: 10px;
        }
        div {
          font-size: 12px;
          color: #000;
        }
      }
      // img {
      //   width: 50px;
      // }
    }
    .avatar-uploader .el-upload:hover {
      border-color: rgba(0, 0, 0, 0.5);
    }
    .contact {
      margin-top: 22px;
    }
    .submit-btn {
      cursor: pointer;
      margin-top: 36px;
      width: 160px;
      padding: 9.5px 52.5px;
      border-radius: 3px;
      background-color: #15beb4;
      font-size: 16px;
      color: #fff;
    }
  }
  .footer {
    background-color: #f7f9fc;
    margin-top: 122px;
    margin-bottom: 126px;
    padding: 1px;
    .footer-title {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      color: #15192a;
      margin: 70px auto;
    }
    ul.content {
      width: 1000px;
      margin: 0 auto;
      display: flex;
      text-align: center;
      li {
        text-align: content;
        flex-direction: row;
        justify-content: space-between;
        width: 33.3333%;
        position: relative;
        div {
          width: 200px;
          height: 4px;
          opacity: 0.4;
          position: absolute;
          background-image: linear-gradient(
            to right,
            #32d07b 3%,
            rgba(51, 145, 241, 0) 94%,
            rgba(51, 145, 241, 0) 94%
          );
          display: block;
          top: 50%;
          left: 230px;
        }
      }
    }
    .footer-detail {
      font-size: 14px;
      line-height: 1.6;
      color: #666;
      width: 916px;
      margin: 30px auto;
      text-align: center;
    }
  }
}
</style>
