<template>
  <div class="service-agreement">
    <div v-if="language == 'zh_CN'">
      <p class="header">
        <strong>隐私条款</strong>
      </p>
      <p>
        8V，本公司运营网站www.8v.com及相关移动应用（以下简称“平台”），并基于该平台为用户提供数字货币之间的交易及相关服务。
      </p>
      <p>
        平台尊重并保护所有使用平台网络服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，平台会按照本隐私权政策的规定使用和披露您的个人信息。您在同意平台注册服务协议之时，即视为您已经同意本隐私协议全部内容。本隐私权协议属于平台网络服务使用协议不可分割的一部分。请注意平台会不时地检查并更新隐私条款，希望您定期查阅本协议内容以确保了解最新版本的隐私政策。在阅读完本协议之后如对平台隐私政策有进一步的问题，请与
        hello@8v.com联系。
      </p>
      <ol>
        <li>
          <strong>适用范围</strong>
        </li>
      </ol>
      <p>A）在您注册平台账号时，您根据平台要求提供的个人信息；</p>
      <p>
        B）在您使用平台网络服务，或访问平台平台网页时，平台自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
      </p>
      <p>C）平台通过合法途径从商业伙伴处取得的用户个人数据。</p>
      <p>您了解并同意，以下信息不适用本隐私权政策：</p>
      <p>A）您在使用平台提供的搜索服务时输入的关键字信息；</p>
      <p>
        B）平台收集到的您在平台发布的有关信息数据，包括但不限于论坛发布的帖子；
      </p>
      <p>C）违反法律规定或违反平台规则行为及平台已对您采取的措施。</p>
      <ol start="2">
        <li>
          <strong>信息使用</strong>
        </li>
      </ol>
      <p>
        A）为服务用户的目的，平台可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与平台合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。
      </p>
      <p>
        B）用户提供给平台的信息，平台会进行收集、整理和妥善处置。如平台与合作伙伴公司共同推出的服务，用户使用时，平台可能必须与合作公司共享用户信息。平台将督促合作公司参照本政策的规定保护用户信息。
      </p>
      <ol start="3">
        <li>
          <strong>信息披露</strong>
        </li>
      </ol>
      <p>
        在如下情况下，平台将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
      </p>
      <p>A）经您事先同意，向第三方披露；</p>
      <p>B）为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</p>
      <p>
        C）根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
      </p>
      <p>
        D）如您出现违反有关法律、法规或者平台服务协议或相关规则的情况，需要向第三方披露；
      </p>
      <p>
        E）如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
      </p>
      <p>F）其它平台根据法律、法规或者网站政策认为合适的披露。</p>
      <ol start="4">
        <li>
          <strong>信息存储和交换</strong>
        </li>
      </ol>
      <p>
        平台收集的有关您的信息和资料将保存在平台及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或平台收集信息和资料所在地的境外并在境外被访问、存储和展示。
      </p>
      <ol start="5">
        <li><strong>Cookie</strong><strong>的使用</strong></li>
      </ol>
      <p>
        A）在您未拒绝接受cookies的情况下，平台会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的平台服务或功能。平台使用cookies可为您提供更加周到的个性化服务，包括推广服务。
      </p>
      <p>
        B）您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的平台网络服务或功能。
      </p>
      <p>C）通过平台所设cookies所取得的有关信息，将适用本政策。</p>
      <ol start="6">
        <li>
          <strong>信息安全</strong>
        </li>
      </ol>
      <p>
        A）平台账号均有安全保护功能，请妥善保管您的用户名及密码信息。平台将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
      </p>
      <p>
        B）在使用平台网络服务进行网上交易时，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是平台用户名及密码发生泄露，请您立即联络平台客服，以便平台采取相应措施。
      </p>
      <ol start="7">
        <li>
          <strong>免责</strong>
        </li>
      </ol>
      <p>下列情况时本网站亦毋需承担任何责任：</p>
      <p>
        A）由于您将用户密码告知他人或与他人共享注册账户，由此导致的任何个人资料泄露。
      </p>
      <p>
        B）任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被篡改等。
      </p>
      <p>
        C）由于与本网站链接的其它网站所造成之个人资料泄露及由此而导致的任何法律争议和后果。
      </p>
      <ol start="8">
        <li>
          <strong>外部链接</strong>
        </li>
      </ol>
      <p>
        平台包含一些外部网站的链接。平台对这些网站的隐私管理和行为不承担责任。当跳转到外部网站时，用户请阅读该网站的隐私政策。这里提供的隐私条例仅供平台收集管理用户信息所用。
      </p>
      <ol start="9">
        <li>
          <strong>与网站联系</strong>
        </li>
      </ol>
      <p>
        如果你有与隐私保护，政策实施或网站操作有关的问题，请将意见或建议发送至我们的邮箱:
        hello@8v.com。
      </p>
    </div>
    <div v-else>
      <p class="header">
        <strong>Privacy Policy</strong>
      </p>
      <p>
        8V, the official website www.8v.com and related mobile applications
        (hereinafter referred to as the "platform"), provides online users with
        digital currency transaction solutions and services.
      </p>
      <p>
        Our platform respects and protects privacy of all users who use the
        platform network services. In order to provide with more accurate and
        personalized services, our platform will use and disclose your personal
        information in accordance with the provisions of this privacy policy.
        When you agree with the platform registration service agreement, you are
        deemed to have agreed to the entire content of this privacy agreement.
        This privacy agreement is an integral part of the platform network
        service usage agreement. Please note that the platform will check and
        update the privacy policy from time to time, and we hope that you
        regularly review the content of this agreement to ensure that you
        understand the latest version. If you have any further inquiries about
        the privacy policy, please contact hello@8v.com.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Scope of Application</strong>
      </p>
      <p>
        A) When you register for a platform account, you will provide your
        personal information according to the requirements of the platform;
      </p>
      <p>
        B) When you use the network services or visit platform web pages, our
        platform will receive and record the information on your browser and/or
        computer, including but not limited to your IP address, browser,
        language, and data, such as the date and time of access, feature
        information, and browsing history;
      </p>
      <p>
        C) Personal data obtained by the platform from business partners through
        lawful act.
      </p>
      <p>The Privacy Policy, however, does not apply to:</p>
      <ol>
        <li>
          A) Keyword information when using the search service provided by the
          platform;
        </li>
        <li>
          B) Relevant information, including but not limited to the posts
          published on the platform;
        </li>
        <li>
          C) Violation of legal regulations or platform rules and the measures
          has taken by the platform against you.
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <strong>Information Use</strong>
      </p>
      <p>
        A) The platform may provide you with information that you are interested
        in, including but not limited to our product and service information, or
        those related to platform partners (prior
        consent&nbsp;is&nbsp;required).
      </p>
      <p>
        B) The platform may collect and share your personal information with our
        business partners. The platform will urge business partners to protect
        your confidential information in accordance with the terms of the
        privacy policy.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Information Disclosure</strong>
      </p>
      <p>
        Under the following circumstances, the platform will disclose your
        personal information in accordance with legal requirements:
      </p>
      <p>A) Disclosure to a third party with your prior consent;</p>
      <p>
        B) In order to provide products and services upon you request, the
        platform must share your personal information with third parties;
      </p>
      <p>
        C) Disclosure to a third party or judicial organization or
        administration in accordance with relevant provisions of any written
        laws;
      </p>
      <p>
        D) If you violate relevant laws, regulations or platform service
        agreements or relevant rules;
      </p>
      <p>
        E) If you are a qualified intellectual property complainant and have
        filed a complaint, you should disclose it to the respondent;
      </p>
      <p>
        F) Disclosure in accordance with laws, regulations or website policies.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Information Exchange</strong>
      </p>
      <p>
        The information and personal data collected by the platform will be
        stored on the server of the platform and/or its affiliates. This
        information and personal data may be transmitted to your country, region
        or the country or where the platform collects the information and data.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Use of Cookies</strong>
      </p>
      <p>
        A) If you do not decline to accept cookies, the platform will allow
        and/or access cookies on your computer so that you can log in or use
        platform services that rely on it. The platform uses cookies to provide
        you with personalized services, such as promotions.
      </p>
      <p>
        B) You are able to accept or decline the cookies. You can decline to
        accept cookies by modifying your browser settings. However, if you
        decline to accept cookies, you may not be able to log in or use platform
        network services that rely on it.
      </p>
      <p>
        C) This privacy policy will apply to information obtained through
        cookies.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Information Security</strong>
      </p>
      <p>
        A) Security protection function is applied to the platform account.
        Please keep your user name and password properly. The platform will
        ensure that your information is not lost, abused or altered by security
        measures, including encrypting passwords. Despite the aforementioned
        security measures, please note that there is no perfect security measure
        of the information network.
      </p>
      <p>
        B) When using platform network services for online transactions, please
        protect your personal information properly. If you find that your
        personal information has been leaked, herein platform user name and
        password, please contact customer service immediately to take
        corresponding measures.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Disclaimer</strong>
      </p>
      <p>
        In the following situations, this platform does not bear any
        responsibility:
      </p>
      <p>
        A) Any personal information leakage caused by sharing password or
        registered account with others.
      </p>
      <p>
        B) Any information leakage, loss, theft or tampering caused by force
        majeure that affects the operation of the information network services
        due to cyber attack, computer intrusion, and/or government shutdown,
        etc.
      </p>
      <p>
        C) Personal information leakage caused by third party websites linked to
        our platform and/or any legal disputes and consequences that caused by
        this matter.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>External Link</strong>
      </p>
      <p>
        The platform is not responsible for privacy management of the external
        links. When redirecting to an external website, users should read the
        privacy policy of that website. The privacy policy provided by the
        platform is only used for collecting and managing user information.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Contact Us</strong>
      </p>
      <p>
        If you have inquiries about our privacy policy, please email us at
        hello@8v.com.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
};
</script>

<style lang="scss">
.service-agreement {
  width: 1200px;
  margin: 0px auto 40px;
  background: #fff;
  padding: 0px 30px 30px;
  .header {
    font-size: 24px;
    text-align: center;
    padding-top: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
    strong {
      margin-top: 0;
    }
  }
  strong {
    display: block;
    margin: 20px 0 10px 0;
  }
  p {
    margin: 10px 0;
  }
}
</style>
