<template>
  <li class="user-center-menu el-menu-item nav-menu-item login">
    <el-submenu
      v-show="userInfoShow"
      style="display: none"
      popper-class="sub-item-wrap select-user-item"
      index="3"
      class="no-arrow"
      :collapse-transition="false"
      :popper-append-to-body="false"
      :show-timeout="0"
      :hide-timeout="0"
    >
      <template slot="title">
        <router-link
          class="uers-ucenter"
          active-class="none-active"
          :to="routeSplicing(language, 'ucenter/situation')"
        >
          <span
            @click="goTo(routeSplicing(language, 'ucenter/situation'))"
            class="user-name"
          >
            <img
              class="user-icon"
              :src="userInfo && userInfo.userInfo.avatar"
            />
            {{
              userInfo &&
                (userInfo.accountInfo.username ||
                  userInfo.accountInfo.email ||
                  userInfo.accountInfo.mobile)
            }}
          </span>
        </router-link>
      </template>

      <el-menu-item class="sub-item" index="9" @click="onLogout">
        <span active-class="none-active">
          <i class="iconfont icontuichudenglu"></i>
          {{ translateTitle("退出登录") }}
        </span>
      </el-menu-item>
    </el-submenu>
  </li>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import { apiGetLoginOut } from "@/model/user/index";
import routeSplicing from "@/assets/js/routeSplicing";
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  mixins: [commonMixin],
  data() {
    return {
      languageName: "简体中文",
      showDownLoadImg: false,
    };
  },
  created() {
    let lang = localStorage.getItem("language");
    // this.handleSelect(lang);
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
  },
  methods: {
    routeSplicing,
    async onLogout() {
      const abc = await apiGetLoginOut();
      this.$store.dispatch("user/logout");
      // 关闭usdt长连接
    },
    goTo(target) {
      this.$router.push(target);
    },
  },
};
</script>
<style lang="scss">
.user-center-menu {
  .uers-ucenter {
    .user-icon {
      width: 22px;
      height: 22px;
    }
  }
}
.user-center-menu.el-menu-item.nav-menu-item.login {
  .driver {
    right: -14px;
    top: 16px;
  }

  .sub-item-wrap-sub ::v-deep .el-submenu__title:hover {
    background-color: #fff;
    text-align: left;
    color: #12161c;
  }

  .el-menu--horizontal.sub-item-wrap.select-user-item {
    // top: 59px !important;
    top: 60px !important;
    .el-menu {
      padding: 10px 0;
      .el-menu-item {
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        min-width: 140px;
        border-radius: 4px;
        i {
          color: #909399 !important;
        }
        &:hover {
          i {
            color: #303133 !important;
          }
        }
      }
    }

    .sub-submenu-a {
      line-height: 36px;
      color: #909399;
      &:hover {
        background-color: #fff;
        color: #12161c;
        .sub-submenu-i {
          color: #12161c;
        }
      }
    }

    .sub-item-wrap-sub ::v-deep .el-menu--popup-right-start {
      margin-left: 1px;
      margin-right: 5px;
      width: 50px !important;
    }
  }
  a.none-active:after {
    background-color: transparent !important;
  }
  .user-name {
    color: #f7b239;
  }
}
</style>
