<template>
  <div
    class="quickly-recharge-content-page scrollbar-default"
    v-loading="pageLoading"
  >
    <div class="recharge-content">
      <div class="left-content">
        <p class="purchasing-price-text">
          <span class="step">1</span>{{ translateTitle("选中充值币种") }}
        </p>
        <div class="coin-select">
          <p class="coin-title">{{ translateTitle("币种") }}</p>
          <el-select
            :popper-append-to-body="false"
            filterable
            :style="{
              background:
                'url(' +
                data.itemDetail.coin_url +
                ') no-repeat 15px center #fff',
            }"
            style="width: 420px; height: 56px;border-radius: 3px;"
            :value="data.itemDetail.capital_name"
            @change="selectChange"
          >
            <el-option
              :value="val.item_id"
              v-for="(val, key) in coinData"
              :key="key"
              :label="val.capital_name + '-' + val.language"
              :class="{ selected: data.item_id == val.item_id }"
            >
              <img
                :src="val.itemDetail.coin_url"
                :alt="val.itemDetail.capital_name + '.png'"
                style="width: 24px; height: 24px; vertical-align: middle"
              />
              <span class="capital-name" style="margin-left: 10px">{{
                val.capital_name
              }}</span>
              <span class="coin-language">{{
                val.language["display_" + language]
              }}</span>
              <!--  +
            "-" +
            val.language 

            +
          '-' +
          data.itemDetail.language['display_' + language]
            
            --->
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="right-content">
        <div class="notice">
          <p>
            {{ translateTitle("注意：") }}
          </p>
          <ul>
            <li>
              <p>
                1.
                {{ translateTitle("禁止向") }}
                {{ data.itemDetail.capital_name }}
                {{ translateTitle("充币地址充值除") }}
                {{ data.itemDetail.capital_name }}
                {{ translateTitle("之外的资产，任何充入") }}
                {{ data.itemDetail.capital_name }}
                {{ translateTitle("地址的非") }}
                {{ data.itemDetail.capital_name }}
                {{ translateTitle("资产将不可找回；") }}
              </p>
            </li>
            <li>
              <p>
                2.
                {{ data.itemDetail.capital_name
                }}{{ translateTitle("的区块确认数达到") }} {{ confirmNum }}
                {{ translateTitle("个后，您的") }}
                {{ data.itemDetail.capital_name }}
                {{ translateTitle("会自动转入到您的账户；") }}
              </p>
            </li>
            <li>
              <p>
                3.
                {{
                  translateTitle("充值完成后，您可以进入充币记录页面跟踪进度。")
                }}
                <!-- {{
                  translateTitle(
                    "使用当前地址进行充值时，需1个网络节点确认后充值金额才会上账。"
                  )
                }} -->
              </p>
            </li>
            <li>
              <i></i>
              <p>
                {{ translateTitle("暂不支持BEP2及BEP20(BSC)充值。") }}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="right-content">
        <p class="purchasing-price-text">
          <span class="step">2</span>{{ translateTitle("确认充值信息") }}
        </p>
        <div class="qr-content">
          <p class="qr-title">{{ translateTitle("链名称") }}</p>
          <div class="qr-list">
            <ul>
              <li
                v-for="(item, index) in chainList"
                :class="acitiveChain == index ? 'active' : ''"
                :key="item.chain_tag"
                @click="onChainClick(index)"
              >
                {{ item.chain_tag }}
              </li>
            </ul>
          </div>
          <div class="qr-img-content">
            <p>{{ translateTitle("充值地址") }}</p>
            <img
              v-if="chainList[acitiveChain].qrcode_url"
              :src="chainList[acitiveChain].qrcode_url"
              alt=""
            />
            <div class="qr-address">
              <span v-if="chainList[acitiveChain].address">{{
                chainList[acitiveChain].address
              }}</span>
              <span
                v-if="chainList[acitiveChain].address"
                class="btn"
                v-clipboard:copy="chainList[acitiveChain].address"
                v-clipboard:success="copySuccess"
                >{{ translateTitle("复制") }}</span
              >
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="bottom-content">
      <p class="purchasing-price-text">
        <span class="step">2</span>{{ translateTitle("确认充值信息") }}
      </p>
      <div class="c-dialog create-address">
        <div class="header">
          <div class="header-title">
            {{ translateTitle("链名称") }}
          </div>
          <!-- 选项卡 -->
          <div class="nav">
            <div class="list-type-tab">
              <div
                v-for="item in coinInfoComputed"
                :key="item.value"
                class="tab"
                :class="{ active: item.value == active }"
                @click="onTypeChange(item.value)"
              >
                <span>{{ item.label }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="address-content">
          <div
            :class="addressList.length > 0 ? '' : 'no-data-left'"
            class="left"
          >
            <div class="scrollbar-default">
              <!--:class="getScrollClass"-->
              <div
                v-if="addressList.length"
                class="scroll-bar"
                :class="getScrollClass"
                @scroll="scrollAdress"
              >
                <ul class="coin-info-content">
                  <i class="coin-info-line"></i>
                  <li
                    v-for="(item, index) in addressList"
                    :key="index"
                    :class="{ active: item.ua_id == addressActive }"
                    @click="onAddressChange(item.ua_id)"
                  >
                    <div class="top">
                      <div class="img-content">
                        <!-- <img
                          :src="data.itemDetail.coin_url"
                          :alt="data.itemDetail.capital_name + '.png'"
                        /> -->
                        <span>{{ item.bak }}:</span>
                      </div>

                      <!-- <span>
                        {{ translateTitle("已充币次数") }}
                        {{ item.transactions }} {{ translateTitle("次") }}</span
                      > -->
                    </div>
                    <div class="bottom">
                      <div class="address">{{ item.address }}</div>
                      <div class="times">
                        {{ translateTitle("已充币次数") }}
                        {{ item.transactions }} {{ translateTitle("次") }}
                      </div>
                    </div>
                  </li>
                  <!-- <div class="coin-info-gap"></div> -->
                </ul>
              </div>
            </div>
            <no-data v-show="!addressList.length" slot="empty"></no-data>

            <div class="crate-address">
              <span class="create-btn-v2" @click="onCreate">
                <!-- <img src="../../../../assets/img/add.png"> -->
                <i class="iconfont icontianjia"></i>
                {{ translateTitle("生成新充币地址") }}
              </span>
              <!-- <span class="add-icon">+</span> -->
            </div>
          </div>

          <div
            :class="addressList.length > 0 ? '' : 'no-data-right'"
            class="right"
          >
            <div v-if="addressList.length">
              <div class="top-title">
                <!-- <img
                  :src="data.itemDetail.coin_url"
                  :alt="data.itemDetail.capital_name + '.png'"
                  class="currentImg"
                /> -->
                <p class="name">
                  {{ data.itemDetail.capital_name }}{{ addressData.bak }}
                </p>
                <div class="edit-content">
                  <img
                    src="../../assets/img/mask/edit.png"
                    alt=""
                    @click="onModify"
                    style="margin-right:21.7px"
                  />
                  <img
                    src="../../assets/img/mask/delete.png"
                    alt=""
                    @click="onDelete"
                  />
                </div>
              </div>
              <qrcode-vue
                class="qr-code"
                :value="addressData.address"
                :size="100"
                level="H"
              ></qrcode-vue>

              <div class="qr-address">
                <span v-if="addressData.address">{{
                  addressData.address
                }}</span>
                <span
                  v-if="addressData.address"
                  class="btn"
                  v-clipboard:copy="addressData.address"
                  v-clipboard:success="copySuccess"
                  >{{ translateTitle("复制") }}</span
                >
              </div>
              <!-- <div class="address">
                <span> {{ addressData.address }}</span>

                <i
                  class="iconfont iconicon-copy"
                  style="color: #9aa1b1"
                  v-clipboard:copy="addressData.address"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                ></i>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="createVisible"
      class="c-dialog"
      width="440px"
      :title="translateTitle('生成新充币地址')"
      :visible.sync="createVisible"
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-input
          @input="onFilterBak"
          v-model.trim="bak"
          :placeholder="translateTitle('请输入新地址备注名')"
          maxlength="15"
        ></el-input>
        <div class="new_buttons">
          <span @click="createVisible = false" class="common-cancel">{{
            translateTitle("取消")
          }}</span>
          <el-button
            @click="onCreateConfirm"
            :loading="creatLoading"
            class="common-save"
            >{{ translateTitle("确认") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      class="c-dialog"
      width="440px"
      :title="translateTitle('修改备注名')"
      @close="onModifyClose"
      :visible.sync="modifyVisible"
      :close-on-click-modal="false"
    >
      <div class="content">
        <el-input
          @input="onFilterNewBak"
          v-model.trim="new_bak"
          maxlength="15"
          :placeholder="translateTitle('请输入新备注名')"
        ></el-input>
        <div class="new_buttons">
          <span @click="modifyVisible = false" class="common-cancel">{{
            translateTitle("取消")
          }}</span>
          <el-button
            @click="onModifyConfirm"
            :loading="modifyLoading"
            class="common-save"
            >{{ translateTitle("确认") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      class="c-dialog"
      width="440px"
      :title="translateTitle('确认删除')"
      :visible.sync="delVisible"
      :close-on-click-modal="false"
    >
      <div class="content">
        <div>{{ translateTitle("您确定要进行本次操作吗？") }}</div>
        <div class="new_buttons">
          <span @click="delVisible = false" class="common-cancel">{{
            translateTitle("取消")
          }}</span>
          <el-button
            @click="onDeleteConfirm"
            :loading="delLoading"
            class="common-save"
            >{{ translateTitle("确认") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { showMessage } from "@/components/dialog/message.js";
import commonMixin from "@/components/common/commonMixin.vue";
import { apiGetStaticTableInfo } from "@/model/settings";
import NoData from "@/components/common/NoData";
import { mapActions, mapGetters } from "vuex";
import {
  getCoinInfo,
  getAssetsData,
  getCurrentCoin,
} from "../ucenter/common/getAssetsCoin";
import {
  apiGetFinanceAddressList,
  apiCreateFinanceAddress,
  apiSaveFinanceAddress,
  apiDelFinanceAddress,
} from "@/model/assets/index";
import QrcodeVue from "qrcode.vue";
export default {
  mixins: [commonMixin],
  components: {
    NoData,
    QrcodeVue,
  },
  data() {
    return {
      isScroll: false,
      createVisible: false,
      modifyVisible: false,
      delVisible: false,
      modifyLoading: false,
      delLoading: false,
      creatLoading: false,
      pageLoading: true,
      coinData: {},
      addressData: {
        address: "",
        bak: "",
        qrcode_url: "",
        transactions: "",
        chain_tag: "",
        ua_id: "",
      },
      chain_tag: "erc20",
      bak: "",
      new_bak: "",
      addressActive: "",
      coinMaps: {},
      addressList4Tag: {},
      time: "",
      active: "",
      chainMap: {},
      item_id: "10007",
      acitiveChain: "0",
      data: { itemDetail: { coin_url: "" } },
      coinInfo: [],
      chainList: [{ address: "", qrcode_url: "" }],
      qrcodeList: [],
      addressList: [],
    };
  },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
    confirmNum() {
      if (JSON.stringify(this.data) !== "{}") {
        let { confirm_num } = this.data.itemDetail;
        return confirm_num;
      } else {
        return "--";
      }
    },
    coinInfoComputed() {
      let tempObj = [];
      for (const key in this.coinInfo) {
        if (this.data.itemDetail.coin_id == this.coinInfo[key].value) {
          tempObj.push({
            key: this.coinInfo[key].value,
            value: this.coinInfo[key].label,
            label: this.coinInfo[key].label.toUpperCase(),
          });
        }
      }
      // if (tempObj.length > 0) {
      //   this.chain_tag = tempObj[0].label;
      // } else {
      //   this.chain_tag = this.data.itemDetail.default_chain.toUpperCase();
      // }
      let erc20 = {};
      let trc20 = {};
      tempObj.forEach(ele => {
        if (ele.label === "ERC20") erc20 = ele;
        if (ele.label === "TRC20") trc20 = ele;
      });
      let newtempObj = tempObj.filter(ele => {
        return ele.label != "ERC20" && ele.label != "TRC20";
      });
      if (JSON.stringify(erc20) != "{}") {
        newtempObj.push(erc20);
      }
      if (JSON.stringify(trc20) != "{}") {
        newtempObj.unshift(trc20);
      }

      return newtempObj;
    },
  },
  async created() {
    this.data = await getCurrentCoin();
    await this.getStaticChainInfo();
    this.coinData = await getCoinInfo();
    for (let item in this.coinData) {
      this.coinData[item].capital_name = this.coinData[
        item
      ].itemDetail.capital_name;
      // this.coinData[item].language = this.coinData[item].itemDetail.language[
      //   "display_" + this.language
      // ];
      for (const key in this.coinMaps) {
        if (this.coinData[item].item_tag == this.coinMaps[key].coin_id) {
          this.coinData[item].language = this.coinMaps[key].language;
        }
      }
    }
    for (let key in this.coinData) {
      if (this.coinData[key].item_name == "usdt") {
        this.data = this.coinData[key];
      }
    }
    console.log(this.coinData);
    this.selectChange(this.item_id);
  },
  beforeDestroy() {},
  destroyed() {},
  methods: {
    //新增确认
    async onCreateConfirm() {
      if (this.bak) {
        this.creatLoading = true;
        const req = {
          item_id: this.data.item_id,
          bak: this.bak,
        };
        // if (!this.data.itemDetail.multi_chain_status) {
        //   this.active = "";
        // } else {
        //   req.chain_tag = this.chain_tag;
        // }
        req.chain_tag = this.chain_tag;
        const { status, data } = await apiCreateFinanceAddress(req);
        this.creatLoading = false;
        if (status == 200) {
          showMessage({
            message: this.translateTitle(`生成新充币地址成功`),
            type: "success",
          });
          this.getAddress(this.chain_tag);
          this.bak = "";
          this.createVisible = false;
        }
      } else {
        showMessage({
          message: this.translateTitle(`请输入新地址备注名`),
          type: "error",
        });
      }
    },
    scrollAdress() {
      this.isScroll = true;
    },
    getScrollClass() {
      let tempVal = "";
      if (this.addressList.length > 6) {
        tempVal = "scroll-calss";
      } else {
        tempVal = "";
      }
      return tempVal;
    },
    onAddressChange(val) {
      //   this.$refs.scorllRef.scrollTo(0,0)
      // //   window.scrollTo(0,0);   // chrome
      // // document.body.scrollTop = 0
      this.addressActive = val;
      this.addressList.forEach(item => {
        if (val == item.ua_id) {
          this.addressData = item;
          this.ua_id = val;
        }
      });
    },
    onModifyClose() {
      this.new_bak = "";
    },
    onCreate() {
      this.createVisible = true;
      this.time = parseInt(new Date().getTime() / 1000);
      this.bak = this.translateTitle(`新地址`) + this.time;
      this.isModify = false;
    },
    // 选项卡切换
    onTypeChange(val) {
      this.isModify = false;
      this.chain_tag = val;
      // this.getAddress(val);
      this.onChangeTag(val);
    },
    //切换链选项卡
    onChangeTag(tagName) {
      this.active = tagName.toUpperCase();
      this.addressList = this.addressList4Tag[tagName.toLowerCase()];
      // this.addressList = data;  //pangpang注释
      // this.addressOptions = data; //pangpang注释

      if (!this.isModify) {
        if (this.addressList4Tag[tagName.toLowerCase()].length > 0) {
          this.addressData = this.addressList4Tag[tagName.toLowerCase()][0];
          this.ua_id = this.addressData.ua_id;
          this.addressActive = this.addressData.ua_id;
          // this.onAddressChange(this.ua_id);
        } else {
          this.addressList = [];
          this.addressData = [];
        }
      } else {
        this.onAddressChange(this.ua_id);
      }
    },
    onFilterNewBak(val) {
      this.new_bak = val.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\$]/g, "");
    },
    onFilterBak(val) {
      this.bak = val.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\$]/g, "");
    },
    mainClose() {
      this.isModify = false;
      // this.chain_tag = "erc20";
    },
    //修改确认
    async onModifyConfirm() {
      if (this.new_bak) {
        if (this.new_bak == this.addressData.bak) {
          this.modifyVisible = false;
          return;
        } else {
          const req = {
            ua_id: this.ua_id,
            bak: this.new_bak,
          };
          this.modifyLoading = true;
          const { status, data } = await apiSaveFinanceAddress(req);
          this.modifyLoading = false;
          if (status == 200) {
            showMessage({
              message: this.translateTitle(`修改成功`),
              type: "success",
            });
            this.getAddress(this.chain_tag);
            this.new_bak = "";
            this.isModify = true;
            this.modifyVisible = false;
          }
        }
      } else {
        showMessage({
          message: this.translateTitle(`请输入新备注名`),
          type: "error",
        });
      }
    },
    //删除确认
    async onDeleteConfirm() {
      this.delLoading = true;
      const req = {
        ua_id: this.ua_id,
      };
      const { status, data } = await apiDelFinanceAddress(req);
      this.delLoading = false;
      if (status == 200) {
        this.getAddress(this.chain_tag);
        this.delVisible = false;
        showMessage({
          message: this.translateTitle(`删除成功`),
          type: "success",
        });
      }
    },
    onModify() {
      this.modifyVisible = true;
      // this.selectChange(this.ua_id);
      this.new_bak = this.addressData.bak;
    },
    onDelete() {
      this.delVisible = true;
      this.isModify = false;
    },
    onChainClick(item) {
      this.acitiveChain = item;
    },
    async getStaticChainInfo() {
      this.coinInfo = [];
      await apiGetStaticTableInfo(1).then(({ data }) => {
        this.coinMaps = data;
      });
      await apiGetStaticTableInfo(56).then(({ data }) => {
        this.chainMap = data;
      });
      // 获取29静态表 获取coin 费率 chain名称 item_id信息
      await apiGetStaticTableInfo(57).then(({ data }) => {
        for (const key in data) {
          for (let item in this.chainMap) {
            if (
              data[key].chain.toUpperCase() ==
                this.chainMap[item].chain_tag.toUpperCase() &&
              data[key].status == 1 &&
              this.chainMap[item].status == 1
            ) {
              this.coinInfo.push({
                label: data[key].chain.toUpperCase(),
                value: data[key].coin_id,
                rate_out: data[key].fee,
                is_default: data[key].is_default,
                chain_status: this.chainMap[item].status,
                status: data[key].status,
                minout: data[key].minout,
                maxout: data[key].maxout,
              });
            }
          }
        }
      });
    },
    // 复制成功
    copySuccess() {
      this.$message({
        message: this.translateTitle("复制成功"),
        type: "success",
      });
    },
    copyError() {
      showMessage({ message: this.translateTitle(`内容复制失败`) });
    },
    // 选择币种
    async selectChange(val) {
      this.item_id = val;
      this.data = this.coinData[val];
      let chain_tag_temp = [];
      let chain_tag_strings = "";
      // if (!val) {
      this.coinInfoComputed.forEach(item => {
        chain_tag_temp.push(item.value);
      });
      chain_tag_strings = chain_tag_temp.join(",");

      const req = {
        item_id: val,
        chain_tag_list: chain_tag_strings,
      };
      this.chainList = [];
      this.qrcodeList = [];
      const { status, data } = await apiGetFinanceAddressList(req);
      if (status == 200) {
        this.pageLoading = false;
        data.forEach(ele => {
          ele.chain_tag = ele.chain_tag.toUpperCase();
          this.chainList.push(ele);
        });
        this.active = this.chain_tag.toUpperCase();
        chain_tag_temp.forEach(tag => {
          this.addressList4Tag[tag.toLowerCase()] = [];
          data.forEach(ele => {
            if (tag == ele.chain_tag) {
              this.addressList4Tag[tag.toLowerCase()].push(ele);
            }
          });
        });

        this.addressList = this.addressList4Tag[this.chain_tag.toLowerCase()];

        if (!this.isModify) {
          if (this.addressList4Tag[this.chain_tag.toLowerCase()].length > 0) {
            this.addressData = this.addressList4Tag[
              this.chain_tag.toLowerCase()
            ][0];
            this.ua_id = this.addressData.ua_id;
            this.addressActive = this.addressData.ua_id;
            // this.onAddressChange(this.ua_id);
          } else {
            this.addressList = [];
            this.addressData = [];
          }
        } else {
          this.onAddressChange(this.ua_id);
        }
      }
      this.acitiveChain = 0;
    },
    // 获取充币地址
    async getAddress(val) {
      // this.chain_tag = val;  //pangpang注释
      const item_id = this.data.item_id;

      let params = {
        item_id: item_id,
      };
      // if (!this.data.itemDetail.multi_chain_status) {
      //   this.active = "";
      // } else {
      //   params.chain_tag_list = this.chain_tag;
      // }
      let chain_tag_temp = [];
      let chain_tag_strings = "";
      // if (!val) {
      this.coinInfoComputed.forEach(item => {
        chain_tag_temp.push(item.value);
      });
      chain_tag_strings = chain_tag_temp.join(",");

      params.chain_tag_list = chain_tag_strings;

      const { status, msg, data } = await apiGetFinanceAddressList(params);
      // apiGetFinanceAddressList(params).then(({ status, msg, data }) => {

      if (status === 200) {
        // if (val) {                          //pangpang 注释
        //   this.active = val.toUpperCase();
        // }
        this.active = this.chain_tag.toUpperCase();
        chain_tag_temp.forEach(tag => {
          this.addressList4Tag[tag.toLowerCase()] = [];
          data.forEach(ele => {
            if (tag.toLowerCase() == ele.chain_tag) {
              this.addressList4Tag[tag.toLowerCase()].push(ele);
            }
          });
        });

        this.addressList = this.addressList4Tag[this.chain_tag.toLowerCase()];

        if (!this.isModify) {
          if (this.addressList4Tag[this.chain_tag.toLowerCase()].length > 0) {
            this.addressData = this.addressList4Tag[
              this.chain_tag.toLowerCase()
            ][0];
            this.ua_id = this.addressData.ua_id;
            this.addressActive = this.addressData.ua_id;
            // this.onAddressChange(this.ua_id);
          } else {
            this.addressList = [];
            this.addressData = [];
          }
        } else {
          this.onAddressChange(this.ua_id);
        }

        this.dialogVisible = true;
      } else {
        showMessage({ message: msg ? msg : status });
      }
    },
  },
};
</script>
<style lang="scss">
.ar {
  .quickly-recharge-content-page .recharge-content .left-content .coin-select {
    .capital-name {
      margin-right: 10px;
    }
    .coin-language {
      float: left;
    }
  }
}
.quickly-recharge-content-page {
  width: 100%;
  height: 850px;
  padding: 40px 0 30px;
  background: url("../../assets/img/quickly-coins/quicklycoins-bg.png") 0 0
    no-repeat;
  background-size: 100% 100%;
  .recharge-content {
    width: 900px;
    margin: 0 auto;
    .left-content {
      width: 420px;
      display: inline-block;
      vertical-align: top;
      .purchasing-price-text {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 500;
        color: #000;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .step {
          width: 22px;
          height: 22px;
          line-height: 22px;
          flex-shrink: 0;
          margin-right: 6.6px;
          background-color: #15bfb5;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .coin-select {
        width: 490px;
        padding-top: 24px;
        .coin-title {
          display: block;
          margin-bottom: 10px;
        }
        .el-select {
          background-size: 32px 32px !important;
          .el-input__inner {
            height: 56px;
            padding-left: 60px;
            background-color: transparent;
            font-family: DINPro-Medium;
            font-size: 18px;
            color: #333333;
          }
        }
        .coin-language {
          float: right;
          margin-right: 10px;
        }
      }
    }
    .right-content {
      margin-left: 60px;
      width: 420px;
      display: inline-block;
      vertical-align: top;
      .notice {
        width: 420px;
        margin-top: 42px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        ul {
          margin-top: 10px;
          li {
            // i {
            //   display: inline-block;
            //   width: 3px;
            //   height: 3px;
            //   line-height: 1;
            //   vertical-align: middle;
            //   margin-right: 15px;
            //   border-radius: 50%;
            //   background: #15bfb5;
            //   margin-top: -4px;
            // }
            p {
              font-size: 12px;
              font-weight: normal;
              color: #333;
              margin-bottom: 3px;
              width: 400px;
              vertical-align: top;
              display: inline-block;
            }
          }
        }
      }
      .qr-content {
        width: 420px;
        height: 355.1px;
        margin-top: 25px;
        border-radius: 6px;
        box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
        background-color: #fff;
        .qr-title {
          padding: 20px 30px 0;
          font-size: 12px;
          font-weight: 500;
          color: #333;
        }
        .qr-list {
          padding: 0 30px 15px;
          margin-top: 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          ul {
            li {
              display: inline-block;
              width: 75px;
              height: 26px;
              margin-right: 10px;
              margin-bottom: 10px;
              line-height: 26px;
              border-radius: 3px;
              background-color: white;
              border: 1px solid #c2c7d0;
              text-align: center;
              color: #354052;
              font-size: 12px;
              font-weight: 500;
              cursor: pointer;
              &.active {
                border: 1px solid #15bfb5;
                background-color: #eefbfa;
                color: #15bfb5;
              }
            }
          }
        }
        .qr-img-content {
          padding: 15px 30px;
          img {
            width: 130px;
            height: 130px;
            margin: 0 auto;
            display: inherit;
          }
          .qr-address {
            margin-top: 22px;
            height: 34px;
            line-height: 34px;
            padding-left: 10px;
            border-radius: 4px;
            border: solid 0.6px rgba(0, 0, 0, 0.1);
            font-size: 11px;
            font-weight: 500;
            .btn {
              user-select: none;
              border-radius: 4px;
              background-color: #15bfb5;
              color: white;
              padding: 0px 24.3px 0px 24.3px;
              float: right;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .bottom-content {
    width: 900px;
    margin: 0 auto;

    .purchasing-price-text {
      margin-top: 40px;
      margin-bottom: 25px;
      font-size: 20px;
      font-weight: 500;
      color: #000;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .step {
        width: 22px;
        height: 22px;
        line-height: 22px;
        flex-shrink: 0;
        margin-right: 6.6px;
        background-color: #15bfb5;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .c-dialog {
    padding: 20px 0;
    &.create-address {
      border-radius: 6px;
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
      background: white;
    }
    .header {
      background: white;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      border: 0;
      .header-title {
        margin-left: 33px;
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 500;
      }
      .nav {
        /* background: #f1f2f5; */
        background: #fff;
        display: flex;
        flex: 1;
        justify-content: space-between;
        font-size: 14px;
        .list-type-tab {
          margin-left: 33px;
          .tab {
            width: 100px;
            text-align: center;
            height: 32px;
            line-height: 32px;
            margin-right: 10px;
            display: inline-block;
            padding: 0px;
            background: #fafafa;
            border-radius: 3px;
            cursor: pointer;
            span {
              color: #666;
              font-size: 14px;
              font-weight: 500;
            }
            &:hover {
              color: #354052;
              &:after {
                content: "";
                height: 2px;
                width: 80%;
                background-color: #354052;
              }
            }
            &.active {
              background-color: #eefbfa;
              color: #15bfb5;
              border: 0;
              span {
                color: #15bfb5;
              }
            }
          }
        }
      }
    }
    .address-content {
      background: #ffffff;
      border: 1px solid #e2e2e4;
      border-bottom: none;
      display: flex;
      border-radius: 4px;
      // height: 448px;

      .left {
        position: relative;
        box-sizing: border-box;
        // border-top-left-radius: 4px;
        // border-right: 1px solid #d60c30;
        width: 430px;
        .scrollbar-default {
          ::-webkit-scrollbar {
            visibility: hidden;
            width: 4px;
            height: 4px;
            position: absolute;
            right: -100px;
          }

          ::-webkit-scrollbar-thumb {
            visibility: hidden;
            border-radius: 4px;
            position: absolute;
            right: -100px;
          }

          &:hover {
            ::-webkit-scrollbar {
              visibility: visible;
              position: absolute;
              right: -100px;
            }

            ::-webkit-scrollbar-thumb {
              background: #7f7f7f;
              border-radius: 4px;
              visibility: visible;
              position: absolute;
              right: -100px;
            }
          }
        }
        .scroll-bar {
          height: 292px;
        }
        .scroll-calss {
          overflow-y: auto;
          width: 380px;
          &:hover {
            overflow-y: auto;
            overflow-x: hidden;
            .active {
              border-right: 1px solid #ffffff;
            }
          }
        }

        .coin-info-content {
          width: 430px;
          max-height: 291px;
          overflow-x: hidden;
          overflow-y: scroll;
          box-sizing: border-box;
          position: relative;
          user-select: text;
          .coin-info-line {
            position: absolute;
            width: 1px;
            right: 0px;
            top: 0;
            background-color: rgba(0, 0, 0, 0.1);
            height: 100%;
          }
          .coin-info-gap {
            height: 10px;
          }
          li {
            list-style: none;
            overflow: visible;
            height: 100px;
            box-sizing: border-box;
            background-color: #fff;
            cursor: pointer;
            font-size: 14px;
            color: #354052;
            border-bottom: 1px solid #e2e2e4;
            // border-top: 0px;
            // border: 0;
            border-left: 3px solid #f8f9fc;
            box-sizing: border-box;
            padding-left: 16px;
            &:first-child {
              border-top: 0;
            }
            &.active {
              // color: #15beb4;
              border-right-color: #fff;
              background: #ffffff;
              width: 430px;
              position: relative;
              border-top: 1px solid #15beb4;
              border-bottom: 1px solid #15beb4;
              border-left: 6px solid #15beb4 !important;
              border-right: 0;
            }
            .top {
              padding: 16px 30px 5px 12px;
              display: flex;
              justify-content: space-between;
              // height: 18px;
              .img-content {
                display: flex;
                align-items: center;
                img {
                  width: 16px;
                  height: 16px;
                  margin-right: 8px;
                }
              }
              span {
                font-size: 16px;
                color: #333333;
                font-weight: 500;
                font-family: DINPro-Medium;
              }
            }
            .bottom {
              text-align: left;
              padding-left: 12px;
              font-size: 12px;
              font-weight: 400;
              div {
                text-overflow: ellipsis;
                overflow: hidden;
                width: 350px;
                margin-bottom: 9px;
                white-space: nowrap;
              }
              .address {
                font-size: 14px;
                color: #666666;
                font-family: DINPro-Medium;
              }
              .times {
                font-size: 12px;
                color: #666666;
                font-family: DINPro-Medium;
              }
            }
          }
        }
        .crate-address {
          // position: absolute;
          // bottom: 0px;
          width: 100%;
          height: 70px;
          border-radius: 2px;
          font-size: 14px;
          box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.04);
          // background-color: #ffffff;
          align-items: center;
          display: flex;
          justify-content: center;
          box-sizing: border-box;
          .create-btn-v2 {
            // position: relative;

            display: flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: 18px;
              margin-right: 5px;
              color: #666;
            }
            border-radius: 32px;
            width: 200px;
            cursor: pointer;
            color: #666;
            // font-size: 14px;
            background-color: #fafafa;
            height: 40px;
            line-height: 40px;
            padding: 0px 10px 0px 10px;
            box-sizing: border-box;
          }
          i {
            font-size: 18px;
            color: #ffffff;
          }
          .add-icon {
            position: absolute;
            left: 116px;
            top: 26px;
            width: 18px;
            height: 18px;
            border: solid 1px #ffffff;
            border-radius: 50%;
            color: #ffffff;
            line-height: 1;
          }
        }
      }
      .right {
        width: 470px;
        // border: 1px solid #e81b40;
        border-left: 0;
        box-sizing: border-box;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        .top-title {
          text-align: left;
          // margin: 82px 0px 8px 0px;
          margin: 4px 9px 17px 31px;
          height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: text;
          width: 400px;
          .edit-content {
          }
          img {
            cursor: pointer;
            width: 15px;
            height: 15px;
            margin: 5px 10px 0 0;
          }
          p {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
            height: 14px;
          }
        }
        .qr-address {
          width: 400px;
          margin: 0 auto;
          margin-top: 22px;
          height: 34px;
          line-height: 34px;
          padding-left: 10px;
          border-radius: 4px;
          border: solid 0.6px rgba(0, 0, 0, 0.1);
          font-size: 11px;
          font-weight: 500;
          .btn {
            user-select: none;
            border-radius: 4px;
            background-color: #15bfb5;
            color: white;
            padding: 0px 24.3px 0px 24.3px;
            float: right;
            cursor: pointer;
          }
        }
        .currentImg {
          margin-top: 40px;
          width: 43px;
          height: 43px;
          border-radius: 50%;
          margin-bottom: 14px;
        }
        .name {
          color: #354052;
          font-size: 16px;
          font-weight: 500;
        }
        .qr-code {
          width: 100px;
          height: 100px;
          // border: 5px solid #b4bac6;
          box-sizing: border-box;
          margin: 81px auto 24px;
          user-select: text;
        }
        .address {
          color: #354052;
          width: 380px;
          margin: 0px 50px;
          height: 36px;
          display: flex;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          span {
            text-align: left;
            user-select: text;
          }
          .iconicon-copy {
            font-size: 16px;
            margin-left: 5px;
          }
        }
        i {
          cursor: pointer;
          color: rgba(39, 198, 131, 0.8);
          &:hover {
            color: rgba(39, 198, 131, 1);
          }
        }
        .collection {
          display: flex;
          justify-content: center;
          align-items: center;

          margin-top: 20px;

          .collection-title {
            color: #354052;
            font-size: 14px;
            font-weight: 500;
          }
          .collection-num {
            color: #354052;
            margin-left: 10px;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .buttons {
          margin-top: 30px;
          display: flex;
          justify-content: center;
          .modify {
            width: 140px;
            height: 40px;
            border-radius: 2px;
            border: solid 1px #e2e2e4;
            background-color: #ffffff;
            line-height: 40px;
            box-sizing: border-box;
            color: #e2e2e4;
            cursor: pointer;
          }
          .delete {
            width: 140px;
            height: 40px;
            border-radius: 2px;
            border: solid 1px #ff7481;
            background-color: #ffffff;
            line-height: 40px;
            box-sizing: border-box;
            margin-left: 10px;
            color: #ff7481;
            cursor: pointer;
          }
        }
      }
      .no-data-right {
        border: none;
      }
      .no-data-left {
        .crate-address {
          border: none;
          // border-right: 1px solid #e2e2e4;
        }
      }
    }
    &.create-address {
      .el-dialog {
        margin-top: 16vh !important;
      }
      .header-title {
        display: flex;
        align-items: center;
        .item-img {
          width: 30px;
          height: 30px;
        }
        .item-name {
          margin-left: 10px;
          font-weight: 500;
          color: #354052;
          font-size: 20px;
        }
        .item-tips {
          margin-left: 3px;
          font-weight: 400;
          color: #9aa1b1;
          font-size: 14px;
        }
      }

      img {
        width: 30px;
        height: 30px;
        vertical-align: middle;
      }
      .title-data {
        height: 30px;
        font-size: 20px;
        font-weight: 500;
        color: #354052;
        vertical-align: middle;
        margin-left: 5px;
        width: fit-content;
        .sub-title-data {
          color: #9aa1b1;
          font-size: 14px;
          font-weight: normal;
        }
      }
      .el-dialog__body {
        /* background: #f1f2f5; */
        background: #fff;
      }
    }
    .el-dialog__header {
      border-radius: 4px;
      padding: 20px 30px;

      .el-dialog__title {
        font-size: 20px;
      }
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__body {
      border-radius: 4px;
      padding: 0px 30px 30px 30px;
      box-sizing: border-box;
      text-align: center;
      border: 5px;
      overflow: hidden;

      /* 选择地址框 */
      .address-select {
        width: 380px;
        margin-top: 20px;
        // min-height: 50px;
        .el-select-dropdown {
          .el-scrollbar {
            // min-height: 40px;
            .el-select-dropdown__item {
              min-height: 53px;
              display: flex;
              // align-items: center;
              flex-direction: column;
              justify-content: center;
              p {
                font-size: 14px;
                line-height: 16px;
                white-space: nowrap;
                word-break: break-all;
                &:first-child {
                  margin-bottom: 6px;
                }
              }
              &.add-address {
                width: 100%;
                height: 40px;
                min-height: 40px;
                color: #14c781;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                position: absolute;
                left: 0;
                bottom: 0;
                .add-icon {
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }

      p {
        font-size: 14px;
        height: 14px;
      }
      .iconduigou {
        float: right;
        color: #ffffff;
      }
      .selected {
        .iconduigou {
          color: #14c781;
        }
      }

      .new_buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
        .cancel {
          width: 140px;
          height: 40px;
          border-radius: 2px;
          border: solid 1px #e2e2e4;
          background-color: #ffffff;
          line-height: 40px;
          box-sizing: border-box;
          color: #e2e2e4;
          cursor: pointer;
        }
        .common-save {
          line-height: 1;
          background-image: linear-gradient(68deg, #00cf88 21%, #0084d6 82%);
        }
        .confirm {
          width: 140px;
          height: 40px;
          border-radius: 2px;
          border: solid 1px #e2e2e4;
          background-color: #e2e2e4;
          // line-height: 40px;
          box-sizing: border-box;
          margin-left: 10px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
