<template>
  <div class="currency-based-contract-component-panel">
    <div class="panel-message">
      <div class="first-item">
        <div>
          <h1 class="coin-item panel-item">
            {{ from_symbol_show }} {{ translateTitle("永续") }}/{{
              to_symbol_show
            }}
          </h1>
          <div class="price-item panel-item V2">
            <span class="multiple-num" @click="onMultipleNumClick">
              {{ translateTitle("杠杆") }} {{ multipleNum }} X
              <img src="../../../assets/img/arrow-down_clear.png" alt="" />
            </span>
          </div>
        </div>
        <div class="price-item panel-item V3">
          <p class="price DINPro" :class="colorClass">
            {{ marketInfoShow.last || "--" }}
            <span class="price-cny">
              ≈
              {{ systemCurrencyMaps[systemCurrency] }}
              <span class="DINPro">{{
                marketInfoShow.currency[systemCurrency] || "--"
              }}</span>
            </span>
            <span class="arrow" v-if="colorClass == 'green'">↑</span>
            <span class="arrow" v-else-if="colorClass == 'red'">↓</span>
            <span class="value increase-item DINPro" :class="className">
              <span
                v-if="marketInfoShow.price_change_percent > 0"
                style="margin-right: -2px; width: 7px; display: inline-block"
                >+</span
              >
              {{ marketInfoShow.price_change_percent || "--" }}%
            </span>
          </p>
          <!-- <p class="price-cny">
            ≈
            {{ systemCurrencyMaps[systemCurrency] }}
            <span class="DINPro">{{
              marketInfoShow.currency[systemCurrency] || "--"
            }}</span>
          </p> -->
        </div>
      </div>
      <div class="heigh-item panel-item">
        <p class="item">{{ translateTitle("24H高") }}</p>
        <p class="value DINPro">{{ marketInfoShow.high || "--" }}</p>
      </div>
      <div class="low-item panel-item">
        <p class="item">{{ translateTitle("24H低") }}</p>
        <p class="value DINPro">{{ marketInfoShow.low || "--" }}</p>
      </div>

      <div class="low-item panel-item">
        <p class="item">{{ translateTitle(`24H量`) }}</p>
        <p class="value">
          <span class="DINPro" v-if="Number(marketInfoShow.vol) > 0">
            {{ Number(marketInfoShow.vol).toFixed(2) }}</span
          >
          <span class="DINPro" v-else>--</span>
          {{ from_symbol_show }}
        </p>
      </div>
      <div class="low-item panel-item">
        <p class="item">{{ translateTitle("本期资金费率") }}</p>
        <p class="value red">
          <span class="DINPro"> {{ fundForm.fund_rate || "--" }}</span>
        </p>
      </div>
      <div class="low-item panel-item">
        <p class="item">{{ translateTitle("倒计时") }}</p>
        <p class="value red">
          <span class="DINPro" v-if="fundTime > 0"> {{ countTime }}</span>
          <span class="DINPro" v-else> ---- </span>
        </p>
      </div>
    </div>
    <div class="collet-item">
      <div
        class="border V2"
        v-if="selfOptionsList.indexOf(swap_symbol_id.toString()) > -1"
      >
        <img
          class="select-icon"
          src="../../../assets/img/exchange/active.png"
          @click="addOption(2)"
          alt=""
        />
      </div>
      <div class="border" v-else>
        <img
          class="select-icon"
          src="../../../assets/img/exchange/noActive.png"
          @click="addOption(1)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
import {
  apiAddOption,
  apiGetOptionList,
  apiGetDeliveryFundingRate,
} from "@/model/markets/markets";
import * as Account from "@/model/user/account";
import { systemCurrencyMaps } from "@/assets/js/keymap.js";
import { mapGetters } from "vuex";
import commonMixin from "@/components/common/commonMixin.vue";
import { format } from "echarts";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],

  props: {
    swap_symbol_id: {
      type: [String, Number],
      default: function() {
        return 1010;
      },
    },
    tradePairArray: {
      type: Array,
      default: function() {
        return [];
      },
    },
    marketList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    from_symbol: {
      type: [String, Number],
    },
    to_symbol: {
      type: [String, Number],
    },
  },
  data() {
    return {
      systemCurrencyMaps,
      multipleNum: 20,
      timer: null,
      multipleNumBtn: false,
      isMaskex: false,
      colorClass: "",
      fundForm: {
        fund_rate: "",
        time: 28800,
      },
      fundTime: 0,
      TIMER: null,
      TIMER2: null,
      selfOptionsList: [],
    };
  },
  created() {
    if (VUE_UNION_STATION == "MaskEx") {
      this.isMaskex = true;
    }
    let tempMultipleNum = JSON.parse(
      localStorage.getItem("delivery-multiple-num")
    );
    for (const key in tempMultipleNum) {
      if (key == this.swap_symbol_id) {
        this.multipleNumBtn = true;
        this.multipleNum = Number(tempMultipleNum[key]);
        setTimeout(() => {
          this.multipleNumBtn = false;
        }, 1000);
      }
    }
    this.getOptionList();
    this.TIMER2 = setInterval(() => {
      this.getFundingRate();
    }, 1000 * 60 * 60);
  },
  destroyed() {
    clearInterval(this.TIMER);
    clearInterval(this.TIMER2);
  },
  beforeDestroy() {
    clearInterval(this.TIMER);
    clearInterval(this.TIMER2);
  },
  methods: {
    routeSplicing,
    onMultipleNumClick() {
      this.$emit("onMultipleNumClick", this.multipleNum);
    },
    setMultipleNum(value) {
      this.multipleNum = value;
    },
    async getFundingRate() {
      if (this.swap_symbol_id) {
        let params = {
          symbol_id: this.swap_symbol_id,
        };
        const { status, data } = await apiGetDeliveryFundingRate(params);
        if (status == 200) {
          this.fundForm = data;
          this.fundTime = data.time;
          this.TIMER = setInterval(() => {
            this.fundTime--;
          }, 1000);
        }
      }
    },
    async getOptionList() {
      let { userInfo } = await Account.getUserInfo();
      if (!userInfo) {
        return;
      }
      apiGetOptionList().then(res => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) != "{}" && res.data.digital) {
            this.selfOptionsList = res.data.digital;
          }
        }
      });
    },
    addOption(type) {
      if (this.userInfo) {
        let params = {
          symbol_id: this.swap_symbol_id,
          option: type,
          swap_type: 2,
        };
        apiAddOption(params).then(res => {
          if (res.status == 200) {
            this.getOptionList();
            this.$emit("onAddOption");
            this.$message({
              message:
                params.option == 1
                  ? this.translateTitle(`添加自选成功`)
                  : this.translateTitle(`删除自选成功`),
              type: params.option == 1 ? "success" : "success",
            });
          }
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: { redirect: this.$route.fullPath },
        });
      }
    },
  },
  watch: {
    fundTime(newValue, oldValue) {
      if (newValue < 0) {
        this.fundTime = 28800;
        clearInterval(this.TIMER);
        this.getFundingRate();
      }
    },
    swap_symbol_id() {
      clearInterval(this.TIMER);
      let tempMultipleNum = JSON.parse(
        localStorage.getItem("delivery-multiple-num")
      );
      for (const key in tempMultipleNum) {
        if (key == this.swap_symbol_id) {
          this.multipleNumBtn = true;
          this.multipleNum = Number(tempMultipleNum[key]);
          setTimeout(() => {
            this.multipleNumBtn = false;
          }, 1000);
        }
      }
      if (this.multipleNumBtn == false) {
        this.multipleNum = 20;
      }
      this.getFundingRate();
    },
    marketInfoShow(newValue, oldValue) {
      if (Number(newValue.last) - Number(oldValue.last) > 0) {
        this.colorClass = "green";
      } else if (Number(newValue.last) - Number(oldValue.last) < 0) {
        this.colorClass = "red";
      }
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language",
    }),
    from_symbol_show() {
      return this.from_symbol.toUpperCase() || "--";
    },
    to_symbol_show() {
      return this.to_symbol.toUpperCase() || "--";
    },
    marketInfoShow() {
      // this.pageLoading = true;
      let marketInfo = {
        last: "",
        currency: { cny: "" },
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      // this.pageLoading = false;
      return marketInfo;
    },
    className() {
      let className = "";
      if (!this.marketInfoShow.price_change_percent) {
        className = "";
      } else if (this.marketInfoShow.price_change_percent > 0) {
        className = "green";
      } else if (this.marketInfoShow.price_change_percent < 0) {
        className = "red";
      }
      return className;
    },
    countTime() {
      //将秒数转换为时分秒格式
      var t;
      if (this.fundTime > -1) {
        var hour = Math.floor(this.fundTime / 3600);
        var min = Math.floor(this.fundTime / 60) % 60;
        var sec = this.fundTime % 60;
        if (hour < 10) {
          t = "0" + hour + ":";
        } else {
          t = hour + ":";
        }

        if (min < 10) {
          t += "0";
        }
        t += min + ":";
        // t += sec;

        if (sec < 10) {
          t += "0";
        }
        t += sec.toFixed(0);
      }
      if (t == "00:00:00") {
        t = "--";
      }
      return t;
    },
  },
};
</script>
<style lang="scss">
.currency-based-contract-component-panel {
  height: 50px;
  background-color: #f5f5f7;
  display: flex;
  align-items: center;
  user-select: none;
  justify-content: space-between;
  .panel-message {
    display: flex;
    align-items: center;
  }
  .first-item {
    // width: 250px;
    display: inline-block;
  }
  .panel-item {
    display: inline-block;
    .red {
      color: #c61b3d;
    }
  }
  .collet-item {
    margin-right: 10px;
    margin-top: -10px;
    .border {
      width: 24px;
      height: 24px;
      border-radius: 2px;
      border: solid 1px #bec1c8;
      display: flex;
      justify-content: center;
      align-items: center;
      &.V2 {
        border: solid 1px #3a7dff;
      }
      .select-icon {
        width: 18px;
        height: 18px;
        cursor: pointer;
        // vertical-align: middle;
        // margin: 3px 3px 4px;
      }
    }
  }
  .coin-item {
    // width: 138px;
    font-size: 16px;
    font-weight: 500;
    margin-left: 15px;
    margin-right: 10px;
    color: #354052;
    // height: 39px;
    vertical-align: middle;
  }
  .area-btn {
    width: 36px;
    height: 18px;
    line-height: 16px;
    border-radius: 2px;
    border: solid 1px #3a7dff;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    color: #3a7dff;
    font-weight: 500;
  }
  .price-item {
    min-width: 110px;
    // margin-left: 12px;
    &.V3 {
      margin-left: 15px;
    }
    &.V2 {
      min-width: 80px;
      // margin-top: -15px;
      vertical-align: middle;
      .multiple-num {
        cursor: pointer;
        width: 68px;
        height: 18px;
        margin: 7px 0px 1px 5px;
        padding: 0px 8px 0 8px;
        border-radius: 2px;
        background-color: #3a7dff;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        line-height: 18px;
        img {
          vertical-align: 10%;
          width: 6px;
          height: 4px;
        }
      }
    }
    .price {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      color: #354052;
      &.green {
        color: #03ad79;
      }
      &.red {
        color: #c61b3d;
      }
      .arrow {
        display: inline-block;
        width: 15px;
        height: 19px;
        font-size: 12px;
        text-align: right;
        &.V2 {
          color: #f5f5f7;
        }
      }
    }
    .price-cny {
      display: inline-block;
      color: #7f8490;
      font-size: 12px;
    }
  }
  .increase-item {
    font-size: 12px;
    margin-left: 3px;
    .item {
      color: #7f8490;
    }
    .value {
      margin-top: 3px;
      &.green {
        color: #03ad79;
      }
      &.red {
        color: #c61b3d;
      }
    }
  }
  .heigh-item,
  .low-item {
    margin-left: 23px;
    font-size: 12px;
    // margin-bottom: 10px;
    .item {
      color: #7f8490;
    }
    .value {
      margin-top: 3px;
      color: #1f2533;
      &.red {
        color: #c61b3d;
      }
    }
  }
}
</style>
