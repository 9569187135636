export function onSelectBlur(me, use, copy) {
    setTimeout(() => {
        me[use] = me[copy];
    }, 100)
}
export function onSelectFilter(val, me, use, copy, value, label) {
    me[use] = me[copy].filter(item => {
        if (!val) {
            return true;
        }
        if (
            String(item[value]).indexOf(val) != -1 ||
            String(item[label]).toUpperCase().indexOf(val.toUpperCase()) != -1
        ) {
            return true
        }else{
            return false;
        }
    })
}