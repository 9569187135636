import api from "@/assets/js/api";
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV
} from "@/assets/js/stationSetting";

//获取数据总览统计信息
export function apiGetTotalRebateStats(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UserRebate/getTotalRebateStats`,
    data,
    type: "POST"
  });
}
//获取推广链接返佣统计
export function apiGetInviteCodeStats(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UserRebate/getInviteCodeStats`,
    data,
    type: "POST"
  });
}
//获取邀请好友返佣统计
export function apiGetFriendRebateStats(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UserRebate/getFriendRebateStats`,
    data,
    type: "POST"
  });
}
//获取返佣流水
export function apiGetRebateList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UserRebate/getRebateList`,
    data,
    type: "POST"
  });
}
//设置默认邀请码、备注
export function apiUpdateInviteCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UserRebate/updateInviteCode`,
    data,
    type: "POST"
  });
}
//生成邀请码
export function apiCreateInviteCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UserRebate/createInviteCode`,
    data,
    type: "POST"
  });
}
//CSV公共导出
export function apiCommonExport(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UserRebate/commonExport`,
    data,
    type: "POST"
  });
}
//获取公共信息
export function apiGetRebateCommonInfo(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UserRebate/getRebateCommonInfo`,
    data,
    type: "POST"
  });
}

//APP初始化API 获取INVITE_URL
export function apiAppIntialDataV2(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/Publics/appIntialDataV2`,
    data,
    type: "POST"
  });
}

//新 获取INVITE_URL
export function apiGetInviteUrl(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/Publics/getInviteUrl`,
    data,
    type: "POST"
  });
}

//删除邀请码
export function apiDelInviteCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UserRebate/delInviteCode`,
    data,
    type: "POST"
  });
}
