<template>
  <div class="coin-info">
    <div
      :class="is_recharge ? 'recharge-select' : 'withdraw-select'"
      class="coin-select"
      v-if="data.itemDetail"
    >
      <el-select
        :popper-append-to-body="false"
        filterable
        :style="{
          background:
            'url(' + data.itemDetail.coin_url + ') no-repeat 15px center #fff',
        }"
        style="width: 440px; height: 40px"
        :value="data.itemDetail.capital_name"
        @change="selectChange"
      >
        <el-option
          :value="val.item_id"
          v-for="(val, key) in coinData"
          :key="key"
          :label="val.capital_name + '-' + val.language"
          :class="{ selected: data.item_id == val.item_id }"
        >
          <img
            :src="val.itemDetail.coin_url"
            :alt="val.itemDetail.capital_name + '.png'"
            style="width: 24px; height: 24px; vertical-align: middle"
          />
          <span style="margin-left: 10px">{{ val.capital_name }}</span>
          <!--  +
            "-" +
            val.language 

            +
          '-' +
          data.itemDetail.language['display_' + language]
            
            --->
        </el-option>
      </el-select>
      <!-- <el-select
        :popper-append-to-body="false"
        filterable
        :style="{
          background:
            'url(' + data.itemDetail.coin_url + ') no-repeat 15px center #fff',
        }"
        style="width: 440px; height: 40px"
        :value="
          data.itemDetail.capital_name +
            '-' +
            data.itemDetail.language['display_' + language]
        "
        @change="selectChange"
      >
        <el-option
          :value="val.item_id"
          v-for="(val, key) in coinData"
          :key="key"
          :label="val.capital_name + '-' + val.language"
          :class="{ selected: data.item_id == val.item_id }"
        >
          <img
            :src="val.itemDetail.coin_url"
            :alt="val.itemDetail.capital_name + '.png'"
            style="width: 24px; height: 24px; vertical-align: middle"
          />
          <span style="margin-left: 10px">{{
            val.capital_name + "-" + val.language
          }}</span>
        </el-option>
      </el-select> -->
    </div>
    <div
      class="assets-info"
      v-if="JSON.stringify(assetsList) !== '{}' && data.itemDetail"
    >
      <div class="snippet">
        <p>{{ translateTitle("可用") }}</p>
        <span>{{
          assetsList.list[data.itemDetail.name]
            ? Number(assetsList.list[data.itemDetail.name].over)
            : "0"
        }}</span>
      </div>
      <div class="snippet">
        <p>{{ translateTitle("冻结") }}</p>
        <span>{{
          assetsList.list[data.itemDetail.name]
            ? Number(assetsList.list[data.itemDetail.name].lock)
            : "0"
        }}</span>
      </div>
      <div class="snippet">
        <p>{{ translateTitle("总额") }}</p>
        <span>{{
          assetsList.list[data.itemDetail.name]
            ? Number(assetsList.list[data.itemDetail.name].coin_num)
            : "0"
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getCoinInfo, getAssetsData } from "../../common/getAssetsCoin";
import SocketDispatcher from "@/assets/js/SocketDispatcher";
import commonMixin from "@/components/common/commonMixin.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [commonMixin],
  props: ["data", "assetsList", "is_recharge"],
  data() {
    return {
      coinData: {},
      oldCoinData: [],
      coinList: [],
    };
  },
  computed: {
    ...mapGetters({
      language: "user/language",
      systemCurrency: "user/currency",
    }),
  },
  async created() {
    this.coinData = await getCoinInfo();
    for (let item in this.coinData) {
      this.coinData[item].capital_name = this.coinData[
        item
      ].itemDetail.capital_name;
      this.coinData[item].language = this.coinData[item].itemDetail.language[
        "display_" + this.language
      ];
      this.coinList.push(this.coinData[item]);
    }
    this.oldCoinData = this.coinList;
    Object.keys(this.coinData).forEach(item_id => {
      let itemDetail = this.coinData[item_id]["itemDetail"];
      if (itemDetail["in_status"] === 0 || itemDetail["out_status"] === 0) {
        delete this.coinData[item_id];
      }
    });
  },
  methods: {
    onFilterItem(val) {
      if (val) {
        this.coinList = [];
        this.coinList = this.oldCoinData.filter(ele => {
          let capital_name = String(ele.capital_name.toUpperCase());
          let language = String(ele.language);
          if (capital_name.indexOf(val.toUpperCase()) != -1) {
            this.coinList.push(ele);
          }
        });
      } else {
        this.coinList = this.oldCoinData;
      }
    },

    // 选择币种
    async selectChange(val) {
      if (val == this.data.item_id) {
        return;
      }
      // 获取用户资产新数据
      await getAssetsData();
      for (let key in this.coinData) {
        if (val == key) {
          const query = JSON.parse(
            JSON.stringify(this.coinData[key].itemDetail.logic_name)
          );
          this.$router.replace(query);
          SocketDispatcher.dispatchEvent(
            "onChainChangeDispatch",
            this.coinData[key].itemDetail.default_chain
          );
          this.$emit("changeAssetData", this.coinData[key]);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.coin-info {
  height: 90px;
  display: flex;
  align-items: flex-end;
  // justify-content: space-between;
  border-bottom: 1px solid rgb(236, 237, 241, 0.6);
  padding-bottom: 27px;

  box-sizing: border-box;
  .el-select-dropdown__item {
    height: 40px;
  }

  .coin-select {
    display: flex;
    align-items: center;
    /* width: 580px; */
    width: 490px;
    .el-select {
      background-size: 24px 24px !important;
      .el-input__inner {
        padding-left: 50px;
        background-color: transparent;
      }
    }
  }
  /* .withdraw-select {
    width: 507px;
  } */

  .assets-info {
    display: flex;
    align-items: center;
    /* margin-left: 20px; */

    .snippet {
      font-size: 14px;
      margin-right: 60px;
      text-align: left;

      p {
        color: #9aa1b1;
      }

      span {
        color: #354052;
        font-size: 14px;
        font-weight: 500;
        user-select: all;
      }
    }
  }
}
</style>
