import api from "@/assets/js/api";
// const { VUE_APP_UCENTER } = process.env;
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

//缓存CoinList,防止多次请求
export async function getStaticCoinList() {
  //先查缓存
  let result = localStorage.getItem("coinListInfo");
  let lastTime = localStorage.getItem("coinListLastTime");
  let nowTime = Date.parse(new Date()) / 1000;
  let coinListInfo;

  if (
    result &&
    result != "undefined" &&
    result != "{}" &&
    result != "[object Object]" &&
    nowTime - lastTime > 1 * 60
  ) {
    let timestamp = Date.parse(new Date()) / 1000;
    localStorage.setItem("coinListLastTime", timestamp);
    console.log("更新CoinList");
  } else if (
    result &&
    result != "undefined" &&
    result != "{}" &&
    result != "[object Object]"
  ) {
    coinListInfo = JSON.parse(result);
    return { status: 200, data: coinListInfo };
  }

  //如果没有缓存
  const { status, data } = await getCoinList();
  // const { data } = res;
  if (!data) {
    return {};
  }

  coinListInfo = data;
  localStorage.setItem("coinListInfo", JSON.stringify(coinListInfo));
  let timestamp = Date.parse(new Date()) / 1000;
  localStorage.setItem("coinListLastTime", timestamp);
  return { status: 200, data: coinListInfo };
}

export function getCoinList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/Settings/itemList`,
    data,
    type: "POST",
  });
}

export function apiGetUserItems(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/User/userItems`,
    data,
    type: "POST",
  });
}

export function getAssetsList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Assets/getAssetsList`,
    data,
    type: "POST",
  });
}

export function getExchangeListV2(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Assets/getCoinExchangeListV2`,
    data,
    type: "POST",
  });
}

export function getINCoinExchangeListV2(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Assets/getCoinExchangeListV2`,
    data,
    type: "POST",
  });
}

export function getUserExchangeLimit(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Users/getUserExchangeLimit`,
    data,
    type: "POST",
  });
}

//获取充币地址
export function getAddress(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/getAddress`,
    data,
    type: "POST",
  });
}

//获取财务充币地址列表
export function apiGetFinanceAddressList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/getFinanceAddressList`,
    data,
    type: "POST",
  });
}

//三方支付wyre地址
export function apiWyreUrl(data = {}) {
  return api({
    url: VUE_APP_APP + `/OtcPay/wyre`,
    data,
    type: "POST",
  });
}
//三方支付快捷买币地址
export function apiFetchOtcUrl(data = {}) {
  return api({
    url: VUE_APP_APP + `/ThirdPayment/fetchOtcUrl`,
    data,
    type: "POST",
  });
}

//获取财务充币地址列表
export function apiCreateFinanceAddress(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/createFinanceAddress`,
    data,
    type: "POST",
  });
}

//获取财务充币地址列表
export function apiSaveFinanceAddress(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/saveFinanceAddress`,
    data,
    type: "POST",
  });
}

//获取财务充币地址列表
export function apiDelFinanceAddress(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/delFinanceAddress`,
    data,
    type: "POST",
  });
}

//获取财务充币地址列表
export function sendEmailCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Users/sendEmailCode`,
    data,
    type: "POST",
  });
}

export function sendSmsCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Users/sendSmsCode`,
    data,
    type: "POST",
  });
}
//发送绑定短信
export function bindMobileCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/bindMobileCode`,
    data,
    type: "POST",
  });
}
//发送换绑短信
export function changeMobileCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/changeMobileCode`,
    data,
    type: "POST",
  });
}
//发送安全项变更短信
export function verifyMobileCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/verifyMobileCode`,
    data,
    type: "POST",
  });
}
//发送提币转账短信
export function transferMobileCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/transferMobileCode`,
    data,
    type: "POST",
  });
}
//发送绑定邮件
export function bindEmailCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/bindEmailCode`,
    data,
    type: "POST",
  });
}
//发送换绑邮件
export function changeEmailCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/changeEmailCode`,
    data,
    type: "POST",
  });
}
//发送安全项变更邮件
export function verifyEmailCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/verifyEmailCode`,
    data,
    type: "POST",
  });
}
//发送提币转账邮件
export function transferEmailCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/transferEmailCode`,
    data,
    type: "POST",
  });
}

export function getAddressList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/addressList`,
    data,
    type: "POST",
  });
}

export function addAddress(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/addAddress`,
    data,
    type: "POST",
  });
}
// 二次验证,提币借口
export function transfer(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Assets/transfer`,
    data,
    type: "POST",
  });
}
//判断地址是否为内部地址
export function apiGetUserInfoByAddress(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/getUserInfoByAddress`,
    data,
    type: "POST",
  });
}
//删除提币地址
export function apiDelAddress(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/delAddress`,
    data,
    type: "POST",
  });
}
