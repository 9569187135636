import api from "@/assets/js/api";
import { VUE_APP_UCENTER, VUE_APP_APP } from "@/assets/js/stationSetting";

// boker
export function apiBrokersProgram(data = {}) {
  return api({
    url: VUE_APP_APP + `/Channel/FormSubmit/brokersProgram`,
    data,
    type: "POST",
  });
}
// 联盟计划
export function apiAffiliateProgram(data = {}) {
  return api({
    url: VUE_APP_APP + `/Channel/FormSubmit/affiliateProgram`,
    data,
    type: "POST",
  });
}
