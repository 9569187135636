<template>
  <div class="law-enforcement-enquiry" id="law-enforcement-enquiry">
    <div class="title">
      {{ translateTitle("MASKEX LEGAL ENFORCEMENT ENQUIRY") }}
    </div>
    <div class="content">
      {{
        translateTitle(
          "Please NOTE that you must be a Legal Enforcement Agent or a Government Official with the official capacity to gather evidence, to make this enquiry from MaskEX."
        )
      }}
    </div>
    <div class="detail">
      {{
        translateTitle(
          "By accessing MaskEX Enquiry Portal, you hereby declare that you are a Law Enforcement Agent or an Authorized Person from a Government Official, that is authorized to make enquiries to MaskEX in their official capacity. You understand that if you make an unauthorized request, you may be subjected to prosecution. For further information, please refer to the"
        )
      }}
      <a
        target="_blank"
        href="https://maskex.zendesk.com/hc/en-us/articles/8281252617617-MaskEX-Guideline-for-Law-Enforcements"
        >{{ translateTitle("(MASKEX : GUIDELINES FOR LAW ENFORCEMENTS).") }}</a
      >
    </div>
    <div class="bten">
      <el-checkbox v-model="checked" :fill="'#15beb4'">{{
        translateTitle("I have read and agree to this disclaimer")
      }}</el-checkbox>
    </div>
    <div class="btn">
      <el-button
        :class="!checked ? 'no' : 'on'"
        @click="pathJump"
        :disabled="!checked"
        type="info"
        round
        >{{ translateTitle("Begin Enquiry") }}</el-button
      >
    </div>
    <div></div>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  data() {
    return {
      checked: false,
    };
  },
  mixins: [commonMixin],
  methods: {
    pathJump() {
      window.open("https://forms.gle/kBrehoX1Y2wiuRpC8");
    },
  },
};
</script>

<style lang="scss">
.el-scrollbar__view {
  background: #fff;
}
#law-enforcement-enquiry {
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #15beb4 !important;
    border-color: #15beb4 !important;
  }
  .el-button--info.on {
    background-color: #15beb4;
    border-color: #15beb4;
  }
  .el-button--info.no {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
  }
}
.law-enforcement-enquiry {
  width: 800px;
  margin: 0 auto;
  // background-color: #fff;
  .title {
    font-size: 38px;
    margin-top: 100px;
    font-weight: bold;
    color: #15192a;
  }
  .content {
    margin-top: 30px;
    font-size: 18px;
    color: #15192a;
  }
  .detail {
    a {
      color: #1f73b7;
    }
    margin-top: 35px;
    padding: 23px 34px;
    font-size: 16px;
    // font-family: DIN;
    line-height: 30px;
    color: #15192a;
    background-color: #f8f8f8;
  }
  .bten {
    text-align: center;
    margin-top: 50px;
    font-size: 14px;
    color: #333;
  }
  .btn {
    margin-top: 35px;
    text-align: center;
    margin-bottom: 35px;
    // padding
    span {
      padding: 5px 15px;
    }
    //     width: 160px;
    //     height: 48px;
    //     display: inline-block;
    //     line-height: 48px;
    //     color: #fff;
    //     cursor: pointer;
    //     border-radius: 50px;
    //     background-color: #15beb4;
    //     margin-top: 35px;
    // }
  }
}
</style>
