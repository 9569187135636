<template>
  <div class="recharge">
    <div class="rechange-content">
      <top-title
        :title="
          isRecharge ? translateTitle('资产管理') : translateTitle('资产管理')
        "
      ></top-title>
      <div class="recharge-body" v-loading="isPageLoading">
        <div class="coin-charge-block">
          <div class="operation-content">
            <div
              class="operation-tabs"
              :class="{
                active: currActicve == item.value,
              }"
              @click="onChangeTab(item)"
              v-for="(item, index) in operationList"
              :key="index"
            >
              <div class="select-tab">
                {{ translateTitle(item.label) }}
              </div>
            </div>
          </div>

          <div class="coin-info-item">
            <div class="coin-gate">
              <span class="coin-title"
                >{{
                  isRecharge ? translateTitle("充币") : translateTitle("提现")
                }}
                {{ translateTitle("币种") }}</span
              >
            </div>
            <coin-info
              :data="data"
              :is_recharge="isRecharge"
              :assetsList="assetsList"
              @changeAssetData="getNewData"
            />
          </div>
          <div class="charge-block" v-if="data.itemDetail">
            <div v-if="data.itemDetail">
              <p class="sub-title">
                {{
                  data.itemDetail.capital_name +
                    (isRecharge
                      ? translateTitle("充币地址")
                      : translateTitle("提币地址"))
                }}
              </p>
              <charge-fragment :data="data" v-if="isRecharge" />
              <!-- 钱包维护，暂停充币 -->
              <!-- <charge-maintain-fragment /> -->
              <withdrawal-address
                @getChainData="getChainData"
                ref="withDrawalAddress"
                v-if="!isRecharge"
                :data="data"
                :exchangeLimit="exchangeLimit"
                :assetsList="assetsList"
                @openVerification="verification = true"
                @withdrawSuccess="withdrawSuccess"
              />
            </div>
            <div
              :class="isRecharge ? 'recharge-explain' : 'withdrawal-explain'"
              class="cr-explain"
            >
              <p class="explain-title">
                {{
                  isRecharge
                    ? translateTitle("充币须知")
                    : translateTitle("转账说明")
                }}
              </p>
              <charge-explain
                :data="data"
                :exchangeLimit="exchangeLimit"
                :capital_name="data.itemDetail.capital_name"
                v-if="isRecharge"
              />
              <withdrawal-explain
                :chainMap="chainMap"
                :data="data"
                :assetsList="assetsList"
                :exchangeLimit="exchangeLimit"
                v-else
              ></withdrawal-explain>
              <!-- <withdraw-faq
                v-if="!isRecharge"
                :chainMap="chainMap"
                :data="data"
                :assetsList="assetsList"
                :exchangeLimit="exchangeLimit"
              ></withdraw-faq> -->
            </div>
          </div>
        </div>
        <div class="history-record">
          <div class="history" v-if="data.itemDetail">
            <p class="sub-title">
              {{
                data.itemDetail.capital_name +
                  (isRecharge
                    ? translateTitle("充币记录")
                    : translateTitle("提币记录"))
              }}
            </p>
            <history
              :data="data"
              :exchangeList="exchangeList"
              :isRecharge="isRecharge"
              :multiChainStatus="multi_chain_status"
              :historyLoading="historyLoading"
            />

            <div class="common-table-pages">
              <p>
                {{ translateTitle("每页") }}
                <span>{{ pageInfo.pageSize }}</span>
                {{ translateTitle(`条，总共`) }}
                <span>{{ pageInfo.pageCount }}</span>
                {{ translateTitle("页") }}
              </p>
              <el-pagination
                class="mt20"
                background
                layout="prev, pager, next"
                :total="pageInfo.pageTotal"
                :page-size="pageInfo.pageSize"
                :current-page.sync="pageInfo.pageCurrent"
                @current-change="onPageChange"
              ></el-pagination>
            </div>
          </div>
          <!-- 前往充值记录 -->
          <!-- <div class="record"> -->
          <!-- <p>
            <span>仅显示最新10条充币记录，如需查询全部充值记录，请去</span>
            <span class="blue" @click="goFinance">{{
              isRecharge ? "充币记录" : "提币记录"
            }}</span>
          </p> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
    <el-dialog
      :title="translateTitle('安全验证')"
      custom-class="confirm-dialog"
      :visible.sync="safetyVerification"
      width="440px"
      :lock-scroll="false"
    >
      <span style="font-size: 16px;">{{
        translateTitle(
          "为保障您的资金安全，请先在个人中心—>安全设置中绑定谷歌、手机或者邮箱"
        )
      }}</span>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="safetyVerification = false">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="onConfirmV2">{{
          translateTitle("确定")
        }}</span>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import TopTitle from "./components/Title";
import CoinInfo from "./components/recharge_withdrawal/CoinInfo";
import History from "./components/recharge_withdrawal/History";
import WithdrawalAddress from "./components/withdrawal/WithdrawalAddress";
import WithdrawalExplain from "./components/withdrawal/WithdrawalExplain";
// import WithdrawFaq from "./components/withdrawal/withdrawFaq";
import ChargeExplain from "./components/recharge/ChargeExplain";
import ChargeFragment from "./components/recharge/ChargeFragment";
// import ChargeMaintainFragment from "./components/recharge/ChargeMaintainFragment";
import { showMessage } from "@/components/dialog/message.js";
import commonMixin from "@/components/common/commonMixin.vue";
import {
  getExchangeListV2,
  getINCoinExchangeListV2,
  getUserExchangeLimit,
} from "@/model/assets/index";
import {
  getAssetsData,
  getCurrentCoin,
  getCoinInfo,
} from "./common/getAssetsCoin";
import { locale } from "moment-timezone";
import routeSplicing from "@/assets/js/routeSplicing";
import { mapGetters } from "vuex";
// 充币页面
export default {
  mixins: [commonMixin],
  data() {
    return {
      isPageLoading: true, //页面加载loading
      safetyVerification: false,
      activeName: "1",
      data: {}, // 单项资产数据
      coinName: "",
      exchangeList: [], // 充提币记录
      assetsList: {}, // 资产列表
      isRecharge: true, // 是否是提币
      exchangeLimit: {}, // 提币限额
      accountInfo: {},
      withdrawTip: "",
      multi_chain_status: 1,
      historyLoading: true,
      chainMap: {},
      is_trade: true,
      operationList: [
        { value: 1, label: "充值" },
        { value: 2, label: "提现" },
        // { value: 3, label: "交易" },
      ],
      currActicve: 1,
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      },
      pageNum: 1,
    };
  },
  async created() {
    // let currentCoin = JSON.parse(sessionStorage.getItem("currentCoin"));
    let currentCoin = JSON.parse(localStorage.getItem("currentCoin"));
    this.is_trade = currentCoin.is_trade;
    // this.initOperition();
    let routeName = this.$route.name;
    let coin_name = this.$route.params.coin;
    if (coin_name) {
      this.coinName = coin_name;
    }
    this.isRecharge = routeName === "recharge";
    this.currActicve = this.isRecharge ? "1" : "2";
    this.coinData = await getCoinInfo();
    this.initialData();

    this.data = getCurrentCoin();
    for (let key in this.coinData) {
      if (this.coinData[key].item_name == this.$route.params.coin) {
        this.data = this.coinData[key];
      }
    }
    if (this.isRecharge && !this.data.itemDetail.in_status) {
      showMessage({
        message: this.translateTitle(`当前币种暂不支持充币`),
        type: "warning",
        duration: 1200,
      });
      this.$router.push(this.routeSplicing(this.language, "ucenter/assets"));
    }
    if (!this.isRecharge && !this.data.itemDetail.out_status) {
      showMessage({
        message: this.translateTitle(`当前币种暂不支持提币`),
        type: "warning",
        duration: 1200,
      });
      this.$router.push(this.routeSplicing(this.language, "ucenter/assets"));
    }
    this.assetsList = await getAssetsData();
    await this.getExchangeList(this.data.item_id);
    await this.getUserExchangeLimit(this.data.item_id);
    this.isPageLoading = false;
  },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  methods: {
    routeSplicing,
    handleClick() {},
    // initOperition() {
    //   this.operationList = [];
    //   if (!this.is_trade) {
    //     this.operationList = [
    //       { value: 1, label: "充值" },
    //       { value: 2, label: "提现" },
    //     ];
    //   } else {
    //     this.operationList = [
    //       { value: 1, label: "充值" },
    //       { value: 2, label: "提现" },
    //       { value: 3, label: "交易" },
    //     ];
    //   }
    // },
    async onChangeTab(item) {
      let active = item.value;

      if (active == 1) {
        this.currActicve = item.value;
        this.isRecharge = true;
        this.getExchangeList(this.data.item_id);
        this.$router.push({
          path: this.routeSplicing(
            this.language,
            "ucenter/assets/recharge/" + this.coinName.toLowerCase()
          ),
        });
      } else if (active == 2) {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

        if (
          userInfo.authInfo.is_bind_security == 1 &&
          userInfo.authInfo.open_google == 0
        ) {
          this.safetyVerification = true;
        } else {
          this.currActicve = item.value;
          this.isRecharge = false;
          this.getExchangeList(this.data.item_id);
          this.$router.push({
            path: this.routeSplicing(
              this.language,
              "ucenter/assets/withdrawal/" + this.coinName.toLowerCase()
            ),
          });
        }
      } else {
        let tempUrl = this.routeSplicing(
          this.language,
          `exchange/${this.coinName.toLowerCase()}_${"usdt"}`
        );
        //  location.href(tempUrl)
        window.open(tempUrl);
      }
      this.assetsList = await getAssetsData();
    },
    onConfirmV2() {
      this.$router.push(
        this.routeSplicing(this.language, "ucenter/safe-setting")
      );
    },
    getChainData(val) {
      this.chainMap = val;
    },
    // 获取 24h 内的提币限制
    getUserExchangeLimit(item_id) {
      let params = {
        item_id,
      };
      getUserExchangeLimit(params).then(({ status, data, msg }) => {
        if (status === 200) {
          this.exchangeLimit = data;
        } else {
          showMessage({ message: msg ? msg : status });
        }
      });
    },
    //前往充，提币页面
    goFinance() {
      /* 充币记录 */
      this.$router.push({
        path: "/ucenter/financeManager",
        query: {
          indexItem: this.isRecharge ? 1 : 2,
          coinId: this.data.item_id,
        },
      });
    },
    // 提币成功
    withdrawSuccess(list) {
      this.assetsList = list;
      this.getExchangeList(this.data.item_id);
      this.getUserExchangeLimit(this.data.item_id);
    },
    // 初始化数据
    initialData() {
      this.bindMobileModel = {};
      this.bindEmailModel = { email: "" };
      this.bindGoogleModel = {};
    },

    // 获取页面新数据
    getNewData(data) {
      this.exchangeLimit = {};
      if (!this.isRecharge) {
        this.$refs.withDrawalAddress.$refs.changePwdForm.resetFields();
      }
      this.data = data;
      this.multi_chain_status = data.itemDetail.multi_chain_status;
      this.getExchangeList(data.item_id);
      this.getUserExchangeLimit(this.data.item_id);
    },
    //页数变更
    onPageChange(val) {
      this.pageNum = val;
      this.getExchangeList(this.data.item_id);
    },
    // 获取 充值/提币 记录
    async getExchangeList(item_id) {
      let type = this.isRecharge ? 1 : 2;
      this.historyLoading = true;
      if (type == 1) {
        await getINCoinExchangeListV2({
          item_id,
          type,
          // page_size: 8,
          // page: 1,
          page: !isNaN(this.pageNum) ? this.pageNum : 1,
          page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10,
        }).then(({ status, data, msg }) => {
          if (status === 200) {
            this.exchangeList = data.list;
            this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
          } else {
            showMessage({ message: msg ? msg : status });
          }
        });
      } else {
        await getExchangeListV2({
          item_id,
          type,
          // page_size: 8,
          // page: 1,
          page: !isNaN(this.pageNum) ? this.pageNum : 1,
          page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10,
        }).then(({ status, data, msg }) => {
          if (status === 200) {
            this.exchangeList = data.list;
            this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
          } else {
            showMessage({ message: msg ? msg : status });
          }
        });
      }
      this.historyLoading = false;
    },
  },
  components: {
    TopTitle,
    CoinInfo,
    ChargeExplain,
    History,
    WithdrawalAddress,
    WithdrawalExplain,
    ChargeFragment,
    // WithdrawFaq,
    // ChargeMaintainFragment
  },
};
</script>
<style lang="scss">
.recharge {
  min-height: 750px;
  display: flex;
  justify-content: center;
  .el-select .el-input .el-select__caret {
    font-weight: 900 !important;
  }
  .operation-content {
    display: flex;
    padding: 20px 0px;
    .operation-tabs {
      width: 100px;
      height: 32px;
      line-height: 32px;
      margin: 0 10px 0 0;
      border-radius: 3px;
      border: solid 1px #c2c7d0;
      background-color: #ffffff;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #354052;
      cursor: pointer;
      .select-ban {
        background-color: #c2c7d0;
        outline: none;
        height: 32px;
        line-height: 32px;
        border: 0;
        color: #ffffff;
        // border: 1px solid #c2c7d0;
        font-weight: 400;
      }
      &.active {
        background-image: linear-gradient(70deg, #c61b4e 10%, #ee244c 41%);
        border: 0px;
        color: #ffffff;
      }
    }
  }

  input:focus {
    border-color: #c61b3d;
  }
  .rechange-content {
    height: 100%;
    width: 100%;
    background-color: transparent;
    // background-color: #fff;

    .recharge-body {
      margin: 20px 20px 0px 20px;
      min-height: 935px;
      .coin-charge-block {
        user-select: none;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
        background-color: #ffffff;

        padding-left: 20px;
        padding-right: 20px;
        background-color: #fff;
        .coin-info-item {
          .coin-gate {
            width: 100%;
            height: 41px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            .el-select .el-input .el-select__caret {
              font-weight: 900 !important;
            }
            span {
              font-size: 20px;
              font-weight: 500;
              height: 40px;
              color: #354052;
              line-height: 40px;
              border: 0;
            }
          }
          .coin-info {
            border-top: 0px;
            height: 63px;
            padding-bottom: 15px;
          }
        }
      }
      .history-record {
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
        .history {
          padding: 10px 20px 0 20px;
          .sub-title {
            color: #354052;
            font-size: 20px;
            font-weight: 500;
            height: 40px;
            line-height: 40px;
            user-select: none;
          }
        }
        .record {
          padding-left: 20px;
          background-color: #fff;
          border-radius: 4px;
        }
      }
    }
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #c61b3d;
  }

  .charge-block {
    display: flex;
    margin-right: 46px;
    padding-bottom: 22px;

    .cr-explain {
      /* margin-left: 20px; */
      margin-top: 54px;
      width: 680px;
      .explain-title {
        font-size: 16px;
        font-weight: 500;
        color: #354052;
      }
    }
    .withdrawal-explain {
      margin-top: 50px;
      /* margin-left: 60px; */
      margin-left: 50px;
    }
    .sub-title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
      line-height: 16px;
      text-align: left;
      padding: 20px 0 20px;
    }
  }

  .record {
    color: #717985;
    font-size: 12px;
    margin-top: 30px;
    padding-bottom: 62px;
    .blue {
      color: #0199f1;
      cursor: pointer;
    }
  }
  .confirm-dialog {
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      text-align: left;
      color: #354052;
      padding: 20px 30px 30px;
      font-size: 16px;
      font-weight: normal;
      line-height: 2;
    }
  }
}
</style>
