<template>
  <div class="broker-program scrollbar-default">
    <div class="ap-content">
      <img src="@/assets/img/broker-banner.webp" class="apc-banner" />
      <div class="apc-form">
        <div class="apc-title">
          {{ translateTitle("MaskEX Broker Program Application Form") }}
        </div>
        <div class="apc-notice">
          {{
            translateTitle(
              "Become a MaskEX broker and enjoy up to 70% commission. Let the world’s MOST SECURE AND PRIVATE CRYPTOCURRENCY WALLET AND TRADING PLATFORM empower your business.Our BD manager will contact you with the most reasonable customized brokerage service plan."
            )
          }}
        </div>
        <div class="form-item">
          <p class="fi-label">{{ translateTitle("Name") }}:</p>
          <el-input v-model="programForm.name" placeholder=""></el-input>
        </div>
        <div class="form-item">
          <p class="fi-label">{{ translateTitle("Email Address") }}:</p>
          <el-input v-model="programForm.email" placeholder=""></el-input>
        </div>
        <div class="form-item">
          <p class="fi-label">{{ translateTitle("Contact Number") }}:</p>
          <el-input v-model="programForm.contact" placeholder="">
            <div class="area" slot="prefix">
              <el-input
                v-model="programForm.area"
                placeholder="+000"
              ></el-input>
              <div class="split"></div>
            </div>
          </el-input>
        </div>
        <div class="form-item">
          <p class="fi-label">{{ translateTitle("MaskEX UID") }}:</p>
          <el-input
            v-model="programForm.uid"
            :placeholder="
              translateTitle(
                'Please register if you don’t have MaskEX account.'
              )
            "
          >
            <div class="register" @click="goToRegister" slot="suffix">
              {{ translateTitle("Register") }}
            </div>
          </el-input>
        </div>
        <div class="form-item">
          <p class="fi-label">{{ translateTitle("Telegram ID") }}:</p>
          <el-input v-model="programForm.tid" placeholder=""></el-input>
        </div>
        <div class="form-item">
          <p class="fi-label">
            {{ translateTitle("Previous Exchange Platform") }}:
          </p>
          <el-input v-model="programForm.marketing" placeholder=""></el-input>
        </div>
        <div class="form-item">
          <p class="fi-label mp">
            {{
              translateTitle(
                "Is your trading volume over the past three month had exceeded 1 million USDT?"
              )
            }}:
          </p>
          <p class="fi-label mp">
            {{ translateTitle("Kindly provide a screenshot:") }}:
          </p>

          <div class="mp-item" @click="chooseMarketing(0)">
            <div class="selected" v-if="marketing == 0">
              <div class="s-active"></div>
            </div>
            <div class="un-selected" v-else></div>
            <span class="mpi-text">{{ translateTitle("Yes") }}</span>
          </div>
          <div class="mp-item" @click="chooseMarketing(1)">
            <div class="selected" v-if="marketing == 1">
              <div class="s-active"></div>
            </div>
            <div class="un-selected" v-else></div>
            <span class="mpi-text">{{ translateTitle("No") }}</span>
          </div>
        </div>
        <div>
          <el-upload
            class="avatar-uploader"
            action="/UCenter/Third/pushPicture"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <div v-else class="up-load">
              <img src="@/assets/img/upLoad.webp" class="tubiao" alt="" />
              <div>
                <p>{{ translateTitle("Upload a Picture") }}</p>
                <div>{{ translateTitle("Max.2m jpg;png;") }}</div>
              </div>
            </div>
          </el-upload>
        </div>
        <div class="submit-btn" @click="submit" :loading="btnLoading">
          {{ translateTitle("Submit") }}
        </div>
        <p class="notice-page">
          {{ translateTitle("Thank you for your registration!") }} <br />
          {{
            translateTitle(
              "Kindly ensure information filled above are correct. If you meet the qualification, we will contact within seven working days"
            )
          }}
          <br />
          {{
            translateTitle(
              "Please do not hesitate to contact us should you have any clarifications"
            )
          }}
          <br />
          {{ translateTitle("1. Email Assistance") }} –
          <a
            class="theme-color"
            href="mailto:marketing@maskex.com"
            target="_blank"
            >marketing@maskex.com</a
          >
          <br />
          {{ translateTitle("2. 24/7 Customer Support") }} –
          <a class="theme-color" href="https://www.maskex.com" target="_blank"
            >www.maskex.com</a
          >
          <br />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetStaticTableInfo } from "@/model/settings";
import { mapGetters } from "vuex";

import commonMixin from "@/components/common/commonMixin.vue";
import { apiBrokersProgram } from "@/model/broker";

import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  // components: { Recharge },
  data() {
    return {
      marketing: 0,
      imageUrl: "",
      programForm: {
        name: "",
        email: "",
        contact: "",
        uid: "",
        tid: "",
        marketing: "",
        area: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language",
    }),
  },
  watch: {},
  async created() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isType = file.type === "image/jpg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isType) {
        this.$message.error("上传头像图片只能是 JPG ｜ PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isType && isLt2M;
    },
    routeSplicing,
    goLogin() {
      if (!this.userInfo) {
        this.$message({
          message: this.translateTitle("请先登录"),
          type: "warning",
          customClass: "zZindex",
        });
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
        });
      }
    },
    chooseMarketing(index) {
      this.marketing = index;
    },
    goToRegister() {
      this.$router.push(this.routeSplicing(this.language, "register"));
    },
    async submit() {
      const req = {
        name: this.programForm.name,
        email: this.programForm.email,
        mobile: this.programForm.area + "" + this.programForm.contact,
        telegram: this.programForm.tid,
        is_exceeded_1_million: this.marketing,
        pre_exchange_platform: this.programForm.marketing,
        screenshot: this.imageUrl,
      };
      this.btnLoading = true;
      const { data, status } = await apiBrokersProgram(req);
      if (status == 200) {
        this.$message(this.translateTitle("添加成功"), "success");
      }
      this.btnLoading = false;
    },
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     this.coinData = data;
    //   }
    // },
    // //7-static_currency-法币表
    // async getStaticCurrencyMap() {
    //   const { status, data } = await apiGetStaticTableInfo(7);
    //   if (status == 200) {
    //     this.currencyList = Object.values(JSON.parse(JSON.stringify(data)));
    //   }
    // },
    // async getStaticOtcThirdPaymentMap() {
    //   // const { status, data } = await apiGetStaticTableInfo(64);
    //   const { status, data } = await apiGetStaticTableInfo(69);
    //   if (status == 200) {
    //     this.otcThirdPaymentMap = Object.values(
    //       JSON.parse(JSON.stringify(data))
    //     );
    //   }
    // },
    // async getStaticThirdPaymentMap() {
    //   // const { status, data } = await apiGetStaticTableInfo(65);
    //   const { status, data } = await apiGetStaticTableInfo(68);
    //   if (status == 200) {
    //     this.thirdPaymentMap = Object.values(JSON.parse(JSON.stringify(data)));
    //     this.thirdPaymentMap.forEach(item => {
    //       item["img"] = this.$data[item.name.toLowerCase()];
    //     });
    //   }
    // },
    // changeActive(num) {
    //   this.active = num;
    // },
    // selectChange(val) {
    //   this.currencyCurr = val;
    // },
    // selectChangeCoin(val) {
    //   this.coinCurr = val;

    //   this.getAddress();
    //   let tempPaymentArray = [];
    //   this.coinCurr.payment.split(",").forEach(ele => {
    //     this.thirdPaymentMap.forEach(item => {
    //       if (ele == item.name) {
    //         tempPaymentArray.push(item);
    //       }
    //     });
    //   });
    //   tempPaymentArray.push({ name: "Wyre", img: this.wyre });
    //   this.thirdPaymentArray = tempPaymentArray;
    //   // this.radioCurr = this.thirdPaymentArray[0].name;
    //   this.rowCount = Math.ceil(tempPaymentArray.length / 2);
    //   this.thirdPaymentShowArray = tempPaymentArray;
    //   let index = null;
    //   for (let i = 0; i < this.thirdPaymentShowArray.length; i++) {
    //     if (this.thirdPaymentShowArray[i].name.toLowerCase() == "itez") {
    //       index = i;
    //       break;
    //     }
    //   }
    //   if (index !== null) {
    //     this.thirdPaymentShowArray.unshift(
    //       ...this.thirdPaymentShowArray.splice(index, 1)
    //     );
    //   }
    //   this.radioCurr = this.thirdPaymentShowArray[0].name;
    // },
    // async getAddress() {
    //   let params = {
    //     item_id: this.coinCurr.item_id,
    //     chain_tag_list: this.coinCurr.chain_name.toUpperCase(),
    //   };
    //   if (!this.userInfo) return;
    //   const { status, msg, data } = await apiGetFinanceAddressList(params);
    //   if (status == 200) {
    //     this.addressCurr = data[0]["address"];
    //   }
    // },
    // async doBuy() {
    //   if (!this.spendValue) {
    //     this.$message({
    //       message: this.translateTitle("请输入金额"),
    //       type: "warning",
    //       customClass: "zZindex",
    //     });
    //     return;
    //   }
    //   let url = await this.generateUrl(
    //     this.radioCurr,
    //     this.coinCurr.item_name.toUpperCase(),
    //     this.spendValue,
    //     this.currencyCurr.logic_name.toUpperCase(),
    //     this.addressCurr
    //   );
    //   window.open(url);
    // },
    // async generateUrl(paymentCode, symbol, amount, currency, address) {
    //   let resultUrl = "";
    //   if (paymentCode.toLowerCase() != "wyre") {
    //     const { status, data: dataUrl } = await apiFetchOtcUrl({
    //       payment_code: paymentCode.toLowerCase(),
    //       symbol: symbol,
    //       amount: amount,
    //       currency: currency,
    //       address: address,
    //     });
    //     if (status == 200) {
    //       resultUrl = dataUrl;
    //     }
    //   } else {
    //     const destCurrency =
    //       symbol.toUpperCase() == "BTC"
    //         ? "bitcoin:" + address
    //         : "ethereum:" + address;
    //     const { status, data } = await apiWyreUrl({
    //       sourceAmount: amount,
    //       sourceCurrency: currency,
    //       destCurrency: symbol,
    //       dest: destCurrency,
    //       country: "US",
    //     });
    //     if (status == 200) {
    //       resultUrl = data.url;
    //     }
    //   }
    //   return resultUrl;
    // },
  },
};
</script>
<style lang="scss">
.ar {
  .broker-program .quickly-rechange-center .quickly-rechange-center-content {
    .purchasing-price-text {
      direction: rtl;
      .step {
        margin-left: 6.6px;
      }
    }
    .checkbox-out .statement-checkbox {
      direction: rtl;
    }
    .statement {
      direction: rtl;
    }
  }
  .broker-program {
    .recharge-content {
      direction: rtl;
      .left-content {
        .step {
          margin-right: 0;
          margin-left: 6.6px;
        }
      }
      .right-content {
        margin-left: 0;
        margin-right: 60px;
      }
    }
    .bottom-content .purchasing-price-text {
      direction: rtl;

      .step {
        margin-right: 0;
        margin-left: 6.6px;
      }
    }
    .quickly-rechange-footer .quickly-rechange-footer-content {
      direction: rtl;
    }
  }
}
.broker-program {
  background-color: #f7f9fc;
  padding: 52px 0 73px;
  display: flex;
  justify-content: center;

  .avatar-uploader .el-upload {
    border: 1px dashed rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    width: 189px;
    height: 64px;
    display: flex;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .up-load {
      display: flex;
      .tubiao {
        width: 40px;
        margin: 10px 15px 10px 15px;
        height: 40px;
      }
      p {
        font-size: 12px;
        color: #000;
      }
      div {
        font-size: 10px;
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.4);
      }
    }
    // img {
    //   width: 50px;
    // }
  }
  .avatar-uploader .el-upload:hover {
    border-color: rgba(0, 0, 0, 0.5);
  }
  .avatar {
  }
  .ap-content {
    width: 800px;
    background-color: #fff;
    .apc-banner {
      width: 100%;
    }
    .apc-form {
      margin: 56px 60px 84px;
      color: #15192a;
      .apc-title {
        font-family: DINPro;
        font-size: 28px;
        font-weight: 500;
        // color: #15192a;
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
      }
      .apc-notice {
        font-family: DINPro;
        font-size: 15px;
        // color: #15192a;
        margin-bottom: 44px;
      }
      .form-item {
        margin-bottom: 20px;
        .fi-label {
          font-family: DINPro;
          font-size: 16px;
          margin-bottom: 7px;
        }
        .mp {
          margin-bottom: 20px;
        }
        .el-input {
          .el-input__inner {
            height: 36px;
            border-radius: 5px;
            border: solid 1px rgba(21, 25, 42, 0.16);
            &::placeholder {
              font-size: 14px;
              color: rgba(21, 25, 42, 0.2);
            }
          }
          .el-input__suffix {
            .el-input__suffix-inner {
              .register {
                cursor: pointer;
                height: 34px;
                padding: 0 20px;
                font-family: DINPro;
                font-size: 14px;
                color: #15beb4;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .el-input__prefix {
            left: 0px;
            .area {
              margin-top: 1px;
              margin-left: 1px;
              margin-right: 20px;
              width: 60px;
              display: flex;
              align-items: center;
              .el-input {
                .el-input__inner {
                  height: 34px;
                  padding-left: 15px;
                  padding-right: 0px;
                  border-width: 0;
                }
              }
              .split {
                width: 1px;
                height: 20px;
                flex-grow: 0;
                opacity: 0.2;
                background-color: #15192a;
              }
            }
          }
        }
        .el-input--prefix {
          .el-input__inner {
            padding-left: 70px;
          }
        }
        .mp-item {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .un-selected {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            flex-grow: 0;
            border: solid 1px rgba(21, 25, 42, 0.2);
          }
          .selected {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            flex-grow: 0;
            border: solid 1px rgba(21, 25, 42, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            .s-active {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              flex-grow: 0;
              background-color: #15beb4;
            }
          }
          .mpi-text {
            font-family: DINPro;
            font-size: 16px;
            color: #000;
            margin-left: 9px;
          }
        }
      }
      .submit-btn {
        cursor: pointer;
        margin-top: 36px;
        width: 160px;
        padding: 9.5px 52.5px;
        border-radius: 3px;
        background-color: #15beb4;
        font-family: DINPro;
        font-size: 16px;
        color: #fff;
      }
      .notice-page {
        margin-top: 76px;
        font-family: DINPro;
        font-size: 16px;
        color: #15192a;
        .theme-color {
          color: #15beb4;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
