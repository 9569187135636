<template>
  <div class="mining-pool scrollbar-default">
    <div class="header">
      <div class="header-content">
        <p class="first-item">汇信矿池</p>
        <p class="second-item">致力于提高矿工收益的综合性服务平台</p>
      </div>
    </div>
    <div class="list-type-content">
      <div class="list-type">
        <div
          v-for="item in listType"
          :key="item.val"
          class="tab"
          :class="{ active: item.val === active }"
          @click="onTypeChange(item.val)"
        >
          <span>{{ translateTitle(item.text) }} </span>
          <i class="type-indicator"></i>
        </div>
      </div>
    </div>
    <div class="pool-content" v-loading="pageLoading">
      <div>
        <el-table
          :data="dataList"
          v-if="!orderLoading"
          height="300"
          style="width: 100%"
        >
          <el-table-column
            prop="create_time"
            align="left"
            :label="translateTitle('挖矿币种')"
            key="1"
            min-width="120"
          >
            <template slot-scope="{ row }">
              <span>{{ row.access_type }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            align="center"
            :label="translateTitle('年化收益率')"
            key="1"
            min-width="120"
          >
            <template slot-scope="{ row }">
              <span>{{ row.access_type }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            align="center"
            :label="translateTitle('挖矿期限 (天)')"
            key="1"
            min-width="170"
          >
            <template slot-scope="{ row }">
              <span>{{ row.access_type }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            align="center"
            :label="translateTitle('挖矿进度')"
            key="1"
            min-width="170"
          >
            <template slot-scope="{ row }">
              <span>{{ row.access_type }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            align="center"
            :label="translateTitle('状态')"
            key="1"
            min-width="170"
          >
            <template slot-scope="{ row }">
              <span>{{ row.access_type }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('操作')"
            key="14"
            align="center"
            min-width="110"
          >
            <template slot-scope="{ row }">
              <el-button
                @click="onOpenConfirm(4, row.plan_order_id)"
                class="cancel-btn"
                >{{ translateTitle("挖矿") }}</el-button
              >
            </template>
          </el-table-column>
          <no-data
            slot="empty"
            v-if="dataList.length == 0 && pageLoading == false"
          />
        </el-table>
      </div>
    </div>
    <el-dialog
      width="1000"
      :custom-class="['finance-dialog', heightClass]"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="financialDialogVisible"
    >
      <financial-dialog
        v-if="financialDialogVisible"
        @onProductInfoChange="onProductInfoChange"
        @onDialogClose="onDialogClose"
        @onConfirm="onConfirm"
        :productId="productId"
      ></financial-dialog>
    </el-dialog>
    <el-dialog
      width="470px"
      :title="confirmDialogTitle"
      custom-class="confirm-dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="confirmDialogVisible"
    >
      <confirm-dialog-content
        v-if="confirmDialogVisible"
        :productId="productId"
        :productInfo="productInfo"
        @onCancel="onConfirmCancel"
        @onConfirm="onConfirmOK"
      >
      </confirm-dialog-content>
    </el-dialog>
    <el-dialog
      width="440px"
      :title="translateTitle('提示信息')"
      custom-class="success-dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="successDialogVisible"
    >
      <div class="dialog-content">
        <img src="../../assets/img/success.png" alt="" />
        <p>
          {{ translateTitle("存入成功") }}
        </p>
      </div>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="onSuccess">{{
          translateTitle("稍后查看")
        }}</span>
        <el-button class="dialog-confirm-btn" @click="goToUseCenter">{{
          translateTitle("查看持仓")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import { mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
import { apiGetDeFiProductList } from "@/model/finance/finance";
import { apiGetAssets } from "@/model/exchange/index";
import { rateTypeMaps, quantTagMaps } from "@/assets/js/keymap.js";
import NoData from "@/components/common/NoData";
import commonMixin from "@/components/common/commonMixin.vue";
import { timestampToDate } from "@/assets/js/formatDate";
import FinancialDialog from "./components/financial-management/financial-dialog";
import ConfirmDialogContent from "./components/financial-management/confirm-dialog.vue";
// import Countdown from "@choujiaojiao/vue2-countdown";
export default {
  mixins: [commonMixin],
  name: "finances-index",
  components: {
    NoData,
    // Countdown,
    FinancialDialog,
    ConfirmDialogContent,
  },
  data() {
    return {
      rateTypeMaps,
      quantTagMaps,
      buyNumber: "",
      voucherInterval: null,
      rightForm: {},
      dataList: [],
      confirmDialogTitle: "量化理财",
      listType: [
        { text: "Defi挖矿", val: 1 },
        { text: "云矿机", val: 2 },
      ],
      active: 1,
      rate1: 1,
      rate2: 1,
      currTab: 1,
      tabList: [
        {
          value: "1",
          label: "7日年化",
        },
        {
          value: "2",
          label: "30日年化",
        },
        {
          value: "3",
          label: "历史净值",
        },
      ],
      pageLoading: false,
      financialDialogVisible: false,
      confirmDialogVisible: false,
      successDialogVisible: false,
      productId: "",
      apiAssetsInfo: "",
      productInfo: {
        product_id: 33,
        product_name: "--",
        item_id: 10007,
        channel_id: 101,
        item_type: 1,
        to_item_id: 10069,
        to_item_id_price: "--",
        product_type: 4,
        rate_type: 1,
        quant_tag: 0,
        annual_rate: "--",
        min_fluctuation_annual_rate: "--",
        max_fluctuation_annual_rate: "--",
        day_rate: "--",
        current_rate: "--",
        period: 1,
        start_time: 0,
        stop_time: 0,
        lock_period: "--",
        amount: 0,
        lowest_amount: 0,
        total_amount: "--",
        min_amount: "--",
        max_amount: "--",
        per_min_num: 1,
        per_max_num: 200,
        number_precision: 2,
        price_precision: 2,
        amount_precision: 2,
        trader_profit: 0,
        status: 7,
        union_product_id: 0,
        union_order_by: 1,
        is_union_master: 0,
        public_raise_amount: "--",
        ico_buy_condition: 1,
        ico_user_register_time: 0,
        is_hide: 0,
        is_show_list: 1,
        is_recommand: 1,
        is_delete: 0,
        access_type: 1,
        order_by: 1,
        i18n_lang: "static_finances_product_product_name_330",
        create_time: 1620324487,
        update_time: 1620975363,
        detail: {
          product_id: 33,
          product_intro: "",
          inout_rule: "",
          earnings_rule: "",
          product_code: "--",
          strategy: "",
          ico_url:
            "https://top6688.zendesk.com/hc/zh-cn/articles/4402147339921-%E8%AE%A4%E8%AF%86-O3",
          ico_eg: "",
          buy_rule: "--",
          risk_tip: "--",
          join_rule: "",
          i18n_lang:
            "ico_url_31,ico_eg_33,buy_rule_31,risk_tip_31,join_rule_0,product_code_33",
        },
        item_name: "USDT",
        item_url: require("../../assets/img/holder-coin-ico.png"),
        to_item_url:
          "https://imgs-dev.aevwjlae.com/admin/20210505/98506549656.png",
        to_item_name: "--",
        end_time: 1621580400,
        user_profit: 1,
        show_profit: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
    heightClass() {
      return "active" + this.active;
    },
  },
  async created() {
    this.getProductlist();
    await this.getAssets();
  },
  mounted() {},
  methods: {
    routeSplicing,
    timestampToDate,
    translateTitle,
    onProductInfoChange(productInfo) {
      this.productInfo = productInfo;
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "remain",
      };
      const { status, data } = await apiGetAssets(params);
      if (status == 200) {
        let tempDate = data.remain.list;
        tempDate.forEach(ele => {
          if (ele.item_id == 10007) {
            this.apiAssetsInfo = ele.over;
          }
        });
      }
    },
    onDialogClose() {
      this.financialDialogVisible = false;
    },
    onConfirm() {
      this.confirmDialogVisible = true;
    },
    onConfirmCancel() {
      this.confirmDialogVisible = false;
    },
    onSuccess() {
      this.successDialogVisible = false;
      this.getProductlist();
    },
    onConfirmOK() {
      this.confirmDialogVisible = false;
      this.financialDialogVisible = false;
      this.successDialogVisible = true;
    },
    onClickAll() {
      this.buyNumber = this.apiAssetsInfo;
    },

    // 选项卡切换
    onTypeChange(val) {
      this.active = val;
      if (val == 1) {
        this.confirmDialogTitle = "Defi挖矿";
      } else if (val == 2) {
        this.confirmDialogTitle = "云矿机";
      }
      this.getProductlist();
    },
    async goToBuy(item) {
      this.productId = item.product_id;
      this.financialDialogVisible = true;
    },
    goToUseCenter() {
      this.$router.push({
        path: this.routeSplicing(this.language, "ucenter/finanManagement"),
      });
    },
    getProductlist() {
      this.pageLoading = true;

      apiGetDeFiProductList().then(res => {
        if (res.status == 200) {
          let tempList = res.data;

          this.dataList = tempList;
        }
        this.pageLoading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.mining-pool {
  width: 100%;
  min-height: calc(100vh - 281px);
  background-color: #ffffff;
  .el-table tr th.is-leaf {
    border-bottom: none;
  }
  .el-table {
    .cell {
      .cancel-btn {
        width: 80px;
        height: 28px;
        border: none;
        padding: 4px 24px;
        border-radius: 2px;
        background-color: #d60c30;
        color: white;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  .finance-dialog {
    width: 1000px;
    // height: 680px;
    &.active1 {
      min-height: 475px;
      overflow: hidden;
    }
    &.active3 {
      min-height: 651px;
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      display: flex;
      border-radius: 8px;
      justify-content: space-between;
    }
  }
  .header {
    width: 100%;
    height: 253px;
    // opacity: 0.61;
    // background: black;
    background-image: url("../../assets/img/mining-pool-bg.png");
    // background-repeat: round;
    .header-content {
      user-select: none;
      height: 253px;
      width: 1020px;
      margin: 0px auto;
      padding-top: 80px;
      .first-item {
        width: 160px;
        height: 56px;
        font-size: 40px;
        font-weight: 500;
        margin: 0 102px 11px 0;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
      }
      .second-item {
        // width: 562px;
        height: 15px;
        margin: 11px 0 0;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.94;
        letter-spacing: normal;
        color: #ffffff;
      }
    }
  }
  .list-type-content {
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    padding: 20px 0 0;
    .list-type {
      display: flex;
      width: 1200px;
      margin: 0px auto 0px;
      user-select: none;
      .tab {
        position: relative;
        padding: 0px 0px 17px 0px;
        margin-right: 22px;
        color: #7f8490;
        line-height: 22px;
        cursor: pointer;
        span {
          font-size: 16px;
          font-weight: 400;
        }
        &:hover {
          color: #354052;
          &:after {
            content: "";
            height: 2px;
            width: 80%;
            background-color: #c61b3d;
          }
        }
        &.active {
          span {
            color: #354052;
            font-size: 20px;
            font-weight: 500;
          }
          &:after {
            content: "";
            height: 3px;
            width: 20px;
            background-color: #c61b3d;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
  .pool-content {
    width: 1200px;
    margin: 0px auto;
    padding: 28px 0;
    min-height: 783px;
    .el-loading-mask {
      min-height: 200px;
    }

    .finances-item {
      user-select: none;
      width: 285px;
      height: 315px;
      margin: 0px 20px 20px 0;
      padding: 20px;
      border-radius: 4px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      // cursor: pointer;
      border: 1px solid white;
      display: inline-block;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        border: solid 1px #3a7dff;
      }
      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 193px;
        display: inline-block;
        vertical-align: middle;
      }
      img {
        width: 28px;
        height: 28px;
        margin: 0 7px 3px 0;
        vertical-align: middle;
      }

      .type-content {
        margin-left: 32px;
        width: 211px;
        height: 22px;
        // .type-6,
        // .type-5
        .type-progress,
        .type-1 {
          width: 102px;
          height: 20px;
          margin: 3px 0px 36px 7px;
          padding: 2.2px 5.1px 1.8px 5.6px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-image: linear-gradient(to right, #f78700 0%, #ffb152 100%);
          font-size: 12px;
          color: white;
        }
        .type-8,
        .type-6,
        .type-5,
        .type-4,
        .type-7 {
          width: 58px;
          height: 20px;
          margin: 3px 5px 36px 4px;
          padding: 2.2px 5.1px 1.8px 5.6px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-color: #c2c7d0;
          font-size: 12px;
          color: white;
        }
        .quant-type-1 {
          display: inline-block;
          // width: 59px;
          height: 20px;
          padding: 2.2px 4.8px 1.8px 5.6px;
          border-top-left-radius: 5px;
          font-size: 12px;
          color: white;
          border-bottom-right-radius: 5px;
          background-image: linear-gradient(265deg, #8871ff 86%, #735af2 0%);
        }
        .quant-type-2 {
          display: inline-block;
          // width: 59px;
          height: 20px;
          nav-right: 5px;
          padding: 2.2px 4.4px 1.8px 5.6px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-image: linear-gradient(266deg, #579fff 35%, #0068f3 120%);
          font-size: 12px;
          color: white;
        }
        .quant-type-3 {
          display: inline-block;
          // width: 59px;
          height: 20px;
          nav-right: 5px;
          padding: 2.2px 4.4px 1.8px 5.6px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
          font-size: 12px;
          color: white;
        }
        .type-red {
          width: 58px;
          height: 20px;
          margin: 3px 0px 36px 7px;
          padding: 2.2px 5.1px 1.8px 5.6px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 0;
          border-bottom-left-radius: 0;
          font-size: 12px;
          color: white;
        }
        .type-3 {
          width: 58px;
          height: 20px;
          margin: 3px 0px 36px 7px;
          padding: 2.2px 5.1px 1.8px 5.6px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
          font-size: 12px;
          color: white;
        }

        .type-2 {
          width: 44px;
          height: 20px;
          nav-right: 5px;
          padding: 2.2px 4.4px 1.8px 5.6px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-image: linear-gradient(266deg, #579fff 35%, #0068f3 120%);
          font-size: 12px;
          color: white;
        }
      }
      .center-content {
        margin-top: 20px;
        text-align: center;
        &.V2 {
          margin-top: 20px;
        }
        span {
          display: block;
        }
        .number-item {
          font-size: 42px;
          font-weight: bold;
          color: #f04a5a;
        }
        .text-item {
          font-size: 14px;
          color: #354052;
          &.V2 {
            margin-top: 4px;
            color: #9aa1b1;
            display: block;
            height: 20px;
          }
        }
        .date-item {
          font-size: 18px;
          font-weight: 500;
          color: #354052;
          margin-top: 12px;
        }
        .btn {
          width: 200px;
          margin: 20px auto;
          cursor: pointer;
        }
        .disabled-btn {
          cursor: pointer;
          width: 200px;
          margin: 20px auto;
          background-color: #c2c7d0;
          background-image: none;
        }
      }
    }
  }
  .no-data-box {
    min-height: calc(100vh - 650px) !important;
    .no-data-content {
      text-align: center;
      .no-data-text {
        margin-top: 20px;
        display: block;
      }
    }
  }
  .confirm-dialog {
    user-select: none;
    .el-dialog__header {
      padding: 20px 30px 5px;
      .el-dialog__title {
        font-size: 20px;
        font-weight: 500;
        color: #354052;
      }
    }
    .el-dialog__body {
      padding: 20px 30px;
    }
  }
  .success-dialog {
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #354052;
      line-height: 28px;
      .dialog-content {
        img {
          vertical-align: middle;
          width: 28px;
          height: 28px;
          display: inline-block;
        }
        p {
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
