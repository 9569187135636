<template>
  <div class="blog-page-detail">
    <div class="blog-content-detail">
      <div class="header">
        <span style="color:#a8acb0;cursor: pointer;" @click="onBtnClick">
          {{ translateTitle("首页") }} /
        </span>
        <span style="color:#15192a;cursor: pointer;" @click="onBtnClick">
          {{ translateTitle("博客") }} /
        </span>
        <span style="color:#15192a"> {{ translateTitle("详情页") }}</span>
      </div>
      <div class="banner-content">
        <div class="left">
          <p class="title">{{ detail.title }}</p>
          <p class="time">{{ timestamp(detail.create_time) || "" }}</p>
          <span class="tag">{{ detail.tag_msg }}</span>
          <div class="blog-list-content">
            <div v-html="detail.content"></div>
          </div>
        </div>
        <div class="right">
          <div class="tag-content">
            <p class="tag-title">{{ translateTitle("Follow us") }}</p>
            <div class="icon-content">
              <ul>
                <li style="margin-left:0">
                  <a
                    href="https://www.facebook.com/maskexglobal"
                    target="_blank"
                  >
                    <img src="../../assets/img/mask/blog/facebook.png" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://mobile.twitter.com/MaskexGlobal"
                    target="_blank"
                  >
                    <img src="../../assets/img/mask/blog/twitter.png" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/maskexglobal/"
                    target="_blank"
                  >
                    <img
                      src="../../assets/img/mask/blog/instagram.png"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UClSeew7MEmHi6i19GBEun9w"
                    target="_blank"
                  >
                    <img src="../../assets/img/mask/blog/youtube.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://t.me/maskextg" target="_blank">
                    <img src="../../assets/img/mask/blog/telegram.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://vm.tiktok.com/ZSeEMpTqm" target="_blank">
                    <img src="../../assets/img/mask/blog/tiktok.png" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://am.linkedin.com/company/maskex-global"
                    target="_blank"
                  >
                    <img src="../../assets/img/mask/blog/linkedin.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://discord.gg/maskex" target="_blank">
                    <img src="../../assets/img/mask/blog/discord.png" alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="hot-list">
            <p class="hot-title">{{ translateTitle("Popular Posts") }}</p>
            <ul class="hot-content">
              <li
                class="hot-item"
                v-for="(item, index) in hotList"
                :key="index"
              >
                <div class="left-item">
                  <img class="hot-img" :src="item.img_url" alt="" />
                </div>
                <div class="right-item">
                  <p class="hot-text">
                    {{ item.title }}
                  </p>
                  <p>{{ timestamp(item.create_time) }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { timestampToYear, timestamp } from "@/assets/js/formatDate";
import commonMixin from "@/components/common/commonMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
import {
  apiGetBlogList,
  apiGetHotList,
  apiGetBanner,
  apiGetBlogDetails,
} from "@/model/blog/index";
export default {
  mixins: [commonMixin],
  components: {},
  data() {
    return {
      searchInput: "",
      tagId: "",
      activeTag: "ALL",
      bannerList: [],
      blogList: [],
      hotList: [],
      detail: {},
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      environment: "user/environment",
      darkMode: "user/darkMode",
    }),
  },
  created() {
    this.getBlogDetails();
    this.getBlogList();
    this.getHotList();
  },
  methods: {
    routeSplicing,
    timestamp,
    onBtnClick() {
      this.$router.push({
        path: this.routeSplicing(this.language, "blog"),
      });
    },
    onTagClick(item) {
      this.activeTag = item;
    },
    getBlogList() {
      const req = {
        search: this.searchInput,
        tag_id: this.tagId,
      };
      apiGetBlogList(req).then(res => {
        if (res.status == 200) {
          this.blogList = res.data.list;
        }
      });
    },
    getHotList() {
      apiGetHotList().then(res => {
        if (res.status == 200) {
          this.hotList = res.data;
        }
      });
    },
    getBlogDetails() {
      let pid = this.$route.query.pid;
      apiGetBlogDetails({ pid }).then(res => {
        if (res.status == 200) {
          this.detail = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.blog-page-detail {
  background: #f9f9f9;
}
.blog-content-detail {
  width: 1200px;
  margin: 0 auto;
  .header {
    user-select: none;
    padding-top: 40px;
    margin-bottom: 27px;
  }
  .banner-content {
    .left {
      vertical-align: top;
      display: inline-block;
      width: 790px;
      .title {
        color: #15192a;
        font-size: 36px;
        font-weight: bold;
        margin: 0 278.1px 8.6px 0;
      }
      .time {
        font-size: 16px;
        font-weight: normal;
        color: #666;
      }
      .tag {
        display: block;
        margin: 18px 697.4px 26.3px 0.1px;
        padding: 6.6px 15px 6.6px 15px;
        border-radius: 3px;
        text-align: center;
        color: #15bfb5;
        font-size: 12px;
        font-weight: 500;
        background-color: #e3f6f5;
      }
    }
    .right {
      margin-left: 60px;
      width: 350px;
      vertical-align: top;
      display: inline-block;
      .search-input {
        width: 350px;
        height: 53.8px;
        padding: 10px 30px 10px 25px;
        border-radius: 37px;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.02);
        border: solid 0.6px #f0f2f5;
        background-color: #fff;
        .el-input__inner {
          border: none;
          margin-top: -4px;
          font-size: 18px;
        }
        .el-icon-search {
          font-size: 20px;
          margin-right: 10px;
        }
      }
      .tag-content {
        margin-top: 30px;
        .tag-title {
          font-size: 18px;
          color: #15192a;
          font-weight: bold;
          height: 25px;
          margin-bottom: 22px;
        }
        .tags {
          width: 350px;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .tag-item {
            cursor: pointer;
            height: 38px;
            flex-grow: 0;
            margin: 0 20px 18.5px 0;
            padding: 10px 13px;
            border-radius: 3px;
            background-color: #f1f1f1;
            color: #999;
            font-size: 14px;
            font-weight: 500;
            &.active {
              color: #15bfb5;
              background-color: #e3f6f5;
            }
          }
        }
        .icon-content {
          width: 350px;
          margin-bottom: 75px;
          ul li {
            margin-left: 27px;
            display: inline-block;
            img {
              cursor: pointer;
              height: 20px;
            }
          }
        }
      }
      .hot-list {
        .hot-title {
          font-size: 18px;
          color: #15192a;
          font-weight: bold;
          height: 25px;
          margin-bottom: 22px;
        }
        .hot-content {
          .hot-item {
            cursor: pointer;
            margin-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #eee;
            .left-item {
              display: inline-block;
              vertical-align: top;
              .hot-img {
                width: 120px;
                height: 67.7px;
              }
            }
            .right-item {
              margin-left: 15px;
              display: inline-block;
              vertical-align: top;
              p {
                width: 214px;
                color: #999;
                font-size: 13px;
              }
              .hot-text {
                height: 43px;
                margin-bottom: 10px;
                font-size: 16px;
                color: #15192a;
                display: -webkit-box;
                display: -moz-box;
                white-space: pre-wrap;
                word-wrap: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
            }
          }
        }
      }
    }
  }
}
</style>
