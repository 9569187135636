<template>
  <div class="broker-content-page scrollbar-default">
    <!-- 头部 -->
    <div class="broker-banner">
      <div class="bb-content">
        <div class="bbc-left">
          <div class="bbc-title">
            {{ translateTitle("Join MaskEX Broker") }}
          </div>
          <div class="bbc-text">
            {{
              translateTitle(
                "Earn up to 70% Rebates ! Over the past year, MaskEX had successfully partnered with over 100 global partners. We are looking for more qualified partners to join us and earn up to 70% rebates"
              )
            }}
          </div>
          <div class="bbc-btn">
            <a :href="routeSplicing(language, `broker-program`)" target="_self">
              <span style="color:white">
                {{ translateTitle("Apply Now") }}
              </span>
            </a>
          </div>
        </div>
        <img class="bbc-right-img" src="@/assets/img/broker/hands.png" />
      </div>
    </div>
    <div class="broker-page">
      <!-- 1 -->
      <div class="bp-first">
        <p class="bpf-title">
          {{ translateTitle("Why Choose MaskEX Broker Program") }}?
        </p>
        <div class="bpf-items">
          <div class="bpf-item">
            <img class="bi-img" src="@/assets/img/broker/bp-1.png" />
            <p class="bi-text">{{ translateTitle("Industry Leading") }}</p>
            <p class="bi-text">{{ translateTitle("Rebates") }}</p>
          </div>
          <div class="bpf-item">
            <img class="bi-img" src="@/assets/img/broker/bp-2.png" />
            <p class="bi-text">
              {{ translateTitle("Cross-platform Marketing") }}
            </p>
            <p class="bi-text">{{ translateTitle("Support") }}</p>
          </div>
          <div class="bpf-item">
            <img class="bi-img" src="@/assets/img/broker/bp-3.png" />
            <p class="bi-text">
              {{ "24/7" }}
            </p>
            <p class="bi-text">{{ translateTitle("Tech Support") }}</p>
          </div>
          <div class="bpf-item">
            <img class="bi-img" src="@/assets/img/broker/bp-4.png" />
            <p class="bi-text">
              {{ translateTitle("Dedicated Relationship") }}
            </p>
            <p class="bi-text">{{ translateTitle("Manager") }}</p>
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div class="bp-second">
        <p class="bps-title">
          {{ translateTitle("Come Join Us If You Are") }}:
        </p>
        <div class="bps-items">
          <div class="bpi-1 bpi-item">
            {{ translateTitle("Trading Bot Services") }}
          </div>
          <div class="bpi-2 bpi-item">
            {{ translateTitle("Professional Trading Platforms") }}
          </div>
          <div class="bpi-3 bpi-item">
            {{ translateTitle("Market Analysis Platforms") }}
          </div>
          <div class="bpi-4 bpi-item">
            {{ translateTitle("Trading Software Providers") }}
          </div>
          <div class="bpi-5 bpi-item">
            {{ translateTitle("Algo-trading Platforms") }}
          </div>
          <div class="bpi-6 bpi-item">
            {{ translateTitle("Broker Solution Providers") }}
          </div>
          <div class="bpi-7 bpi-item">
            {{ translateTitle("Exchange Aggregators") }}
          </div>
          <div class="bpi-8 bpi-item">
            {{ translateTitle("Trading Terminals") }}
          </div>
          <div class="bpi-9 bpi-item">
            {{ translateTitle("Copy Trading Services") }}
          </div>
          <div class="bpi-10 bpi-item">
            {{ translateTitle("Social Trading Platforms") }}
          </div>
          <div class="bpi-11 bpi-item">
            {{ translateTitle("Hedge Fund Services") }}
          </div>
        </div>
      </div>
      <!-- 3 -->
      <div class="bp-third">
        <p class="bpt-title">{{ translateTitle("How To Earn Rebates") }}?</p>
        <div class="bpt-items">
          <div class="bpt-item">
            <div class="bpt-left">
              <img class="bpt-img" src="@/assets/img/broker/er-1.png" />
              <div class="bpt-text">
                <p class="btt-title">
                  {{ translateTitle("Submit Application") }}
                </p>
                <p class="btt-tips">
                  {{
                    translateTitle(
                      "Fill out your information online and our team will contact you within 1 working days."
                    )
                  }}
                </p>
              </div>
            </div>
            <img class="bpt-img-n" src="@/assets/img/broker/er-1-n.png" />
          </div>
          <div class="bpt-item">
            <div class="bpt-left">
              <img class="bpt-img" src="@/assets/img/broker/er-2.png" />
              <div class="bpt-text">
                <p class="btt-title">
                  {{ translateTitle("Create a MasKEX account") }}
                </p>
                <p class="btt-tips">
                  {{ translateTitle("Create a MaskEX account with us.") }}
                </p>
              </div>
            </div>
            <img class="bpt-img-n" src="@/assets/img/broker/er-2-n.png" />
          </div>

          <div class="bpt-item">
            <div class="bpt-left">
              <img class="bpt-img" src="@/assets/img/broker/er-3.png" />
              <div class="bpt-text">
                <p class="btt-title">
                  {{ translateTitle("Send us your UID") }}
                </p>
                <p class="btt-tips">
                  {{ translateTitle("Send us the MaskEX UID you created.") }}
                </p>
              </div>
            </div>
            <img class="bpt-img-n" src="@/assets/img/broker/er-3-n.png" />
          </div>

          <div class="bpt-item">
            <div class="bpt-left">
              <img class="bpt-img" src="@/assets/img/broker/er-4.png" />
              <div class="bpt-text">
                <p class="btt-title">
                  {{ translateTitle("Conduct a trade") }}
                </p>
                <p class="btt-tips">
                  {{
                    translateTitle(
                      "Conduct a test trade to finalize your integration and check your rebates in the broker dashboard."
                    )
                  }}
                </p>
              </div>
            </div>
            <img class="bpt-img-n" src="@/assets/img/broker/er-4-n.png" />
          </div>
        </div>
      </div>
      <!-- 4 -->
      <div class="bp-fourth">
        <div class="bpf-content">
          <div class="bpf-left">
            <p class="bpl-title">{{ translateTitle("QUESTION") }}?</p>
            <p class="bpl-text">
              {{
                translateTitle(
                  "Please contact the MaskEX Broker Program team or Apply Now"
                )
              }}!
            </p>
            <div class="c-and-a">
              <div class="contact">{{ translateTitle("Contact US") }}</div>
              <div class="apply">
                <a
                  :href="routeSplicing(language, `broker-program`)"
                  target="_self"
                >
                  <span style="color:white">
                    {{ translateTitle("Apply Now") }}
                  </span>
                </a>
              </div>
            </div>
          </div>
          <img class="bpf-right-img" src="@/assets/img/broker/canda.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetStaticTableInfo } from "@/model/settings";

import { mapGetters } from "vuex";

import commonMixin from "@/components/common/commonMixin.vue";

import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language",
    }),
  },
  watch: {},
  async created() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    routeSplicing,
    goLogin() {
      if (!this.userInfo) {
        this.$message({
          message: this.translateTitle("请先登录"),
          type: "warning",
          customClass: "zZindex",
        });
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.broker-content-page {
  background-color: #fff;
  font-family: DINPro;

  .broker-banner {
    background-image: linear-gradient(to bottom, #defdff 0%, #fff 100%);
    display: flex;
    justify-content: center;
    .bb-content {
      width: 1200px;
      position: relative;
      display: flex;
      .bbc-left {
        width: 576px;
        .bbc-title {
          margin-top: 187px;
          font-size: 50px;
          font-weight: bold;
          color: #15192a;
        }
        .bbc-text {
          margin-top: 16px;
          font-size: 22px;
          line-height: 1.48;
          color: #000;
        }
        .bbc-btn {
          cursor: pointer;
          margin-top: 40px;
          margin-bottom: 153px;
          width: 200px;
          height: 48px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 50px;
          border-radius: 6px;
          background-color: #15beb4;
          font-size: 18px;
          font-weight: 500;
          color: #fff;
        }
      }
      .bbc-right-img {
        position: absolute;
        right: -58px;
        width: 666px;
        height: 642px;
      }
    }
  }
  .broker-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    .bp-first {
      margin-top: 106px;
      width: 1200px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .bpf-title {
        margin-bottom: 58px;
        font-size: 36px;
        font-weight: bold;
        color: #15192a;
      }
      .bpf-items {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .bpf-item {
          width: 288px;
          height: 203px;
          flex-grow: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          background-color: #f7f9fc;
          .bi-img {
            width: 68px;
            height: 68px;
            margin-bottom: 20px;
          }
          .bi-text {
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            color: #15192a;
          }
        }
      }
    }
    .bp-second {
      margin-top: 117px;
      width: 1200px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .bps-title {
        margin-bottom: 47px;
        font-size: 36px;
        font-weight: bold;
        color: #15192a;
      }
      .bps-items {
        width: 100%;
        height: 353px;
        position: relative;
        font-weight: 500;
        color: #15192a;
        .bpi-item {
          border-radius: 5px;
          background-color: #f7f9fc;
        }
        .bpi-1 {
          position: absolute;
          top: 34px;
          left: 99px;
          padding: 11.5px 17px;
          font-size: 14px;
          font-weight: 500;
        }
        .bpi-2 {
          position: absolute;
          top: 4px;
          left: 340px;
          padding: 11.5px 17px;
          font-size: 14px;
          font-weight: 500;
        }
        .bpi-3 {
          position: absolute;
          top: 0px;
          left: 642px;
          padding: 14.5px;
          font-size: 16px;
          font-weight: 500;
        }
        .bpi-4 {
          position: absolute;
          top: 19px;
          left: 895px;
          padding: 12.5px 15px;
          font-size: 14px;
          font-weight: 500;
        }
        .bpi-5 {
          position: absolute;
          top: 99px;
          left: 223px;
          padding: 12.5px 9px;
          font-size: 16px;
          font-weight: 500;
        }
        .bpi-6 {
          position: absolute;
          top: 85px;
          left: 463px;
          padding: 19.5px 33.6px;
          font-size: 20px;
          font-weight: 500;
        }
        .bpi-7 {
          position: absolute;
          top: 101px;
          left: 821px;
          padding: 13.5px 22px;
          font-size: 16px;
          font-weight: 500;
        }
        .bpi-8 {
          position: absolute;
          top: 173px;
          left: 138px;
          padding: 14.5px 24.5px;
          font-size: 16px;
          font-weight: 500;
        }
        .bpi-9 {
          position: absolute;
          top: 180px;
          left: 357px;
          padding: 15.5px 24.5px;
          font-size: 18px;
          font-weight: 500;
        }
        .bpi-10 {
          position: absolute;
          top: 174px;
          left: 664px;
          padding: 14.5px 24.5px;
          font-size: 18px;
          font-weight: 500;
        }
        .bpi-11 {
          position: absolute;
          top: 184px;
          left: 934px;
          padding: 10.5px 16.5px;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .bp-third {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: #f7f9fc;
      .bpt-title {
        width: 1200px;
        margin-top: 94px;
        margin-bottom: 56px;
        font-size: 36px;
        font-weight: bold;
        color: #15192a;
        display: flex;
        justify-content: center;
      }
      .bpt-items {
        width: 1200px;
        margin-bottom: 68px;
        .bpt-item {
          background-color: #fff;
          width: 100%;
          height: 180px;
          padding: 10px;
          margin-bottom: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .bpt-left {
            display: flex;
            .bpt-img {
              width: 160px;
              height: 160px;
            }
            .bpt-text {
              margin-left: 46px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .btt-title {
                font-size: 28px;
                font-weight: bold;
                color: #000;
              }
              .btt-tips {
                margin-top: 12.7px;
                font-size: 18px;
                color: #666;
                width: 710px;
              }
            }
          }
          .bpt-img-n {
            width: 92px;
            height: 73px;
            margin-right: 100px;
          }
        }
      }
    }
    .bp-fourth {
      width: 100%;
      padding: 122px 0 132px;
      display: flex;
      justify-content: center;
      .bpf-content {
        width: 1200px;
        border-radius: 10px;
        background-color: #f7f9fc;
        padding-left: 48px;
        display: flex;
        justify-content: space-between;
        .bpf-left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .bpl-title {
            font-size: 36px;
            font-weight: bold;
            color: #15192a;
            margin-bottom: 18px;
          }
          .bpl-text {
            font-size: 18px;
            line-height: 0.69;
            color: #666;
            margin-bottom: 33px;
          }
          .c-and-a {
            display: flex;
            .contact {
              display: flex;
              flex-grow: 0;
              flex-shrink: 0;
              justify-content: center;
              align-items: center;
              padding: 10px 50px;
              border-radius: 6px;
              border: solid 1.3px #15beb4;
              font-size: 18px;
              font-weight: 500;
              color: #15beb4;
            }
            .apply {
              margin-left: 20px;
              display: flex;
              flex-grow: 0;
              flex-shrink: 0;
              justify-content: center;
              align-items: center;
              padding: 10px 50px;
              border-radius: 6px;
              background-color: #15beb4;
              font-size: 18px;
              font-weight: 500;
              color: #fff !important;
            }
          }
        }
        .bpf-right-img {
          width: 566px;
          height: 328px;
        }
      }
    }
  }
}
</style>
