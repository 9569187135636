import api from "@/assets/js/api";

import { VUE_APP_UCENTER, VUE_APP_APP } from "@/assets/js/stationSetting";

//元宇宙市场趋势
export function apiMarketTrend(data = {}) {
  return api({
    url: `${VUE_APP_APP}/Metaverse/marketTrend`,
    data,
  });
}
//元宇宙元宇宙趋势
export function apiMetaverseTrend(data = {}) {
  return api({
    url: `${VUE_APP_APP}/Metaverse/trend`,
    data,
  });
}
//元宇宙元宇宙趋势
export function apiMetaverseExpress(data = {}) {
  return api({
    url: `${VUE_APP_APP}/Metaverse/express`,
    data,
  });
}
