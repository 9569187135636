import api from "@/assets/js/api";
import { VUE_APP_UCENTER, VUE_APP_APP } from "@/assets/js/stationSetting";

// Blog 列表
export function apiGetBlogList(data = {}) {
  return api({
    url: VUE_APP_APP + `/Blog/Common/blogList`,
    data,
    type: "POST",
  });
}
// Blog 右侧 列表
export function apiGetHotList(data = {}) {
  return api({
    url: VUE_APP_APP + `/Blog/Common/hotList`,
    data,
    type: "POST",
  });
}
// Blog banner 列表
export function apiGetBanner(data = {}) {
  return api({
    url: VUE_APP_APP + `/Blog/Common/banner`,
    data,
    type: "POST",
  });
}
// Blog banner 详情
export function apiGetBlogDetails(data = {}) {
  return api({
    url: VUE_APP_APP + `/Blog/Common/details`,
    data,
    type: "POST",
  });
}
// Blog  TAG 列表
export function apiGetTags(data = {}) {
  return api({
    url: VUE_APP_APP + `/Blog/Common/tags`,
    data,
    type: "POST",
  });
}
