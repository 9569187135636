<template>
  <div class="V8-former-universe-content">
    <div class="home-content-one"></div>
    <h2 class="market-trend-title">
      {{ translateTitle("市场趋势") }}
    </h2>
    <div class="markets-header-content" v-if="headerForm.length > 0">
      <div
        class="market-header-item-v2"
        v-for="(item, index) in headerForm"
        :key="index"
        @click="onSymbolBlockClick(headerForm[index].symbol)"
      >
        <div class="title">
          {{ translateTitle("最高24小时营业额") }}
        </div>
        <div class="symbol-content">
          <span class="symbol">
            <img class="coin-url" :src="headerForm[index].icon" alt="" />
            <span class="coin-name"> {{ headerForm[index].symbol }}</span></span
          >
          <span class="quote-change" :class="className(index)">
            <span
              class="plus-icon"
              v-if="headerForm[index].usd_price_change_24h > 0"
              >+</span
            >
            {{ headerForm[index].usd_price_change_24h }}
            <span v-if="headerForm[index].usd_price_change_24h != '--'">%</span>
          </span>
        </div>
        <div class="price-content">
          <span class="price DINPro" :class="className(index)">
            {{ headerForm[index].usd_price }}</span
          >
          <!-- <span class="fiat-price DINPro">
            ≈ {{ systemCurrencyMaps[systemCurrency] }}
            {{ headerForm[0].currency[systemCurrency] }}
          </span> -->
          <span class="fiat-price V2"
            >24H {{ translateTitle("成交量") }}
            <span
              class="DINPro"
              v-if="Number(headerForm[index].usd_volume_24h) != 0"
              >{{ formatNumberRgx(headerForm[index].usd_volume_24h) }}</span
            >
            <span v-else>--</span>
          </span>
        </div>
      </div>
    </div>
    <div style="height: 214px" class="markets-header-content" v-else>
      <div class="market-header-item-v2" v-for="item in 5" :key="item">
        <div class="first-content">
          <span class="first-left"></span><span class="first-right"></span>
        </div>
        <div class="second-content"></div>
        <div class="third-content"></div>
      </div>
    </div>
    <div class="home-content-three">
      <div class="trend-content">
        <h2 class="trend-content-title">
          {{ translateTitle("元宇宙趋势") }}
        </h2>
        <div class="content-one">
          <div class="left">
            <p>{{ translateTitle("市值") }}</p>
            <p style="margin-top: 10px; font-weight: bolder" class="DINPro">
              ${{ formatNumberRgx(marketTrendObj.usd_market_cap) }}
            </p>
            <p
              style="margin-top: 20px"
              :class="
                Number(marketTrendObj.usd_market_cap_change_24h) > 0
                  ? 'green'
                  : 'red'
              "
            >
              <i
                class="iconxiala1 iconfont"
                v-if="Number(marketTrendObj.usd_market_cap_change_24h) > 0"
              ></i>
              <i class="iconxiala iconfont" v-else></i>
              {{ marketTrendObj.usd_market_cap_change_24h }}%
            </p>
          </div>
          <div class="right">
            <img
              src="../../assets/img/bv/raise.png"
              v-if="Number(marketTrendObj.usd_market_cap_change_24h) > 0"
              alt=""
            />
            <img v-else src="../../assets/img/bv/down.png" alt="" />
          </div>
        </div>
        <div class="content-two">
          <div class="left">
            <p>{{ translateTitle("交易额") }}</p>
            <p style="margin-top: 10px; font-weight: bolder" class="DINPro">
              ${{ formatNumberRgx(marketTrendObj.usd_trading_volume) }}
            </p>
            <p
              style="margin-top: 20px"
              :class="
                Number(marketTrendObj.usd_trading_volume_change_24h) > 0
                  ? 'green'
                  : 'red'
              "
            >
              <i
                class="iconxiala1 iconfont"
                v-if="Number(marketTrendObj.usd_trading_volume_change_24h) > 0"
              ></i>
              <i class="iconxiala iconfont" v-else></i>
              {{ marketTrendObj.usd_trading_volume_change_24h }}%
            </p>
          </div>
          <div class="right">
            <img
              src="../../assets/img/bv/raise.png"
              v-if="marketTrendObj.usd_trading_volume_change_24h > 0"
              alt=""
            />
            <img v-else src="../../assets/img/bv/down.png" alt="" />
          </div>
        </div>
        <div class="content-three">
          <p>
            {{ translateTitle("涨/跌 占比") }}
          </p>
          <div class="out-content">
            <div
              class="win"
              style="background: #34be88"
              :style="{
                width:
                  (marketTrendObj.gainer_amount * 100) /
                    (marketTrendObj.gainer_amount +
                      marketTrendObj.loser_amount) +
                  '%',
              }"
            ></div>
            <div
              class="lose"
              style="background: #ff6565"
              :style="{
                width:
                  (marketTrendObj.loser_amount * 100) /
                    (marketTrendObj.gainer_amount +
                      marketTrendObj.loser_amount) +
                  '%',
              }"
            ></div>
          </div>
          <div class="number">
            <span>
              {{ marketTrendObj.gainer_amount }}
              <span style="color: #697786">
                ({{
                  (
                    (marketTrendObj.gainer_amount * 100) /
                    (marketTrendObj.gainer_amount + marketTrendObj.loser_amount)
                  ).toFixed(0)
                }}%)
              </span>
            </span>
            <span
              >{{ marketTrendObj.loser_amount }}
              <span style="color: #697786">
                ({{
                  (
                    (marketTrendObj.loser_amount * 100) /
                    (marketTrendObj.gainer_amount + marketTrendObj.loser_amount)
                  ).toFixed(0)
                }}%)
              </span></span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="home-content-two" :class="imgList.length == 0 ? '' : ''">
      <h2 class="swiper-sider-title">
        {{ translateTitle("元宇宙快讯") }}
      </h2>
      <div class="swiper-sider-content" v-if="showBanner">
        <div class="swiper-content">
          <div class="swiper-prev" slot="button-prev">
            <img src="../../assets/img/left-a.png" alt="" />
          </div>
          <swiper
            :options="swiperOption"
            ref="mySwiper"
            class="swiper-container"
          >
            <swiper-slide
              class="swiper-item"
              v-for="(item, index) of imgList"
              :key="index"
            >
              <div @click="jumpToUrlV2(item)">
                <img class="swiper-img" :src="item.illustration" alt="" />
                <div class="swiper-text">
                  <p class="title">{{ item.title }}</p>
                  <div class="content">{{ item.summary }}</div>
                </div>
              </div>
            </swiper-slide>
          </swiper>

          <div class="swiper-next" slot="button-next">
            <img src="../../assets/img/right-a.png" alt="" />
          </div>
        </div>
      </div>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->

      <div class="quotes-content">
        <h2 class="table-title">
          {{ translateTitle("元宇宙行情") }}
        </h2>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="translateTitle('币币')" name="1"></el-tab-pane>
          <el-tab-pane :label="translateTitle('合约')" name="2"></el-tab-pane>
          <!-- <el-tab-pane label="BTC" name="3"></el-tab-pane> -->
        </el-tabs>
        <el-table
          v-loading="tableLoading"
          :data="tableDataComputed"
          @row-click="onClickRow"
          row-key="symbol_id"
          style="width: 100%"
          class="trade-table"
        >
          <el-table-column
            :label="translateTitle('交易对')"
            align="left"
            min-width="140"
            prop="from_symbol"
          >
            <template slot-scope="{ row }" style="text-align: left">
              <span
                class="coin-content"
                @click="onSymbolClick(row)"
                style="cursor: pointer"
              >
                <img class="coin-img" :src="row.coin_url" alt="" />
                <span
                  class="left-symbol"
                  style="margin-left: 10px; font-size: 14px"
                  >{{ row.from_symbol }}</span
                >
                <span class="dark-color"> / {{ row.to_symbol }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('最新价')"
            align="left"
            prop="usd"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro left-row"> {{ row.last }} </span>
                <span class="dark-color DINPro"
                  >/ {{ systemCurrencyMaps[systemCurrency]
                  }}{{ row.currency[systemCurrency] }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('24H涨跌幅')"
            align="center"
            prop="info.price_change_percent"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <span
                class="left-row DINPro"
                :class="Number(row.price_change_percent) > 0 ? 'green' : 'red'"
                ><span
                  class="green"
                  v-show="Number(row.price_change_percent) > 0"
                  style="margin-right: -3px"
                >
                  + </span
                >{{ row.price_change_percent }} %</span
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('行情')"
            align="center"
            prop="info.high"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <!-- <span class="DINPro">{{ row.info.high }}</span> -->
              <img
                class="mrket-img"
                v-if="Number(row.price_change_percent) > 0"
                src="../../assets/img/markets/markt-up.png"
              />
              <img
                class="mrket-img"
                v-else
                src="../../assets/img/markets/markt-download.png"
              />
            </template>
          </el-table-column>

          <el-table-column
            :label="translateTitle('操作')"
            align="center"
            min-width="50"
          >
            <template slot-scope="{ row }">
              <el-button class="operate" @click="onSymbolClick(row)">{{
                translateTitle("交易")
              }}</el-button>
            </template>
          </el-table-column>
          <no-data
            slot="empty"
            v-show="!tableLoading && tableDataComputed.length"
          ></no-data>
        </el-table>
        <!-- <div class="more-info">
          <div @click="onLookMarket" class="more">
            <span class="more-title">{{ translateTitle("查看更多行情") }}</span>
            <i class="iconfont icongengduo"></i>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { apiGetStaticTableInfo } from "@/model/settings";
import NoData from "@/components/common/NoData";
import Cookies from "js-cookie";
import Socket from "@/assets/js/socket.js";
import {
  apiMarketTrend,
  apiMetaverseTrend,
  apiMetaverseExpress,
} from "@/model/metaverse/index";
import { mapActions, mapGetters } from "vuex";
import { apiWsUrl, apiGetSpotList } from "@/model/exchange/index";
import { apiGetContractList } from "@/model/contractExchange/market";
import { systemCurrencyMaps } from "@/assets/js/keymap.js";
import routeSplicing from "@/assets/js/routeSplicing";
import { VUE_APP_ID } from "@/assets/js/stationSetting";
var vm = null;

export default {
  mixins: [commonMixin],
  components: {
    swiper,
    swiperSlide,
    NoData,
  },

  data() {
    return {
      tt: {}, //WS延时触发器
      lockReconnect: false, //避免重复连接
      wsContentTime: 0,
      detailsUrl: "",
      systemCurrencyMaps,
      token: "",
      wsUrl: "",
      appDownLoadImg: "",
      activeName: "1",
      // realIndex:0,
      isHuiXin: false,
      is8V: false,
      isUeasy: false,
      isS6: false,
      sideLen: 3,
      isResiter: false,
      resiterVal: "",
      resiterValue: "",
      isMobile: false,
      isEmail: false,
      showDownLoadImg: false,
      showBanner: true,
      doanGoogleUrl: "https://www.topcreditbank.com/app_download/",
      doanIosUrl: "https://apps.apple.com/app/id1525081932",
      tipUrl: "",
      headerForm: [],
      metaCoinMaps: {},
      metaCoinMapsV2: {},
      firstArticles: {},
      secondArticles: {},
      marketTrendObj: {
        usd_market_cap: "--",
        usd_market_cap_change_24h: "--",
        usd_trading_volume: "--",
        usd_trading_volume_change_24h: "--",
        gainer_amount: "1",
        loser_amount: "1",
      },
      swiperOption: {
        initialSlide: 0,
        // //循环
        loop: true,
        // slidesPerView: "auto",
        slidesPerView: 3,

        // 箭头配置
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
        on: {
          click: function () {
            // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
            // console.log(this); // -> Swiper
            // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
            const realIndex = this.realIndex;
            // vm.handleClickSlide(realIndex);
          },
        },

        spaceBetween: 24,
        loopedSlides: 3,
        slidesPerGroup: 3,
        loopFillGroupWithBlank: true,
        infinite: 1, // 无限滚动前后遍历数
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 4000,
        },
        autoplayDisableOnInteraction: false,

        // // //每张播放时长3秒，自动播放
        // autoplay: 2000,
        // // //滑动速度
        // speed: 1000,
      },
      imgList: [],
      tempMarkList: [],
      articlesList: [],
      // tableDataComputed: [],
      exchangeCoinMap: {},
      metaExchangeCoinMap: {},
      coinInfoList: {},
      tableLoading: true,
      tableData: [],
      contractTableData: [],
      activeSymbol: "1",
      collectSymbol: [22],
      ws: null,
      logoImg: "",
      timer: null,
    };
  },
  async created() {
    vm = this;

    this.initURL();
    this.getMetaverseTrend();
    await this.getStaticTableInfo();
    await this.getExchangeCoinMap();
    await this.getExchangeCoinMapV2();
    this.getMarketTrend();
    this.getMetaverseExpress();
    this.getTradePairList();
    this.getContractList();
    this.getMetaverseExpress();
    this.initWS();
  },
  mounted() {
    let me = this;
    if (this.language == "zh_CN" || this.language == "zh_HK") {
      let titleTag = document.getElementById("pageTitle");
      titleTag.innerHTML = "元界 - 数字加密货币全球数字资产交易所";
      let metaTag = document.getElementById("description");
      metaTag.name = "description";
      metaTag.content = "元界 - 数字加密货币全球数字资产交易所";
      let metaTag2 = document.getElementById("keywords");
      metaTag2.name = "keywords";
      metaTag2.content = "加密货币,加密数字货币,数字加密货币";
    } else {
      let titleTag = document.getElementById("pageTitle");
      titleTag.innerHTML =
        "Metaverse - Cryptocurrency global digital asset exchange";
      let metaTag = document.getElementById("description");
      metaTag.name = "description";
      metaTag.content =
        "Metaverse - Cryptocurrency global digital asset exchange";
      let metaTag2 = document.getElementById("keywords");
      metaTag2.name = "keywords";
      metaTag2.content =
        "cryptocurrency, encrypted digital currency, digital cryptocurrency";
    }
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
    tableDataComputed() {
      let tempArr = this.tableData;
      if (this.activeName == 1) {
        tempArr = this.tableData;
      } else {
        tempArr = this.contractTableData;
      }
      return tempArr;
    },
  },
  watch: {
    // activeName(newValue) {
    //   if (newValue == 1) {
    //     this.tableDataComputed = this.tableData;
    //   } else {
    //     this.tableDataComputed = this.contractTableData;
    //   }
    // },
  },
  methods: {
    routeSplicing,
    mounted() {
      window.USDT_WS.sub({
        action: "Topic.sub",
        data: {
          type: "contractM",
          app_id: VUE_APP_ID,
        },
      });
    },
    initWS() {
      let wsUrl = this.wsUrl;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "marketM",
            app_id: VUE_APP_ID,
          },
        },
        callback: this.onResponse,
        mounted: this.mounted,
        onConnect,
      };
      window.USDT_WS = this.ws = new Socket(option).getInstance();
      me.global.setWs(this.ws);
      function onConnect() {
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWS();
          me.lockReconnect = false;
        }, 3000);
      }
    },
    onResponse(data) {
      this.tableLoading = false;
      if (data.action == "Pushdata.marketM") {
        this.tableData = data.data;

        if (this.tableData == null || this.tableData.length == 0) {
          return;
        }
        this.tableData.forEach((ele) => {
          for (const key in this.exchangeCoinMap) {
            if (key == ele.symbol_id) {
              ele["from_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[0]
                .toUpperCase();
              ele["to_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[1]
                .toUpperCase();
              ele["info"] = this.exchangeCoinMap[key];
              ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
            }
          }
          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (
                this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
              ) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
        });
        this.tableData = this.tableData.filter((ele) => {
          return ele.from_symbol != undefined;
        });
      } else if (data.action == "Pushdata.contractM") {
        let tempArr = data.data;
        let tempMarkList = [];
        tempArr.forEach((ele) => {
          ele.lastV2 = Number(ele.last);
          ele.currency.usdV2 = Number(ele.currency.usd);
          ele.price_change_percentV2 = Number(ele.price_change_percent);
          for (const key in this.metaExchangeCoinMap) {
            if (ele.symbol_id == key) {
              ele["from_symbol"] = this.metaExchangeCoinMap[key].name
                .split("_")[0]
                .toUpperCase();
              ele["to_symbol"] = this.metaExchangeCoinMap[key].name
                .split("_")[1]
                .toUpperCase();
              ele["info"] = this.metaExchangeCoinMap[key];
              ele["web_recommend"] =
                this.metaExchangeCoinMap[key].web_recommend;
              tempMarkList.push(ele);
            }
          }
          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (
                this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
              ) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
        });
        this.tableLoading = false;
        this.contractTableData = tempMarkList.filter((ele) => {
          return ele.from_symbol != undefined;
        });
      }
    },
    handleClick(tab, event) {
      console.log(tab);
    },
    className(key) {
      let className = "";
      if (!this.headerForm[key].usd_price_change_24h) {
        className = "";
      } else if (this.headerForm[key].usd_price_change_24h > 0) {
        className = "green";
      } else if (this.headerForm[key].usd_price_change_24h < 0) {
        className = "red";
      }
      return className;
    },
    getMarketTrend() {
      apiMarketTrend().then((res) => {
        if (res.status == 200) {
          this.marketTrendObj = res.data;
        }
      });
    },
    getMetaverseTrend() {
      apiMetaverseTrend().then((res) => {
        if (res.status == 200) {
          this.headerForm = res.data;
        }
      });
    },
    jumpToUrl(url) {},
    jumpToUrlV2(item) {
      window.open(item.content_rul);
    },

    async getExchangeCoinMap() {
      await apiGetStaticTableInfo(69).then(({ data }) => {
        this.metaCoinMaps = data;
      });

      const { status, data } = await apiGetStaticTableInfo(55);
      if (status == 200) {
        let tempObj = {};
        let tempObjV2 = {};
        for (const key in data) {
          if (data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        for (const key in tempObj) {
          for (const index in this.metaCoinMaps) {
            if (tempObj[key].symbol_id == this.metaCoinMaps[index].symbol_id) {
              tempObjV2[key] = tempObj[key];
            }
          }
        }
        this.exchangeCoinMap = JSON.parse(JSON.stringify(tempObjV2));
      }
    },
    async getExchangeCoinMapV2() {
      await apiGetStaticTableInfo(70).then(({ data }) => {
        this.metaCoinMapsV2 = data;
      });

      const { status, data } = await apiGetStaticTableInfo(49);
      if (status == 200) {
        let tempObj = {};
        let tempObjV2 = {};
        for (const key in data) {
          if (data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        for (const key in tempObj) {
          for (const index in this.metaCoinMapsV2) {
            if (
              tempObj[key].symbol_id == this.metaCoinMapsV2[index].symbol_id
            ) {
              tempObjV2[key] = tempObj[key];
            }
          }
        }
        this.metaExchangeCoinMap = JSON.parse(JSON.stringify(tempObjV2));
      }
    },

    async initURL() {
      let params = {
        __version: "3.4.3",
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    getTradePairList() {
      apiGetSpotList().then((res) => {
        if (res.status == 200) {
          let tempArr = res.data;
          let tempMarkList = [];
          tempArr.forEach((ele) => {
            ele.lastV2 = Number(ele.last);
            ele.currency.usdV2 = Number(ele.currency.usd);
            ele.price_change_percentV2 = Number(ele.price_change_percent);
            for (const key in this.exchangeCoinMap) {
              if (ele.symbol_id == key) {
                ele["from_symbol"] = this.exchangeCoinMap[key].name
                  .split("_")[0]
                  .toUpperCase();
                ele["to_symbol"] = this.exchangeCoinMap[key].name
                  .split("_")[1]
                  .toUpperCase();
                ele["info"] = this.exchangeCoinMap[key];
                ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
                tempMarkList.push(ele);
              }
            }
            for (const key in this.coinInfoList) {
              if (ele.from_symbol) {
                if (
                  this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
                ) {
                  ele.coin_url = this.coinInfoList[key].coin_url;
                }
              }
            }
          });
          this.tableLoading = false;
          this.tableData = tempMarkList.filter((ele) => {
            return ele.from_symbol != undefined;
          });
          // this.tableDataComputed = this.tableData;
        }
      });
    },
    getContractList() {
      apiGetContractList().then((res) => {
        if (res.status == 200) {
          let tempArr = res.data;
          let tempMarkList = [];
          tempArr.forEach((ele) => {
            ele.lastV2 = Number(ele.last);
            ele.currency.usdV2 = Number(ele.currency.usd);
            ele.price_change_percentV2 = Number(ele.price_change_percent);
            for (const key in this.metaExchangeCoinMap) {
              if (ele.symbol_id == key) {
                ele["from_symbol"] = this.metaExchangeCoinMap[key].name
                  .split("_")[0]
                  .toUpperCase();
                ele["to_symbol"] = this.metaExchangeCoinMap[key].name
                  .split("_")[1]
                  .toUpperCase();
                ele["info"] = this.metaExchangeCoinMap[key];
                ele["web_recommend"] =
                  this.metaExchangeCoinMap[key].web_recommend;
                tempMarkList.push(ele);
              }
            }
            for (const key in this.coinInfoList) {
              if (ele.from_symbol) {
                if (
                  this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
                ) {
                  ele.coin_url = this.coinInfoList[key].coin_url;
                }
              }
            }
          });
          this.tableLoading = false;
          this.contractTableData = tempMarkList.filter((ele) => {
            return ele.from_symbol != undefined;
          });
          // this.contractTableData = [...this.tableDataComputed, ...temp];
        }
      });
    },
    onSymbolBlockClick(from_symbol, to_symbol) {
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${from_symbol.toLowerCase()}_${to_symbol.toLowerCase()}`
      );
      window.open(tempUrl);
    },

    handleClickSlide(index) {
      console.log("當前點擊索引：", index);
    },

    onLookMarket() {
      this.$router.push(this.routeSplicing(this.language, `markets`));
    },

    async getMetaverseExpress() {
      const { status, data } = await apiMetaverseExpress();
      if (status == 200) {
        let len = data.length;
        // if (len <= 4) {
        //   data.forEach((ele, index) => {
        //     this.oldImgList[index] = ele;
        //   });
        // } else {
        //   }
        this.imgList = data;
        this.showBanner = true;
        this.sideLen = len >= 3 ? 3 : len;
      }
    },
    async getStaticTableInfo() {
      const { status, data } = await apiGetStaticTableInfo(1);
      if (status == 200) {
        this.coinInfoList = JSON.parse(JSON.stringify(data));
      }
    },

    onSymbolClick(row) {
      let tempUrl = "";
      if (this.activeName == 1) {
        tempUrl = `/exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`;
      } else {
        tempUrl = `/contract-exchange/${row.from_symbol.toLowerCase()}${row.to_symbol.toLowerCase()}`;
      }
      window.open(tempUrl);
    },
    onClickRow(row, column, event) {
      let tempUrl = "";
      if (this.activeName == 1) {
        tempUrl = `/exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`;
      } else {
        tempUrl = `/contract-exchange/${row.from_symbol.toLowerCase()}${row.to_symbol.toLowerCase()}`;
      }
      window.open(tempUrl);
    },
  },
};
</script>
<style lang="scss">
.ar {
  .V8-former-universe-content .el-table tbody .cell .coin-content {
    float: right;
    margin-right: 80px;
    .coin-img {
      margin-left: 10px;
    }
  }

  .V8-former-universe-content
    .home-content-four
    .left-content
    .tips-details
    li
    i {
    margin-left: 6px;
    margin-right: 0;
  }
  .V8-former-universe-content
    .home-content-four
    .left-content
    .download-content {
    direction: ltr;
  }
  .V8-former-universe-content .home-content-four .left-content .download-code {
    height: 160px;
    .qr_code_canvas {
      margin-right: 0;
    }
  }
}
.zh_CN {
  .V8-former-universe-content .home-content-one {
    background: url("../../assets/img/bv/former-bg1-cn.png") 0 0 repeat;
    background-size: 100% 100%;
  }
}
.V8-former-universe-content {
  margin-top: -2px;
  // height: 2977px;
  display: block;
  [v-cloak] {
    display: none;
  }
  .home-content-three {
    background: #fff;
    overflow: hidden;
    .trend-content {
      width: 1200px;
      height: 200px;
      margin: 30px auto 0;
      .trend-content-title {
        font-size: 18px;
        font-weight: 500;
        color: #112132;
        margin-bottom: 20px;
      }
      .content-one {
        vertical-align: top;
        display: inline-block;
        width: 435px;
        height: 140px;
        padding: 20px 45px 27px 30px;
        border-radius: 2px;
        background-color: #f7f7f7;
        .left {
          display: inline-block;
          .red {
            color: #ff6565;
          }
          .green {
            color: #34be88;
          }
        }
        .right {
          margin-left: 40px;
          display: inline-block;
          vertical-align: top;
          img {
            width: 168px;
            height: 68px;
          }
        }
      }
      .content-two {
        display: inline-block;
        vertical-align: top;
        width: 435px;
        height: 140px;
        margin-left: 20px;
        padding: 20px 45px 27px 30px;
        border-radius: 2px;
        background-color: #f7f7f7;
        .left {
          display: inline-block;
          .red {
            color: #ff6565;
          }
          .green {
            color: #34be88;
          }
        }
        .right {
          margin-left: 40px;
          display: inline-block;
          vertical-align: top;
          img {
            width: 168px;
            height: 68px;
          }
        }
      }
      .content-three {
        vertical-align: top;
        display: inline-block;
        width: 290px;
        height: 140px;
        margin-left: 20px;
        padding: 20px 45px 27px 30px;
        border-radius: 2px;
        background-color: #f7f7f7;
        p {
          text-align: center;
          margin-bottom: 10px;
        }
        .out-content {
          div {
            height: 8px;
            display: inline-block;
          }
        }
        .number {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .markets-header-content {
    width: 1200px;
    height: 194px;
    margin: 0 auto;
    display: flex;
    padding-bottom: 25px;

    .market-header-item-v2 {
      width: 230px;
      height: 140px;
      margin: 20px 13px 0 0;
      padding: 10px 15px;
      border-radius: 3px;
      background-color: #fff;
      border: solid 1px #fff;
      // cursor: pointer;
      .title {
        margin-bottom: 15px;
      }
      .first-content {
        // background: #c2c7d0;
        width: 260px;
        height: 26px;
        span {
          display: inline-block;
          background: #c2c7d0;
          height: 25px;
          border-radius: 10px;
        }
        .first-left {
          width: 100px;

          float: left;
        }
        .first-right {
          width: 67px;
          float: right;
        }
      }
      .second-content {
        background: #c2c7d0;
        width: 160px;
        height: 14px;
        border-radius: 10px;
        margin-top: 10px;
      }
      .third-content {
        background: #c2c7d0;
        width: 160px;
        height: 14px;
        margin-top: 10px;
        border-radius: 10px;
      }
      // &:hover {
      //   border: solid 1px #e81b40;
      // }
      &:last-child {
        margin-right: 0;
      }
      .symbol-content {
        height: 26px;
        // cursor: pointer;
        .symbol {
          float: left;
          font-size: 16px;
          line-height: 24px;
          color: #112132;
          .coin-url {
            width: 24px;
            height: 24px;
            vertical-align: middle;
          }
          .coin-name {
            vertical-align: middle;
            margin-left: 5px;
          }
        }
        .quote-change {
          height: 24px;
          // line-height: 13px;
          display: inline-block;
          float: right;
          font-size: 12px;
          background: rgba(127, 132, 144, 0.3);
          color: #fff;
          padding: 3px 9px;
          border-radius: 3px;
          min-width: 60px;
          &.green {
            background: rgba(3, 173, 121, 1);
            color: #fff;
            padding: 3px 9px;
          }
          &.red {
            background: rgba(198, 27, 61, 1);
            color: #fff;
            padding: 3px 9px;
          }
          .plus-icon {
            margin-right: -2px;
          }
        }
      }
      .price-content {
        text-align: left;
        .price {
          color: #112132;
          // &.green {
          //   color: #22ad64;
          //   font-size: 16px;
          // }
          // &.red {
          //   color: #e0403f;
          //   font-size: 16px;
          // }
        }
        .fiat-price {
          padding-top: 10px;
          color: #7f8490;
          font-size: 12px;
          &.V2 {
            display: block;
          }
        }
      }
    }
  }
  .fiv-svg {
    position: relative;
    i {
      z-index: 900;
      position: absolute;
      font-size: 60px;
      top: -2px;
      left: 110px;
    }
  }

  // font-family: PingFangSC, BinancePlex, Arial, sans-serif !important;
  .is-resiger {
    height: 340px;
  }
  .no-resiger {
    height: 340px;
  }
  user-select: none;
  padding: 0;
  // min-height: 2000px;
  flex: 1;
  .swiper-img {
    width: 168px;
    height: 108px;
    text-align: left;
    border-radius: 6px;
    vertical-align: top;
  }
  .swiper-text {
    width: 190px;
    display: inline-block;
    vertical-align: top;
    margin-left: 19px;
    height: 108px;
    text-align: left;
    .title {
      width: 182px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      -webkit-line-clamp: 1;
      color: #697786;
    }
    .content {
      width: 182px;
      display: -webkit-box;
      display: -moz-box;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
  .home-content-one {
    // background: url("../../assets/img/home/home-bg.png") 0 0 repeat;
    background: url("../../assets/img/bv/former-bg1.png") 0 0 repeat;
    background-size: 100% 100%;
    padding-bottom: 30px;
    min-height: 350px;

    .first-lable {
      color: white;
      font-size: 38px;
      font-weight: bolder;
      text-align: center;
      padding-top: 80px;
    }
    .second-lable {
      color: #d9dad8;
      font-size: 14px;
      text-align: center;
      padding-top: 15px;
    }
  }
  .market-trend-title {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    color: #112132;
    margin-top: 30px;
  }
  .home-content-two {
    min-height: 788px;
    background: #fff;
    padding-bottom: 30px;

    &.V2 {
      min-height: 588px;
    }
    .swiper-sider-title {
      width: 1200px;
      margin: 0 auto;
      font-size: 18px;
      font-weight: 500;
      padding-top: 40px;

      color: #112132;
    }
    .swiper-sider-content {
      display: flex;
      justify-content: center;
      padding-top: 10px;
      position: static;
      .swiper-next {
        cursor: pointer;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        z-index: 99;
        width: 30px;
        height: 144px;
        top: 0px;
        outline: none;
        position: absolute;
        // background-color: rgb(0, 0, 0, 0.4);
        right: 0px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        img {
          height: 28px;
          width: 28px;
        }
      }
      .swiper-prev {
        cursor: pointer;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        z-index: 99;
        width: 30px;
        height: 144px;
        top: 0px;
        outline: none;
        position: absolute;
        // background-color: rgb(0, 0, 0, 0.4);
        left: 0px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        img {
          height: 28px;
          width: 28px;
        }
      }
      .swiper-content {
        display: flex;
        position: relative;
        align-items: center;
        min-height: 144px;
        width: 1200px;
        .button-prev {
          margin-right: 13px;
        }
        i {
          &:focus {
            border: 0px;
            outline: 0px;
          }
        }
        .iconfont {
          font-size: 28px;
          color: #747a7e;
          outline: none;
          cursor: pointer;
          border: 0px;
        }
        .swiper-container {
          width: 1200px;
          height: 144px;
          position: relative;
          border-radius: 6px;
          .swiper-wrapper {
            text-align: center !important;
          }
          .swiper-item {
            cursor: pointer;
          }
          img {
            object-fit: cover;
            // width: 282px;
            height: 108px;
            // opacity: 0.7;
            box-sizing: border-box;
            opacity: 1;
            &:hover {
              opacity: 1;
            }
          }
          .swiper-slide {
            padding: 16px 0;
            margin-left: 0px !important;
          }
        }
      }
      .old-img {
        display: flex;
        width: 1200px;
        ul {
          // width: 1110px;
          &:last-child {
            li img {
              margin-right: 0;
            }
          }
          li {
            cursor: pointer;
            // width: 282px;
            // padding-right: 20px;
          }
        }

        img {
          width: 282px;
          height: 144px;
          margin-right: 24px;
          border-radius: 5px;
        }
      }
    }
    .swiper-pagination {
      position: relative !important;
      margin-top: 15px;
      span {
        margin: 0px 10px;
        // color: #9aa1b1;
      }
    }

    .tips-massage-content {
      padding: 20px 0px;
      font-size: 14px;
      color: #080c34;
      display: flex;
      align-items: center;
      justify-content: center;
      .left {
        // min-width: 265px;
        // width: auto;
        i {
          font-size: 16px;
          color: #080c34;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      .left-driver {
        width: 1px;
        height: 18px;
        margin: 2px 15px 0;
        background-color: #c2c7d0;
      }

      .bottom {
        // min-width: 200px;
        // margin-left: 15px;
        text-align: left;
      }
      .article-title {
        color: #080c34 !important;
        min-width: 250px;
        display: inline-block;
        vertical-align: middle;
        max-width: 550px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .right-driver {
        width: 1px;
        height: 18px;
        margin: 2px 20px 0 15px;
        background-color: #c2c7d0;
      }
      .right {
        cursor: pointer;
        i {
          font-size: 14px;
          color: #9aa1b1;
          margin-top: 2px;
          &:hover {
            color: #3a7dff;
          }
        }
        &:hover {
          color: #3a7dff;
          i {
            color: #3a7dff;
          }
        }
      }
    }
    .quotes-content {
      width: 1200px;
      // height: 572px;
      margin: 0 auto;
      padding: 20px 0px 0px;
      background-color: #ffffff;
      .el-tabs__active-bar {
        background-color: #f7b239;
        height: 3px;
      }
      .table-title {
        color: #112132;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .left-symbol {
        color: #354052;
        font-size: 14px;
        font-weight: 500;
      }
      .left-row {
        color: #354052;
        font-size: 14px;
        font-weight: 600;
      }

      .el-table {
        min-height: 430px !important;
        tr {
          cursor: pointer;
        }
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #f1f5f6;
      }
      .more-info {
        display: flex;
        align-items: center;
        height: 72px;
        justify-content: center;

        text-align: center;
        font-size: 16px;
        color: #7f8490;
        .more-title {
          width: 102px;
        }
        .more {
          cursor: pointer;
          &:hover {
            color: #3a7dff;
            span {
              color: #3a7dff;
            }
          }
        }

        span {
          color: #9aa1b1;
        }
        .icongengduo {
          font-size: 14px;
          // margin-top: 4px;
        }
      }

      .el-table {
        min-height: 350px;
        .el-icon-star-off {
          cursor: pointer;
        }
      }
      .el-table::before {
        height: 0;
      }
    }
  }

  .el-table {
    padding: 0px 0px 0 10px;
    .el-table__header {
      thead {
        .cell {
          font-size: 14px;
          color: #7f8490;
        }
      }
    }
    tbody {
      .operate {
        width: 70px;
        height: 28px;
        padding: 4px 0px;
        text-align: center;
        border-radius: 2px !important;
        background-color: #3a7dff;
        border: 1px solid #3a7dff;
        font-weight: 400 !important;
        font-size: 14px;
        box-sizing: border-box;
        span {
          font-weight: 400 !important;
          color: #ffffff !important;
        }
      }
      .mrket-img {
        height: 30px;
      }

      .cell {
        .coin-content {
          display: inline-block;
          .coin-img {
            width: 30px;
            height: 30px;
            vertical-align: middle;
          }
        }
        .red {
          color: #c61b3d;
        }
        .green {
          color: #03ad79;
        }
        .active {
          color: #c61b3d;
          font-size: 18px;
          margin-top: 4px;
          margin-left: -3px;
        }
        .el-icon-star-on,
        .el-icon-star-off {
          text-align: left;
          cursor: pointer;
          margin-right: 10px;
        }
        p,
        span,
        code {
          color: #1f2533;
          // font-weight: 600;
        }
        .el-table__expand-icon {
          display: none;
        }
        .dark-color {
          color: #9aa1b1;
          font-size: 12px;
        }
      }
    }

    .table-expand {
      min-height: 228px;
      padding: 10px;
      display: flex;
      cursor: auto;
      .expand-detail {
        flex: 1;
        display: inline-block;
        margin-right: 32px;
      }
      .el-form {
        display: inline-block;
        width: 300px;
        .el-form-item {
          margin-bottom: 0px;
          padding: 0 20px;
          .el-form-item__label {
            padding: 0;
          }
          &:nth-child(2n + 1) {
            background-color: #f5f5f7;
          }
        }
        .el-form-item__content {
          display: inline-block;
          float: right;
        }
      }
    }
  }
  .el-tabs__item {
    font-size: 16px;
    margin-bottom: 10px;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #bec1c8 !important;
    font-weight: 500;
  }
}
</style>
